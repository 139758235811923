import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../../../../../_shared/Modal';
import RequestErrorModal from '../../../../../../../_shared/RequestErrorModal';
import UploadForm from './components/UploadForm';
import LoadingCircle from '../../../../../../../_shared/LoadingCircle';

import { uploadDocument } from '../../../../../../../../api/documents';

import classes from './index.module.scss';

/**
 * Stages:
 * -1: upload error
 *  0: upload form
 *  1: loading circle
 */

type Props = {
    homelessId: string;
    open: boolean;
    onClose: () => void;
};

function UploadModal({ homelessId, open, onClose }: Props): JSX.Element {
    const [errors, setErrors] = useState([]);
    const [stage, setStage] = useState(0);
    const [cachedForm, cacheForm] = useState<{}>({});

    const conditionalOnCloseCallback = useCallback(() => {
        if (stage !== 1) {
            setStage(0);
            onClose();
        }
    }, [stage, onClose]);

    const uploadFile = useCallback(
        ({ fileName, file }: { fileName: string; file: File }) => {
            setStage(1);
            cacheForm({ fileName, file });

            uploadDocument(homelessId, fileName, file)
                .then((res) => res.json())
                .then((resJson) => {
                    if (resJson.errors) {
                        setErrors(resJson.errors);
                        return setStage(0);
                    }

                    return onClose();
                })
                .catch(() => {
                    // setErrors;
                    setStage(-1);
                });
        },
        [homelessId, onClose]
    );

    return (
        <Modal open={open} onClose={conditionalOnCloseCallback}>
            <RequestErrorModal
                open={stage === -1}
                onClose={conditionalOnCloseCallback}
                message="Error occurred while uploading your document."
                retryFunc={() =>
                    uploadFile(cachedForm as { fileName: string; file: File })
                }
            />
            <div className={classes.modal}>
                {stage === 0 && (
                    <UploadForm
                        onCancel={onClose}
                        onSubmit={uploadFile}
                        errors={errors}
                        initialState={
                            cachedForm as { fileName: string; file: File }
                        }
                    />
                )}
                {stage === 1 && (
                    <LoadingCircle>Uploading Document</LoadingCircle>
                )}
            </div>
        </Modal>
    );
}

const mapStateToProps = (state: {
    homeless: { homelessInfo: { id: string } };
}) => ({
    homelessId: state.homeless.homelessInfo.id
});

export default connect(mapStateToProps)(UploadModal);
