import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';
import Input from './components/Input';
import Notice from './components/Notice';

import {
    formatDate,
    formatTime,
    groupMessagesByDate,
    renderTimeStamp,
    scrollToBottom
} from './utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        height: 'calc(100vh - 500px)',
        overflow: 'auto',
        padding: '20px 20px 5px 20px',
        '& div': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0'
    },
    bubble: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '60%',
        width: 'fit-content',
        padding: '10px 16px',
        margin: '2px 0',
        borderRadius: '15px'
    },
    replyBubble: {
        alignSelf: 'flex-end',
        color: '#FFFFFF',
        backgroundColor: '#7279E8'
    },
    memberBubble: {
        alignItems: 'flex-start',
        color: '#1F1F51',
        backgroundColor: '#EFF0FF'
    },
    messageTime: {
        fontSize: '12px',
        lineHeight: '22px',
        color: '#A6A6BF',
        marginBottom: '15px',
        scrollMargin: '20px'
    },
    dateHeading: {
        margin: '30px 0',
        '& span': {
            margin: 'auto',
            fontWeight: '800',
            fontSize: '18px',
            lineHeight: '20px',
            color: '#1F1F51'
        }
    },
    iconContainer: {
        marginRight: '10px',
        '& i': {
            fontSize: '22px',
            color: '#F23D3D',
            cursor: 'pointer'
        }
    }
}));

const MessageMember = ({
    chatMessages,
    closeView,
    handleEllipsisClick,
    handleResendPopup,
    holder,
    isFetchingChatMessages,
    isResendingMessage,
    LoadingCircle,
    message,
    setMessage,
    submitMessage
}) => {
    const classes = useStyles();
    const messagesEndRef = useRef(null);
    const name = holder.name.split(' ')[0];

    chatMessages.sort((a, b) => new Date(a.date) - new Date(b.date));
    const messagesByDate = groupMessagesByDate(chatMessages);

    const [messageId, setMessageId] = useState(null);

    const renderMessagesByDate = () => {
        return Object.keys(messagesByDate).map((date) => {
            return (
                <div key={date} className={classes.messages}>
                    <div className={classes.dateHeading}>
                        <span>{formatDate(date)}</span>
                    </div>
                    {messagesByDate[date].messages.map((message, index) => {
                        const nextMessage =
                            messagesByDate[date].messages[index + 1];
                        const showTime = renderTimeStamp(message, nextMessage);

                        return (
                            <React.Fragment key={message.id}>
                                <div
                                    style={
                                        message.type === 'PendingEncouragement'
                                            ? {
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justifyContent: 'flex-end',
                                                  marginTop: '5px'
                                              }
                                            : null
                                    }
                                >
                                    {message.type ===
                                        'PendingEncouragement' && (
                                        <span className={classes.iconContainer}>
                                            <i
                                                className="far fa-exclamation-circle"
                                                onClick={() => {
                                                    handleResendPopup(
                                                        message.id,
                                                        message.message
                                                    );

                                                    setMessageId(message.id);
                                                }}
                                            />
                                        </span>
                                    )}
                                    <span
                                        className={`${classes.bubble} ${
                                            message.recipient_type ===
                                            'Homeless'
                                                ? classes.replyBubble
                                                : classes.memberBubble
                                        }`}
                                        style={
                                            showTime &&
                                            message.recipient_type ===
                                                `Homeless`
                                                ? {
                                                      borderRadius:
                                                          '15px 15px 0 15px'
                                                  }
                                                : showTime &&
                                                  message.recipient_type ===
                                                      `User`
                                                ? {
                                                      borderRadius:
                                                          '15px 15px 15px 0'
                                                  }
                                                : null
                                        }
                                    >
                                        <span>
                                            {message.id === messageId &&
                                            isResendingMessage
                                                ? ` `
                                                : message.message}
                                        </span>
                                    </span>
                                </div>
                                {showTime && (
                                    <span
                                        ref={messagesEndRef}
                                        className={classes.messageTime}
                                        style={
                                            message.recipient_type ===
                                            `Homeless`
                                                ? {
                                                      alignSelf: 'flex-end'
                                                  }
                                                : {
                                                      alignSelf: 'flex-start'
                                                  }
                                        }
                                    >
                                        {formatTime(message.date)}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        });
    };

    useEffect(() => {
        scrollToBottom(messagesEndRef);
    }, [chatMessages]);

    return (
        <>
            <Header
                closeView={closeView}
                handleEllipsisClick={() => handleEllipsisClick(name)}
                memberName={name}
                memberPhoto={holder.photo}
            />
            <div className={classes.messageContainer}>
                <Notice />
                {isFetchingChatMessages ? (
                    <LoadingCircle />
                ) : (
                    renderMessagesByDate(messagesByDate)
                )}
            </div>
            <Input
                message={message}
                setMessage={setMessage}
                scrollToBottom={() => scrollToBottom(messagesEndRef)}
                submitMessage={submitMessage}
            />
        </>
    );
};

MessageMember.propTypes = {
    chatMessages: PropTypes.array.isRequired,
    closeView: PropTypes.func.isRequired,
    handleEllipsisClick: PropTypes.func.isRequired,
    handleResendPopup: PropTypes.func.isRequired,
    holder: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    submitMessage: PropTypes.func.isRequired
};

export default MessageMember;
