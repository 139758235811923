import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonCardContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%'
        },
        buttonTitle: {
            color: ' #1F2051',
            fontSize: '12px',
            fontWeight: 300,
            letterSpacing: '0.15em',
            textTransform: 'uppercase'
        },
        button: {
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            color: '#7378E8',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            padding: '16px'
        }
    })
);

type CardButtonProps = {
    icon: string;
    infoModal?: boolean;
    infoType: string | null;
    inputType: string;
    setSummaryState?: any;
};

const CardButton: React.FC<CardButtonProps> = ({
    icon,
    inputType,
    setSummaryState
}) => {
    const classes = useStyles();

    const inputTypeToAddInfoType = {
        Email: 'contact_info',
        'Phone Number': 'contact_info',
        'Date of Birth': 'birthday',
        'Full Name': 'full_name',
        'Insurance ID': 'member_id'
    } as {
        [key: string]: string;
    };

    const handleAddInfoClick = () => {
        const mappedInfoType = inputTypeToAddInfoType[inputType];

        if (mappedInfoType && setSummaryState) {
            setSummaryState((prevState: any) => ({
                ...prevState,
                infoModal: true,
                infoType: mappedInfoType
            }));
        }
    };

    return (
        <div className={classes.buttonCardContainer}>
            <span className={classes.buttonTitle}>{inputType}</span>
            <Button className={classes.button} onClick={handleAddInfoClick}>
                <span>Add {inputType}</span>
                <i className={`fal ${icon}`} />
            </Button>
        </div>
    );
};

export default CardButton;
