import Card from '../../../../../_shared/Card';
import SDOHStatus from 'assets/sdoh-ph.png';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: '#EFEFFC !important'
    },
    cardBody: {
        position: 'absolute',
        bottom: '-10%',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        width: '70%',
        margin: 'auto'
    },
    cardBodyHeader: {
        fontWeight: 800,
        fontSize: '20px',
        margin: 'auto',
        textAlign: 'center'
    },
    cardBodyContent: {
        fontSize: '14px',
        textAlign: 'center'
    },
    buttonContainer: {
        paddingTop: '16px'
    },
    captureButton: {
        fontWeight: 700,
        fontSize: '22px',
        color: '#7378E8',
        padding: '10px 20px',
        borderRadius: '36px',
        textTransform: 'none'
    },
    gradient: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        margin: '30px 0 40px 0',
        paddingBottom: '20px',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'inline-block',
            background:
                'linear-gradient(180deg, rgba(240, 240, 255, 0) 9.09%, #F0F0FF 52.58%, #F0F0FF 100%)'
        },
        '& img': {
            display: 'block',
            width: '300px'
        }
    }
}));

type Props = {
    exited: boolean;
    name: string;
    openSDOH: () => void;
};

const EmptySdohStatus: React.FC<Props> = ({ exited, name, openSDOH }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <div className={classes.gradient}>
                <img src={SDOHStatus} alt="SDOH Status" />
                <div className={classes.cardBody}>
                    {!exited ? (
                        <>
                            <div className={classes.cardBodyHeader}>
                                Record {name}'s Progress
                            </div>
                            <div className={classes.cardBodyContent}>
                                Log {name}'s progress towards housing and health
                                goals, and track their progress over time.
                            </div>
                            <div className={classes.buttonContainer}>
                                <Button
                                    component="span"
                                    className={classes.captureButton}
                                    onClick={() => openSDOH()}
                                >
                                    Capture first snapshot
                                </Button>
                            </div>
                        </>
                    ) : (
                        <span
                            className={classes.cardBodyHeader}
                            style={{ marginBottom: '64px' }}
                        >
                            No Progress Recorded for {name}
                        </span>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default EmptySdohStatus;
