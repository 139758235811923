import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import * as api from '../../api/touchpoints';

function getHomelessTouchpoints() {
    return {
        type: CONSTANTS.GET_HOMELESS_TOUCHPOINTS
    };
}

function getOrganizationTouchpoints() {
    return {
        type: CONSTANTS.GET_ORGANIZATION_TOUCHPOINTS
    };
}

export function getV3HomelessTouchpoints(_token: string, homeless_id: number) {
    return (dispatch: Function) => {
        dispatch(getHomelessTouchpoints());
        return api
            .fetchV3HomelessTouchpoints(homeless_id)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { touchpoints: any[] }) => {
                dispatch(setHomelessTouchpoints(responseJson.touchpoints));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function getV3OrganizationTouchpoints() {
    return (dispatch: Function) => {
        dispatch(getOrganizationTouchpoints());
        return api
            .fetchV3OrganizationTouchpoints()
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { touchpoints: any[] }) => {
                dispatch(setOrganizationTouchpoints(responseJson.touchpoints));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

// TODO: Fix any types
function setHomelessTouchpoints(touchpoints: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_TOUCHPOINTS,
        payload: touchpoints
    };
}

// TODO: Fix any types
function setOrganizationTouchpoints(touchpoints: any) {
    return {
        type: CONSTANTS.SET_ORGANIZATION_TOUCHPOINTS,
        payload: touchpoints
    };
}
