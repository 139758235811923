import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import CTAButton1 from "../../../../../_shared/buttons/CTAButton1";
import Input from '../../../../../_shared/forms/Input.tsx';
import Label from "../../../../../_shared/forms/Label";

import classes from "./Form.module.scss";

export default function RedemptionForm({
  title,
  items,
  instructions,
  disclaimer,
  onSubmit,
  initialState,
}) {
  const [formAnswers, setFormAnswers] = useState(initialState || {});

  // check that all required fields have a value
  const isComplete = useMemo(() => {
    return items.every((i) => {
      if (i.is_optional) return true;

      return !!formAnswers[i.title];
    });
  }, [formAnswers, items]);

  const onSubmitCallback = (e) => {
    e.preventDefault();

    if (!isComplete) return;

    onSubmit({
      ...formAnswers,
    });
  };

  const onInputChange = (e) => {
    setFormAnswers({
      ...formAnswers,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      className={classes.form}
      onSubmit={onSubmitCallback}
      autoComplete="off"
    >
      <div className={classes.disclaimer}>{disclaimer}</div>
      <div className={classes.instructions}>{instructions}</div>
      <div className={classes.formItems}>
        <div className={classes.formTitle}>{title}</div>
        {items.map((item) => {
          return (
            <div key={item.title} className={classes.formGroup}>
              <Label htmlFor={item.title} className={classes.itemLabel}>
                {item.title + (item.is_optional ? "" : "*")}
              </Label>
              {/* If item options exist then make radio buttons out of them */}
              {item.options.length ? (
                <div className={classes.radioGroup}>
                  {item.options.map((option) => (
                    <Label
                      htmlFor={option}
                      style={{ whiteSpace: "nowrap" }}
                      key={option}
                    >
                      <Input
                        type="radio"
                        name={item.title}
                        id={option}
                        value={option}
                        checked={formAnswers[item.title] === option}
                        onChange={onInputChange}
                      />
                      {option}
                    </Label>
                  ))}
                </div>
              ) : (
                <Input
                  name={item.title}
                  id={item.title}
                  required={!item.is_optional}
                  value={formAnswers[item.title] || ""}
                  onChange={onInputChange}
                />
              )}
            </div>
          );
        })}
        <CTAButton1 type="submit" data-testid="checkoutButton">
          Checkout
        </CTAButton1>
      </div>
    </form>
  );
}

RedemptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  instructions: PropTypes.string.isRequired,
  disclaimer: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

RedemptionForm.defaultProps = {
  title: "Account Information:",
};
