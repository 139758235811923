import * as _Jimp from 'jimp';
import { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import BlurModal from './BlurModal';

import { Button, FormControlLabel, Switch } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        webcamContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 20px'
        },
        closeBtnContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 0',
            width: '98%'
        },
        closeIcon: {
            fontSize: '24px'
        },
        title: {
            fontSize: '24px',
            fontWeight: 800,
            padding: '0px 16px'
        },
        webcam: {
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            padding: '10px 0',
            width: '100%',
            '& video': {
                border: '8px solid rgba(227, 227, 250, 0.5)',
                borderRadius: '16px'
            }
        },
        btnContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            width: '100%',
            padding: '10px'
        },
        btn: {
            color: '#FFFFFF',
            borderRadius: '36px',
            fontSize: '18px',
            fontWeight: 800,
            padding: '5px 20px',
            textTransform: 'none'
        },
        useBtn: {
            backgroundColor: '#7378E8',
            '&:hover': {
                backgroundColor: '#c3c5ef'
            }
        },
        retakeBtn: {
            color: '#7378E8'
        },
        preview: {
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            padding: '10px 0',
            width: '100%',
            '& img': {
                border: '8px solid rgba(227, 227, 250, 0.5)',
                borderRadius: '16px'
            }
        },
        uploadContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.25)',
            padding: '20px',
            '& input[type=file]::-webkit-file-upload-button': {
                display: 'none'
            },
            '& input[type=file]': {
                marginTop: '10px',
                textAlignLast: 'center',
                color: 'transparent',
                display: 'none'
            }
        }
    })
);

type WebcamContentProps = {
    handleModalClick: () => void;
    setProfilePicture: (file: File) => void;
    setShowWebcam: (value: boolean) => void;
};

const WebcamContent: React.FC<WebcamContentProps> = ({
    handleModalClick,
    setProfilePicture,
    setShowWebcam
}) => {
    const classes = useStyles();
    // @ts-ignore: Can't define Jimp type
    // eslint-disable-next-line no-restricted-globals
    const Jimp = typeof self !== 'undefined' ? self.Jimp : _Jimp;
    const webcamRef = useRef<Webcam>(null);

    const [blurredData, setBlurredData] = useState(null);
    const [filterType, setFilterType] = useState<boolean | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const dataURItoBlob = useCallback((dataURI: string) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }, []);

    const blurPhoto = useCallback(
        async (imageSrc: string) => {
            try {
                const base64Data = imageSrc.split(',')[1]; // Extracting base64 data

                const buffer = Buffer.from(base64Data, 'base64'); // Convert base64 to buffer

                const blurredImg = await Jimp.read(buffer);
                blurredImg.blur(10);

                const resultBase64 = await blurredImg.getBase64Async(
                    Jimp.MIME_JPEG
                );
                setBlurredData(resultBase64);
            } catch (error) {
                console.error(
                    'Error occurred while blurring the image:',
                    error
                );
            }
        },
        [Jimp, setBlurredData]
    );

    const capture = useCallback(async () => {
        if (filterType) {
            try {
                const imageSrc = webcamRef.current
                    ? webcamRef.current.getScreenshot()
                    : null;

                if (imageSrc) {
                    await blurPhoto(imageSrc);
                    setImageSrc(imageSrc);
                }
            } catch (error) {
                console.error('Error capturing photo or blurring:', error);
            }
        } else {
            const imageSrc = webcamRef.current
                ? webcamRef.current.getScreenshot()
                : null;

            if (imageSrc) {
                setImageSrc(imageSrc);
            }
        }
    }, [blurPhoto, filterType, webcamRef]);

    const handleCloseClick = () => {
        handleModalClick();
        setShowWebcam(false);
    };

    // test this
    const handleUseClick = () => {
        const pictureToSet = filterType ? blurredData : imageSrc;

        if (!pictureToSet) {
            return;
        } else {
            // setProfilePicture(dataURItoBlob(pictureToSet));
            const file = new File(
                [dataURItoBlob(pictureToSet)],
                'profilePicture.jpg',
                { type: 'image/jpeg' }
            );

            setProfilePicture(file);
        }

        handleModalClick();
        setShowWebcam(false);
    };

    const handleRetakeClick = () => {
        setImageSrc(null);
    };

    const videoConstraints = {
        width: 1080,
        height: 1080,
        facingMode: 'user'
    };

    return (
        <>
            {filterType === null && (
                <div className={classes.uploadContainer}>
                    <BlurModal setFilterType={setFilterType} />
                </div>
            )}
            {filterType !== null && (
                <div className={classes.webcamContainer}>
                    <div className={classes.closeBtnContainer}>
                        <span className={classes.title}>Webcam Capture</span>
                        <Button onClick={() => handleCloseClick()}>
                            <i
                                className={`far fa-times ${classes.closeIcon}`}
                            />
                        </Button>
                    </div>
                    {!imageSrc && (
                        <div className={classes.webcam}>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={400}
                                videoConstraints={videoConstraints}
                            />
                        </div>
                    )}
                    {imageSrc && (
                        <div className={classes.preview}>
                            <img
                                src={
                                    !filterType || blurredData === null
                                        ? URL.createObjectURL(
                                              dataURItoBlob(imageSrc)
                                          )
                                        : URL.createObjectURL(
                                              dataURItoBlob(blurredData)
                                          )
                                }
                                style={{ width: '100%', height: 'auto' }}
                                alt="webcam capture"
                            />
                        </div>
                    )}
                    <div className={classes.btnContainer}>
                        {!imageSrc && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={filterType}
                                        onChange={() =>
                                            setFilterType(!filterType)
                                        }
                                        name="blurred"
                                    />
                                }
                                label="Blurred"
                            />
                        )}
                        {!imageSrc ? (
                            <Button
                                className={`${classes.btn} ${classes.useBtn}`}
                                onClick={capture}
                            >
                                Capture photo
                            </Button>
                        ) : (
                            <>
                                <Button
                                    className={`${classes.btn} ${classes.useBtn}`}
                                    onClick={() => handleUseClick()}
                                >
                                    Use as profile picture
                                </Button>
                                <Button
                                    className={`${classes.btn} ${classes.retakeBtn}`}
                                    onClick={() => handleRetakeClick()}
                                >
                                    Retake
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default WebcamContent;
