import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    CatchUpItems,
    DebitCard,
    DebitCardOptions,
    Homeless,
    SupplementalQuestions,
    TouchpointAnswers,
    TouchpointQuestions
} from 'types';

import Complete from './components/Complete';
import Ellipse from './components/Ellipse';
import Summary from './components/Summary';
import Welcome from './components/Welcome';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        catchupContainer: {
            position: 'absolute',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#F5F2FF',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
        },
        closeButton: {
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 100,
            fontWeight: 400,
            fontSize: '2.75rem',
            color: '#999DFF'
        },
        title: {
            fontSize: '1.5rem',
            fontWeight: 500,
            marginBottom: 10,
            textAlign: 'center'
        },
        description: {
            fontSize: '1rem',
            textAlign: 'center'
        }
    })
);

type CatchupProps = {
    catchUpItemIndex: number;
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    getApiErrorResponse: { message: string } | {};
    holder: Homeless;
    isEditingGoalAction: boolean;
    items: CatchUpItems[];
    maxBonusAmount: number;
    quickCatchUp: boolean;
    remainingBonusAmount: number;
    setAddStatus: (value: boolean) => void;
    setCatchUpItemIndex: (index: number) => void;
    setQuickCatchUp: (value: boolean) => void;
    setShowCatchUp: (showCatchUp: boolean) => void;
    supplementalQuestions: SupplementalQuestions[];
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    updateGoalActionStatus: (
        id: number,
        status: 'cancelled' | 'completed'
    ) => void;
    updateHomelessInfoField: (field: string, value: string) => void;
    updateProfileCompletedItems: (type: string) => void;
};

type HistoryStateType = {
    from?: string;
    id?: number;
};

const Catchup: React.FC<CatchupProps> = ({
    catchUpItemIndex,
    debitCardOptions,
    debitInfo,
    getApiErrorResponse,
    holder,
    isEditingGoalAction,
    items,
    maxBonusAmount,
    quickCatchUp,
    remainingBonusAmount,
    setAddStatus,
    setCatchUpItemIndex,
    setQuickCatchUp,
    setShowCatchUp,
    supplementalQuestions,
    touchpointAnswers,
    touchpointQuestions,
    updateGoalActionStatus,
    updateHomelessInfoField,
    updateProfileCompletedItems
}) => {
    const classes = useStyles();
    const history = useHistory();
    const itemLengthRef = useRef<number>(items.length);

    const { name } = holder;

    const [step, setStep] = useState(0);

    const handleCloseClick = () => {
        setCatchUpItemIndex(0);
        setQuickCatchUp(false);
        setShowCatchUp(false);
    };

    const handleProceedClick = () => {
        if (step !== 1) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const renderWelcome = () => {
        if (step === 0 && items.length !== 0) {
            return <Welcome name={name} />;
        }
    };

    const summaryProps = {
        catchUpItemIndex,
        catchUpItemsLength: itemLengthRef.current,
        debitInfo,
        debitCardOptions,
        getApiErrorResponse,
        holder,
        isEditingGoalAction,
        items,
        maxBonusAmount,
        name,
        quickCatchUp,
        remainingBonusAmount,
        setAddStatus,
        setCatchUpItemIndex,
        step,
        supplementalQuestions,
        touchpointAnswers,
        touchpointQuestions,
        updateGoalActionStatus,
        updateHomelessInfoField,
        updateProfileCompletedItems
    };

    useEffect(() => {
        if (
            history.location.state &&
            (history.location.state as HistoryStateType).from === 'catchUp'
        ) {
            setStep(1);
        }
    }, [history.location.state]);

    return (
        <Fade in timeout={2000}>
            <div
                className={classes.catchupContainer}
                onClick={handleProceedClick}
            >
                {items.length !== 0 && (
                    <Button className={classes.closeButton}>
                        <i
                            className="far fa-times"
                            onClick={handleCloseClick}
                        />
                    </Button>
                )}
                <Ellipse position="top-left" />
                <Ellipse position="bottom-right" />
                {renderWelcome()}
                {step === 1 &&
                    ((!quickCatchUp && items.length > 0) ||
                        (quickCatchUp &&
                            catchUpItemIndex !== items.length)) && (
                        <Summary {...summaryProps} />
                    )}
                {((!quickCatchUp && items.length === 0) ||
                    (quickCatchUp && catchUpItemIndex === items.length)) && (
                    <Complete
                        setCatchUpItemIndex={setCatchUpItemIndex}
                        setShowCatchUp={setShowCatchUp}
                        setQuickCatchUp={setQuickCatchUp}
                    />
                )}
            </div>
        </Fade>
    );
};

export default Catchup;
