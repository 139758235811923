import classes from './CloseButton.module.scss';

type CloseButtonProps = {
    onClick: () => void;
    small?: boolean;
    className?: string;
};

export default function CloseButton({
    onClick,
    small,
    className,
    ...props
}: CloseButtonProps): JSX.Element {
    const classNames = [classes.closeButton, className];

    if (small) {
        classNames.push(classes.small);
    }

    return (
        <div
            className={classNames.join(' ')}
            onClick={onClick}
            role="button"
            aria-label="close view"
            {...props}
        >
            <i className="far fa-times" />
        </div>
    );
}
