import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        navContainer: {
            display: 'flex',
            gap: '32px',
            margin: 'auto',
            overflow: 'auto',
            padding: '20px',
            width: 'fit-content',
            '@media (max-width: 1202px)': {
                width: 'auto'
            }
        },
        navItem: {
            position: 'relative',
            flex: '0 0 auto',
            alignSelf: 'flex-end',
            color: '#1F2152',
            cursor: 'pointer',
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '34px',
            opacity: '0.5',
            transition: 'all 0.2s ease-in-out',
            zIndex: 1
        },
        active: {
            cursor: 'default',
            fontSize: '32px',
            opacity: '1',
            lineHeight: '39px'
        },
        underline: {
            position: 'absolute',
            bottom: '-2px',
            left: 0,
            background: '#EAEBFB',
            borderRadius: '5px',
            height: '5px',
            width: '100%',
            zIndex: -1
        }
    })
);

type NavSelectorProps = {
    active: string;
    empty?: boolean;
    getDebitCardInfo?: (id: number) => void;
    holderID?: number;
    navItems: (string | null)[];
    setActive: (item: string) => void;
    style?: React.CSSProperties;
};

const NavSelector: React.FC<NavSelectorProps> = ({
    active,
    navItems,
    setActive,
    ...rest
}) => {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const firstRenderRef = useRef(true);

    const { empty, getDebitCardInfo, holderID, style } = rest;

    const handleActive = (item: string) => {
        if (empty) {
            return;
        }

        setActive(item);

        ReactGA.event({
            category: 'Navigation',
            action: 'Clicked Nav Item',
            label: item
        });
    };

    const navItemClasses = (item: string) => {
        return classNames(classes.navItem, {
            [classes.active]: active === item
        });
    };

    const renderEmptyStyle = () => {
        if (empty) {
            return {
                cursor: 'default',
                disabled: 'true',
                opacity: '0.5'
            };
        }
    };

    useEffect(() => {
        if (active && containerRef.current) {
            const activeNavItem = document.getElementById(
                `${active.replace(' ', '-')}-nav`
            );

            if (activeNavItem && !firstRenderRef.current) {
                activeNavItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            } else {
                firstRenderRef.current = false;
            }
        }
    }, [active, containerRef]);

    useEffect(() => {
        if (active === 'Manage Funds' && getDebitCardInfo && holderID) {
            getDebitCardInfo(holderID);
        }
    }, [active, getDebitCardInfo, holderID]);

    return (
        <div className={classes.navContainer} style={style}>
            {navItems.map((item, index) => {
                if (item === null) {
                    return null;
                }

                return (
                    <div
                        key={index}
                        className={navItemClasses(item)}
                        id={`${item.replace(' ', '-')}-nav`}
                        onClick={() => handleActive(item)}
                        ref={containerRef}
                        style={renderEmptyStyle()}
                    >
                        {item}
                        {active === item && (
                            <span className={classes.underline} />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default NavSelector;
