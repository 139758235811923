import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    navContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '40px 30px'
    },
    backButton: {
        color: '#7279E8',
        fontSize: '22px',
        lineHeight: '24px',
        fontWeight: '500',
        cursor: 'pointer'
    },
    navTitle: {
        margin: 'auto',
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '24px',
        color: '#1F1F51'
    }
}));

const Nav = ({ closeProgress, holderName }) => {
    const classes = useStyles();

    return (
        <div className={classes.navContainer}>
            <div className={classes.backButton} onClick={() => closeProgress()}>
                <i className="fas fa-chevron-left"></i>
            </div>
            <div className={classes.navTitle}>
                <span>{holderName} Statuses</span>
            </div>
        </div>
    );
};

Nav.propTypes = {
    closeProgress: PropTypes.func.isRequired,
    holderName: PropTypes.string.isRequired
};

export default Nav;
