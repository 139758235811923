import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { DebitCardOptions, Homeless } from 'types';

import AssignCardPopUp from '../holder_profile/profile_2.0/Funds/AssignCardPopUp';
import Card from '../_shared/Card';
import Celebrate from '../../assets/celebrate.png';
import Confetti from '../_shared/Confetti';
import OptionalAction from './OptionalAction';
import PopUp from '../holder_profile/profile_2.0/PopUp';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionCardContainer: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            background: '#FBFBFF',
            maxWidth: '640px',
            margin: '20px auto !important',
            padding: '26px 20px 20px 20px !important',
            zIndex: 3
        },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '800px',
            '@media (max-width: 768px)': {
                flexDirection: 'column'
            }
        },
        btnContainer: {
            padding: '20px 0 40px 0'
        },
        cardTitle: {
            position: 'absolute',
            top: '-56px',
            left: '24px',
            background: '#8882D8',
            borderRadius: '32px',
            color: '#FFFFFF',
            fontFamily: 'Manrope',
            fontSize: '18px',
            fontWeight: 800,
            letterSpacing: '0.94px',
            margin: '2.375rem 0 0',
            padding: '3px 38px',
            textTransform: 'uppercase'
        },
        congratsPage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#2F365B',
            backgroundImage: `url(${Celebrate})`,
            backgroundPosition: 'bottom center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
            zIndex: 2
        },
        memberName: {
            color: '#FBFBFF',
            fontSize: '2.5rem',
            fontWeight: 700,
            margin: '20px',
            padding: '20px',
            width: '60%',
            textAlign: 'center'
        },
        memberPhoto: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            border: '4px solid #FFFFFF',
            borderRadius: '50%',
            height: '320px',
            margin: '30px 0 50px 0',
            overflow: 'hidden',
            width: '320px',
            '& img': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                borderRadius: '50%',
                height: '100%',
                minHeight: '100%',
                minWidth: '100%',
                objectFit: 'cover',
                transform: 'translate(-50%, -50%)',
                width: 'auto'
            }
        },
        profileBtn: {
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            color: '#FBFBFF',
            fontSize: '24px',
            fontWeight: 700,
            padding: '12px 102px',
            textTransform: 'none',
            zIndex: 3,
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            }
        }
    })
);

type CongratsPageProps = {
    debitCardOptions: DebitCardOptions[];
    homelesses: Homeless[];
    intakeId: number;
    memberName: string;
};

const CongratsPage: React.FC<CongratsPageProps> = ({
    debitCardOptions,
    homelesses,
    intakeId,
    memberName
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [assignPopUp, setAssignPopUp] = useState(false);
    const [assignDebit, setAssignDebit] = useState<boolean>(false);
    const [debitType, setDebitType] = useState<'givecard' | 'pex' | null>(null);
    const [popUp, setPopUp] = useState<boolean>(false);

    const handleCardClick = () => {
        if (assignDebit) {
            return;
        }

        if (debitCardOptions.length === 1) {
            setDebitType(debitCardOptions[0].type);
        } else if (debitCardOptions.length > 1) {
            setAssignPopUp(true);
        } else {
            setPopUp(true);
            setDebitType(null);
        }
    };

    const holder = homelesses.find((homeless) => homeless.id === intakeId);

    const possessiveMemberName = (name: string) => {
        if (!name) return '';

        if (name.toLowerCase().endsWith('s')) {
            return `${name}'`;
        } else {
            return `${name}'s`;
        }
    };

    useEffect(() => {
        if (debitType) {
            setAssignPopUp(false);
            setPopUp(true);
        }
    }, [debitType]);

    return (
        <div className={classes.congratsPage}>
            <Confetti stopConfetti={false} />
            <span className={classes.memberName}>
                {possessiveMemberName(memberName)} Membership is now live! 🎉
            </span>
            <span className={classes.memberPhoto}>
                <img src={holder?.photo} alt="member-profile" />
            </span>
            <Card className={classes.actionCardContainer}>
                <span className={classes.cardTitle}>Optional Actions</span>
                <div className={classes.actions}>
                    <OptionalAction
                        memberName={possessiveMemberName(memberName)}
                        type="membership"
                    />
                    <OptionalAction
                        assignDebit={assignDebit}
                        handleCardClick={handleCardClick}
                        memberName={memberName}
                        type="debit"
                    />
                </div>
            </Card>
            <div className={classes.btnContainer}>
                <Button
                    className={classes.profileBtn}
                    variant="contained"
                    onClick={() => history.push(`/holders/${intakeId}`)}
                >
                    Go to Profile
                </Button>
            </div>
            {assignPopUp && (
                <AssignCardPopUp
                    debitCardOptions={debitCardOptions}
                    handleModalClick={() => {
                        setAssignPopUp(false);
                        setDebitType(null);
                    }}
                    photo={holder ? holder.photo : ''}
                    setDebitType={setDebitType}
                />
            )}
            {popUp && (
                <PopUp
                    content="debit card"
                    debitCardOptions={debitCardOptions}
                    debitType={debitType}
                    handleModalClick={() => setPopUp(false)}
                    holder={holder}
                    setAssignDebit={setAssignDebit}
                    setDebitType={setDebitType}
                />
            )}
        </div>
    );
};

export default CongratsPage;
