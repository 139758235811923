import { SAMARITAN_URL } from '../settings';

import AuthManager from '../auth';
import SamaritanFetch from './httpClient';

// TODO: Fix any types
export const editGoalAction = (payload: any, id: number) => {
    const token = AuthManager.getInstance().getToken();

    payload.SESSION_TOKEN = token;

    return SamaritanFetch.v3.put(`/goal_actions/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    });
};

export function fetchGoalActions(id: number) {
    return SamaritanFetch.v3(`/goal_actions`, {
        query: {
            goal_actions: {
                homeless_id: [id]
            }
        }
    });
}

export const fetchGoalActionHistory = (id: number) => {
    return SamaritanFetch.v3(`/goal_actions`, {
        query: {
            goal_actions: {
                user_id: [id]
            },
            page: 1,
            per_page: 3
        }
    })
        .then((res: Response) => res.json())
        .then((data: { goal_actions: any[] }) => data.goal_actions);
};

export function postNewGoalAction({
    goal_id,
    description,
    homeless_id,
    due_at,
    stake_match_amount,
    qol_measure_id
}: {
    goal_id: number;
    description: string;
    homeless_id: number;
    due_at: string;
    stake_match_amount: number | string;
    qol_measure_id: number | null;
}) {
    return SamaritanFetch.v3.post(`/goal_actions`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            goal_action: {
                description,
                homeless_id,
                due_at,
                stake_match_amount,
                quality_of_life_measure_id: qol_measure_id,
                goal_id
            }
        })
    });
}

export const sendReminder = (id: number) => {
    return SamaritanFetch.v3.post(`/goal_actions/${id}/reminders`, {
        headers: { 'Content-Type': 'application/json' }
    });
};

export function updateGoalActionMatchAmount({
    id,
    matchAmount
}: {
    id: number;
    matchAmount: number | string;
}) {
    const token = AuthManager.getInstance().getToken();

    let url = `${SAMARITAN_URL}/api/v3/goal_actions/${id}`;
    let params = {
        SESSION_TOKEN: token,
        goal_action: {
            stake_match_amount: matchAmount
        }
    };

    return fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    }).catch((error) => {
        console.error(
            'Failed to fetch updateGoalActionMatch Amount error:',
            error
        );
    });
}

export function updateGoalActionStatus({
    id,
    status
}: {
    id: number;
    status: string;
}) {
    const token = AuthManager.getInstance().getToken();

    let params = {
        SESSION_TOKEN: token,
        goal_action: {
            status: status
        }
    };

    return SamaritanFetch.v3(`/goal_actions/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    });
}
