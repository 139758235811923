import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        sectionTitle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#062D7A',
            color: '#fff',
            borderRadius: '100px',
            width: 'fit-content',
            margin: '0 auto 10px auto',
            maxWidth: '100%',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '30px',
            letterSpacing: '1.6px',
            '& span': {
                overflow: 'hidden',
                padding: '8px 24px',
                textAlign: 'center',
                textOverflow: 'ellipsis'
            }
        }
    })
);

type Props = {
    count: number;
    name: string;
    title: string;
};

const SectionTitle: React.FC<Props> = ({ count, name, title }) => {
    const classes = useStyles();

    const possessiveSuffix = name
        ? name.endsWith('s') || name.endsWith('S')
            ? `${name}'`
            : `${name}'s`
        : `member's`;

    const renderTitle = () => {
        switch (title) {
            case 'actions':
                return 'Action Steps';
            case 'debit':
                return 'Debit Balance';
            case 'facts':
                return 'Fun Facts';
            case 'funds':
                return 'Membership Balance';
            case 'history':
                return 'History Timeline';
            case 'contact':
                return 'Contact Information';
            case 'documents':
                return 'Document Storage';
            case 'enrollment':
                return 'Enrollment Status';
            case 'lifestory':
                return count === 1 ? 'Life Update' : 'Life Updates';
            case 'member':
                return 'Member Information';
            case 'needs':
                return count === 1 ? 'Need' : 'Needs';
            case 'profile':
                return 'Basic Info';
            case 'status':
                return `${possessiveSuffix} Status`;
            default:
                return '';
        }
    };

    return (
        <div className={classes.sectionTitle}>
            <span>{count ? `${count} ${renderTitle()}` : renderTitle()}</span>
        </div>
    );
};

export default SectionTitle;
