import { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { unsetUser } from 'redux/actions/user';
import Routes from 'routes';

import AuthManager from '../../auth';

class LogoutRoute extends Component<{
    dispatch: (arg0: { type: string }) => void;
}> {
    renderLogout = () => {
        var auth = AuthManager.getInstance();
        auth.unsetToken();
        this.props.dispatch(unsetUser());
        return <Redirect to={{ pathname: Routes.login }} />;
    };

    render() {
        return <Route exact path={Routes.logout} render={this.renderLogout} />;
    }
}

export default connect()(LogoutRoute);
