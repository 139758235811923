import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import * as api from '../../api/payments';

function fetchingOrganizationPayments() {
    return {
        type: CONSTANTS.GET_ORGANIZATION_PAYMENTS
    };
}

function setOrganizationPayments(response: any) {
    return {
        type: CONSTANTS.SET_ORGANIZATION_PAYMENTS,
        payload: response
    };
}

function fetchingMemberPayments() {
    return {
        type: CONSTANTS.GET_MEMBER_PAYMENTS
    };
}

function setMemberPayments(response: any) {
    return {
        type: CONSTANTS.SET_MEMBER_PAYMENTS,
        payload: response
    };
}

function setFullMemberPayments(response: any) {
    return {
        type: CONSTANTS.SET_FULL_MEMBER_PAYMENTS,
        payload: response
    };
}

export function resetFullMemberPayments() {
    return {
        type: CONSTANTS.RESET_FULL_MEMBER_PAYMENTS
    };
}

export function getOrganizationPayments(_token: string, userId: number) {
    return (dispatch: Function) => {
        dispatch(fetchingOrganizationPayments());

        return api
            .fetchOrganizationPayments(userId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: any) => {
                return dispatch(setOrganizationPayments(responseJson));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function getMemberPayments(id: number, full: boolean) {
    return (dispatch: Function) => {
        dispatch(fetchingMemberPayments());

        const fetchFunction = full
            ? api.fetchMemberPayments(id, true)
            : api.fetchMemberPayments(id, false);

        return fetchFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: { payments: any }) => {
                const actionCreator = full
                    ? setFullMemberPayments
                    : setMemberPayments;
                return dispatch(actionCreator(responseJson.payments));
            })
            .catch((error: any) => handleSamaritanApiError(error, dispatch));
    };
}
