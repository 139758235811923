import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    needButtonContainer: {
        display: 'flex',
        gap: '130px',
        alignItems: 'center'
    },
    needButton: {
        fontSize: '16px',
        lineHeight: '21px',
        color: '#FFFFFF',
        width: '34px',
        height: '34px'
    },
    edit: {
        backgroundColor: '#999DFF'
    },
    complete: {
        backgroundColor: '#7FE8B2 !important',
        color: '#FFFFFF !important'
    },
    cancelled: {
        backgroundColor: '#A6A6BF !important',
        color: '#FFFFFF !important'
    },
    purchasedContainer: {
        position: 'relative'
    },
    purchased: {
        display: 'flex',
        alignItems: 'center',
        height: '34px',
        padding: '0 40px 0 16px',
        borderRadius: '200px',
        color: '#1B9757',
        backgroundColor: '#E9FBF2',
        position: 'absolute',
        right: '0',
        textWrap: 'nowrap'
    }
}));

const NeedButtons = ({
    complete,
    edit,
    need,
    setHasQOLMeasures,
    setNeedID,
    touchpointQuestionQOLs,
    updatedNeedIDs
}) => {
    const classes = useStyles();

    const { id, quality_of_life_measures, status } = need;
    const isUpdated = updatedNeedIDs.includes(id);

    const handleCompleteClick = () => {
        const hasQOLMeasures =
            quality_of_life_measures.length > 0
                ? quality_of_life_measures.some((qol) =>
                      touchpointQuestionQOLs.includes(qol.id)
                  )
                : false;

        setHasQOLMeasures(hasQOLMeasures);
        complete(true);
        setNeedID(id);
    };

    const handleEditClick = () => {
        edit(true);
        setNeedID(id);
    };

    return (
        <div className={classes.needButtonContainer}>
            {!isUpdated ? (
                <>
                    <IconButton
                        aria-label="due date"
                        className={`${classes.needButton} ${classes.edit}`}
                        onClick={() => handleEditClick()}
                        size="large">
                        <i className="fas fa-pencil" />
                    </IconButton>
                    <div className={classes.purchasedContainer}>
                        <div className={classes.purchased}>Purchased?</div>
                        <IconButton
                            aria-label="due date"
                            className={`${classes.needButton} ${classes.complete}`}
                            onClick={() => handleCompleteClick()}
                            size="large">
                            <i className="fas fa-check" />
                        </IconButton>
                    </div>
                </>
            ) : (
                <>
                    {status === 'completed' && (
                        <IconButton
                            aria-label="due date"
                            className={`${classes.needButton} ${classes.complete}`}
                            disabled
                            size="large">
                            <i className="fas fa-check" />
                        </IconButton>
                    )}
                    {status === 'cancelled' && (
                        <IconButton
                            aria-label="due date"
                            className={`${classes.needButton} ${classes.cancelled}`}
                            disabled
                            size="large">
                            <i className="fas fa-times" />
                        </IconButton>
                    )}
                </>
            )}
        </div>
    );
};

NeedButtons.propTypes = {
    complete: PropTypes.func,
    edit: PropTypes.func,
    need: PropTypes.object.isRequired,
    setNeedID: PropTypes.func.isRequired,
    updatedNeedIDs: PropTypes.array.isRequired
};

export default NeedButtons;
