import { Component } from 'react';
import { SamaritanLight } from '../_theme';

class ErrorPage extends Component {
    render() {
        return (
            <SamaritanLight>
                <h1> ERROR OCCURED</h1>
            </SamaritanLight>
        );
    }
}

export default ErrorPage;
