import { SAMARITAN_URL } from '../../settings';
import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from '.';

function getDeterminantRanks() {
    return {
        type: CONSTANTS.GET_DETERMINANT_RANKS
    };
}

export function getDeterminantRanksRequest(apiToken: string) {
    return (dispatch: any) => {
        dispatch(getDeterminantRanks());

        let url = `${SAMARITAN_URL}/api/v3/determinant_ranks`;

        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Token token=${apiToken}`
            }
        })
            .then((response) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson) => {
                return dispatch(
                    setDeterminantRanks(responseJson.determinant_ranks)
                );
            })
            .catch((error) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

function setDeterminantRanks(ranks: string[]) {
    return {
        type: CONSTANTS.SET_DETERMINANT_RANKS,
        payload: ranks
    };
}
