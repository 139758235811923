import { ReactNode } from 'react';
import UpdateStatus from '../UpdateStatus';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        titleContainer: {
            padding: '30px 0'
        },
        title: {
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 800,
            color: '#FFFFFF'
        },
        btnContainer: {
            padding: '20px 0'
        },
        btn: {
            borderRadius: '36px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 800,
            color: '#FFFFFF',
            padding: '10px 20px',
            textTransform: 'none'
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 6
        },
        modalContent: {
            backgroundColor: 'white',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            width: '80%',
            maxWidth: '660px',
            maxHeight: '95%',
            overflow: 'auto',
            borderRadius: '16px',
            position: 'relative'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            padding: '30px'
        }
    })
);

type SDOHPopUpProps = {
    content: ReactNode | string;
    catchUpID?: number;
    handleModalClick: (value: boolean) => void;
    updateStatus: boolean;
    setAddStatus?: (value: boolean) => void;
    setUpdateStatus: (value: boolean) => void;
    updateStatusProps: {
        answers: object[];
        id: number;
        name: null | string;
        statusId: null | number;
        statusQuestions: object[];
    };
};

const SDOHPopUp: React.FC<SDOHPopUpProps> = ({
    content,
    catchUpID,
    handleModalClick,
    updateStatus,
    setAddStatus,
    setUpdateStatus,
    updateStatusProps
}) => {
    const classes = useStyles();

    const { answers, id, name, statusId, statusQuestions } = updateStatusProps;

    return (
        <div className={classes.modal}>
            {!updateStatus && (
                <div className={classes.titleContainer}>
                    <span className={classes.title}>
                        Updating Member Statuses
                    </span>
                </div>
            )}
            <div className={classes.modalContent}>
                {!updateStatus || !statusId ? (
                    <span className={classes.content}>{content}</span>
                ) : (
                    <UpdateStatus
                        answers={answers}
                        catchUpID={catchUpID}
                        id={id}
                        name={name || ''}
                        setAddStatus={setAddStatus}
                        setUpdateStatus={setUpdateStatus}
                        statusId={statusId || 0}
                        questions={statusQuestions}
                    />
                )}
            </div>
            {!updateStatus && (
                <div className={classes.btnContainer}>
                    <Button
                        className={classes.btn}
                        onClick={() => handleModalClick(false)}
                    >
                        Close
                    </Button>
                </div>
            )}
        </div>
    );
};

export default SDOHPopUp;
