import CONSTANTS from '../constants/constants';

import { Homeless, Caseload } from 'types';

const initialState = {
    isFetchingCaseloads: false,
    isFetchingHomelesses: false,
    setCaseloads: false,
    setHomelesses: false,
    caseloads: null,
    homelesses: {},
    languages: []
} as {
    isFetchingCaseloads: boolean;
    isFetchingHomelesses: boolean;
    setCaseloads: boolean;
    setHomelesses: boolean;
    caseloads: Caseload[] | null;
    homelesses: Homeless[] | {};
    languages: string[];
};

export default function homelesses_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.ADD_TEMP_CASELOAD:
            return {
                ...state,
                caseloads: [
                    ...(state.caseloads || []),
                    {
                        homeless_id: action.payload,
                        status: 'active'
                    }
                ]
            };
        case CONSTANTS.GET_CASELOADS:
            return {
                ...state,
                isFetchingCaseloads: true
            };
        case CONSTANTS.GET_HOMELESSES:
            return {
                ...state,
                isFetchingHomelesses: true
            };
        case CONSTANTS.SET_CASELOADS:
            return {
                ...state,
                isFetchingCaseloads: false,
                setCaseloads: true,
                caseloads: action.payload
            };
        case CONSTANTS.SET_CASELOADS_ERROR:
            return {
                ...state,
                isFetchingCaseloads: false,
                setCaseloads: false
            };
        case CONSTANTS.SET_HOMELESSES:
            return {
                ...state,
                isFetchingHomelesses: false,
                setHomelesses: true,
                homelesses: action.payload
            };
        case CONSTANTS.SET_HOMELESSES_ERROR:
            return {
                ...state,
                isFetchingHomelesses: false,
                setHomelesses: false
            };
        case CONSTANTS.SET_HOMELESSES_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        case CONSTANTS.UPDATE_HOMELESSES:
            const newHomeless = action.payload;

            const homelessArray = Array.isArray(state.homelesses)
                ? state.homelesses
                : Object.values(state.homelesses || {});

            return {
                ...state,
                homelesses: [...homelessArray, newHomeless]
            };
        case CONSTANTS.ADD_MEMBER_TO_MY_MEMBERS:
            const { caseloadID, memberID, accepted } = action.payload;

            if (
                state.caseloads &&
                state.caseloads.find(
                    (caseload: Caseload) => caseload.id === caseloadID
                )
            ) {
                const addedMemberCaseloads = state.caseloads.map(
                    (caseload: Caseload) => {
                        if (caseload.id === caseloadID) {
                            const status = {
                                ...caseload,
                                status: 'active'
                            };

                            if (accepted) {
                                status.is_accepted = 'referral accepted';
                            }

                            return status;
                        }
                        return caseload;
                    }
                );

                return {
                    ...state,
                    caseloads: addedMemberCaseloads
                };
            } else {
                return {
                    ...state,
                    caseloads: [
                        ...(state.caseloads || []),
                        {
                            homeless_id: memberID,
                            id: caseloadID,
                            status: 'active'
                        }
                    ]
                };
            }
        case CONSTANTS.REMOVE_MEMBER_FROM_MY_MEMBERS:
            const { caseloadID: caseload_id, rejected } = action.payload;

            const removedMemberCaseloads =
                state.caseloads &&
                state.caseloads.map((caseload) => {
                    if (caseload.id === caseload_id) {
                        const status = {
                            ...caseload,
                            status: 'cancelled'
                        };

                        if (rejected) {
                            status.is_accepted = 'referral rejected';
                        }

                        return status;
                    }
                    return caseload;
                });

            return {
                ...state,
                caseloads: removedMemberCaseloads
            };
        case CONSTANTS.UPDATE_HOMELESSES_MEMBER_INFO:
            const { memberID: homelessID, field, value } = action.payload;

            const homelessArray2 = Array.isArray(state.homelesses)
                ? state.homelesses
                : Object.values(state.homelesses || {});

            const updatedHomelesses = homelessArray2.map(
                (homeless: Homeless) => {
                    if (homeless.id === homelessID) {
                        return {
                            ...homeless,
                            [field]: value
                        };
                    }
                    return homeless;
                }
            );

            return {
                ...state,
                homelesses: updatedHomelesses
            };
        case CONSTANTS.RESET:
            return initialState;
        default:
            return state;
    }
}
