import * as CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import { newGoalActionThunk } from './goal_actions';
import * as api from '../../api/goals';

import randomString from '../../util/randomString';

function fetchHomelessGoals() {
    return {
        type: CONSTANTS.GET_HOMELESS_GOALS
    };
}

function setHomelessGoals(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_GOALS,
        payload: response
    };
}

// TODO: Fix any types
export function getHomelessGoals(_token: string, id: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessGoals());
        return api
            .fetchHomelessGoals(id)
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: { goals: any[] }) =>
                dispatch(setHomelessGoals(responseJson.goals))
            )
            .catch((error: any) => handleSamaritanApiError(error, dispatch));
    };
}

// TODO: Fix any types
export function postingNewGoal(payload: any, tempRef: string) {
    return {
        type: CONSTANTS.POSTING_NEW_HOMELESS_GOAL,
        payload,
        tempRef
    };
}

// TODO: Fix any types
export function postedNewGoal(tempRef: string, newGoal: any) {
    return {
        type: CONSTANTS.POSTED_NEW_HOMELESS_GOAL,
        tempRef: tempRef,
        newGoal
    };
}

export function failedNewGoal(tempRef: string, error: string) {
    return {
        type: CONSTANTS.FAILED_NEW_HOMELESS_GOAL,
        tempRef,
        error
    };
}

export function newGoalThunk(
    homelessId: number,
    goalDescription: string,
    date: string,
    amount: number,
    customGoalDescription: string | null,
    qols: number | null
) {
    const tempRef = randomString();
    const payload = {
        goal: {
            homeless_id: homelessId,
            description: goalDescription
        }
    };

    // TODO: Fix any types
    return async (dispatch: Function, getState: any) => {
        const currentGoals = getState().goals.homelessGoals;

        const matchedGoals = currentGoals.filter(
            (g: { description: string }) =>
                g.description === customGoalDescription
        )[0];
        let goal_id = matchedGoals ? matchedGoals.id : undefined;

        if (customGoalDescription) {
            if (matchedGoals === undefined) {
                dispatch(postingNewGoal(payload, tempRef));

                let res = await api.postHomelessGoal({
                    homelessId,
                    description: customGoalDescription
                });

                if (res && !res.ok) {
                    const e = await res.json();
                    return dispatch(failedNewGoal(tempRef, e.error));
                }

                let resJson = await res.json();
                dispatch(postedNewGoal(tempRef, resJson.goal));

                goal_id = resJson.goal.id;
            }
        }

        return dispatch(
            newGoalActionThunk(
                homelessId,
                goal_id,
                goalDescription,
                date,
                amount,
                customGoalDescription,
                qols
            )
        );
    };
}
