import CONSTANTS from '../constants/constants';

import { PexHistory } from 'types';

import { updateObject } from '../../util';

const initialState = {
    fullPexHistory: [],
    isFetchingPexHistory: false,
    isFetchingPexInfo: false,
    pexHistory: [],
    pexInfo: {},
} as {
    fullPexHistory: PexHistory[];
    isFetchingPexHistory: boolean;
    isFetchingPexInfo: boolean;
    pexHistory: PexHistory[];
    pexInfo: any;
};

export default function pex_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_HOMELESS_PEX_INFO:
            return {
                ...state,
                isFetchingPexInfo: true
            };
        case CONSTANTS.SET_HOMELESS_PEX_INFO:
            return {
                ...state,
                pexInfo: action.payload,
                isFetchingPexInfo: false
            };
        case CONSTANTS.SET_HOMELESS_PEX_BALANCE:
            return {
                ...state,
                pexInfo: action.payload
            };
        case CONSTANTS.UPDATE_HOMELESS_PEX_BALANCE:
            return {
                ...state,
                pexInfo: updateObject(
                    state.pexInfo,
                    'available_balance',
                    state.pexInfo.available_balance + action.payload.balance
                )
            };
        case CONSTANTS.FETCH_PEX_HISTORY:
            return {
                ...state,
                isFetchingPexHistory: true
            };
        case CONSTANTS.SET_PEX_HISTORY:
            return {
                ...state,
                pexHistory: action.payload,
                isFetchingPexHistory: false
            };
        case CONSTANTS.SET_FULL_PEX_HISTORY:
            return {
                ...state,
                fullPexHistory: action.payload,
                isFetchingPexHistory: false
            };
        default:
            return state;
    }
}
