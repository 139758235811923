import SamaritanFetch from './httpClient';

// TODO: Fix any types
const buildQueryParams = (page: number, additionalParams: any) => {
    return page
        ? { page: page, per_page: 10, ...additionalParams }
        : additionalParams;
};

export const getChatRooms = (memberId: number, page: number) => {
    const queryParams = buildQueryParams(page, { member_id: memberId });
    return SamaritanFetch.v3('/encouragement_chat_rooms', {
        query: queryParams
    });
};

export const getMemberMessages = (
    memberId: number,
    recipientId: number,
    page: number
) => {
    const queryParams = buildQueryParams(page, { user_id: recipientId });
    return SamaritanFetch.v3(`/encouragement_chat_rooms/${memberId}`, {
        query: queryParams
    });
};

export const sendMessage = (data: any) => {
    return SamaritanFetch.v3('/encouragement_chat_rooms', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    });
};

export const sendMessageForMember = (
    memberId: number,
    message: string,
    userId: number
) => {
    return SamaritanFetch.v3('/encouragement_chat_rooms', {
        method: 'POST',
        body: { member_id: memberId, message, user_id: userId }
    });
};
