/* eslint-disable react-hooks/rules-of-hooks */
/**
 * Inspired by https://medium.com/ecovadis-engineering/using-feature-toggle-in-a-react-application-part-1-ee34a0e72cf4
 */

import React, { createContext, useContext } from 'react';

export const featureToggleContext = createContext({});

type FeatureToggleProviderProps = {
    defaults: {
        [key: string]: boolean;
    };
    children: React.ReactNode;
};
/**
 * Context provider for feature toggles.
 * Supply with an object with default features.
 */
export const FeatureToggleProvider = ({
    defaults,
    children
}: FeatureToggleProviderProps) => {
    if (defaults === undefined) {
        throw new Error(
            'FeatureToggleProvider does not have a defaults prop provided'
        );
    }

    /**
     * A use effect hook here could be used to add support for `firebase remote config`.
     */

    return (
        <featureToggleContext.Provider value={defaults}>
            {children}
        </featureToggleContext.Provider>
    );
};

type FeatureToggleProps = {
    children: React.ReactNode;
    feature: string;
};
/**
 * Wrapper for `FeatureToggle.On` and `FeatureToggle.Off`.
 *
 * Providing a `feature` prop with the feature name
 * will clone the prop to every child.
 *
 * This wrapper is useful when specifying a feature once for
 * when a component is on and off
 *
 * `Example`:
 * ```jsx
 *  <FeatureToggle feature="foobar">
 *    <FeatureToggle.On>
 *      <OnComponent />
 *    </FeatureToggle.On>
 *    <FeatureToggle.Off>
 *      <OffComponent />
 *    </FeatureToggle.Off>
 *  </FeatureToggle>
 * ```
 */
const FeatureToggle = ({ children, feature }: FeatureToggleProps) => {
    const toggleChildren = React.Children.map(children, (child) =>
        React.cloneElement(child as any, { feature })
    );

    return toggleChildren;
};
export default FeatureToggle;

type FeatureToggleOnProps = {
    children: React.ReactNode;
    feature: string;
};
/**
 * Renders children when `feature` is `true`.
 *
 * Can be used independently with a `feature` prop
 * or wrapped in a `FeatureToggle` component.
 */
FeatureToggle.On = ({ children, feature }: FeatureToggleOnProps) => {
    const features = useContext(featureToggleContext) as any;
    if (features === undefined) {
        throw new Error(
            'FeatureToggle.On is not in context of FeatureToggleProvider.'
        );
    }

    if (features[feature] === undefined) {
        throw new Error(`No toggle exists for feature: ${feature}`);
    }

    const toggledOn = features[feature];

    return toggledOn ? children : null;
};

type FeatureToggleOffProps = {
    children: React.ReactNode;
    feature: string;
};
/**
 * Renders children when `feature` is `false`.
 *
 * Can be used independently with a `feature` prop
 * or wrapped in a `FeatureToggle` component.
 */
FeatureToggle.Off = ({ children, feature }: FeatureToggleOffProps) => {
    const features = useContext(featureToggleContext) as any;
    if (features === undefined) {
        throw new Error(
            'FeatureToggle.Off is not in context of FeatureToggleProvider.'
        );
    }

    if (features[feature] === undefined) {
        throw new Error(`No toggle exists for feature: ${feature}`);
    }

    const toggledOn = features[feature];

    return toggledOn ? null : children;
};
