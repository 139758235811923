import PopUp2 from 'components/_shared/PopUp2';
import SDOHSurvey from 'containers/StatusCapture';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px 20px 0 0',
            '& button': {
                fontSize: '1.5rem',
                color: '#999DFF'
            }
        }
    })
);

type UpdateStatusPopUpProps = {
    catchUpItemID: number;
    onClose: () => void;
};

const UpdateStatusPopUp = ({
    catchUpItemID,
    onClose
}: UpdateStatusPopUpProps) => {
    const classes = useStyles();

    const popUpStyles = {
        content: { backgroundColor: '#3D3D6E', padding: '0' }
    };

    const surveyStyles = {
        cardBackground: { borderRadius: '40px 40px 0 0' },
        statusContainer: { margin: '0', padding: '0', width: '100%' }
    };

    return (
        <PopUp2
            customClasses={popUpStyles}
            onClose={onClose}
            open={true}
            title="Updating Status"
        >
            <>
                <div className={classes.buttonContainer}>
                    <Button onClick={onClose}>
                        <i className="fas fa-times" />
                    </Button>
                </div>
                <SDOHSurvey
                    catchUpItemID={catchUpItemID}
                    customClasses={surveyStyles}
                />
            </>
        </PopUp2>
    );
};

export default UpdateStatusPopUp;
