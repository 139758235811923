import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    button: {
        position: 'relative',
        background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
        borderRadius: 100,
        boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
        color: '#FFFFFF',
        fontFamily: 'Manrope',
        fontSize: 16,
        fontWeight: 800,
        letterSpacing: 0.4,
        lineHeight: '19px',
        padding: '24px 0',
        textAlign: 'center',
        textTransform: 'none',
        width: 372
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 52
    }
});

type ContinueButtonProps = {
    active: boolean;
    clickHandler: () => void;
};

const ContinueButton: React.FC<ContinueButtonProps> = ({
    active,
    clickHandler
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Button
                className={classes.button}
                disabled={!active}
                onClick={clickHandler}
                style={{
                    color: active ? 'white' : 'white',
                    opacity: active ? 1 : 0.5
                }}
            >
                Create Membership
            </Button>
        </div>
    );
};

export default ContinueButton;
