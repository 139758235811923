import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

import { removeAccess } from '../utils';

import ToggleButton from '@mui/material/ToggleButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    selectorContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        // padding: '0 30px',
        // margin: '0 30px',
        marginBottom: '20px'
    },
    selectorItem: {
        display: 'flex',
        cursor: 'pointer',
        marginRight: '8px',
        border: 'none !important',
        textTransform: 'none',
        '&:last-child': {
            marginRight: '0'
        }
    },
    selectorItemIconContainer: {},
    selectorItemTitle: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#1F2152',
        lineHeight: '24px',
        whiteSpace: 'nowrap'
    },
    selectorItemActive: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#EDEDFA',
        borderRadius: '16px',
        padding: '15px 20px',
        cursor: 'default',
        '& i': {
            marginRight: '12px',
            fontSize: '20px',
            color: '#1F2152'
        }
    },
    selectorItemInactive: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#8d8ea8',
        backgroundColor: '#f4f4fd',
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        '& i': {
            fontSize: '20px'
        },
        '&:hover': {
            backgroundColor: '#EDEDFA',
            color: '#1F2152'
        }
    }
}));

const Selector = ({
    activeStatus,
    handleStatusChange,
    touchpointQuestions
}) => {
    const classes = useStyles();

    const statusQuestions = touchpointQuestions.filter(
        (question) =>
            question.type === 'StatusQuestion' ||
            question.type === 'QualityOfLifeMeasureQuestion'
    );

    const handleStatusChangeClick = (questionID, qualityOfLifeMeasureID) => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'SDOH Status Progress Selector Clicked',
            label: `Progress for ${questionID} and ${qualityOfLifeMeasureID}`
        });

        handleStatusChange(questionID, qualityOfLifeMeasureID);
    };

    return (
        <div className={classes.selectorContainer}>
            {statusQuestions.map((question, index) => {
                const isActive = activeStatus === question.id;

                return (
                    <ToggleButton
                        className={`${classes.selectorItem} ${
                            isActive
                                ? classes.selectorItemActive
                                : classes.selectorItemInactive
                        }`}
                        value={question.id}
                        key={index}
                        onClick={() =>
                            handleStatusChangeClick(
                                question.id,
                                question.quality_of_life_measure_id
                            )
                        }
                        disabled={isActive}
                    >
                        <i className={`far fa-${question.icon_name}`} />
                        {isActive && (
                            <div className={classes.selectorItemTitle}>
                                <span>
                                    {removeAccess(question.friendly_name)}
                                </span>
                            </div>
                        )}
                    </ToggleButton>
                );
            })}
        </div>
    );
};

Selector.propTypes = {
    activeStatus: PropTypes.number.isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    touchpointQuestions: PropTypes.array.isRequired
};

export default Selector;
