import Card from '../../CaseManagement/components/Card';
import EditName from './EditName';
import EditProfilePicture from './EditProfilePicture';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    namePhotoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        borderRadius: '24px',
        border: '1px solid #E0E0E0',
        padding: '48px',
        '@media (max-width: 768px)': {
            padding: '24px'
        }
    }
}));

const BasicInfo = ({ ...props }) => {
    const classes = useStyles();

    const {
        editName,
        editProfilePicture,
        handleSaveName,
        handleSavePhoto,
        name,
        newName,
        newProfilePicture,
        photo,
        setEditName,
        setEditProfilePicture,
        setNewName,
        setNewProfilePicture
    } = props;

    const editNameProps = {
        editName,
        handleSaveName,
        name,
        newName,
        setEditName,
        setNewName
    };

    const editProfilePictureProps = {
        editProfilePicture,
        handleSavePhoto,
        newProfilePicture,
        photo,
        setEditProfilePicture,
        setNewProfilePicture
    };

    return (
        <div className={classes.infoContainer}>
            <Card info profile />
            <div className={classes.namePhotoContainer}>
                <EditProfilePicture {...editProfilePictureProps} />
                <EditName {...editNameProps} />
            </div>
        </div>
    );
};

export default BasicInfo;
