import Card from 'components/_shared/Card';

import { DeploymentOrgs } from 'types';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            margin: '12px !important',
            width: '90%'
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: 600,
            padding: '16px 0',
            '& .MuiButtonBase-root': {
                fontWeight: 600,
                gap: '8px'
            }
        },
        itemDetail: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            height: '100%',
            opacity: 0.6,
            width: '100%'
        },
        picture: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '50px',
            fontSize: '14px',
            height: '48px',
            marginRight: '16px',
            width: '48px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        referButton: {
            border: '1px solid #999DFF',
            borderRadius: '100px',
            color: '#999DFF',
            padding: '4px 12px',
            textTransform: 'none'
        }
    })
);

type ReferOrgItemProps = {
    deployment: DeploymentOrgs;
    setReferralOrg: (referral: DeploymentOrgs) => void;
};

const ReferOrgItem: React.FC<ReferOrgItemProps> = ({
    deployment,
    setReferralOrg
}) => {
    const classes = useStyles();

    const { name, logo } = deployment;

    return (
        <Card className={classes.card}>
            <div className={classes.item}>
                <div className={classes.itemDetail}>
                    <span className={classes.picture}>
                        <img src={logo} alt="org-logo" />
                    </span>
                    <span>{name}</span>
                </div>
                <Button
                    className={classes.referButton}
                    onClick={() => setReferralOrg(deployment)}
                >
                    Refer <i className="far fa-arrow-right" />
                </Button>
            </div>
        </Card>
    );
};

export default ReferOrgItem;
