// TODO: Fix ts-nocheck SDOH3 for QuestionBody
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Homeless, TouchpointQuestions } from 'types';

import CardBackground from 'components/_shared/CardBackground';
import LoadingCircle from 'components/_shared/LoadingCircle';
import QuestionBody from './components/QuestionBody';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        statusContainer: {
            position: 'relative',
            borderRadius: '16px',
            height: 'fit-content',
            margin: '0 auto 50px auto',
            padding: '50px 0 0 0',
            width: '80%'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px 40px 20px 40px'
        },
        nextBtn: {
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '28px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            color: '#FBFBFF',
            fontSize: '24px',
            fontWeight: 700,
            padding: '15px 140px',
            textTransform: 'none',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            },
            '& i': {
                marginLeft: '15px'
            }
        },
        disabled: {
            background: '#E3E3FA',
            color: '#ffffff !important'
        },
        success: {
            background: 'linear-gradient(180deg, #42dd9e 0%, #7fe8bd 100%)',
            color: '#ffffff !important'
        }
    })
);

type StatusCaptureProps = {
    catchUpItemID?: number;
    customClasses?: {
        [key: string]: {
            [key: string]: string;
        };
    };
    getTouchpointAnswersRequest: (
        id: number,
        ids: number[],
        qols: number[]
    ) => void;
    homelessInfo: Homeless;
    isSubmittingStatusCapture: boolean;
    setSdohStatus: (status: boolean) => void;
    submitStatusCapture: (id: number, answers: any) => void;
    touchpointQuestions: TouchpointQuestions[];
    touchpointQuestionIds: number[];
    touchpointQuestionQOLs: number[];
    updateGoalActionStatus: (
        id: number,
        status: 'cancelled' | 'completed'
    ) => void;
};

const StatusCapture: React.FC<StatusCaptureProps> = ({
    catchUpItemID,
    customClasses,
    getTouchpointAnswersRequest,
    homelessInfo,
    isSubmittingStatusCapture,
    setSdohStatus,
    submitStatusCapture,
    touchpointQuestions,
    touchpointQuestionIds,
    touchpointQuestionQOLs,
    updateGoalActionStatus
}) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const questions = touchpointQuestions?.filter(
        (question) => question.type === 'StatusQuestion'
    );

    const [answers, setAnswers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const formatAnswers = (
        answers: {
            answer: string[];
            quality_of_life_measure_id: number;
        }[]
    ) => {
        return answers.map((answer) => {
            return {
                ...answer,
                answer:
                    answer.answer.length > 1
                        ? answer.answer.join(', ')
                        : answer.answer[0],
                quality_of_life_measure_id: answer.quality_of_life_measure_id
            };
        });
    };

    const handleSubmit = () => {
        const formattedAnswers = formatAnswers(answers);
        const id = homelessInfo?.id;
        const newUrl = match.url.replace(/\/qol/, '');

        setLoading(true);

        submitStatusCapture(id, formattedAnswers, catchUpItemID);

        // this makes it refresh, which is something that needs to be changed soon
        if (!isSubmittingStatusCapture && !catchUpItemID) {
            setSubmitted(true);

            setTimeout(() => {
                getTouchpointAnswersRequest(
                    id,
                    touchpointQuestionIds,
                    touchpointQuestionQOLs
                );

                if (!history.location.state) {
                    const fromState = {
                        from: 'updateStatusComplete'
                    };

                    history.push(newUrl, fromState);
                } else {
                    // TODO: Fix type for fromState
                    const fromState = {};

                    if (
                        history.location.state.from === 'profileQOLAssessment'
                    ) {
                        updateGoalActionStatus(
                            history.location.state.id,
                            'completed'
                        );

                        fromState.from = 'exitedProfileQOLAssessment';
                    } else if (history.location.state.from === 'exitQol') {
                        fromState.from = 'exitedQol';
                    }

                    setSdohStatus(true);

                    history.push(newUrl, fromState);
                }
            }, 1500);
        }
    };

    const renderDisabledClass = () => {
        const isDisabled = answers && answers.length === 0;
        const isSuccess = submitted && !loading;

        return isSuccess
            ? `${classes.nextBtn} ${classes.success}`
            : isDisabled
            ? `${classes.nextBtn} ${classes.disabled}`
            : classes.nextBtn;
    };

    const renderLoading = () => {
        if (!loading && !submitted) {
            return 'Save Statuses';
        }

        if (!loading && submitted) {
            return 'Status Captured 🎉';
        }

        return <LoadingCircle />;
    };

    useEffect(() => {
        if (isSubmittingStatusCapture) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isSubmittingStatusCapture]);

    return (
        <div
            className={classes.statusContainer}
            style={
                customClasses?.statusContainer
                    ? customClasses.statusContainer
                    : {}
            }
        >
            <CardBackground customClasses={customClasses} />
            {questions.map((question, index) => (
                <QuestionBody
                    key={index}
                    setAnswers={setAnswers}
                    name={homelessInfo.name}
                    questions={question}
                    questionLength={questions.length}
                    questionNum={index}
                />
            ))}
            <div className={classes.buttonContainer}>
                <Button
                    className={renderDisabledClass()}
                    disabled={
                        (answers && !answers.length) || loading || submitted
                    }
                    onClick={() => handleSubmit()}
                >
                    {renderLoading()}
                </Button>
            </div>
        </div>
    );
};

export default StatusCapture;
