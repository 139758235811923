import * as CONSTANTS from "../constants/constants";

const initialState = {
    isFetchingSupplementalQuestions: false,
    isFetchingTouchpointQuestions: false,
    isFetchingTouchpointQuestionIds: false,
    isFetchingTouchpointQuestionQOLs: false,
    supplementalQuestions: [],
    supplementalQuestionIds: [],
    touchpointQuestions: [],
    touchpointQuestionIds: [],
    touchpointQuestionQOLs: []
};

export default function touchpoint_question_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.GET_TOUCHPOINT_QUESTIONS:
            return {
                ...state,
                isFetchingTouchpointQuestions: true,
                isFetchingTouchpointQuestionIds: true,
                isFetchingTouchpointQuestionQOLs: true
            };
        case CONSTANTS.SET_TOUCHPOINT_QUESTIONS_ERROR:
            return {
                ...state,
                isFetchingTouchpointQuestions: false,
                isFetchingTouchpointQuestionIds: false
            };
        case CONSTANTS.SET_TOUCHPOINT_QUESTIONS:
            return {
                ...state,
                isFetchingTouchpointQuestions: false,
                touchpointQuestions: action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_QUESTION_IDS:
            return {
                ...state,
                isFetchingTouchpointQuestionIds: false,
                touchpointQuestionIds: action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_QUESTION_QUALITY_OF_LIFE_MEASURES:
            return {
                ...state,
                isFetchingTouchpointQuestionQOLs: false,
                touchpointQuestionQOLs: action.payload
            };
        case CONSTANTS.GET_SUPPLEMENTAL_QUESTIONS:
            return {
                ...state,
                isFetchingSupplementalQuestions: true
            };
        case CONSTANTS.SET_SUPPLEMENTAL_QUESTIONS:
            return {
                ...state,
                isFetchingSupplementalQuestions: false,
                supplementalQuestions: action.payload
            };
        case CONSTANTS.SET_SUPPLEMENTAL_QUESTION_IDS:
            return {
                ...state,
                supplementalQuestionIds: action.payload
            };
        case CONSTANTS.RESET:
            return initialState;
        default:
            return state;
    }
}
