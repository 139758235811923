import CONSTANTS from '../constants/constants';

import * as api from '../../api/user';

import { foundUnauthorizedUser, handleSamaritanApiError } from './error';
import { getTouchpointQuestionsRequest } from './touchpointQuestions';

type User = {
    api_token: string;
    auth_type: string;
    braintree_customer_id: string;
    braintree_custumer_id: string;
    'default_give_amount=': number | null;
    donator_id: number;
    email: string;
    id: number;
    is_merchant: boolean;
    is_new_user: boolean;
    is_non_profit: boolean;
    is_showing_intake_guides: boolean;
    language_preference: string;
    last_name: string;
    members_enrolled: number;
    name: string;
    partner: {
        deployment: {
            id: number;
            monthly_bonus_pool_max: number;
            name: string;
            organization_ids: number[];
        };
        deployment_id: number;
        donation_url: string;
        email: string;
        header: string;
        id: number;
        is_hidden: boolean;
        logo: string;
        name: string;
        sub_header: string;
        website_url: string;
    };
    partner_name: string;
    payment_methods: string[];
    phone_number: string;
    referral_code: string;
    referral_code_value: string | null;
    referral_count: number;
    referred_by: string | null;
    session_token: string;
    signup_date: string;
    success: boolean;
    user_id: number;
};

export function fetchLanguages() {
    return async (dispatch: Function) => {
        try {
            const response = await api.fetchLanguagePreferences();

            if (response && response.ok) {
                const resJson = await response.json();

                dispatch(setLanguages(resJson.language_preferences));
            }
        } catch (_e: any) {
            handleSamaritanApiError(
                { message: 'Failed to fetch user language preferences' },
                dispatch
            );
        }
    };
}

export function fetchUser(id: number) {
    return async (dispatch: Function) => {
        try {
            const response = await api.fetchUserData(id);

            if (response && response.ok) {
                const resJson = await response.json();

                dispatch(setUser(resJson.user));
                dispatch(
                    setMaxBonusAmount(
                        resJson.user.partner.deployment
                            ?.monthly_bonus_pool_max || 40
                    )
                );
                dispatch(fetchLanguages());
                dispatch(getTouchpointQuestionsRequest());
            } else {
                dispatch(foundUnauthorizedUser());
            }
        } catch (e) {
            dispatch(foundUnauthorizedUser());
        }
    };
}

export function incrementEnrolledMembers() {
    return {
        type: CONSTANTS.INCREMENT_ENROLLED_MEMBERS
    };
}

function setLanguages(languages: string[]) {
    return {
        type: CONSTANTS.SET_LANGUAGES,
        payload: languages
    };
}

export function setMaxBonusAmount(maxBonusAmount: number) {
    return {
        type: CONSTANTS.SET_MAX_BONUS_AMOUNT,
        payload: maxBonusAmount
    };
}

export function setUser(user: User) {
    return {
        type: CONSTANTS.SET_USER,
        payload: user
    };
}

export function unsetUser() {
    return {
        type: CONSTANTS.UNSET_USER
    };
}

function updateLanguageSetting(language: string) {
    return {
        type: CONSTANTS.UPDATE_LANGUAGE_SETTING,
        payload: language
    };
}

export function updateShowGuideSetting(isShowingIntakeGuides: boolean) {
    return {
        type: CONSTANTS.UPDATE_SHOW_GUIDE_SETTING,
        payload: isShowingIntakeGuides
    };
}

export function updateUserSettings(
    userId: number,
    settings: { intakeGuide?: boolean; languagePreference?: string }
) {
    return async (dispatch: Function) => {
        try {
            let response;

            if (settings.languagePreference) {
                response = await api.updateUserLanguageSetting(
                    userId,
                    settings.languagePreference
                );
            } else {
                if (settings.intakeGuide !== undefined) {
                    response = await api.updateUserGuideSetting(
                        userId,
                        settings.intakeGuide
                    );
                }
            }

            if (response && response.ok) {
                const resJson = await response.json();

                if (settings.languagePreference) {
                    dispatch(
                        updateLanguageSetting(resJson.language_preference)
                    );
                } else {
                    dispatch(
                        updateShowGuideSetting(resJson.is_showing_intake_guides)
                    );
                }
            }
        } catch (e: any) {
            handleSamaritanApiError(e, dispatch);
        }
    };
}