import SamaritanFetch from './httpClient';

export const fetchTouchpointQuestions = (): Promise<object> =>
    SamaritanFetch.v3('/touchpoint_questions', {
        query: {
            'touchpoint_question[relations][slider_options][relations][determinant_rank]':
                '{}',
            'touchpoint_questions[active]': true
        }
    });

export const fetchSupplementalQuestions = (): Promise<object> =>
    SamaritanFetch.v3('/touchpoint_questions', {
        query: {
            'touchpoint_questions[active]': true,
            'touchpoint_questions[question_type]': 'SUPPLEMENTAL_QUESTION'
        }
    });