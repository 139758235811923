import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    backBtnContainer: {
        alignSelf: 'flex-end',
        '& button': {
            fontSize: '24px',
            color: '#7378E8'
        }
    }
}));

type BackButtonProps = {
    handleClick: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ handleClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.backBtnContainer}>
            <Button onClick={handleClick}>
                <i className="fal fa-chevron-left" />
            </Button>
        </div>
    );
};

export default BackButton;
