import classes from './SecondaryButton.module.scss';

type SecondaryButtonProps = {
    children: React.ReactNode;
    className?: string;
    hideLine?: boolean;
    light?: boolean;
    style?: React.CSSProperties;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function SecondaryButton({
    children,
    className,
    hideLine,
    light,
    style,
    ...rest
}: SecondaryButtonProps) {
    const lightClass = light ? classes.light : '';

    return (
        <button
            style={{ border: hideLine ? 'none' : '', ...style }}
            className={`${classes.button} ${className || ''} ${lightClass}`}
            {...rest}
        >
            {children}
        </button>
    );
}
