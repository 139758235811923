import { Deployments } from 'types';

// eventually use the new DateInput component
// import DateInput from 'components/_shared/DateInput';
import CTAButton1 from 'components/_shared/buttons/CTAButton1';
import OrgSelect from './OrgSelect';

import TextField from '@mui/material/TextField';

type EditMemberInfoProps = {
    classes: {
        title: string;
        inputFields: string;
        inputBirthday: string;
        underline: string;
        btnDiv: string;
        btn: string;
    };
    newFirstName: string;
    setFirstName: (firstName: string) => void;
    newMiddleName: string;
    setMiddleName: (middleName: string) => void;
    newLastName: string;
    setLastName: (lastName: string) => void;
    newBirthday: string;
    setBirthday: (birthday: string) => void;
    deployments: any;
    newDeployment: number;
    setNewDeployment: (deployment: number) => void;
    newIdentificationNumber: string;
    setIdentificationNumber: (identificationNumber: string) => void;
    newQR: string;
    setQR: (qr: string) => void;
    handleSave: () => void;
};

const EditMemberInfo: React.FC<EditMemberInfoProps> = ({
    classes,
    ...props
}) => {
    const {
        newFirstName,
        setFirstName,
        newMiddleName,
        setMiddleName,
        newLastName,
        setLastName,
        newBirthday,
        setBirthday,
        deployments,
        newDeployment,
        setNewDeployment,
        newIdentificationNumber,
        setIdentificationNumber,
        newQR,
        setQR,
        handleSave
    } = props;

    const nonGrandfatheredDeployments =
        deployments?.filter(
            (deployment: Deployments) => !deployment.is_grandfathered
        ) || [];

    const formatInputDate = (dateString: string) => {
        var date = new Date(dateString);
        var formattedDate = date.toISOString().split('T')[0];

        return formattedDate;
    };

    const handleDateChange = (e: any) => {
        const dateObject = new Date(e.target.value);

        setBirthday(dateObject.toISOString());
    };

    const handleDepSelect = (e: any) => {
        setNewDeployment(e.target.value);
    };

    return (
        <div id="edit-private-body" style={{ padding: '5px 24px 5px' }}>
            <h5 className={classes.title}>First Name</h5>
            <input
                className={classes.inputFields}
                placeholder="Jane"
                name="first-name"
                id="first-name"
                value={newFirstName || ''}
                onChange={(e) => setFirstName(e.target.value)}
            />
            <div className={classes.underline} />
            <h5 className={classes.title}>Middle Name</h5>
            <input
                className={classes.inputFields}
                placeholder="Anne"
                name="middle-name"
                id="middle-name"
                value={newMiddleName || ''}
                onChange={(e) => setMiddleName(e.target.value)}
            />
            <div className={classes.underline} />
            <h5 className={classes.title}>Last Name</h5>
            <input
                className={classes.inputFields}
                placeholder="Doe"
                name="last-name"
                id="last-name"
                value={newLastName || ''}
                onChange={(e) => setLastName(e.target.value)}
            />
            <div className={classes.underline} />
            <h5 className={classes.title}>Date Of Birth</h5>
            <TextField
                className={classes.inputBirthday}
                id="date"
                type="date"
                defaultValue={
                    newBirthday ? formatInputDate(newBirthday) : `2000-01-01`
                }
                InputProps={{
                    disableUnderline: true,
                    style: {
                        fontSize: '1.5rem',
                        color: newBirthday ? '#1f2152' : '#757575',
                        fontStyle: newBirthday ? 'normal' : 'italic',
                        padding: '0'
                    }
                }}
                onChange={(e) => handleDateChange(e)}
                variant="standard"
            />
            <div className={classes.underline} />
            {nonGrandfatheredDeployments.length > 1 ? (
                <>
                    <h5 className={classes.title}>Member's Insurance</h5>
                    <OrgSelect
                        deployments={nonGrandfatheredDeployments}
                        newDeployment={newDeployment}
                        handleDepSelect={handleDepSelect}
                    />
                    <div className={classes.underline} />
                </>
            ) : null}
            <h5 className={classes.title}>insurance id number</h5>
            <input
                className={classes.inputFields}
                placeholder="418-217-122231"
                name="insurance id number"
                id="insurance id number"
                value={newIdentificationNumber || ''}
                onChange={(e) => setIdentificationNumber(e.target.value)}
            />
            <div className={classes.underline} />
            <h5 className={classes.title}>Membership QR ID</h5>
            <input
                className={classes.inputFields}
                placeholder="Click to assign QR Code"
                name="membership-qr-id"
                id="membership-qr-id"
                value={newQR || ''}
                onChange={(e) => setQR(e.target.value)}
            />
            <div className={classes.underline} />
            <div className={classes.btnDiv}>
                <CTAButton1
                    className={classes.btn}
                    // disabled={renderDisabled()}
                    onClick={() => handleSave()}
                >
                    Save Changes
                </CTAButton1>
            </div>
        </div>
    );
};

export default EditMemberInfo;
