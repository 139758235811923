import { ThemeProvider } from '@mui/material/styles';
import { SAMARITAN_MATERIAL_UI_THEME } from '../../settings';

const Default = ({ children }: { children: React.ReactNode }) => {
    return (
        <ThemeProvider theme={SAMARITAN_MATERIAL_UI_THEME}>
            {children}
        </ThemeProvider>
    );
};

export default Default;
