import SamaritanFetch from './httpClient';

export const fetchQOLMeasures = (
    QOL_ID: number,
    holder_id: number,
    additionalParams?: boolean
) => {
    const baseQuery: { [key: string]: number } = {
        'quality_of_life_measures[homeless_id]': holder_id
    };

    if (additionalParams) {
        baseQuery.page = 1;
        baseQuery.per_page = 1;
    }

    return SamaritanFetch.v3(`/quality_of_life_measures/${QOL_ID}/answers`, {
        method: 'GET',
        query: baseQuery
    });
};

export const getQOLMeasures = () => {
    return SamaritanFetch.v3(`/quality_of_life_measures`, {
        method: 'GET'
    })
        .then((res: Response) => res.json())
        .then(
            (data: { quality_of_life_measures: object[] }) =>
                data.quality_of_life_measures
        );
};
