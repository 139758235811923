import { useCallback, useEffect, useState } from 'react';

import { ActionStepTemplate } from 'types';

import { fetchTemplateActions } from 'api/intake';

export default function useTemplateActions(org_id: number) {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [templateActions, setTemplateActions] = useState<
        ActionStepTemplate[] | null
    >(null);

    const fetch = useCallback(() => {
        setError(null);
        setIsLoading(true);

        fetchTemplateActions(org_id)
            .then((res: Response) => {
                if (!res.ok) {
                    throw new Error(`Error: ${res.status} ${res.statusText}`);
                }
                return res.json();
            })
            .then((data: { action_step_templates: ActionStepTemplate[] }) => {
                setTemplateActions(data.action_step_templates);
            })
            .catch((e: Error) => {
                setError(e);
                setTemplateActions(null);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [org_id]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        error,
        isError: error !== null,
        isLoaded: !isLoading && !error,
        isLoading,
        refetch: fetch,
        templateActions
    };
}
