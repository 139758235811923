const addOrdinalSuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
        return day + 'th';
    } else {
        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return day + 'st';
            case 2:
                return day + 'nd';
            case 3:
                return day + 'rd';
            default:
                return day + 'th';
        }
    }
};

export const formatDate = (date: string) => {
    const newDate = new Date(date);
    const options: object = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = newDate.toLocaleDateString('en-US', options);

    const day = newDate.getDate();
    const ordinalSuffix = addOrdinalSuffix(day);

    const finalDate = formattedDate.replace(day.toString(), ordinalSuffix);

    return finalDate;
};

export const getColor = (
    index: number,
    colors: string | string[],
    options: number
) => {
    const primaryColor = [
        '#A9142A',
        '#A9610B',
        '#A98E0B',
        '#268D8D',
        '#0BA162'
    ];

    const secondaryColor = [
        '#FFE9EB',
        '#FFF4E7',
        '#FEFBE7',
        '#EBFAFB',
        '#E7FEF4'
    ];

    if (colors === 'primary') {
        colors = primaryColor;
    } else if (colors === 'secondary') {
        colors = secondaryColor;
    } else if (colors === 'primaryReverse') {
        colors = primaryColor.reverse();
    } else {
        colors = secondaryColor.reverse();
    }

    const segmentSize = 1 / (options - 1);
    const colorIndex = segmentSize * index;
    const color = colors[Math.floor(colorIndex * (colors.length - 1))];

    return color;
};
