import { GuideMessages } from '../../../../../types';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        secondaryMessage: {
            position: 'relative',
            top: '-30px',
            right: '-15px',
            background: '#a1a7d0',
            borderRadius: '10px',
            color: '#fff',
            height: 'auto',
            padding: '24px',
            width: '100%',
            '& ul li': {
                margin: '14px 0'
            }
        },
        listItem: {}
    })
);

type SecondaryMessageProps = {
    guide: boolean;
    intakeStep: number;
    messageItems: GuideMessages;
    showGuide: boolean;
    showIntro: boolean;
    stepTitle: string;
    stepTwo: boolean;
};

const SecondaryMessage: React.FC<SecondaryMessageProps> = ({
    guide,
    intakeStep,
    messageItems,
    showGuide,
    showIntro,
    stepTitle,
    stepTwo
}) => {
    const classes = useStyles();

    const secondaryMessagesToShow = stepTwo
        ? messageItems[stepTitle || intakeStep]?.stepTwo?.secondaryMessage
        : messageItems[stepTitle || intakeStep]?.secondaryMessage;

    return (
        <>
            {!showIntro && !guide && showGuide && secondaryMessagesToShow && (
                <div className={classes.secondaryMessage}>
                    <ul style={{ padding: '0 20px' }}>
                        {secondaryMessagesToShow.map((message, index) => (
                            <li
                                key={index}
                                className={classes.listItem}
                                dangerouslySetInnerHTML={{
                                    __html: message
                                }}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default SecondaryMessage;
