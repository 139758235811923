import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '../../CaseManagement/components/Card';

import { newNeedThunk } from '../../../../../redux/actions/needs';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    descriptionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    description: {
        fontSize: '18px',
        lineHeight: '25px'
    },
    fakeInput: {
        width: '100%',
        height: '20px',
        borderBottom: '1px solid #E3E3FA'
    },
    addBtn: {
        background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
        borderRadius: '36px',
        color: '#FFFFFF',
        textTransform: 'none',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: '800',
        padding: '8px 24px'
    }
}));

const OtherCard = ({ amount }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [customNeed, setCustomNeed] = useState('');
    const [showInput, setShowInput] = useState(false);

    const handleAddNeed = () => {
        if (customNeed) {
            setShowInput(false);
            setCustomNeed('');

            dispatch(newNeedThunk(customNeed, amount, null, null, null, null));
        }
    };

    return (
        <Card
            cardStyle={{
                width: '100%',
                cursor: 'pointer',
                '&:active': {
                    transform: 'scale(0.98)'
                }
            }}
        >
            <div className={classes.descriptionContainer}>
                {!showInput ? (
                    <>
                        <span className={classes.description}>Other:</span>
                        <span
                            className={classes.fakeInput}
                            onClick={() => setShowInput(true)}
                        />
                    </>
                ) : (
                    <>
                        <TextField
                            fullWidth
                            autoFocus
                            InputProps={{ disableUnderline: true }}
                            value={customNeed}
                            onChange={(e) => setCustomNeed(e.target.value)}
                            variant="standard"
                        />
                        <Button
                            className={classes.addBtn}
                            onClick={() => handleAddNeed()}
                        >
                            Add
                        </Button>
                    </>
                )}
            </div>
        </Card>
    );
};

OtherCard.propTypes = {
    amount: PropTypes.number.isRequired
};

export default OtherCard;
