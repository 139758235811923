import { useEffect, useRef, useState } from 'react';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelCrop
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        photoCropContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            padding: '10px 20px'
        },
        closeBtnContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 0',
            width: '98%'
        },
        closeIcon: {
            fontSize: '24px'
        },
        title: {
            fontSize: '24px',
            fontWeight: 800,
            padding: '0px 16px'
        },
        cropWrapper: {
            border: '8px solid rgba(227, 227, 250, 0.5)',
            borderRadius: '16px',
            maxHeight: '30%',
            maxWidth: '500px !important',
            '& svg': {
                borderRadius: '8px'
            }
        },
        previewContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px 0',
            '& img': {
                border: '8px solid rgba(227, 227, 250, 0.5)',
                borderRadius: '16px',
                height: 'auto',
                maxWidth: '100%',
                objectFit: 'cover'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            padding: '0 0 20px 0',
            width: '80%',
            '& button': {
                borderRadius: '50px',
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 800,
                padding: '12px 24px',
                textTransform: 'none',
                '& i': {
                    fontSize: '20px',
                    marginRight: '10px'
                },
                '&:hover': {
                    color: '#7378E8'
                }
            }
        },
        recropBtn: {
            color: '#7378E8 !important',
            width: '100%'
        },
        cropBtn: {
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            width: '300px',
            '&:hover': {
                color: '#E7E7FF !important'
            }
        },
        doneBtn: {
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            width: '100%',
            '&:hover': {
                color: '#E7E7FF !important'
            }
        }
    })
);

const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) => {
    return centerCrop(
        makeAspectCrop(
            { unit: 'px', width: mediaWidth },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
};

const convertToBlob = (base64Image: string) => {
    const byteString = atob(base64Image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const croppedPictureBlob = new Blob([ab], {
        type: 'image/jpeg'
    });

    return croppedPictureBlob;
};

type PhotoCropProps = {
    handleModalClick: () => void;
    image: File;
    setProfilePicture: (profilePicture: Blob) => void;
};

const PhotoCrop: React.FC<PhotoCropProps> = ({
    handleModalClick,
    image,
    setProfilePicture
}) => {
    const classes = useStyles();
    const imgRef = useRef<HTMLImageElement | null>(null);

    const [crop, setCrop] = useState<PixelCrop>();
    const [croppedImg, setCroppedImg] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState(URL.createObjectURL(image));

    const handleCloseClick = () => {
        handleModalClick();
    };

    const handleCropClick = () => {
        if (!imgRef.current || !imgRef.current.complete) {
            throw new Error('Image load error');
        }

        const image = imgRef.current as HTMLImageElement;
        const scaleX = image.naturalWidth / imgRef.current.width;
        const scaleY = image.naturalHeight / imgRef.current.height;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (crop) {
            canvas.width = crop.width;
            canvas.height = crop.height;

            ctx?.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        }

        const base64CroppedImg = canvas.toDataURL('image/jpeg');

        setCroppedImg(base64CroppedImg);
    };

    const handleDoneClick = () => {
        const newProfilePicture = croppedImg ? convertToBlob(croppedImg) : null;

        if (newProfilePicture) {
            setProfilePicture(newProfilePicture);
        }

        handleModalClick();
    };

    const handleImageLoaded = () => {
        const img = imgRef.current;
        const { width, height } = img
            ? img.getBoundingClientRect()
            : { width: 0, height: 0 };

        setCrop(centerAspectCrop(width, height, 1));
    };

    const handleRecropClick = () => {
        setCroppedImg(null);
    };

    useEffect(() => {
        if (image) {
            setImageUrl(URL.createObjectURL(image));
        }
    }, [image]);

    return (
        <div className={classes.photoCropContainer}>
            <div className={classes.closeBtnContainer}>
                <span className={classes.title}>Set Your Profile Picture</span>
                <Button onClick={() => handleCloseClick()}>
                    <i className={`far fa-times ${classes.closeIcon}`} />
                </Button>
            </div>
            {!croppedImg && (
                <ReactCrop
                    aspect={1}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    className={classes.cropWrapper}
                >
                    <img
                        alt="profile-crop"
                        ref={imgRef}
                        src={imageUrl}
                        onLoad={() => handleImageLoaded()}
                        style={{
                            borderRadius: '8px'
                        }}
                    />
                </ReactCrop>
            )}
            <div className={classes.previewContainer}>
                {croppedImg && (
                    <img
                        src={URL.createObjectURL(convertToBlob(croppedImg))}
                        alt="profile-crop"
                    />
                )}
            </div>
            <div className={classes.buttonContainer}>
                {!croppedImg && (
                    <Button
                        className={classes.cropBtn}
                        onClick={() => handleCropClick()}
                    >
                        <i className="far fa-crop" />
                        Apply Crop
                    </Button>
                )}
                {croppedImg && (
                    <Button
                        className={classes.doneBtn}
                        onClick={() => handleDoneClick()}
                    >
                        Set as Profile Picture
                    </Button>
                )}
                {croppedImg && (
                    <Button
                        className={classes.recropBtn}
                        onClick={() => handleRecropClick()}
                    >
                        Adjust Crop
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PhotoCrop;
