import classNames from 'classnames';

import GuideAsset from '../../../../assets/intake-guide-mockup.png';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        popupWrapper: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.7)',
            zIndex: 12,
            overflow: 'auto'
        },
        test: {
            position: 'relative',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#1F2152',
            borderRadius: '10px',
            width: '480px',
            padding: '32px 24px',
            '& img': {
                height: '600px'
            }
        },
        cardBody: {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            gap: '3rem',
            fontWeight: 300,
            borderRadius: '0 0 10px 10px',
            padding: '1.5rem 0',
            '& h2': {
                margin: 0
            },
            '& p': {
                margin: 0
            }
        },
        cardTitle: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '0.5rem'
        },
        title: {
            fontWeight: 700
        },
        description: {
            color: '#5A6A81',
            fontWeight: 300,
            textAlign: 'center'
        },
        cardDescription: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '0.25rem',
            width: '80%'
        },
        subtext: {
            color: '#A6A6BF',
            fontWeight: 300,
            fontSize: '14px',
            paddingBottom: '0.75rem'
        },
        btn: {
            borderRadius: '50px',
            fontWeight: 700,
            fontSize: '18px',
            padding: '10px 24px',
            textTransform: 'none',
            width: '100%'
        },
        showBtn: {
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#FFFFFF'
        },
        dontShowBtn: {
            color: '#999DFF'
        }
    })
);

type Props = {
    ctpId: number;
    setShowGuideSettingPopup: (value: boolean) => void;
    updateUserSettings: (
        id: number,
        settings: { [key: string]: boolean | string }
    ) => void;
};

const GuideSettingsPopup: React.FC<Props> = ({
    ctpId,
    setShowGuideSettingPopup,
    updateUserSettings
}) => {
    const classes = useStyles();

    const dontShowBtn = classNames(classes.btn, classes.dontShowBtn);
    const showBtn = classNames(classes.btn, classes.showBtn);

    const handleHideGuideClick = () => {
        updateUserSettings(ctpId, { intakeGuide: false });
        setShowGuideSettingPopup(false);
    };

    const handleShowGuideClick = () => {
        updateUserSettings(ctpId, { intakeGuide: true });
        setShowGuideSettingPopup(false);
    };

    return (
        <div className={classes.popupWrapper}>
            <div className={classes.test}>
                <img src={GuideAsset} alt="Guide Asset" />
                <div className={classes.cardBody}>
                    <div className={classes.cardTitle}>
                        <h2 className={classes.title}>
                            Member Registration Guide
                        </h2>
                        <p className={classes.description}>
                            Do you want to continue seeing the step-by-step
                            guide when registering new members?
                        </p>
                    </div>
                    <div className={classes.cardDescription}>
                        <p className={classes.subtext}>
                            You can change this anytime in the settings page
                        </p>
                        <Button
                            className={showBtn}
                            onClick={handleShowGuideClick}
                        >
                            Show the guide
                        </Button>
                        <Button
                            className={dontShowBtn}
                            onClick={handleHideGuideClick}
                        >
                            Don't show the guide
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideSettingsPopup;
