import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        fontWeight: '800',
        fontSize: '18px',
        lineHeight: '22px',
        padding: '10px 0'
    },
    messageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '70px',
        padding: '14px 20px',
        borderBottom: '2px solid #EDEDFA',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        '& i': {
            fontSize: '22px',
            lineHeight: '22px'
        }
    },
    memberInfo: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            marginRight: '10px'
        },
        '& span': {
            color: '#1F1F51'
        }
    },
    conversationHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& span': {
            color: '#1F1F51'
        }
    },
    conversationTitle: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px'
    },
    conversationName: {
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '20px',
        paddingTop: '10px'
    },
    backButton: {
        color: '#7279E8',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '500',
        cursor: 'pointer',
        marginRight: '35px'
    },
    ellipsisBtn: {
        color: '#1F2051'
    }
}));

const Header = ({
    closeView,
    handleEllipsisClick,
    memberName,
    memberPhoto,
    samaritanName
}) => {
    const classes = useStyles();

    return (
        <div className={classes.messageHeader}>
            <div className={classes.memberInfo}>
                <i
                    className={`${classes.backButton} far fa-chevron-left`}
                    onClick={() => closeView()}
                />
                {memberName && memberPhoto && (
                    <>
                        <img src={memberPhoto} alt="profile" />
                        <span style={{ fontWeight: '800' }}>{memberName}</span>
                    </>
                )}
                {memberName && samaritanName && (
                    <div className={classes.conversationHeader}>
                        <span className={classes.conversationTitle}>
                            {memberName}'s conversation with:
                        </span>
                        <span className={classes.conversationName}>
                            {samaritanName}
                        </span>
                    </div>
                )}
                {!memberName && !samaritanName && (
                    <span className={classes.headerTitle}>Messages</span>
                )}
            </div>
            {handleEllipsisClick && (
                <IconButton onClick={() => handleEllipsisClick()} size="large">
                    <i className={`${classes.ellipsisBtn} far fa-ellipsis-h`} />
                </IconButton>
            )}
        </div>
    );
};

export default Header;
