import CONSTANTS from '../constants/constants';

const initialState = {
    goalActionCompletePopupId: 0,
    layoutTitles: {
        title: '',
        subtitle: ''
    },
    showGoalActionCompletePopup: false
};

export default function samaritan_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_MONTHLY_SUMMARY:
            return {
                ...state,
                isFetchingMonthlySummary: true
            };
        case CONSTANTS.SET_MONTHLY_SUMMARY:
            return {
                ...state,
                isFetchingMonthlySummary: false,
                monthlySummary: action.payload
            };
        case CONSTANTS.SET_LAYOUT_TITLES:
            return {
                ...state,
                layoutTitles: action.payload
            };

        case CONSTANTS.SHOW_GOAL_ACTION_COMPLETE_POPUP:
            return {
                ...state,
                showGoalActionCompletePopup: action.payload
            };
        case CONSTANTS.SET_GOAL_ACTION_COMPLETE_POPUP:
            return {
                ...state,
                goalActionCompletePopupId: action.payload
            };
        case CONSTANTS.RESET:
            return initialState;
        default:
            return state;
    }
}
