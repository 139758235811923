import ReactGA from 'react-ga4';

import { Deployments, Homeless } from 'types';

import Card from '../../CaseManagement/components/Card';

import Button from '@mui/material/Button';

type Props = {
    classes: {
        body: string;
        editButton: string;
        editButtonDiv: string;
        holderOrgDiv: string;
        marginBottom: string;
        memberInfoContainer: string;
        name: string;
        orgLogo: string;
        orgName: string;
        title: string;
    };
    deployments: Deployments[];
    exited: boolean;
    formatDate: (date: string) => string;
    holder: Homeless;
    setIsEditing: (isEditing: boolean) => void;
};

const MemberInfo: React.FC<Props> = ({ classes, holder, ...props }) => {
    const { deployments, exited, formatDate, setIsEditing } = props;

    const memberDeployment = deployments.find(
        (dep) => dep.id === holder.deployment_id
    );

    const displayFullName = () => {
        const trimmedFirstName = (holder.first_name || '').trim();
        const trimmedMiddleName = (holder.middle_name || '').trim();
        const trimmedLastName = (holder.last_name || '').trim();

        if (
            !trimmedFirstName.length &&
            !trimmedMiddleName.length &&
            !trimmedLastName.length
        ) {
            return '-';
        } else {
            return `${trimmedFirstName} ${trimmedMiddleName} ${trimmedLastName}`.trim();
        }
    };

    const handleEditClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Edit Member Info Click'
        });

        setIsEditing(true);

        const memberInfoContainer = document.getElementById(
            'member-info-container'
        );

        if (memberInfoContainer) {
            memberInfoContainer.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const namesAreNull = () => {
        if (
            holder.first_name === null &&
            holder.middle_name === null &&
            holder.last_name === null
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.memberInfoContainer} id="member-info-container">
            <Card info member name={holder.name} />
            <div className={`${classes.body} ${classes.marginBottom}`}>
                {!exited && (
                    <div className={classes.editButtonDiv}>
                        <Button
                            onClick={() => handleEditClick()}
                            title="Edit Private Info"
                            className={classes.editButton}
                        >
                            <i className="far fa">&#xf040;</i>
                        </Button>
                    </div>
                )}
                <h5 className={classes.title}>Full Name</h5>
                <h4 className={classes.name}>
                    {!namesAreNull() ? displayFullName() : '-'}
                </h4>
                <h5 className={classes.title}>Date Of Birth</h5>
                {holder.birthday ? (
                    <h4 className={classes.name}>
                        {formatDate(holder.birthday)}
                    </h4>
                ) : (
                    <h4 className={classes.name}> - </h4>
                )}
                {deployments.length > 1 && (
                    <>
                        <h5 className={classes.title}>Member's Insurance</h5>
                        <div className={classes.holderOrgDiv}>
                            <h4 className={classes.orgName}>
                                {memberDeployment ? memberDeployment.name : '-'}
                            </h4>
                        </div>
                    </>
                )}
                <h5 className={classes.title}>Insurance ID Number</h5>
                <h4 className={classes.name}>
                    {holder.member_id &&
                    holder.member_id !== null &&
                    holder.member_id.length !== 0
                        ? holder.member_id
                        : '-'}
                </h4>
                <h5 className={classes.title}>Membership QR ID</h5>
                <h4 className={classes.name}>
                    {holder.qr_code_id &&
                    holder.qr_code_id !== null &&
                    holder.qr_code_id.length !== 0
                        ? holder.qr_code_id
                        : '-'}
                </h4>
            </div>
        </div>
    );
};

export default MemberInfo;
