import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import ActionCardOverlay from '../ActionSteps/components/ActionCardOverlay';
import Card from './Card';
import History from './History';
import Input from './Input';
import LoadingCircle from '../../../../_shared/LoadingCircle';
import Modal from '../../../../_shared/Modal';

import { formatLongDate, getShortMonthName } from '../utils';

import { fetchGoalActionHistory } from '../../../../../api/goal_actions';
import { fetchNeedHistory } from '../../../../../api/needs';
import useRemoteCopy from '../../../../../hooks/useRemoteCopy';

import { newGoalThunk } from '../../../../../redux/actions/goals';
import { newNeedThunk } from '../../../../../redux/actions/needs';
import { updateBonusAmount } from '../../../../../redux/actions/homeless';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionTitle: {
            position: 'absolute',
            top: '-40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#8882D8',
            borderRadius: '100px',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: 800,
            letterSpacing: '0.1em',
            padding: '8px 20px',
            textTransform: 'uppercase'
        },
        actionBody: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '28px',
            padding: '12px 0px',
            '& span': {
                padding: '10px 0'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px'
        },
        addButton: {
            background: 'linear-gradient(90deg, #9897E3 0%, #B8C2FF 100%)',
            borderRadius: '100px',
            color: '#fff',
            fontSize: '1.25rem',
            fontWeight: 800,
            margin: '0',
            padding: '10px 20px',
            textTransform: 'none'
        },
        cancelButton: {
            background: 'transparent',
            borderRadius: '100px',
            color: '#F5577A',
            fontSize: '1.25rem',
            fontWeight: 700,
            marginTop: '10px',
            padding: '15px 30px',
            textTransform: 'none'
        },
        disabled: {
            background: '#E3E4FA',
            color: '#fff !important'
        },
        inputTitle: {
            color: '#1F1F51',
            fontSize: '14px',
            fontWeight: 700,
            letterSpacing: '0.15em',
            opacity: '50%',
            textTransform: 'uppercase'
        },
        inputTitleDisabled: {
            color: '#1F1F51',
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: '0.15em',
            textTransform: 'uppercase'
        },
        bonusTooltip: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            color: '#1F2152',
            fontSize: '16px',
            fontWeight: 300,
            padding: '12px !important',
            width: 'fit-content',
            '& i': {
                color: '#7378E8',
                fontSize: '24px',
                marginRight: '10px'
            }
        }
    })
);

type AddProps = {
    addFailed?: boolean;
    cancelAction: (value: boolean) => void;
    dateISO: string;
    failedNeed?: any;
    holder: {
        description: string;
        id: number;
        name: string;
        photo: string;
    };
    maxBonusAmount: number;
    profileCompletionType?: null | string;
    remainingBonusAmount: number;
    setAddFailed?: (value: boolean) => void;
    setDateISO: (value: string) => void;
    setFailedNeed?: (value: any) => void;
    setShowActionOverlay?: (value: boolean) => void;
    showActionOverlay?: boolean;
    totalBonusAmount?: number;
    type: string;
    updateProfileCompletedItems: (type: string) => void;
    userID: number;
};

const Add: React.FC<AddProps> = ({
    addFailed,
    cancelAction,
    dateISO,
    failedNeed,
    holder,
    maxBonusAmount,
    profileCompletionType,
    remainingBonusAmount,
    setAddFailed,
    setDateISO,
    setFailedNeed,
    setShowActionOverlay,
    showActionOverlay,
    type,
    updateProfileCompletedItems,
    userID
}) => {
    const addRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const refetchCount = useRef(0);
    const suggestions = useRemoteCopy(
        type === 'action step' ? 'action_step_suggestions' : 'need_suggestions'
    );

    const actionType = type.charAt(0).toUpperCase() + type.slice(1);
    const { id } = holder;

    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [history, setHistory] = useState<any[] | null>(null);
    const [useHistory, setUseHistory] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const calculateDateDifference = (date1: string, date2: string) => {
        const today = new Date();
        const date1Obj = new Date(date1);
        const date2Obj = new Date(date2);
        const timeDiff = Math.abs(date2Obj.getTime() - date1Obj.getTime());
        const newDate = new Date(timeDiff + today.getTime());

        return newDate.toISOString();
    };

    const handleCancelClick = () => {
        cancelAction(false);
    };

    const handleUseHistory = (data: {
        amount: string;
        created_at: string;
        description: string;
        due_at: string;
        match_value: number;
    }) => {
        const { amount, created_at, description, due_at, match_value } = data;

        setUseHistory(true);
        setDescription(description);

        if (type === 'action step') {
            if (match_value === 0) {
                setAmount('No Bonus');
            } else {
                const bonusAmount =
                    match_value > remainingBonusAmount
                        ? `$${remainingBonusAmount}`
                        : `$${match_value}`;

                if (bonusAmount === '$0') {
                    setAmount('No Bonus');
                } else {
                    setAmount(bonusAmount);
                }
            }
        } else {
            if (amount === '0.0') {
                setAmount('No Amount');
            } else {
                const amountValue = parseInt(amount.replace(/[^0-9.-]+/g, ''));

                setAmount(`$${amountValue}`);
            }
        }

        setDate(formatLongDate(calculateDateDifference(created_at, due_at)));
        setDateISO(calculateDateDifference(created_at, due_at));
    };

    const shouldDisplayTooltip =
        (remainingBonusAmount < maxBonusAmount && !amount.length) ||
        remainingBonusAmount === 0 ||
        (showTooltip && remainingBonusAmount !== maxBonusAmount);

    const submitAdd = () => {
        const amountInt = parseInt(amount.replace(/[^0-9.-]+/g, ''));
        const amountValue = !isNaN(amountInt) ? amountInt : 0;

        if (type === 'action step') {
            dispatch(
                newGoalThunk(id, description, dateISO, amountValue, null, null)
            );

            dispatch(updateBonusAmount(amountValue));
            updateProfileCompletedItems('action_steps');
        } else {
            dispatch(newNeedThunk(description, amountValue, dateISO));
            updateProfileCompletedItems('needs');
        }

        if (profileCompletionType) {
            dispatch(updateProfileCompletedItems(profileCompletionType));
        }

        cancelAction(false);
    };

    const inputTitleClass = (input: string) => {
        return input
            ? classes.inputTitle
            : `${classes.inputTitle} ${classes.inputTitleDisabled}`;
    };

    const renderBonusTooltip = (remainingAmount: number) => {
        if (remainingAmount <= 0) {
            return `${getShortMonthName()}'s bonus pool was fully assigned. Bonuses will be assignable starting next month!`;
        }

        return `$${remainingAmount} remaining from ${getShortMonthName()}'s bonus pool.`;
    };

    const renderDisabled = () => {
        if (!description.length || !amount.length || !date.length) {
            return {
                classes: `${classes.addButton} ${classes.disabled}`,
                disabled: true
            };
        } else {
            return {
                classes: classes.addButton,
                disabled: false
            };
        }
    };

    useEffect(() => {
        if (addRef && addRef.current) {
            addRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }, [addRef, suggestions]);

    useEffect(() => {
        if (suggestions.isError && refetchCount.current < 5) {
            refetchCount.current++;
            suggestions.refetch();
        }

        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [suggestions]);

    // TODO: Fix any types
    useEffect(() => {
        if (type === 'action step') {
            fetchGoalActionHistory(userID)
                .then((data: any) => setHistory(data))
                .catch((error: any) => {
                    console.error('Error fetching goal action history:', error);
                });
        } else {
            fetchNeedHistory(userID)
                .then((data: any) => setHistory(data))
                .catch((error: any) => {
                    console.error('Error fetching need history:', error);
                });
        }
    }, [type, userID]);

    useEffect(() => {
        if (description.length && remainingBonusAmount <= 0) {
            setAmount('No Bonus');
        }
    }, [description, remainingBonusAmount]);

    if (isErrored) {
        return (
            <Modal
                open={isErrored}
                onClose={() => {
                    setIsErrored(false);
                }}
            >
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Card id="add-card" style={{ position: 'relative' }}>
            {addFailed && failedNeed && showActionOverlay && (
                <ActionCardOverlay
                    setAddFailed={setAddFailed!}
                    setFailedAction={setFailedNeed!}
                    setShowActionOverlay={setShowActionOverlay!}
                >
                    <i className="fas fa-exclamation-circle" />
                    <span>{failedNeed.error}</span>
                </ActionCardOverlay>
            )}
            <div style={{ position: 'relative', width: '100%' }} ref={addRef}>
                {!suggestions.isLoaded || !history || !suggestions ? (
                    <LoadingCircle />
                ) : (
                    <>
                        <div
                            className={classes.actionTitle}
                        >{`New ${actionType}`}</div>
                        <div className={classes.actionBody}>
                            <Input
                                add
                                intakeInput={description}
                                setIntakeInput={setDescription}
                                inputTitleClass={inputTitleClass}
                                suggestions={suggestions.remoteCopy}
                                type={'descriptions'}
                                useHistory={useHistory}
                            />
                            {history.length && !description.length ? (
                                <History
                                    historyData={history}
                                    handleUseHistory={handleUseHistory}
                                />
                            ) : null}
                            {description.length ? (
                                <>
                                    <Input
                                        add
                                        intakeInput={amount}
                                        setIntakeInput={setAmount}
                                        inputTitleClass={inputTitleClass}
                                        label={
                                            type === 'need'
                                                ? 'it costs'
                                                : 'bonus'
                                        }
                                        remainingBonusAmount={
                                            remainingBonusAmount
                                        }
                                        setShowTooltip={setShowTooltip}
                                        suggestions={suggestions.remoteCopy}
                                        type={
                                            type === 'need' ? 'cost' : 'bonus'
                                        }
                                        useHistory={useHistory}
                                    />
                                    {shouldDisplayTooltip && (
                                        <span className={classes.bonusTooltip}>
                                            <i className="fas fa-info-circle" />
                                            {renderBonusTooltip(
                                                remainingBonusAmount
                                                // maxBonusAmount,
                                                // totalBonusAmount
                                            )}
                                        </span>
                                    )}
                                </>
                            ) : null}
                            {description.length && amount.length ? (
                                <Input
                                    add
                                    intakeInput={date}
                                    setIntakeInput={setDate}
                                    setDateISO={setDateISO}
                                    inputTitleClass={inputTitleClass}
                                    label={'need it latest by'}
                                    suggestions={suggestions.remoteCopy}
                                    type={'due_dates'}
                                    useHistory={useHistory}
                                />
                            ) : null}
                        </div>
                        {description.length ? (
                            <div className={classes.buttonContainer}>
                                <Button
                                    className={renderDisabled().classes}
                                    disabled={renderDisabled().disabled}
                                    onClick={submitAdd}
                                >
                                    {`Add ${actionType
                                        .split(' ')
                                        .map(
                                            (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                        )
                                        .join(' ')}`}
                                </Button>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => handleCancelClick()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </Card>
    );
};

export default Add;
