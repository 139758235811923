import React from 'react';
import { animated, useTrail } from 'react-spring';

type SlideInItemsProps = {
    children: React.ReactNode;
    config: object;
};

const SlideInItems = ({ children, config }: SlideInItemsProps) => {
    // TOOD: Fix any type
    const childrenArr: any = React.Children.toArray(children);
    const trail = useTrail(childrenArr.length, {
        from: {
            transform: 'translateX(60%)',
            opacity: 0
        },
        to: {
            transform: 'translateX(0%)',
            opacity: 1
        },
        config: {
            tension: 410,
            friction: 30,
            clamp: true
        },
        ...config
    });

    return trail.map((props, i) => (
        <animated.div style={props} key={childrenArr[i].key}>
            {childrenArr[i]}
        </animated.div>
    ));
};

export default SlideInItems;
