import CTAButton1 from 'components/_shared/buttons/CTAButton1';
import EditButton from '../../CaseManagement/components/EditButton';
import PopUp from '../../PopUp';

import { formatNumber, renderLanguage } from '../utils';

type Props = {
    classes: {
        btn: string;
        btnDiv: string;
        inputFields: string;
        language: string;
        languageField: string;
        name: string;
        title: string;
        underline: string;
        underlineError: string;
    };
    handleSave: () => void;
    holder: any; // update this
    newEmail: string;
    newLanguage: string;
    newPhoneNumber: string;
    setEmail: (value: string) => void;
    setNewLanguage: (value: string) => void;
    setPhoneNumber: (value: string) => void;
    setUpdateLanguage: (value: boolean) => void;
    updateLanguage: string;
    validateEmail: (email: string) => boolean;
    validatePhone: (phone: string) => boolean;
};

const EditContactInfo: React.FC<Props> = ({ classes, ...props }) => {
    const {
        holder,
        handleSave,
        newEmail,
        setEmail,
        newLanguage,
        setNewLanguage,
        newPhoneNumber,
        setPhoneNumber,
        updateLanguage,
        setUpdateLanguage,
        validateEmail,
        validatePhone
    } = props;

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;

        setEmail(email);
    };

    const handlePhoneNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let phone = e.target.value;
        phone = phone.replace(/[^0-9]/g, ``);

        setPhoneNumber(formatNumber(phone));
    };

    // const renderDisabled = () => {
    //     if (!newPhoneNumber && !newEmail) return true;

    //     if (newPhoneNumber && !validatePhone(newPhoneNumber)) return true;

    //     if (newEmail && !validateEmail(newEmail)) return true;

    //     return false;
    // };

    return (
        <div id="edit-contact-body" style={{ padding: '5px 24px 5px' }}>
            <h5 className={classes.title}>Phone Number</h5>
            <input
                type="phone-number"
                className={classes.inputFields}
                placeholder="(123) 456-7890"
                name="phone-number"
                id="phone-number"
                value={newPhoneNumber}
                onChange={(e) => handlePhoneNumberChange(e)}
            />
            <div
                className={
                    validatePhone(newPhoneNumber)
                        ? classes.underline
                        : classes.underlineError
                }
            />
            <h5 className={classes.title}>Email</h5>
            <input
                className={classes.inputFields}
                placeholder="email@domain.com"
                name="email"
                id="email"
                value={newEmail}
                onChange={(e) => handleEmailChange(e)}
            />
            <div
                className={
                    validateEmail(newEmail)
                        ? classes.underline
                        : classes.underlineError
                }
            />
            <h5 className={classes.title}>Language Preference</h5>
            <div className={classes.languageField}>
                <span className={classes.language}>
                    {renderLanguage(newLanguage)}
                </span>
                <EditButton
                    handleEditClick={() => setUpdateLanguage(true)}
                    inputValState={newLanguage}
                />
            </div>
            <div className={classes.btnDiv}>
                <CTAButton1
                    className={classes.btn}
                    // disabled={renderDisabled()}
                    onClick={() => handleSave()}
                >
                    Save Changes
                </CTAButton1>
            </div>
            {updateLanguage && (
                <PopUp
                    content="update language"
                    handleModalClick={setUpdateLanguage}
                    holder={holder}
                    privateInfo
                    setNewLanguage={setNewLanguage}
                />
            )}
        </div>
    );
};

export default EditContactInfo;
