import { forwardRef } from 'react';
import classNames from 'classnames';

import InfoCard from './InfoCard';
import SectionTitle from './SectionTitle';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: '#FFFFFF',
        color: '#1F2152',
        boxShadow: '0px 8px 16px 4px #0000000D',
        borderRadius: '16px',
        padding: '20px 24px'
    },
    info: {
        backgroundColor: '#E7EFFE',
        color: '#062D7A',
        boxShadow: 'none'
    },
    active: {
        '&:active': {
            transform: 'scale(0.98)'
        }
    }
}));

type Props = {
    action?: boolean;
    active?: boolean;
    backgroundColor?: string;
    cardStyle?: React.CSSProperties;
    children?: React.ReactNode;
    className?: string;
    contact?: boolean;
    count?: number;
    debit?: boolean;
    documents?: boolean;
    enrollment?: boolean;
    exited?: boolean;
    facts?: boolean;
    funds?: boolean;
    history?: boolean;
    id?: string;
    info?: boolean;
    lifestory?: boolean;
    member?: boolean;
    name?: string;
    need?: boolean;
    onClick?: () => void;
    profile?: boolean;
    status?: boolean;
    style?: React.CSSProperties;
};

const Card: React.FC<Props> = forwardRef(
    (
        {
            action,
            active,
            children,
            className,
            contact,
            count,
            debit,
            documents,
            enrollment,
            exited,
            facts,
            funds,
            history,
            info,
            lifestory,
            member,
            name,
            need,
            profile,
            status,
            ...options
        },
        ref: React.Ref<HTMLDivElement> | null
    ) => {
        const classes = useStyles();

        const combinedClassName = classNames(
            classes.card,
            { [classes.info]: info },
            active && classes.active,
            className
        );

        const renderType = () => {
            if (action) return 'actions';
            if (contact) return 'contact';
            if (debit) return 'debit';
            if (documents) return 'documents';
            if (enrollment) return 'enrollment';
            if (facts) return 'facts';
            if (funds) return 'funds';
            if (history) return 'history';
            if (lifestory) return 'lifestory';
            if (member) return 'member';
            if (need) return 'needs';
            if (profile) return 'profile';
            if (status) return 'status';

            return '';
        };

        return (
            <div
                className="card"
                id={options.id}
                ref={ref}
                style={options.cardStyle}
            >
                {info && (
                    <SectionTitle
                        count={count || 0}
                        name={name || ''}
                        title={renderType()}
                    />
                )}
                <div
                    className={combinedClassName}
                    onClick={options.onClick ? options.onClick : undefined}
                    style={{
                        cursor: options.onClick ? 'pointer' : 'default',
                        backgroundColor: options.backgroundColor,
                        ...options.style
                    }}
                >
                    {info && (
                        <InfoCard
                            count={count}
                            exited={exited}
                            name={name}
                            type={renderType()}
                        />
                    )}
                    {children}
                </div>
            </div>
        );
    }
);

export default Card;
