/**
 * getElementInnerDimensions gets an element's width without padding, width, scrollbar, and border
 */
const getElementInnerDimensions = (node: Element) => {
    var computedStyle = window.getComputedStyle(node);

    let width = node.clientWidth; // width with padding
    let height = node.clientHeight; // height with padding

    height -=
        parseFloat(computedStyle.paddingTop) +
        parseFloat(computedStyle.paddingBottom);
    width -=
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight);
    return { height, width };
};

export default getElementInnerDimensions;
