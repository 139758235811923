import { useCallback, useEffect, useRef, useState } from 'react';

import { EditButton } from '../buttons';
import FormInput from '../../../../_shared/FormInput';
import Underline from './Underline';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 15px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            width: '100%',
            '&:focus': {
                outline: 'none !important'
            },
            '&:placeholder-shown': {
                fontStyle: 'italic'
            }
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        phoneInputContainer: {
            marginBottom: '40px'
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

type PhoneInputProps = {
    inputTitleClass: (input: string) => string;
    intakeInput: string;
    remoteCopy: {
        label: string;
        placeholder: string;
        required: boolean;
    };
    setIntakeInput: (input: string) => void;
};

const PhoneInput: React.FC<PhoneInputProps> = ({
    inputTitleClass,
    intakeInput,
    remoteCopy,
    setIntakeInput
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { label, placeholder, required } = remoteCopy;

    const [input, setInput] = useState(intakeInput);
    const [savedInput, setSavedInput] = useState(intakeInput.length > 0);
    const [shouldFocus, setShouldFocus] = useState(false);

    const formatNumber = (phoneInput: string) => {
        const phoneLength = phoneInput.length;

        if (phoneLength < 4) return phoneInput;

        if (phoneLength < 7) {
            return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(3)}`;
        }

        return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(
            3,
            6
        )}-${phoneInput.slice(6, 10)}`;
    };

    const renderDisabledUnderline = () => {
        return !validatePhone(input) && input.length > 0;
    };

    const handleBlurClick = () => {
        if (input.length > 0) {
            handleSaveClick();
        }
    };

    const handleEditClick = () => {
        setSavedInput(false);
        setShouldFocus(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        setPhone(value);
    };

    const handleInputClick = () => {
        setSavedInput(false);
    };

    const handleSaveClick = useCallback(() => {
        if (!validatePhone(input)) {
            return;
        }

        setSavedInput(true);
    }, [input]);

    const setPhone = (phoneInput: string) => {
        const rawPhoneInput = phoneInput.replace(/[^0-9]/g, ``);

        setInput(formatNumber(rawPhoneInput));
    };

    const validatePhone = (phoneInput: string) => {
        const validPhoneRegex = /^\d{10}$/;
        const rawPhoneInput = phoneInput.replace(/[^0-9]/g, ``);

        return validPhoneRegex.test(rawPhoneInput);
    };

    useEffect(() => {
        if (validatePhone(input)) {
            setIntakeInput(input);
        }
    }, [input, setIntakeInput]);

    useEffect(() => {
        if (shouldFocus && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(input.length, input.length);
            setShouldFocus(false);
        }
    }, [shouldFocus, input]);

    return (
        <div className={classes.phoneInputContainer}>
            <span className={inputTitleClass(intakeInput)}>
                {label}
                <span
                    className={
                        classes[required ? 'requiredLabel' : 'optionalLabel']
                    }
                >
                    {`(${required ? 'REQUIRED' : 'OPTIONAL'})`}
                </span>
            </span>
            {!savedInput ? (
                <FormInput
                    autoComplete="on"
                    className={classes.inputFields}
                    id="phone-number-input"
                    name="phone-number"
                    onBlur={handleBlurClick}
                    onChange={handleInputChange}
                    onClick={() => handleInputClick()}
                    placeholder={placeholder}
                    ref={inputRef}
                    saveInput={handleSaveClick}
                    value={input}
                />
            ) : (
                <EditButton
                    handleEditClick={handleEditClick}
                    inputValState={intakeInput}
                    saveInputState={savedInput}
                />
            )}
            {!savedInput && <Underline disabled={renderDisabledUnderline()} />}
        </div>
    );
};

export default PhoneInput;
