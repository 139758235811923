import classNames from 'classnames';
import { useEffect } from 'react';

import { Homeless } from 'types';

import Card from '../../Card';

import useUpdateHomelessInfo from 'hooks/useUpdateHomelessInfo';
import { possessiveSuffix } from 'util/index';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            display: 'flex',
            alignItems: 'center',
            flex: 'none',
            flexGrow: 0,
            gap: '16px',
            borderRadius: '20px',
            boxShadow: '0px 8px 16px 4px #0000000D',
            boxSizing: 'border-box',
            fontWeight: 800,
            height: '40px',
            order: 0,
            padding: '0px 32px',
            textTransform: 'none'
        },
        buttonCancel: {
            border: '1px solid #7378E8',
            color: '#7378E8'
        },
        buttonConfirm: {
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#FFFFFF'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '24px'
        },
        cardStyle: {
            margin: '0px !important'
        },
        closeCardContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px'
        },
        title: {
            color: '#1F2152',
            fontWeight: 800,
            marginBottom: '0',
            textAlign: 'center'
        }
    })
);

type ConfirmPopUpProps = {
    holder: Homeless;
    onClose: () => void;
    setLoading?: (loading: boolean) => void;
    setMembershipStatus?: (status: string) => void;
    setSuccess?: (success: boolean) => void;
};

const ConfirmPopUp: React.FC<ConfirmPopUpProps> = ({
    holder,
    onClose,
    setLoading,
    setMembershipStatus,
    setSuccess
}) => {
    const classes = useStyles();

    const {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updateHomelessInfoRequest
    } = useUpdateHomelessInfo();

    const { id, name } = holder;

    const buttonClass = classNames(classes.button, classes.buttonCancel);

    const handleUpdateProfileStage = (stage: string) => {
        const _formData = new FormData();

        _formData.append('samaritan_member[profile_stage]', stage);

        setMembershipStatus && setMembershipStatus(stage);
        updateHomelessInfoRequest(id, _formData);
    };

    useEffect(() => {
        if (isLoading && setLoading) {
            setLoading(true);
        }
    }, [isLoading, setLoading]);

    useEffect(() => {
        if (isSuccess && setLoading && setSuccess) {
            setLoading(false);
            setSuccess(true);

            setTimeout(() => {
                onClose();
            }, 2000);
        }
    }, [isSuccess, onClose, setLoading, setSuccess]);

    // handle the error
    console.log('error', error);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                reset();
            }, 2000);
        }
    }, [isError, reset]);

    return (
        <div className={classes.closeCardContainer}>
            <h2 className={classes.title}>
                Confirm {possessiveSuffix(name)} Membership
            </h2>
            <Card className={classes.cardStyle}>
                Current membership status:
                <br />
                <b>Pending Confirmation</b>
                <br />
                <ul>
                    <li>
                        By confirming {possessiveSuffix(name)} Samaritan
                        Membership, their Membership will become fully active.
                    </li>
                    <li>
                        If their Membership should end, due to eligibility or
                        other reasons, feel free to close their pending
                        Membership below.
                    </li>
                </ul>
                Do you want to confirm {possessiveSuffix(name)} Membership or
                close their pending Membership?
                <div className={classes.buttonContainer}>
                    <Button
                        className={buttonClass}
                        disabled={isLoading}
                        onClick={() => handleUpdateProfileStage('LIVE')}
                    >
                        <i className="fas fa-check" /> Confirm Membership
                    </Button>
                    <Button
                        className={buttonClass}
                        onClick={() =>
                            handleUpdateProfileStage('UNSUCCESSFUL_PENDING')
                        }
                    >
                        <i className="fas fa-times" />
                        Close Membership
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default ConfirmPopUp;
