import Lottie from 'lottie-react';

import Card from 'components/_shared/Card';
import ActionStepForm from './ActionStepForm';

import iconEdit from 'assets/icon-edit.png';
import Sparkle from 'assets/lottie/sparkle-effect.json';

import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    actionCount: {
        position: 'absolute',
        left: '20px',
        top: '-12px',
        backgroundColor: '#8882D8',
        borderRadius: '100px',
        color: '#fff',
        fontWeight: '800',
        letterSpacing: '0.1em',
        padding: '0.25rem 1rem',
        textTransform: 'uppercase'
    },
    actionDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    },
    actionDetailsContainer: {
        display: 'flex',
        gap: '8px',
        color: '#5A6A81'
    },
    actionStepCard: {
        position: 'relative',
        borderRadius: '20px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '20px',
        width: '90%'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        paddingRight: '16px',
        width: '100%'
    },
    description: {
        fontSize: '20px',
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    editIconContainer: {
        position: 'absolute',
        top: '30%',
        right: '-25px',
        zIndex: 2,
        display: 'flex',
        background: '#fff',
        borderRadius: '100px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '12px',
        '& img': {
            height: '20px'
        },
        '& #iconEdit': {
            cursor: 'pointer'
        },
        '&:active': {
            transform: 'scale(0.95)'
        }
    },
    sparkleContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    '@global': {
        '#summary-card': {
            margin: '20px 0 !important'
        }
    }
}));

type ActionStepSummaryProps = {
    actionSuggestions: any;
    actionType: any[];
    editAction: number[];
    from: string;
    intakeGoals: any[];
    remainingBonusAmount: number;
    setDisplayNeedBtns?: (displayNeedBtns: boolean) => void;
    setEditAction: (editAction: any[]) => void;
    setGuide: (guide: any) => void;
    setIntakeGoals: (intakeGoals: any[]) => void;
    setShowForm: (showForm: boolean) => void;
    showForm: boolean;
};

const ActionStepSummary: React.FC<ActionStepSummaryProps> = ({
    actionSuggestions,
    actionType,
    editAction,
    from,
    intakeGoals,
    remainingBonusAmount,
    setDisplayNeedBtns,
    setEditAction,
    setGuide,
    setIntakeGoals,
    setShowForm,
    showForm
}) => {
    const classes = useStyles();

    const count = actionType ? actionType.length : 0;
    const customStep = false;
    const lastIndex = count - 1;

    const handleEditClick = (index: number) => {
        setShowForm(false);

        if (!editAction.includes(index)) {
            setEditAction([...editAction, index]);
        }
    };

    const renderCost = (bonus: number) => {
        if (bonus === 0 || !bonus) {
            return '- bonus';
        } else {
            return `${bonus} bonus`;
        }
    };

    const renderDueAt = (due_at: number | string) => {
        if (typeof due_at === 'string') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            let dueDate: Date;

            if (due_at.length <= 10) {
                dueDate = new Date(due_at + 'T00:00:00');
            } else if (due_at.length > 10) {
                dueDate = new Date(due_at);
            } else {
                dueDate = new Date(today);
                dueDate.setDate(today.getDate() + parseInt(due_at));
            }

            dueDate.setHours(0, 0, 0, 0);

            const diffTime = Math.abs(dueDate.getTime() - today.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 3) {
                return `ASAP`;
            } else {
                return `${diffDays} Days`;
            }
        } else {
            if (due_at === 0 || !due_at) {
                return '- days';
            } else {
                return `${due_at} days`;
            }
        }
    };

    return (
        <>
            {actionType.map((action, index) => {
                const isLastItem = index === lastIndex;

                if (editAction.includes(index)) {
                    return (
                        <Fade in={!showForm} timeout={500}>
                            <div style={{ width: '100%' }}>
                                <ActionStepForm
                                    key={index}
                                    actionSuggestions={actionSuggestions}
                                    editAction={editAction}
                                    editForm={{
                                        actionIndex: index,
                                        costInputState:
                                            intakeGoals[index].amount,
                                        customGoalInputState:
                                            intakeGoals[index].customGoals,
                                        dateInputState:
                                            intakeGoals[index].due_at,
                                        descriptionInput: true,
                                        descriptionInputState:
                                            intakeGoals[index].description,
                                        goalInputState:
                                            intakeGoals[index].goals,
                                        qolMeasureState:
                                            intakeGoals[index].qolMeasure
                                    }}
                                    from={from}
                                    intakeGoals={intakeGoals}
                                    onClick={() => setDisplayNeedBtns!(true)}
                                    remainingBonusAmount={remainingBonusAmount}
                                    setEditAction={setEditAction}
                                    setGuide={setGuide}
                                    setIntakeGoals={setIntakeGoals}
                                    setShowForm={setShowForm}
                                    showEdit={true}
                                    showForm={showForm}
                                />
                            </div>
                        </Fade>
                    );
                }

                const cardContent = (
                    <Card
                        key={index}
                        className={classes.actionStepCard}
                        id="summary-card"
                    >
                        {actionType.length !== 0 && index === 0 && (
                            <span className={classes.actionCount}>
                                {count} ACTION STEPS
                            </span>
                        )}
                        {intakeGoals[index].default !== true && (
                            <div
                                className={classes.editIconContainer}
                                data-edit={index}
                                onClick={() => handleEditClick(index)}
                            >
                                <img
                                    alt="edit-icon"
                                    id="iconEdit"
                                    src={iconEdit}
                                />
                            </div>
                        )}
                        <div
                            className={classes.cardBody}
                            style={index === 0 ? { paddingTop: '8px' } : {}}
                        >
                            <span
                                className={classes.description}
                                style={customStep ? { color: '#A6A6BF' } : {}}
                            >
                                {action.description}
                            </span>
                            <div
                                className={classes.actionDetailsContainer}
                                style={customStep ? { color: '#A6A6BF' } : {}}
                            >
                                {(action.amount > 0 || customStep) && (
                                    <span className={classes.actionDetails}>
                                        <i className="fal fa-usd-circle" />$
                                        {renderCost(action.amount)}
                                    </span>
                                )}
                                {((action.amount > 0 && action.due_at) ||
                                    customStep) && <span>•</span>}
                                {(action.due_at || customStep) && (
                                    <span className={classes.actionDetails}>
                                        <i className="fal fa-clock" />
                                        {renderDueAt(action.due_at)}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Card>
                );

                return isLastItem && index !== 0 ? (
                    <Fade in={true} timeout={500} key={index}>
                        <div className={classes.sparkleContainer}>
                            <Lottie
                                animationData={Sparkle}
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    right: '-8px',
                                    top: '16px',
                                    height: '56px',
                                    width: '56px'
                                }}
                            />
                            {cardContent}
                        </div>
                    </Fade>
                ) : (
                    cardContent
                );
            })}
        </>
    );
};

export default ActionStepSummary;
