import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import Arrow from 'assets/arrow-transfer.png';

const useStyles = makeStyles(() => ({
    amounts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& p': {
            fontSize: '24px',
            fontWeight: '700'
        }
    },
    amountView: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '100%',
        '& div': {
            padding: '0 20px'
        },
        '& .arrowContainer': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    arrowContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40%'
    },
    balanceLabel: {
        color: '#A6A6BF',
        fontSize: '16px',
        textAlign: 'center'
    },
    transferAmount: {
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: '800',
        color: '#7378E8'
    }
}));

type TransferViewProps = {
    cardBalance: number;
    memberBalance: number;
    nums: string;
};

export default function TransferView({ cardBalance, memberBalance, nums }: TransferViewProps) {
    const classes = useStyles();

    return (
        <div className={classes.amountView}>
            <div className={classes.amounts}>
                <Typography>${memberBalance}</Typography>
                <span className={classes.balanceLabel}>Membership</span>
            </div>
            <div className={classes.arrowContainer}>
                <Typography className={classes.transferAmount}>
                    ${nums ? nums : 0}
                </Typography>
                <img src={Arrow} alt="arrow" />
            </div>
            <div className={classes.amounts}>
                <Typography>${cardBalance}</Typography>
                <span className={classes.balanceLabel}>Debit Card</span>
            </div>
        </div>
    );
}
