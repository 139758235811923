import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    menuContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    menuBtn: {
        background: '#FBFBFF',
        border: 'none',
        width: '100%',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#1F1F51',
        padding: '24px 36px',
        textTransform: 'none',
        flexGrow: 1,
        '&:hover': {
            backgroundColor: '#F2F2FD'
        },
        '&:first-child': {
            borderRadius: '24px 24px 0 0'
        },
        '&:last-child': {
            borderRadius: '0 0 24px 24px'
        }
    },
    divider: {
        width: '100%',
        height: '1px',
        background: '#E3E3FA'
    }
}));

const Menu = (props) => {
    const classes = useStyles();

    const {
        ctpInfo,
        fetchChatMessages,
        fetchChatRooms,
        handleMessageMember,
        handleShowChatRooms,
        holder
    } = props;

    const holderInfo = {
        deployment: holder.deployment_id,
        id: holder.id,
        name: holder.name.split(' ')[0],
        org: holder.organization_id
    };

    return (
        <div className={classes.menuContainer}>
            <Button
                className={`${classes.menuBtn}`}
                onClick={() => {
                    fetchChatMessages(holderInfo.id, ctpInfo.id);
                    handleMessageMember();
                }}
            >
                Message {holderInfo.name}
            </Button>
            <div className={classes.divider} />
            {ctpInfo.deployment === holderInfo.deployment &&
            ctpInfo.org === holderInfo.org ? (
                <Button
                    className={`${classes.menuBtn}`}
                    onClick={() => {
                        fetchChatRooms(holderInfo.id);
                        handleShowChatRooms();
                    }}
                >
                    View {holderInfo.name}'s Messages
                </Button>
            ) : null}
        </div>
    );
};

Menu.propTypes = {
    ctpInfo: PropTypes.object.isRequired,
    fetchChatMessages: PropTypes.func.isRequired,
    fetchChatRooms: PropTypes.func.isRequired,
    handleMessageMember: PropTypes.func.isRequired,
    handleShowChatRooms: PropTypes.func.isRequired,
    holder: PropTypes.object.isRequired
};

export default Menu;
