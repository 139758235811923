import PropTypes from 'prop-types';

import Card from '../../CaseManagement/components/Card';

import {
    calculateDateDifference,
    extractNumbersAndAgo,
    renderClasses
} from '../../CaseManagement/utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    needsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    needs: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    description: {
        fontSize: '18px',
        lineHeight: '25px',
        fontWeight: '700'
    },
    date: {
        color: '#7A4406',
        backgroundColor: '#FEF3E7',
        padding: '6px 16px',
        borderRadius: '200px',
        '& i': {
            marginRight: '8px'
        }
    },
    noDueDate: {
        color: '#1F2152',
        backgroundColor: '#F0F0FF'
    },
    dueDateWarning: {
        color: '#A80A2D',
        backgroundColor: '#FEE7EC'
    }
}));

const NeedCard = ({ activeNeedList, setActiveNeedList, need }) => {
    const classes = useStyles();

    const { description, due_at, id } = need;

    const renderNeedDate = (dateTime) => {
        if (!dateTime) {
            return 'No Due Date';
        }

        const dateDifference = calculateDateDifference(dateTime);
        const { number, hasAgo } = extractNumbersAndAgo(dateDifference);

        if (!hasAgo) {
            if (number === 'NaN days') {
                return `${dateDifference}`;
            } else {
                return `Needed in ${dateDifference}`;
            }
        } else {
            return `Needed ${dateDifference}`;
        }
    };

    return (
        <Card cardStyle={{ width: '100%' }}>
            <div className={classes.needsContainer}>
                <div className={classes.needs}>
                    <span className={classes.description}>{description}</span>
                    {!due_at ? null : (
                        <span
                            className={renderClasses(classes, due_at).dateClass}
                        >
                            <i className="fal fa-clock" />
                            {renderNeedDate(due_at)}
                        </span>
                    )}
                </div>
                {!activeNeedList.includes(id) ? (
                    <i
                        className="fal fa-circle"
                        style={{
                            color: '#E3E3FA',
                            fontSize: '24px',
                            cursor: 'pointer'
                        }}
                        onClick={() =>
                            setActiveNeedList([...activeNeedList, id])
                        }
                    />
                ) : (
                    <i
                        className="fas fa-check-circle"
                        style={{
                            color: '#7378E8',
                            fontSize: '24px',
                            cursor: 'pointer'
                        }}
                        onClick={() =>
                            setActiveNeedList(
                                activeNeedList.filter((needId) => needId !== id)
                            )
                        }
                    />
                )}
            </div>
        </Card>
    );
};

NeedCard.propTypes = {
    activeNeedList: PropTypes.array.isRequired,
    setActiveNeedList: PropTypes.func.isRequired,
    need: PropTypes.object
};

export default NeedCard;
