import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import * as api from '../../api/samaritan';

function fetchingMonthlySummary() {
    return {
        type: CONSTANTS.GET_MONTHLY_SUMMARY
    };
}

function setMonthlySummary(response: object) {
    return {
        type: CONSTANTS.SET_MONTHLY_SUMMARY,
        payload: response
    };
}

export function getMonthlySummary() {
    return (dispatch: Function) => {
        dispatch(fetchingMonthlySummary());

        return api
            .fetchMonthlySummary()
            .then((response: object) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: object) => {
                dispatch(setMonthlySummary(responseJson));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function setLayoutTitles(title: string, subtitle: string) {
    return {
        type: CONSTANTS.SET_LAYOUT_TITLES,
        payload: { title, subtitle }
    };
}

export function setGoalActionCompletePopup(bool: boolean) {
    return {
        type: CONSTANTS.SHOW_GOAL_ACTION_COMPLETE_POPUP,
        payload: bool
    };
}

export function setGoalActionCompletePopupId(id: number) {
    return {
        type: CONSTANTS.SET_GOAL_ACTION_COMPLETE_POPUP,
        payload: id
    };
}
