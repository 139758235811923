import ActionSteps from './ActionSteps';
import SDOHStatus from './SDOHStatus';

import {
    GoalActions,
    Homeless,
    NewGoalActions,
    TouchpointAnswers,
    TouchpointQuestions,
    User
} from 'types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    caseManagementContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '80px',
        padding: '30px 12% 60px 12%'
    }
}));

type CaseManagementProps = {
    addStatus: boolean;
    assignedBonusAmount: number;
    exited: boolean;
    fetchTouchpointAnswersById: (id: number) => void;
    goalActions: GoalActions[];
    holder: Homeless;
    isEditingGoalAction: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingTouchpointAnswers: boolean;
    maxBonusAmount: number;
    newHomelessGoalActions: NewGoalActions[];
    remainingBonusAmount: number;
    setAddStatus: (value: boolean) => void;
    setShowToast: (value: boolean) => void;
    setToastMessage: (value: string) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointAnswersById: number[];
    touchpointQuestions: TouchpointQuestions[];
    touchpointQuestionQOLs: number[];
    updateProfileCompletedItems: (profileCompletedItems: string) => void;
    user: User;
};

const CaseManagement: React.FC<CaseManagementProps> = ({
    goalActions,
    ...props
}) => {
    const classes = useStyles();

    const statusProps = {
        goalActions,
        ...props
    };

    return (
        <div className={classes.caseManagementContainer}>
            {!props.exited && <ActionSteps {...statusProps} />}
            <SDOHStatus {...statusProps} />
        </div>
    );
};

export default CaseManagement;
