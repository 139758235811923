export const renderCardType = (debitType: 'givecard' | 'pex' | null) => {
    switch (debitType) {
        case 'givecard':
            return 'GiveCard';
        case 'pex':
            return 'PEX Card';
        default:
            return 'Debit Card';
    }
};
