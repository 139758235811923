import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { Button } from '@mui/material';

const useStyles = makeStyles({
    wrapper: {
        margin: '10px',
        padding: '20px 25px',
        height: 'auto',
        borderRadius: '6px',
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    btn: {
        borderBottom: '1px solid #A6A6BF',
        borderRadius: '0',
        background: '#FBFBFF',
        border: 'none',
        width: '100%',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#1F1F51',
        padding: '20px 36px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'F2F2FD'
        }
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1
    },
    modalContent: {
        backgroundColor: 'white',
        boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
        maxWidth: '80%',
        width: '300px',
        maxHeight: '80%',
        overflow: 'auto',
        borderRadius: '16px',
        color: '#000',
        position: 'fixed',
        bottom: '350px',
        right: '110px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
        // '& span': {
        //     fontWeight: '700'
        // }
    },
    report: {
        color: '#F5577A'
    }
});

const Resend = ({ handleEllipsisClick, deleteMessage, resendMessage }) => {
    const classes = useStyles();

    return (
        <div className={classes.modal}>
            <div className={classes.modalContent}>
                <div className={classes.content}>
                    <Button
                        className={classes.btn}
                        onClick={() => resendMessage()}
                    >
                        <span>Resend</span>
                    </Button>
                    <Button
                        className={classes.btn}
                        onClick={() => deleteMessage()}
                    >
                        <span className={classes.report}>Delete</span>
                    </Button>
                    <Button
                        className={classes.btn}
                        onClick={() => handleEllipsisClick()}
                    >
                        <span>Dismiss</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

Resend.propTypes = {
    handleEllipsisClick: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    resendMessage: PropTypes.func.isRequired
};

export default Resend;
