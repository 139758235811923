import Lottie from 'react-lottie';

import * as animationData from './samaritan-confetti.json';

const Confetti: React.FC<{ stopConfetti: boolean }> = ({ stopConfetti }) => {
    const confettiStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2
    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData
    };

    return (
        <div style={confettiStyle}>
            <Lottie options={defaultOptions} isStopped={stopConfetti} />
        </div>
    );
};

export default Confetti;
