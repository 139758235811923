import ReactGA from 'react-ga4';

import Card from '../../../../_shared/Card';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            margin: '12px !important',
            width: '90%'
        },
        check: {
            color: '#1B9757 !important'
        },
        completed: {
            opacity: 0.6
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '50px',
            fontSize: '14px',
            height: '36px',
            width: '36px'
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: 600,
            padding: '16px 0',
            '& i': {
                color: '#999DFF',
                fontSize: '24px',
                fontWeight: 700,
                marginRight: '16px'
            }
        },
        itemDetail: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& span': {
                marginLeft: '16px'
            }
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            height: '100%',
            opacity: 0.6,
            width: '100%'
        }
    })
);

type ItemsType = {
    [key: string]: {
        background: string;
        icon: string;
        label: string;
    };
};

type ProfileItemProps = {
    item: string;
    setType: (type: string) => void;
    status: string;
};

const ProfileItem: React.FC<ProfileItemProps> = ({ item, setType, status }) => {
    const classes = useStyles();

    const items: ItemsType = {
        action_steps: {
            background: '#ECFFE7',
            icon: '✅',
            label: 'Action Step'
        },
        birthday: {
            background: '#E7FFFF',
            icon: '📅',
            label: 'Date of Birth'
        },
        contact_info: {
            background: '#ECE7FF',
            icon: '☎️',
            label: 'Contact Info'
        },
        full_name: {
            background: '#FFF5E7',
            icon: '🪪',
            label: 'Legal Name'
        },
        member_id: {
            background: '#E7F1FF',
            icon: '🔖',
            label: 'Insurance ID'
        },
        name: {
            background: '#FFE7E7',
            icon: '👤',
            label: 'Nickname'
        },
        needs: {
            background: '#F4E7FF',
            icon: '💝',
            label: 'an Immediate Need'
        },
        photo: {
            background: '#FFF5E7',
            icon: '📸',
            label: 'a Profile Photo'
        },
        status: {
            background: '#ECFFE7',
            icon: '📈',
            label: 'Status'
        },
        supplemental_questions: {
            background: '#FAE7FF',
            icon: '💬',
            label: 'a Fun Fact'
        }
    };

    const handleClick = () => {
        ReactGA.event({
            category: 'Profile',
            action: 'Complete Profile Item Click',
            label: item
        });

        setType(item);
    };

    return (
        <Card
            active
            className={classes.card}
            disabled={status === 'pending'}
            onClick={handleClick}
        >
            {status === 'pending' && <div className={classes.overlay} />}
            <div className={classes.item}>
                <div className={classes.itemDetail}>
                    <span
                        className={classes.icon}
                        style={{ background: items[item].background }}
                    >
                        {items[item].icon}
                    </span>
                    <span>Add {items[item].label}</span>
                </div>
                {status === 'incomplete' ? (
                    <i className="fas fa-chevron-right" />
                ) : (
                    <i className={`fas fa-check-circle ${classes.check}`} />
                )}
            </div>
        </Card>
    );
};

export default ProfileItem;
