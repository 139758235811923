import classNames from 'classnames';

import LoadingCircle from 'components/_shared/LoadingCircle';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        loadingOverlay: {
            position: 'absolute',
            left: '20px',
            top: '20px',
            height: 'calc(100% - 40px)',
            width: 'calc(100% - 40px)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '12px',
            // borderRadius: '16px',
            zIndex: 2
        },
        statusText: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            color: '#FFFFFF',
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'center',
            transform: 'translate(-50%, -50%)',
            '& i': {
                fontSize: '40px'
            }
        },
        statusTextAccepted: {
            color: '#1B9757'
        },
        statusTextRejected: {
            color: '#A80A2D'
        }
    })
);

type LoadingOverlayProps = {
    accepted: boolean;
    loading: boolean;
    rejected: boolean;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    accepted,
    loading,
    rejected
}) => {
    const classes = useStyles();

    const tagIcon = accepted
        ? 'fa-check-circle'
        : rejected
        ? 'fa-times-circle'
        : 'fa-user-plus';
    const tagText = accepted
        ? 'Referral accepted'
        : rejected
        ? 'Referral rejected'
        : 'Referred';

    const tagTextClass = classNames({
        [classes.statusText]: true,
        [classes.statusTextAccepted]: accepted,
        [classes.statusTextRejected]: rejected
    });

    return (
        <div className={classes.loadingOverlay}>
            {loading ? (
                <LoadingCircle />
            ) : (
                <span className={tagTextClass}>
                    <i className={`fas ${tagIcon}`} />
                    {tagText}
                </span>
            )}
        </div>
    );
};

export default LoadingOverlay;
