import { withStyles } from '@mui/styles';

const styles = () => ({
    active: {
        display: 'inline-block',
        background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
        border: 'none',
        borderRadius: '1000px',
        color: '#fff',
        cursor: 'pointer',
        padding: '0.5rem 1rem'
    },
    inactive: {
        display: 'inline-block',
        background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
        border: 'none',
        borderRadius: '100px',
        color: '#fff',
        marginLeft: '20px',
        padding: '0.5rem 1rem'
    }
});

type CommonButtonProps = {
    active?: boolean;
    children: React.ReactNode;
    classes: {
        [key: string]: string;
    };
    className?: string;
    onClick?: (value: any) => void;
};

const CommonButton: React.FC<CommonButtonProps> = ({
    active = false,
    children,
    classes,
    className = '',
    ...rest
}) => {
    let klass = active ? classes.active : classes.inactive;
    klass += ` ${className}`;

    return (
        <button className={klass} {...rest}>
            {children}
        </button>
    );
};

export default withStyles(styles)(CommonButton);
