import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    bigCircle: {
        bottom: '0px !important',
        right: '0px !important',
        background: '#FFFFFF',
        '& i': {
            fontSize: '40px !important'
        }
    },
    circle: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        borderRadius: '50%',
        color: '#999DFF',
        zIndex: 2,
        '& i': {
            fontSize: '24px'
        }
    },
    percentage: {
        position: 'absolute',
        left: '35%',
        top: '85%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#999DFF',
        borderRadius: '200px',
        color: '#FFFFFF',
        fontWeight: 700,
        padding: '4px 8px',
        zIndex: 2
    },
    percentagePopup: {
        left: 'calc(50% - 24px)',
        top: '88px',
        padding: '2px 6px'
    },
    progressBar: {
        position: 'absolute',
        left: '-22px',
        top: '-22px',
        transform: 'rotate(90deg)',
        zIndex: 1
    },
    progressBarPopup: {
        left: 'calc(50% - 62px)',
        top: 'calc(50% - 62px)'
    }
}));

type CompleteProfileItemsProps = {
    percentage: number;
    popupStyle?: boolean;
};

const CompleteProfileItems: React.FC<CompleteProfileItemsProps> = ({
    percentage,
    popupStyle
}) => {
    const classes = useStyles();

    const [circumference, setCircumference] = useState(0);

    const circleClass = classNames({
        [classes.bigCircle]: !popupStyle,
        [classes.circle]: true
    });
    const percentageClass = classNames({
        [classes.percentage]: true,
        [classes.percentagePopup]: popupStyle
    });
    const progressBarClass = classNames({
        [classes.progressBar]: true,
        [classes.progressBarPopup]: popupStyle
    });

    const profilePercentage = Math.round(percentage);
    const progressLength = (percentage * circumference) / 100;
    const dashOffset = circumference - progressLength;

    useEffect(() => {
        const radius = 40;
        const circumference = 2 * Math.PI * radius;

        setCircumference(circumference);
    }, []);

    return (
        <>
            {profilePercentage !== 100 ? (
                <span className={percentageClass}>{profilePercentage}%</span>
            ) : (
                <span className={circleClass}>
                    <i className="fas fa-check-circle" />
                </span>
            )}
            <svg
                className={progressBarClass}
                width={popupStyle ? '124' : '204'}
                height={popupStyle ? '124' : '204'}
                viewBox="0 0 100 100"
            >
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="3"
                />
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke={profilePercentage === 100 ? '#FFFFFF' : '#999DFF'}
                    strokeDasharray={`${circumference}`}
                    strokeDashoffset={`${dashOffset}`}
                    strokeWidth="4"
                />
            </svg>
        </>
    );
};

export default CompleteProfileItems;
