import React from "react";
import PropTypes from "prop-types";

import classes from "./index.module.scss";

export default function Label({ children, className, ...props }) {
  const joinedClassNames = [classes.label, className].join(" ");

  return (
    <label className={joinedClassNames} {...props}>
      {children}
    </label>
  );
}

Label.propTypes = {
  className: PropTypes.string,
};

Label.defaultProps = {
  className: "",
};
