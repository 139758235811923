import { connect } from 'react-redux';
import actions from '../redux/actions';
import PhotoUpload from '../components/intake/Account/components/PhotoUpload';

function mapStateToProps(state: any) {
    const { intakeProfilePic } = state.intake;

    return {
        intakeProfilePic
    };
}

const mapDispatchToProps = {
    setIntakeProfilePicture: actions.setIntakeProfilePicture
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUpload);
