import React from 'react';

export const formatDate = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date + 'T00:00:00Z'); // Append time and UTC offset

    currentDate.setUTCHours(0, 0, 0, 0);
    inputDate.setUTCHours(0, 0, 0, 0);

    if (isSameDay(inputDate, currentDate)) {
        return 'Today';
    } else if (isSameDay(inputDate, getPreviousDay(currentDate))) {
        return 'Yesterday';
    } else {
        return formatDateToString(inputDate);
    }
};

const isSameDay = (date1, date2) => {
    return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate()
    );
};

const getPreviousDay = (date) => {
    const previousDay = new Date(date);
    previousDay.setUTCDate(previousDay.getUTCDate() - 1);
    return previousDay;
};

const formatDateToString = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
};

export const formatTime = (date) => {
    const d = new Date(date);
    const time = d.toLocaleTimeString([], { timeStyle: 'short' });

    return time;
};

export const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
        const date = message.date.split(`T`)[0];

        if (!acc[date]) {
            acc[date] = {
                messages: [],
                type: message.type
            };
        }

        acc[date].messages.push(message);

        return acc;
    }, {});
};

export const renderMessagesByDate = (messages, classes, messagesEndRef) => {
    return Object.keys(messages).map((date) => {
        return (
            <div key={date} className={classes.messages}>
                <div className={classes.dateHeading}>
                    <span>{formatDate(date)}</span>
                </div>
                {messages[date].messages.map((message, index) => {
                    const nextMessage = messages[date].messages[index + 1];
                    const showTime = renderTimeStamp(message, nextMessage);

                    return (
                        <React.Fragment key={message.id}>
                            <span
                                className={`${classes.bubble} ${
                                    message.recipient_type === 'User'
                                        ? classes.replyBubble
                                        : classes.memberBubble
                                }`}
                                style={
                                    showTime &&
                                    message.recipient_type === `User`
                                        ? {
                                              borderRadius: '15px 15px 0 15px'
                                          }
                                        : showTime &&
                                          message.recipient_type === `Homeless`
                                        ? {
                                              borderRadius: '15px 15px 15px 0'
                                          }
                                        : null
                                }
                            >
                                <span>{message.message}</span>
                            </span>
                            {showTime && (
                                <span
                                    ref={messagesEndRef}
                                    className={classes.messageTime}
                                    style={
                                        message.recipient_type === `User`
                                            ? { alignSelf: 'flex-end' }
                                            : { alignSelf: 'flex-start' }
                                    }
                                >
                                    {formatTime(message.date)}
                                </span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    });
};

export const renderTimeStamp = (message, nextMessage) => {
    if (
        !nextMessage ||
        (message.type === 'Encouragement' &&
            nextMessage.type === 'EncouragementReply') ||
        (message.type === 'EncouragementReply' &&
            nextMessage.type === 'Encouragement')
    ) {
        return true;
    }
    const messageDate = new Date(message.date);
    const nextMessageDate = new Date(nextMessage.date);

    return nextMessageDate - messageDate > 60 * 60 * 1000;
};

export const scrollToBottom = (ref) => {
    ref.current?.scrollIntoView?.({
        behavior: 'auto',
        block: 'nearest'
    });
};