import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import Slide, { SlideProps } from '@mui/material/Slide';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        messageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        viewButton: {
            color: '#1E4620',
            padding: 0,
            textTransform: 'none',
            '& i': {
                marginLeft: '4px'
            }
        }
    })
);

const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="down" />;
};

type ToastProps = {
    icon?: string;
    message: JSX.Element | string;
    open: boolean;
    severity: 'error' | 'info' | 'success' | 'warning';
    viewClick?: () => void;
};

const Toast: React.FC<ToastProps> = ({
    icon,
    message,
    open,
    severity,
    viewClick
}) => {
    const classes = useStyles();

    const renderIcon = () => {
        if (icon) {
            return <i className={`fas ${icon}`} />;
        }

        switch (severity) {
            case 'error':
                return <i className="fas fa-exclamation-circle" />;
            case 'info':
                return <i className="fas fa-info-circle" />;
            case 'success':
                return <i className="fas fa-check-circle" />;
            case 'warning':
                return <i className="fas fa-exclamation-triangle" />;
            default:
                return null;
        }
    };

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            autoHideDuration={5000}
            open={open}
            sx={{ padding: 0 }}
            TransitionComponent={SlideTransition}
        >
            <SnackbarContent
                message={
                    <Alert
                        icon={renderIcon()}
                        severity={severity}
                        sx={{ alignItems: 'center' }}
                    >
                        <span>{message}</span>
                        {viewClick && (
                            <Button
                                className={classes.viewButton}
                                onClick={viewClick}
                            >
                                View <i className="fas fa-chevron-right" />
                            </Button>
                        )}
                    </Alert>
                }
                sx={{
                    padding: 0,
                    '& .MuiAlert-message': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '8px',
                        width: '100%'
                    },
                    '& .MuiSnackbarContent-message': {
                        alignItems: 'center',
                        padding: 0,
                        width: '100%'
                    }
                }}
            />
        </Snackbar>
    );
};

export default Toast;
