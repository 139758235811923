import ReactMarkdown from 'react-markdown';

import { RecordType } from 'types';

import {
    calculateDateDifference,
    renderClasses
} from '../../CaseManagement/utils';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionButton: {
            color: '#FFFFFF',
            fontSize: '16px',
            height: '34px',
            lineHeight: '21px',
            width: '34px'
        },
        actionDetailsContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#5A6A81'
        },
        actionStepContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
        },
        bonus: {
            fontWeight: 400,
            '& i': {
                marginRight: '4px'
            }
        },
        buttonWarning: {
            backgroundColor: '#E87F7F'
        },
        cancelled: {
            backgroundColor: '#A6A6BF !important',
            color: '#FFFFFF !important'
        },
        complete: {
            backgroundColor: '#7FE8B2 !important',
            color: '#FFFFFF !important'
        },
        date: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FEF3E7',
            borderRadius: '200px',
            color: '#7A4406',
            fontWeight: 4000,
            height: '34px',
            padding: '0 16px',
            textWrap: 'nowrap',
            width: 'max-content',
            '& i': {
                marginRight: '6px'
            }
        },
        description: {
            color: '#1F2152',
            fontWeight: 700,
            fontSize: '16px',
            '& p': {
                margin: '0'
            }
        },
        dueDate: {
            backgroundColor: '#F7C354'
        },
        dueDateContainer: {
            position: 'relative',
            '& button': {
                position: 'absolute',
                right: '0',
                top: '0'
            }
        },
        dueDateWarning: {
            backgroundColor: '#FEE7EC',
            color: '#A80A2D'
        },
        edit: {
            backgroundColor: '#999DFF'
        }
    })
);

type ActionStepProps = {
    actionStep: RecordType;
};

const ActionStep: React.FC<ActionStepProps> = ({ actionStep }) => {
    const classes = useStyles();

    const { description, due_at, match_value } = actionStep || {
        description: '',
        due_at: '',
        match_value: 0
    };

    const { dateClass } = renderClasses(classes, due_at!);

    const hyperlinkComponents = {
        a: ({
            href,
            children
        }: {
            href?: string;
            children?: React.ReactNode;
        }) => (
            <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        )
    };

    return (
        <div className={classes.actionStepContainer}>
            <span className={classes.description}>
                <ReactMarkdown components={hyperlinkComponents}>
                    {description}
                </ReactMarkdown>
            </span>
            <div className={classes.actionDetailsContainer}>
                <div className={classes.dueDateContainer}>
                    <div className={dateClass}>
                        <i className="fal fa-clock" />
                        <span style={{ width: 'max-content' }}>
                            Due: {calculateDateDifference(due_at as string)}
                        </span>
                    </div>
                </div>
                {match_value! > 0 && due_at!.length && <span>•</span>}
                {match_value! > 0 && (
                    <span className={classes.bonus}>
                        <i className="fal fa-usd-circle" />${match_value}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ActionStep;
