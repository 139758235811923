import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { getRedemptionThunk } from "../../../../../../redux/actions/redemption";

import TemplateListItem from "../TemplateListItem";

import LoadingCircle from "../../../../../_shared/LoadingCircle";
import getElementInnerDimensions from "../../../../../../util/getElementInnerDimensions";

import classes from "./index.module.scss";

function TemplateList({ listItems, status, dispatch, onSelect }) {
  const listRef = useRef(null);

  useEffect(() => {
    // fetch redemption template list if its been fetched yet.
    if (listItems.length === 0 && status !== "success") {
      dispatch(getRedemptionThunk());
    }
  }, [listItems, status, dispatch]);

  // resize div to match columns
  useEffect(() => {
    /**
     * callback gets the size of the child elements then sets
     * the width of this element to exactly the width of one row.
     *
     * This lets the list be centered while the items are left justified.
     */
    const updateWidth = () => {
      if (listRef.current) {
        // get parent div width
        const parent = listRef.current.parentElement;
        const { width: parentWidth } = getElementInnerDimensions(parent);

        const child = listRef.current.firstElementChild;

        // get listItem width
        const childOffsetWidth = child.offsetWidth;

        // get listItem margins
        const childStyles = window.getComputedStyle(child);
        const left = Number(childStyles.marginLeft.replace("px", ""));
        const right = Number(childStyles.marginRight.replace("px", ""));
        const childClientMargin = left + right;

        // sum for total width
        const childWidth = childOffsetWidth + childClientMargin;
        // use it to set this element's width
        const numColumns = Math.min(4, Math.floor(parentWidth / childWidth));
        listRef.current.style.maxWidth = `${numColumns * childWidth + 1}px`;

        // set list visibility
        listRef.current.style.visibility = "unset";
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [listItems]);

  // return loading if loading
  if (!listItems.length) return <LoadingCircle />;

  return (
    <div className={classes.list} ref={listRef}>
      {listItems.map(({ template }) => {
        const onClick = () => {
          onSelect(template.backend_id);
        };

        return (
          <TemplateListItem
            key={template.backend_id}
            category={template.category}
            title={template.title}
            thumbnail={template.thumbnail_url}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
}

TemplateList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  listItems: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  listItems: state.redemption.redemptionList,
  status: state.redemption.status,
});

export default connect(mapStateToProps)(TemplateList);
