import React, { useRef } from 'react';
import classNames from 'classnames';

import classes from './Card.module.scss';

import ButtonBase from '@mui/material/ButtonBase';

type CardProps = {
    active?: boolean;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    id?: string;
    onClick?: (value: any) => void;
    style?: React.CSSProperties;
};

export default function Card({
    active,
    children,
    className,
    ...rest
}: CardProps): JSX.Element {
    const nodeRef = useRef(null);

    const combinedClassName = classNames(classes.card, className);

    return (
        <>
            {active ? (
                <ButtonBase
                    className={combinedClassName}
                    component="div"
                    ref={nodeRef}
                    {...rest}
                >
                    <div className={classes.buttonCard}>{children}</div>
                </ButtonBase>
            ) : (
                <div className={combinedClassName} {...rest}>
                    {children}
                </div>
            )}
        </>
    );
}
