import { Route, Redirect } from 'react-router-dom';

import DrawerNav from '../../_layout/DrawerNav';
import NewUserModal from '../NewUserModal';
import SideNav from '../../_layout/side_nav';

import classes from './PrivateRoute.module.scss';

import Routes from 'routes';

import AuthManager from '../../../auth';

type PrivateRouteProps = {
    component: any;
    email: string;
    exact?: boolean;
    last_name: string;
    name: string;
    path: string;
    unauthorizedUser?: boolean;
};

const PrivateRoute = function ({
    component: Component,
    email,
    last_name,
    name,
    unauthorizedUser,
    ...rest
}: PrivateRouteProps) {
    let auth = AuthManager.getInstance();
    const user_id = auth.getUserID();

    const ctpInfoProps = {
        email,
        name,
        last_name
    };

    // Routes to login page if token does not exist, else produces passed in component
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!unauthorizedUser && user_id) {
                    return (
                        <div className={classes.root}>
                            <NewUserModal />
                            <div className={classes.hamburgerNav}>
                                <DrawerNav {...ctpInfoProps} />
                            </div>
                            <div
                                className="header"
                                style={{ backgroundColor: '#3D3D6E' }}
                            />
                            <div className={classes.sideNav}>
                                <SideNav {...ctpInfoProps} />
                            </div>
                            <div className={classes.content}>
                                <Component {...props} />
                            </div>
                        </div>
                    );
                } else {
                    auth.unsetToken();
                    return <Redirect to={{ pathname: Routes.login }} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
