import { useEffect, useRef } from 'react';

import { Organization } from 'types';

import {
    CTAButton1,
    QuickAction,
    SecondaryButton
} from '../../_shared/buttons';
import FormInputTextWithLabelSpecial from './FormInputTextWithLabelSpecial';
import PasswordRequirements from './PasswordRequirements';
import SlideInItems from './SlideInItems';

import classes from './index.module.scss';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type SignUpInputsProps = {
    cityName: string | undefined;
    email: string;
    // TODO: type error
    error: any;
    inputsRef: {
        current: HTMLDivElement | null;
    };
    joinCode: string;
    lastName: string;
    loading: boolean;
    name: string;
    orgList: Organization[];
    orgName: string;
    password: string;
    passwordConfirmation: string;
    passwordIsValid: (password: string) => boolean;
    phoneNumber: string;
    setCity: (city: { name: string; subdomain_name: string }) => void;
    setEmail: (email: string) => void;
    setJoinCode: (joinCode: string) => void;
    setLastName: (lastName: string) => void;
    setName: (name: string) => void;
    setOrgId: (id: null | number) => void;
    setOrgName: (name: string) => void;
    setPassword: (password: string) => void;
    setPhoneNumber: (phoneNumber: string) => void;
    setStage: (stage: string) => void;
};

const SignUpInputs = ({
    cityName,
    email,
    error,
    inputsRef,
    joinCode,
    lastName,
    loading,
    name,
    orgList,
    orgName,
    password,
    passwordConfirmation,
    passwordIsValid,
    phoneNumber,
    setCity,
    setEmail,
    setJoinCode,
    setLastName,
    setName,
    setOrgId,
    setOrgName,
    setPassword,
    setPhoneNumber,
    setStage
}: SignUpInputsProps) => {
    // have to track first mount to only scroll on mount
    const justMounted = useRef(true);

    // this ref is used to scroll email input into view
    // if theres an error with email
    const emailInputRef = useRef<HTMLInputElement | null>(null);

    // if an error while signup scroll it into view
    // only real error should be email, since
    // client validates form before submission
    useEffect(() => {
        if (emailInputRef.current && error)
            emailInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [error]);

    const confirmPasswordRef = useRef<HTMLObjectElement | null>(null);
    // validation for password confirmation
    useEffect(() => {
        if (!confirmPasswordRef.current) return;
        if (password !== passwordConfirmation)
            return confirmPasswordRef.current.setCustomValidity(
                "Passwords don't match"
            );
        confirmPasswordRef.current.setCustomValidity('');
    }, [password, passwordConfirmation]);

    const formatNumber = (phoneInput: string) => {
        const phoneLength = phoneInput.length;

        if (phoneLength < 4) return phoneInput;

        if (phoneLength < 7) {
            return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(3)}`;
        }

        return `(${phoneInput.slice(0, 3)}) ${phoneInput.slice(
            3,
            6
        )}-${phoneInput.slice(6, 10)}`;
    };

    const slideInItemOnRest = () => {
        // if scrolled once, then don't scroll again
        if (inputsRef.current && justMounted.current) {
            justMounted.current = false;
            inputsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <div className={classes.formInputsDiv} ref={inputsRef}>
            <div>
                <QuickAction
                    type="reset"
                    icon="chevron-left"
                    small
                    invert
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setStage('cities');
                        setCity({
                            name: '',
                            subdomain_name: ''
                        });
                    }}
                />
                <span style={{ marginLeft: 5 }}>{cityName}</span>
            </div>

            <FormInputTextWithLabelSpecial
                label="Email"
                type="email"
                id="email"
                required
                autoComplete="email"
                value={email}
                error={error}
                inputRef={emailInputRef}
                onChange={(e) => setEmail(e.target.value)}
            />
            {error && error.errors && <div>{error.errors}</div>}

            <FormInputTextWithLabelSpecial
                label="Password"
                type="password"
                id="password"
                required
                autoComplete="new-password"
                minLength={8}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <SlideInItems
                config={{
                    onRest: slideInItemOnRest
                }}
            >
                <div>
                    <label
                        htmlFor="passwordRequirements"
                        style={{ fontSize: '14px' }}
                    >
                        Password must include:
                    </label>
                    <PasswordRequirements password={password} />
                </div>

                <div className={classes.formSectionTitle}>Account Details</div>

                <div>
                    <label htmlFor="name">First Name</label>
                    <div>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Jane"
                            className={classes.formInput}
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="lastName">Last Name</label>
                    <div>
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Doe"
                            className={classes.formInput}
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="phoneNumber">10 digit phone number</label>
                    <div>
                        <input
                            type="tel"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="(123) 456-7890"
                            className={classes.formInput}
                            minLength={14}
                            maxLength={14}
                            required
                            value={formatNumber(phoneNumber)}
                            onChange={(e) =>
                                setPhoneNumber(
                                    e.target.value.replace(/[^0-9]/g, '')
                                )
                            }
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="orgName" className={classes.formLabel}>
                        Organization
                    </label>
                    <div>
                        <Select
                            name="orgName"
                            id="orgName"
                            required
                            style={{
                                width: '100%',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                marginTop: '5px'
                            }}
                            value={orgName}
                            displayEmpty
                            onChange={(e) => {
                                setOrgName(e.target.value);
                            }}
                        >
                            <MenuItem
                                value=""
                                disabled
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <span>Click to select organization</span>
                            </MenuItem>
                            {orgList.map((org, index: number) => {
                                return (
                                    <MenuItem
                                        value={org.name}
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => setOrgId(org.id)}
                                    >
                                        <div
                                            style={{
                                                padding: '0 10px',
                                                display: 'flex',
                                                alignItems: 'center '
                                            }}
                                            data-id={org.id}
                                        >
                                            <img
                                                src={org.logo}
                                                alt="logo"
                                                style={{
                                                    width: '24px',
                                                    height: '24px',
                                                    borderRadius: '50%',
                                                    marginRight: '12px'
                                                }}
                                            />
                                            {org.name}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                {orgName.length && orgName ? (
                    <div>
                        <label htmlFor="joinCode" className={classes.formLabel}>
                            Org verification code
                        </label>
                        <div>
                            <input
                                type="text"
                                name="joinCode"
                                id="joinCode"
                                placeholder="Optional code"
                                className={classes.formInput}
                                value={joinCode}
                                onChange={(e) => setJoinCode(e.target.value)}
                            />
                        </div>
                    </div>
                ) : null}
            </SlideInItems>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 40
                }}
            >
                <CTAButton1
                    invert
                    type="submit"
                    style={{
                        paddingBlock: '0.6em',
                        outline: 'none',
                        ...(passwordIsValid(password) && orgName
                            ? {
                                  backgroundColor: '#7378E8',
                                  color: '#FFFFFF'
                              }
                            : { cursor: 'not-allowed' })
                    }}
                    disabled={
                        passwordIsValid(password) && orgName ? false : true
                    }
                >
                    {loading ? 'Creating Account' : 'Create Account'}
                </CTAButton1>
                <div
                    style={{
                        marginTop: 5,
                        paddingBottom: 40
                    }}
                >
                    or{' '}
                    <SecondaryButton
                        light
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setStage('inputs');
                        }}
                    >
                        Go back to sign in
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
};

export default SignUpInputs;
