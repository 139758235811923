import { useEffect, useRef, useState } from 'react';
import { getDay, getDate, getMonth, getYear, isSameDay, parse } from 'date-fns';

import { ActivityHistory, Homeless } from 'types';

import Card from '../CaseManagement/components/Card';
import Divider from '../Funds/Divider';
import LoadingCircle from '../../../_shared/LoadingCircle';
import MemberHistoryItem from './components/MemberHistoryItem';
import PopUp from '../PopUp';
import Selectors from './components/Selectors';

import { fetchHomelessHistory } from '../../../../api/homeless';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        memberHistoryContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 12% 60px 12%'
        },
        selectorContainer: {},
        loadingContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            fontSize: '18px',
            height: '100%',
            marginTop: '80px'
        },
        memberHistoryList: {
            marginTop: '80px'
        },
        groupedItemContainer: {
            paddingBottom: '16px'
        },
        date: {
            color: '#1F2152',
            fontSize: '24px',
            fontWeight: 800
        },
        dateDivider: {
            borderLeft: '2px solid #E5E5E5',
            height: '24px',
            margin: '4px 0 4px 24px'
        }
    })
);

const groupItemsByMonth = (historyItems: ActivityHistory[]) => {
    const groupedItems = {} as { [key: string]: ActivityHistory[] };

    historyItems.forEach((item) => {
        const date = new Date(item.created_at);
        const fullDate = `${
            date.getMonth() + 1
        }-${date.getDate()}-${date.getFullYear()}`;

        if (!groupedItems[fullDate]) {
            groupedItems[fullDate] = [];
        }

        groupedItems[fullDate].push(item);
    });

    return groupedItems;
};

const renderDateHeader = (fullDate: string) => {
    const inputDate = parse(fullDate, 'MM-dd-yyyy', new Date());

    const currentDate = new Date();

    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    if (isSameDay(inputDate, currentDate)) {
        return 'Today';
    }

    const day = days[getDay(inputDate)];
    const numericalDay = getDate(inputDate);
    const shortMonth = months[getMonth(inputDate)];
    const year = getYear(inputDate);

    const formattedDate = `${day}, ${numericalDay} ${shortMonth} ${year}`;
    return formattedDate;
};

type MemberHistoryProps = {
    historyFilters: { description: string; friendly_name: string }[];
    holder: Homeless;
};

const MemberHistory: React.FC<MemberHistoryProps> = ({
    historyFilters,
    holder
}) => {
    const classes = useStyles();
    const initialHistoryControllerRef = useRef(new AbortController());

    const { id } = holder;

    const [dateButtonActive, setDateButtonActive] = useState(false);
    const [typeButtonActive, setTypeButtonActive] = useState(false);
    const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
    const [filterDate, setFilterDate] = useState<Date | null>(null);
    const [filterType, setFilterType] = useState('');
    const [history, setHistory] = useState<ActivityHistory[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [viewFilter, setViewFilter] = useState<null | string>(null);

    const filterItems = (
        items: ActivityHistory[] | null,
        filterDate: Date | null,
        filterEndDate: Date | null,
        filterType: string
    ) => {
        if (!items) return [];

        if (filterDate) {
            const startOfDay = new Date(filterDate);
            startOfDay.setHours(0, 0, 0, 0);
            filterDate = startOfDay;
        }

        if (filterEndDate) {
            const endOfDay = new Date(filterEndDate);
            endOfDay.setHours(23, 59, 59, 999);
            filterEndDate = endOfDay;
        }

        if (filterType === 'all') return items;
        if (!filterEndDate && !filterDate) return items;

        return items.filter((item) => {
            const itemDate = new Date(item.created_at);

            const isDateInRange =
                (!filterDate || itemDate >= filterDate) &&
                (!filterEndDate || itemDate <= filterEndDate);
            const isTypeMatching =
                !filterType || item.filter_type[0] === filterType;

            return isDateInRange && isTypeMatching;
        });
    };

    const possessiveS = holder.name.endsWith('s') ? "'" : "'s";

    const renderNoHistory = () => (
        <div className={classes.loadingContainer}>
            <span className={classes.date}>No records meet that criteria</span>
            <span>
                Please try a different selection of filters or remove the filter
                to view {`${holder.name}${possessiveS} activity history`}
            </span>
        </div>
    );

    const filteredItems = filterItems(
        history,
        filterDate,
        filterEndDate,
        filterType
    );
    const groupedItems = groupItemsByMonth(filteredItems);
    let totalIndex = 0;

    useEffect(() => {
        let isCurrentRequest = true;

        const fetchData = async () => {
            setIsLoading(true);

            initialHistoryControllerRef.current.abort();
            const filterController = new AbortController();
            initialHistoryControllerRef.current = filterController;

            try {
                let historyData;

                if (filterDate && filterEndDate) {
                    historyData = await fetchHomelessHistory(
                        id,
                        filterController.signal,
                        undefined,
                        filterDate as any,
                        filterEndDate as any
                    );
                } else if (filterType) {
                    historyData = await fetchHomelessHistory(
                        id,
                        filterController.signal,
                        filterType
                    );
                } else {
                    historyData = await fetchHomelessHistory(
                        id,
                        filterController.signal
                    );
                }

                if (isCurrentRequest) {
                    setHistory(historyData as ActivityHistory[]);
                }
            } catch (error: any) {
                if (error.name !== 'AbortError' && isCurrentRequest) {
                    console.error('Failed to fetch history data:', error);
                }
            } finally {
                if (isCurrentRequest) {
                    setIsLoading(false);
                }
            }
        };

        if (
            filterType ||
            filterDate ||
            filterEndDate ||
            !filterDate ||
            !filterEndDate
        ) {
            fetchData();
        }

        return () => {
            isCurrentRequest = false;
        };
    }, [filterDate, filterEndDate, filterType, id]);

    useEffect(() => {
        if (!isLoading && history) {
            const memberHistoryContainer = document.getElementById(
                'member-history-container'
            );

            if (memberHistoryContainer) {
                memberHistoryContainer.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [isLoading, history]);

    return (
        <div
            className={classes.memberHistoryContainer}
            id="member-history-container"
        >
            <Card info history name={holder.name} />
            <div className={classes.selectorContainer}>
                <Selectors
                    dateButtonActive={dateButtonActive}
                    setDateButtonActive={setDateButtonActive}
                    typeButtonActive={typeButtonActive}
                    setTypeButtonActive={setTypeButtonActive}
                    filterDate={filterDate}
                    filterType={filterType}
                    setFilterEndDate={setFilterEndDate}
                    setFilterDate={setFilterDate}
                    setFilterType={setFilterType}
                    setViewFilter={setViewFilter}
                />
                <Divider />
            </div>
            {isLoading ? (
                <div className={classes.loadingContainer}>
                    <LoadingCircle />
                </div>
            ) : (
                <div className={classes.memberHistoryList}>
                    {Object.keys(groupedItems).map((fullDate, dateIndex) => (
                        <div
                            key={dateIndex}
                            className={classes.groupedItemContainer}
                        >
                            <div className={classes.date}>
                                {renderDateHeader(fullDate)}
                            </div>
                            <div className={classes.dateDivider} />
                            {groupedItems[fullDate].map((item, itemIndex) => (
                                <MemberHistoryItem
                                    key={itemIndex}
                                    history={filteredItems}
                                    item={item}
                                    totalIndex={totalIndex++}
                                />
                            ))}
                        </div>
                    ))}
                    {!filteredItems.length && renderNoHistory()}
                </div>
            )}
            {viewFilter && (
                <PopUp
                    content={viewFilter}
                    filterDate={filterDate}
                    filterType={filterType}
                    handleModalClick={setViewFilter as any}
                    historyFilters={historyFilters}
                    setDateButtonActive={setDateButtonActive}
                    setTypeButtonActive={setTypeButtonActive}
                    setFilterEndDate={setFilterEndDate}
                    setFilterDate={setFilterDate}
                    setFilterType={setFilterType}
                />
            )}
        </div>
    );
};

export default MemberHistory;
