import Card from '../../../_shared/Card';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    title: {
        position: 'absolute',
        top: '-56px',
        left: '16px',
        background: '#8882D8',
        borderRadius: '32px',
        color: '#FFFFFF',
        fontFamily: 'Manrope',
        fontSize: '18px',
        fontWeight: 800,
        letterSpacing: '0.94px',
        margin: '2.375rem 0 0',
        padding: '3px 38px',
        textTransform: 'uppercase'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    terms: {
        '& li': {
            margin: '1rem 0'
        }
    }
}));

type TermsCardProps = {
    list: string[];
    title: string;
};

const TermsCard: React.FC<TermsCardProps> = ({ list, title }) => {
    const classes = useStyles();

    return (
        <Card style={{ position: 'relative', width: '100%' }}>
            <span className={classes.title}>{title.replace(/_/g, ' ')}</span>
            <div className={classes.body}>
                <ul className={classes.terms}>
                    {list.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </Card>
    );
};

export default TermsCard;
