import { GuideMessages } from '../../../../../types';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            background: '#5a628b',
            borderRadius: '10px',
            color: '#fff',
            height: 'auto',
            padding: '24px',
            width: '100%',
            zIndex: 4
        }
    })
);

type PrimaryMessageProps = {
    guide: boolean;
    intakeStep: number;
    messageItems: GuideMessages;
    showGuide: boolean;
    showIntro: boolean;
    stepTitle: string;
    stepTwo: boolean;
};

const PrimaryMessage: React.FC<PrimaryMessageProps> = ({
    guide,
    intakeStep,
    messageItems,
    showGuide,
    showIntro,
    stepTitle,
    stepTwo
}) => {
    const classes = useStyles();

    const stepSequence = intakeStep === 1 ? `First` : `Next`;

    const messagesToShow = stepTwo
        ? messageItems[stepTitle || intakeStep]?.stepTwo?.messages
        : messageItems[stepTitle || intakeStep]?.messages?.(stepSequence);

    return (
        <>
            {!showIntro &&
                !guide &&
                showGuide &&
                messagesToShow?.map((message, index) => (
                    <div
                        key={index}
                        className={classes.message}
                        dangerouslySetInnerHTML={{
                            __html: message
                        }}
                    />
                ))}
        </>
    );
};

export default PrimaryMessage;
