import CONSTANTS from '../constants/constants';

const initialState = {
    isFetchingDeterminantRanks: false,
    determinantRanks: []
};

export default function determinant_ranks_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.GET_DETERMINANT_RANKS:
            return {
                ...state,
                isFetchingDeterminantRanks: true
            };
        case CONSTANTS.SET_DETERMINANT_RANKS:
            return {
                ...state,
                isFetchingDeterminantRanks: false,
                determinantRanks: action.payload
            };
        default:
            return state;
    }
}
