import { dateDiffs } from '../../../../../util';

import Clock from '../../../../../assets/icon-clock.png';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        historyWrapper: {
            marginTop: '60px'
        },
        historyTitle: {
            fontSize: '14px',
            letterSpacing: '0.15em',
            textTransform: 'uppercase'
        },
        historyList: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            padding: '10px 0'
        },
        historyItem: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '3px solid #EAEBFB',
            borderRadius: '40px',
            cursor: 'pointer',
            margin: '5px 0',
            padding: '8px 20px',
            '&:hover': {
                backgroundColor: '#FFFFFF'
            }
        },
        itemDescription: {
            fontSize: '16px',
            fontWeight: 700,
            maxWidth: '60%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        itemGoals: {
            display: 'flex',
            alignItems: 'center',
            gap: '15px'
        },
        itemGoalsAmount: {
            fontSize: '20px',
            fontWeight: 800
        },
        dollarSign: {
            fontSize: '15px',
            fontWeight: 500,
            margin: '2px'
        },
        itemGoalsDate: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        clock: {
            height: '20px',
            marginRight: '10px',
            width: '20px'
        }
    })
);

// TODO: Add type for historyData
type HistoryProps = {
    historyData: any[];
    handleUseHistory: (need: any) => void;
};

const History: React.FC<HistoryProps> = ({ historyData, handleUseHistory }) => {
    const classes = useStyles();

    const dueDate = (need: { created_at: string; due_at: string }) => {
        const created = new Date(need.created_at);
        created.setHours(0, 0, 0, 0);
        const due = new Date(need.due_at);
        const diff = dateDiffs(created, due);

        if (diff.days <= 0) return 0;

        return diff.days;
    };

    return (
        <div className={classes.historyWrapper}>
            <div className={classes.historyTitle}>FROM HISTORY ...</div>
            <div className={classes.historyList}>
                {historyData.map((need, i) => (
                    <div
                        className={classes.historyItem}
                        key={i}
                        onClick={() => handleUseHistory(need)}
                    >
                        <div className={classes.itemDescription}>
                            {need.description}
                        </div>
                        <div className={classes.itemGoals}>
                            {need.amount > 0 || need.match_value > 0 ? (
                                <div className={classes.itemGoalsAmount}>
                                    <span className={classes.dollarSign}>
                                        $
                                    </span>
                                    {need.amount
                                        ? parseInt(need.amount) % 1 === 0
                                            ? parseInt(need.amount).toFixed(0)
                                            : need.amount.toFixed(2)
                                        : need.match_value}
                                </div>
                            ) : null}
                            <div className={classes.itemGoalsDate}>
                                <img
                                    src={Clock}
                                    className={classes.clock}
                                    alt="clock"
                                />
                                {dueDate(need)} days
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default History;
