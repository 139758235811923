import SamaritanFetch from './httpClient';

export const fetchV3HomelessTouchpoints = (homeless_id: number) => {
    let urlParams = `touchpoints[homeless_id]=${homeless_id}&touchpoint[relations][touchpoint_answers][dumbHack]`;

    return SamaritanFetch.v3(`/touchpoints?${urlParams}`);
};

export const fetchV3OrganizationTouchpoints = () => {
    let urlParams = `scope_to_organization=true&touchpoints&touchpoint[relations][touchpoint_answers][dumbHack]`;

    return SamaritanFetch.v3(`/touchpoints?${urlParams}`, {
        method: 'GET'
    });
};
