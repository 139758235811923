import CONSTANTS from '../constants/constants';

const initialState = {
    unauthorizedUser: false,
    getApiError: false,
    getApiErrorResponse: ''
};

export default function error_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_API_ERROR:
            return {
                ...state,
                getApiError: true,
                getApiErrorResponse: action.payload
            };
        case CONSTANTS.RESET:
            return initialState;
        case CONSTANTS.RESET_API_ERROR:
            return {
                ...state,
                getApiError: false,
                getApiErrorResponse: ''
            };
        case CONSTANTS.RESET_UNAUTHORIZED:
            return {
                ...state,
                unauthorizedUser: false
            };
        case CONSTANTS.UNAUTHORIZED_USER:
            return {
                ...state,
                unauthorizedUser: true
            };
        default:
            return state;
    }
}
