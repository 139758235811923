import SamaritanFetch from './httpClient';

export const fetchOrganizationPayments = (userId: number) =>
    SamaritanFetch.v2(`/payment_profile/${userId}/payments`, {
        query: {
            scope_to_organization: true
        }
    });

export const fetchMemberPayments = (userId: number, full: boolean) => {
    const query = {
        include_organization_tips: false,
        'payments[homeless_id]': userId,
        'payment[relationships][user]{}': null
    } as {
        include_organization_tips: boolean;
        'payments[homeless_id]': number;
        'payment[relationships][user]{}': null;
        page?: number;
        per_page?: number;
    };

    if (!full) {
        query.page = 1;
        query.per_page = 3;
    }

    return SamaritanFetch.v3(`/payments`, {
        query
    });
};
