import { useCallback, useMemo, useRef, useState } from 'react';

import {
    CTAButton1,
    CTAButton2,
    SecondaryButton
} from '../../../../../../../../_shared/buttons';
import Input from '../../../../../../../../_shared/forms/Input';

import classes from './UploadForm.module.scss';

type Props = {
    onCancel: () => void;
    onSubmit: (data: { file: File; fileName: string }) => void;
    errors: any[];
    initialState: { file: File; fileName: string } | null;
};

export default function UploadForm({
    onCancel,
    onSubmit,
    errors,
    initialState
}: Props): JSX.Element {
    const fileSelectorRef = useRef<HTMLLabelElement>(null);

    const [file, setFile] = useState(initialState ? initialState.file : null);
    const [fileName, setFileName] = useState(
        initialState ? initialState.fileName : ''
    );

    const isCorrectFileType = (file: File | null) => {
        if (!file) return true;

        if (
            file.type === 'application/pdf' ||
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg'
        )
            return true;
        return false;
    };

    const canSubmit = useMemo(() => {
        return file && fileName && isCorrectFileType(file);
    }, [file, fileName]);

    /**
     * Forward the button press to the label because it
     * doesn't bubble for some reason?
     */
    const onFileSelectorButtonClick = useCallback(() => {
        if (fileSelectorRef.current) fileSelectorRef.current.click();
    }, []);

    const onFileSelect = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                setFile(e.target.files[0]);
                setFileName(fileName || e.target.files[0].name);
            }
        },
        [fileName]
    );

    const onSubmitCallback = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!file || !fileName) return;

            onSubmit({
                file,
                fileName
            });
        },
        [file, fileName, onSubmit]
    );

    return (
        <>
            <span
                className="font-lg"
                style={{
                    textAlign: 'center',
                    marginBottom: 40,
                    fontWeight: 'normal'
                }}
            >
                New Document
            </span>
            <form className={classes.form} onSubmit={onSubmitCallback}>
                <div className={classes.formGroup}>
                    <input
                        className={classes.hiddenElement}
                        type="file"
                        accept=".jpg,.jpeg,.pdf"
                        aria-label="select file"
                        name="file"
                        id="file"
                        onChange={onFileSelect}
                    />
                    <label htmlFor="file">
                        <div className={classes.label}>File:</div>
                    </label>
                    <label
                        htmlFor="file"
                        ref={fileSelectorRef}
                        onClick={onFileSelectorButtonClick}
                    >
                        <CTAButton2 iconName="file">
                            <div className={classes.fileName}>
                                {file ? file.name : 'Select File'}
                            </div>
                        </CTAButton2>
                    </label>
                </div>
                {!isCorrectFileType(file) && (
                    <div className={classes.error}>
                        Incorrect file type. File must be type .jpeg, .jpg, or
                        .pdf
                    </div>
                )}
                <div className={classes.formGroup}>
                    <label htmlFor="fileName">
                        <div className={classes.label}>File Name:</div>
                    </label>
                    <Input
                        type="text"
                        name="fileName"
                        id="fileName"
                        value={fileName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFileName(e.target.value)
                        }
                        required
                    />
                </div>
                {!!errors.length && (
                    <span className={classes.error}>
                        {errors[0].description}
                    </span>
                )}
                <div style={{ textAlign: 'center' }}>
                    <CTAButton1
                        type="submit"
                        disabled={!canSubmit}
                        iconName="chevron-right"
                    >
                        Upload Document
                    </CTAButton1>
                    <div style={{ marginTop: 10 }}>
                        <SecondaryButton
                            onClick={(e: { preventDefault: () => void }) => {
                                e.preventDefault();
                                onCancel();
                            }}
                        >
                            Cancel
                        </SecondaryButton>
                    </div>
                </div>
            </form>
        </>
    );
}
