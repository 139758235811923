import Checkbox from '@mui/material/Checkbox';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    passwordCheckbox: {
        height: '14px',
        width: '14px',
        padding: '1px',
        marginRight: '8px',
        '& span > i': {
            fontSize: '16px',
            border: 'none'
        }
    },
    passwordCheckboxLabel: {
        fontSize: '12px'
    },
    requirementsContainer: {
        marginTop: '7px'
    },
    requirementItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '6px'
    }
}));

const PasswordRequirements = ({ password }: { password: string }) => {
    const classes = useStyles();

    const hasNumberOrSymbol = () => {
        const regex = /[0-9!@#$%^&*]/g;
        return regex.test(password);
    };

    const hasUppercase = () => {
        const regex = /(?=.*[A-Z])(?=.*[a-z])/;
        return regex.test(password);
    };

    return (
        <div className={classes.requirementsContainer}>
            <div className={classes.requirementItem}>
                <Checkbox
                    icon={<i className="far fa-circle" />}
                    checkedIcon={<i className="fas fa-check-circle" />}
                    checked={password.length >= 8}
                    className={classes.passwordCheckbox}
                    style={
                        password.length >= 8
                            ? { backgroundColor: '#FFFFFF', color: '#7FE8B2' }
                            : {}
                    }
                    tabIndex={-1}
                />
                <span className={classes.passwordCheckboxLabel}>
                    At least 8 characters
                </span>
            </div>
            <div className={classes.requirementItem}>
                <Checkbox
                    icon={<i className="far fa-circle" />}
                    checkedIcon={<i className="fas fa-check-circle" />}
                    checked={hasNumberOrSymbol()}
                    className={classes.passwordCheckbox}
                    style={
                        hasNumberOrSymbol()
                            ? { backgroundColor: '#FFFFFF', color: '#7FE8B2' }
                            : {}
                    }
                    tabIndex={-1}
                />
                <span className={classes.passwordCheckboxLabel}>
                    At least one number or symbol
                </span>
            </div>
            <div className={classes.requirementItem}>
                <Checkbox
                    icon={<i className="far fa-circle" />}
                    checkedIcon={<i className="fas fa-check-circle" />}
                    checked={hasUppercase()}
                    className={classes.passwordCheckbox}
                    style={
                        hasUppercase()
                            ? { backgroundColor: '#FFFFFF', color: '#7FE8B2' }
                            : {}
                    }
                    tabIndex={-1}
                />
                <span className={classes.passwordCheckboxLabel}>
                    At least one uppercase and lowercase letter
                </span>
            </div>
        </div>
    );
};

export default PasswordRequirements;
