import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        completePopup: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            backgroundColor: '#EDEDFA',
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 800,
            padding: '24px 0',
            width: '100%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
            color: '#1F2152',
            fontSize: '16px',
            fontWeight: 400,
            padding: '36px'
        },
        action: {
            fontWeight: 800
        },
        markMetBtn: {
            background: 'none',
            borderRadius: '36px',
            color: '#7FE8B2',
            border: '1px solid #7FE8B2',
            textTransform: 'none',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 800,
            padding: '16px 40px',
            margin: '10px 0 30px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& i': {
                marginRight: '12px',
                fontSize: '20px'
            }
        }
    })
);

type CompleteProps = {
    handleCompleteClick: () => void;
    type: string;
};

const Complete: React.FC<CompleteProps> = ({ handleCompleteClick, type }) => {
    const classes = useStyles();

    const header = type.replace(/\b\w/g, (match) => match.toUpperCase());

    const renderDescription = () => {
        if (type === 'action step') {
            return `Once an action step is marked as complete, any bonuses added to
            the action step will be given to the Member. We'll let any
            supporting samaritans know too! 🎉`;
        } else {
            return `Once a need is marked as met, we'll let supporting samaritans know of their impact! 🎉`;
        }
    };

    return (
        <div className={classes.completePopup}>
            <div className={classes.header}>Mark {header} as Met</div>
            <div className={classes.body}>{renderDescription()}</div>
            <Button
                className={classes.markMetBtn}
                onClick={() => handleCompleteClick()}
            >
                <i className="fas fa-check" />
                Mark As Met
            </Button>
        </div>
    );
};

export default Complete;
