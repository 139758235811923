import ReactGA from 'react-ga4';

import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    searchBarWrapper: {
        position: 'sticky',
        top: 0,
        background: '#FBFBFF',
        width: '100%',
        zIndex: 2
    },
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        paddingLeft: '24px',
        width: '95%',
        zIndex: 2
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        boxShadow: '0px 8px 16px 4px #0000000D',
        height: 'auto',
        margin: '16px 0',
        maxWidth: '450px',
        padding: '16px 24px',
        width: '95%'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%'
        }
    },
    searchIcon: {
        color: '#A6A6BF',
        pointerEvents: 'none'
    },
    cancelIcon: {
        color: '#7378E8',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        }
    }
}));

type SearchBarProps = {
    empty: boolean;
    query: string;
    setQuery: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setState: (state: { query: string }) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
    empty,
    query,
    setQuery,
    setState
}) => {
    const classes = useStyles();

    const handleSearchBarClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: 'Search Membber Bar'
        });
    };

    return (
        <div className={classes.searchBarWrapper}>
            <div className={classes.searchBarContainer}>
                <div className={classes.searchBar}>
                    <div className={classes.iconContainer}>
                        <div className={classes.searchIcon}>
                            <i className={'far fa-search'} />
                        </div>
                        <InputBase
                            disabled={empty}
                            id="search-bar-input"
                            inputProps={{
                                'aria-label': 'search'
                            }}
                            onChange={setQuery}
                            onClick={handleSearchBarClick}
                            placeholder="Find your member"
                            value={query}
                        />
                    </div>
                    {query && query.length && (
                        <div
                            className={classes.cancelIcon}
                            onClick={() => setState({ query: '' })}
                        >
                            <i className={'fas fa-times'} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
