import { GuideMessages } from '../../../../../types';

import { Bonus, ProfilePhoto } from '../Guides';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            background: '#5a628b',
            borderRadius: '10px',
            color: '#fff',
            height: 'auto',
            padding: '20px',
            width: '100%',
            zIndex: 4,
            '@media (max-width: 768px)': {
                width: 'auto'
            }
        },
        guidedMessage: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px'
        },
        listItem: {}
    })
);

type GuidedMessageProps = {
    guide: boolean;
    intakeStep: number;
    messageItems: GuideMessages;
    stepTitle: string;
};

const GuidedMessage: React.FC<GuidedMessageProps> = ({
    guide,
    intakeStep,
    messageItems,
    stepTitle
}) => {
    const classes = useStyles();

    const renderGuidedMessage = (stepTitle: string) => {
        switch (stepTitle) {
            case 'Action Steps':
                return <Bonus />;
            case 'Private Info':
                return <ProfilePhoto />;
            default:
                return '';
        }
    };

    return (
        <>
            {guide && (
                <div className={classes.guidedMessage}>
                    {messageItems[
                        stepTitle ? stepTitle : intakeStep
                    ].guideMessage?.map((message, index) => (
                        <div
                            key={index}
                            className={classes.message}
                            dangerouslySetInnerHTML={{
                                __html: message
                            }}
                        />
                    ))}
                    {renderGuidedMessage(stepTitle)}
                </div>
            )}
        </>
    );
};

export default GuidedMessage;
