import { DeploymentUsers } from 'types';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        managerItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        managerItemContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        },
        selectorButton: {
            fontSize: '24px',
            color: '#A6A6BF',
            padding: '0',
            minWidth: 'auto',
            borderRadius: '50%'
        }
    })
);

type ManagerItemProps = {
    manager: DeploymentUsers;
    referralManager: DeploymentUsers | null;
    setReferralManager: (manager: DeploymentUsers) => void;
};

const ManagerItem: React.FC<ManagerItemProps> = ({
    manager,
    referralManager,
    setReferralManager
}) => {
    const classes = useStyles();

    const checkSelected =
        referralManager &&
        referralManager.name === manager.name &&
        referralManager.id === manager.id;

    const handleSelect = () => {
        setReferralManager(manager);
    };

    return (
        <div className={classes.managerItemContainer}>
            <div className={classes.managerItem}>
                <span>{manager.name}</span>
                <Button
                    className={classes.selectorButton}
                    onClick={handleSelect}
                >
                    {checkSelected ? (
                        <i
                            className="fas fa-check-circle"
                            style={{ color: '#7378E8' }}
                        />
                    ) : (
                        <i className="fal fa-circle" />
                    )}
                </Button>
            </div>
        </div>
    );
};

export default ManagerItem;
