import { connect } from 'react-redux';

import CatchUp from 'components/holder_profile/profile_2.0/CatchUp';

import actions from 'redux/actions';

function mapStateToProps(state: any) {
    const { debitCardOptions } = state.debitCard;
    const {
        // homelesssGoalActions,
        isEditingGoalAction
    } = state.goalActions;
    const { catchUpItems, homelessInfo, maxBonusAmount, remainingBonusAmount } =
        state.homeless;
    const { supplementalQuestionAnswers, touchpointAnswers } =
        state.touchpointAnswers;
    const {
        supplementalQuestions,
        supplementalQuestionIds,
        touchpointQuestions
    } = state.touchpointQuestions;
    // const { maxBonusAmount } = state.user;

    return {
        catchUpItems,
        debitCardOptions,
        holder: homelessInfo,
        isEditingGoalAction,
        maxBonusAmount,
        remainingBonusAmount,
        supplementalQuestionAnswers,
        supplementalQuestionIds,
        supplementalQuestions,
        touchpointAnswers,
        touchpointQuestions
    };
}

const mapDispatchToProps = {
    completeCatchUpItem: actions.completeItemRequest,
    getHomelessCatchUpItems: actions.fetchCatchUpItemsRequest,
    getHomelessInfo: actions.getHomelessInfo,
    getTouchpointAnswers: actions.getTouchpointAnswers,
    getTouchpointQuestions: actions.getTouchpointQuestionsRequest,
    snoozeCatchUpItem: actions.snoozeItemRequest,
    updateGoalActionStatus: actions.updateGoalActionStatus,
    updateHomelessInfoField: actions.updateHomelessInfoField,
    updateProfileCompletedItems: actions.updateProfileCompletedItems
};

export default connect(mapStateToProps, mapDispatchToProps)(CatchUp);
