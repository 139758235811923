import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import * as api from '../../api/skills';

function getSkills() {
    return {
        type: CONSTANTS.GET_SKILLS
    };
}

function setSkills(skills: string[]) {
    return {
        type: CONSTANTS.SET_SKILLS,
        payload: skills
    };
}

function cancelGetSkills() {
    return {
        type: CONSTANTS.CANCEL_GET_SKILLS
    };
}

export function getSkillsRequest(_token: string) {
    return (dispatch: Function) => {
        dispatch(getSkills());
        return api
            .fetchSkills()
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { skills: string[] }) => {
                return dispatch(setSkills(responseJson.skills));
            })
            .catch((error: any) => {
                dispatch(cancelGetSkills());
                handleSamaritanApiError(error, dispatch);
            });
    };
}
