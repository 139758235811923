import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { User } from 'types';

import AuthManager from 'auth';
import { fetchOrganization } from '../../api/organization';
import { getToken } from 'api/samaritanApiService';

import { fetchLanguages, setMaxBonusAmount } from '../../redux/actions/user';

const useLogin = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [user, setUser] = useState<User | null>(null);

    const loginFn = useCallback(
        ({ email, password }: { email: string; password: string }) => {
            setIsLoading(true);
            setError(false);
            return getToken(email, password, (response: any) => {
                if (response.status === 200) {
                    const auth = AuthManager.getInstance();

                    auth.setApiToken(response.data.api_token);
                    auth.setToken(response.data.session_token);
                    auth.setUserID(response.data.id);
                    auth.setUserName(
                        `${response.data.name} ${response.data.last_name}`
                    );

                    if (response.data.hasOwnProperty('partner')) {
                        auth.setUserDeploymentIds(
                            response.data.partner.deployment_ids
                        );
                    }

                    if (
                        response.data.hasOwnProperty('partner') &&
                        response.data.is_non_profit
                    ) {
                        const getOrg = async () => {
                            const orgResponse = await fetchOrganization(
                                response.data.partner.id
                            );
                            const org = await orgResponse.json();

                            if (
                                org.organization.hasOwnProperty('intake_steps')
                            ) {
                                auth.setIntakeSteps(
                                    org.organization.intake_steps
                                );
                            } else {
                                auth.setIntakeSteps([]);
                            }
                        };
                        getOrg();

                        auth.setUserDeploymentIds(
                            response.data.partner.deployment_ids
                        );
                        auth.setUserOrganizationId(response.data.partner.id);
                        auth.setUserOrganizationName(
                            response.data.partner.name
                        );

                        if (
                            response.data.partner.hasOwnProperty('deployment')
                        ) {
                            auth.setUserDeploymentId(
                                `${response.data.partner.deployment.id}`
                            );
                            auth.setDeploymentOrgIds([
                                ...response.data.partner.deployment
                                    .organization_ids
                            ]);
                            auth.setUserDeploymentName(
                                response.data.partner.deployment.name
                            );

                            dispatch(
                                setMaxBonusAmount(
                                    response.data.partner.deployment
                                        ?.monthly_bonus_pool_max || 40
                                )
                            );
                        } else {
                            auth.setDeploymentOrgIds(response.data.partner.id);
                        }
                    }

                    setIsLoading(false);
                    setUser(response.data);
                    setIsSuccess(true);

                    if (response.data.id) {
                        dispatch(fetchLanguages());
                    }
                } else {
                    setIsLoading(false);
                    setIsSuccess(false);
                    setError(response);
                }
            });
        },
        [dispatch]
    );

    return {
        isSuccess,
        isLoading,
        error,
        user,
        login: loginFn
    };
};

export default useLogin;
