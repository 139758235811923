import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import * as api from '../../api/organization';

function fetchingOrganization() {
    return {
        type: CONSTANTS.GET_ORGANIZATION
    };
}

function setOrganization(response: any) {
    return {
        type: CONSTANTS.SET_ORGANIZATION,
        payload: response
    };
}

export function getOrganization(orgId: number) {
    return (dispatch: Function) => {
        dispatch(fetchingOrganization());

        return api
            .fetchOrganization(orgId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { organization: any }) => {
                return dispatch(setOrganization(responseJson.organization));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}
