import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: '4rem 0',
        padding: '0 0 2.25rem',
        width: '75%',
        zIndex: 1
    },
    inactiveBox: {
        border: '1px solid #7378E8',
        borderRadius: '4px',
        marginRight: '1rem',
        maxHeight: '44px',
        maxWidth: '44px',
        minHeight: '44px',
        minWidth: '44px',
        '& i': {
            display: 'none'
        }
    },
    activeBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#7378E8',
        border: '1px solid #7378E8',
        borderRadius: '4px',
        marginRight: '1rem',
        maxHeight: '44px',
        maxWidth: '44px',
        minHeight: '44px',
        minWidth: '44px',
        '& i': {
            color: '#FFFFFF',
            fontSize: '1.25rem'
        }
    },
    description: {
        color: '#1F1F51',
        fontFamily: 'Manrope',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.9rem'
    }
});

type AgreeCheckboxProps = {
    active: boolean;
    clickHandler: () => void;
    profileName: string;
};

const AgreeCheckbox = (props: AgreeCheckboxProps) => {
    const classes = useStyles();
    const { active, clickHandler, profileName } = props;

    const boxClassName = active ? classes.activeBox : classes.inactiveBox;

    return (
        <div className={classes.root}>
            <span onClick={clickHandler} className={boxClassName}>
                <i className={'far fa-check'} />
            </span>
            <span className={classes.description}>
                By checking this box, you are confirming that {profileName} is
                agreeing to the terms of services and privacy policy agreements
                above
            </span>
        </div>
    );
};

export default AgreeCheckbox;
