import React, { useRef } from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    progress: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        float: 'left',
        margin: '4px',
        textAlign: 'center'
    },
    barOverflow: {
        position: 'relative',
        height: '180px',
        marginBottom: '-104px',
        width: '360px'
    },
    bar: {
        transform: 'rotate(18deg) scaleX(-1) translate(-4%, 24%)',
        transformOrigin: '50% 50%',
        stroke: '#999DFFB2',
        strokeWidth: '8px',
        strokeLinecap: 'round'
    },
    barBackground: {
        transform: 'rotate(-35deg) scaleY(-1) scaleX(-1) translate(10%, -27%)',
        transformOrigin: '50% 50%',
        stroke: '#F3F3F3',
        strokeDasharray: 0,
        strokeDashoffset: 0,
        strokeLinecap: 'round',
        strokeWidth: '14px'
    },
    remainderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '18px'
    },
    amountContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    totalAmount: {
        fontWeight: 400
    },
    remainingAmount: {
        fontSize: '72px',
        fontWeight: 800,
        lineHeight: '77px'
    },
    remainingText: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '22px'
    },
    maxAmountText: {
        position: 'absolute',
        left: '79%',
        top: '77%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 300
    },
    bonusTooltip: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #E3E3FA',
        borderRadius: '8px',
        color: '#1F2152',
        fontSize: '16px',
        fontWeight: 300,
        margin: '0px 4px',
        padding: '12px !important',
        width: 'fit-content',
        '& i': {
            color: '#0AA823',
            fontSize: '24px',
            marginRight: '10px'
        }
    }
}));

type ProgressBarProps = {
    amount: number;
    maxAmount: number;
    name: string;
    remainingAmount: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
    amount,
    maxAmount,
    name,
    remainingAmount
}) => {
    const classes = useStyles();
    const progressBarBackgroundRef = useRef(null);
    const progressBarRef = useRef(null);

    const remaining = (amount / maxAmount) * 281;

    function getShortMonthName() {
        const today = new Date();
        const nextMonth = new Date(today);

        nextMonth.setMonth(today.getMonth() + 1);

        if (nextMonth.getMonth() !== (today.getMonth() + 1) % 12) {
            nextMonth.setFullYear(today.getFullYear(), today.getMonth() + 1);
        }

        const monthShortName = nextMonth.toLocaleString('default', {
            month: 'short'
        });

        return monthShortName;
    }

    const possessiveSuffix = name
        ? name.endsWith('s') || name.endsWith('S')
            ? `${name}'`
            : `${name}'s`
        : `member's`;

    const renderRemainingContent = () => {
        if (remainingAmount > 0) {
            return (
                <>
                    <span className={classes.remainingAmount}>
                        ${remainingAmount}
                    </span>
                    <span className={classes.remainingText}>
                        Remaining to assign
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span className={classes.remainingAmount}>🎊</span>
                    <span className={classes.remainingText}>
                        All Bonus Assigned!
                    </span>
                </>
            );
        }
    };

    return (
        <>
            <div className={classes.progress}>
                <div className={classes.barOverflow}>
                    <svg
                        width="360"
                        height="200"
                        viewBox="0 0 220 120"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            ref={progressBarBackgroundRef}
                            className={classes.barBackground}
                            d="M 30,25 A 80,80 0 0 0 190,105"
                            fill="transparent"
                        />
                        <path
                            ref={progressBarRef}
                            className={classes.bar}
                            style={
                                remainingAmount <= 0
                                    ? { stroke: '#E7FEEB' }
                                    : {}
                            }
                            d="M 190,105 A 80,80 0 0 0 30,25"
                            fill="transparent"
                            strokeDasharray={2 * Math.PI * 80}
                            strokeDashoffset={503 - remaining}
                        />
                    </svg>
                </div>
                <div className={classes.remainderContainer}>
                    <div className={classes.amountContainer}>
                        {renderRemainingContent()}
                    </div>
                    <div className={classes.maxAmountText}>
                        <span>${maxAmount}</span>
                        <span>maximum</span>
                    </div>
                </div>
            </div>
            {remainingAmount <= 0 && (
                <span className={classes.bonusTooltip}>
                    <i className="fas fa-info-circle" />
                    {possessiveSuffix} bonus pool will refresh in{' '}
                    {getShortMonthName()}!
                </span>
            )}
        </>
    );
};

export default ProgressBar;
