import useSummaryItemTypes from './Summary/SummaryItemTypes';

import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            fontSize: '1rem',
            fontWeight: 800,
            lineHeight: '1.5rem'
        },
        errorMessage: {
            color: '#E87F7F',
            fontSize: '0.7rem',
            fontWeight: 300,
            textAlign: 'center'
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            color: '#FFFFFF',
            fontSize: '12px',
            fontWeight: 900,
            height: '32px',
            width: '32px'
        },
        overlay: {
            position: 'absolute',
            // zIndex: 1,
            zIndex: 6,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            background: '#FFFFFFE5',
            borderRadius: '0 0 16px 16px',
            height: '100%',
            width: '100%'
        }
    })
);

type OverlayProps = {
    itemType: string;
    message: string;
    overlayType: null | string;
};

const Overlay: React.FC<OverlayProps> = ({
    itemType,
    message,
    overlayType
}) => {
    const classes = useStyles();

    const itemTypes = useSummaryItemTypes();

    const { updateText } = itemTypes[itemType];

    const overlayContent = {
        add: {
            color: '#1FAD64',
            icon: 'fa-check',
            text: typeof updateText === 'string' ? updateText : updateText.add
        },
        complete: {
            color: '#1FAD64',
            icon: 'fa-check',
            text: 'Marked as complete'
        },
        edit: {
            color: '#1FAD64',
            icon: 'fa-check',
            text: 'Due date updated'
        },
        error: {
            color: '#E87F7F',
            icon: 'fa-exclamation-circle',
            text: 'Error!'
        },
        loading: {
            color: '#7378E8',
            icon: 'fa-spinner fa-spin',
            text: 'Loading...'
        },
        redeem: {
            color: '#1FAD64',
            icon: 'fa-check',
            text:
                typeof updateText === 'string' ? updateText : updateText.redeem
        },
        remind: {
            color: '#1FAD64',
            icon: 'fa-check',
            text: 'Reminder sent'
        },
        skip: {
            color: '#E87F7F',
            icon: 'fa-arrow-right',
            text: 'Catch-up item skipped'
        },
        transfer: {
            color: '#1FAD64',
            icon: 'fa-check',
            text:
                typeof updateText === 'string'
                    ? updateText
                    : updateText.transfer
        }
    } as {
        [key: string]: {
            color: string;
            icon: string;
            text: string;
        };
    };

    if (!overlayType) return null;

    return (
        <Fade in timeout={1000}>
            <div className={classes.overlay}>
                <i
                    className={`${classes.icon} far ${overlayContent[overlayType].icon}`}
                    style={{
                        backgroundColor: overlayContent[overlayType].color
                    }}
                />
                <span
                    className={classes.content}
                    style={{ color: overlayContent[overlayType].color }}
                >
                    {overlayContent[overlayType].text}
                </span>
                {message && (
                    <span className={classes.errorMessage}>{message}</span>
                )}
            </div>
        </Fade>
    );
};

export default Overlay;
