import Link from '../CustomLink';

import { withStyles } from '@mui/styles';

const styles = () => ({
    root: {
        backgroundColor: '#7279e8',
        color: 'white',
        fontSize: '25px',
        height: '50px',
        width: '50px',
        borderRadius: '50px',
        border: '0',
        left: '0.2em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus': {
            outline: 'none',
            border: 0
        }
    },
    icon: {
        marginLeft: '-2px'
    }
});

type BackButtonProps = {
    classes: {
        [key: string]: string;
    };
    href: string;
};

const BackButton: React.FC<BackButtonProps> = ({ classes, href }) => {
    return (
        <Link to={href}>
            <button className={classes.root}>
                <i className={`far fa-chevron-left ${classes.icon}`} />
            </button>
        </Link>
    );
};

export default withStyles(styles)(BackButton);
