import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateHomelessInfoField } from 'redux/actions/homeless';

import { updateHomelessPrivateInfo } from 'api/homeless';

const useUpdatePrivateProfile = () => {
    const dispatch = useDispatch();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);

    const updatePrivateProfile = useCallback(
        (memberId: number, data: any) => {
            setIsLoading(true);
            setIsError(false);
            updateHomelessPrivateInfo(memberId, data)
                .then(
                    (res: {
                        errors: { attribute: string; description: string }[];
                    }) => {
                        if (res.errors) {
                            const { attribute, description } = res.errors[0];
                            setIsSuccess(false);
                            setIsError(true);
                            setIsLoading(false);
                            setError(
                                `${attribute ? attribute : ''} ${description}`
                            );
                        } else {
                            data.forEach((value: string, key: string) => {
                                const matchedKey = key.match(/\[(.*?)\]/);
                                let formattedKey = matchedKey
                                    ? matchedKey[1]
                                    : key;

                                formattedKey = formattedKey.replace(
                                    /^pii_/,
                                    ''
                                );

                                if (formattedKey === 'date_of_birth') {
                                    formattedKey = 'birthday';
                                }

                                if (formattedKey === 'phone') {
                                    formattedKey = 'phone_number';
                                }

                                dispatch(
                                    updateHomelessInfoField(formattedKey, value)
                                );
                            });

                            setIsSuccess(true);
                            setIsError(false);
                            setIsLoading(false);
                            setError(null);
                        }
                    }
                )
                .catch((err: any) => {
                    setIsSuccess(false);
                    setIsError(true);
                    setIsLoading(false);
                    setError(err);
                });
        },
        [dispatch]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updatePrivateProfile
    };
};

export default useUpdatePrivateProfile;
