import SamaritanFetch from './httpClient';

export const fetchCatchUpItems = (
    memberId: number,
    statuses: string[] = ['active']
) => {
    const statusQuery = statuses
        .map((status) => `member_catch_up_items[status][]=${status}`)
        .join('&');

    return SamaritanFetch.v3(
        `/samaritan_members/${memberId}/catch_up_items?member_catch_up_item[relations][record]{}&${statusQuery}`,
        {
            method: 'GET'
        }
    );
};

export const snoozeCatchUpItem = (catchUpItemId: number) =>
    SamaritanFetch.v3.put(
        `/member_catch_up_items/${catchUpItemId}?member_catch_up_item[status]=snoozed`
    );