import SamaritanFetch from './httpClient';

export const createSessionToken = () => {
    return SamaritanFetch.v3('intake_session_tokens', {
        method: 'POST'
    }).then((res: { json: () => any }) => res.json());
};

export const fetchFile = async (
    url: string,
    fileName: string,
    mimeType: string
) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const file = new File([blob], fileName, { type: mimeType });

    return file;
};

export const fetchSession = (token: string) => {
    return SamaritanFetch.v3(`intake_session_tokens/${token}`, {
        method: 'GET'
    }).then((res: { json: () => any }) => res.json());
};

export const uploadPhoto = (token: string, city: string, photo: File) => {
    const body = new FormData();
    body.append('intake_session_token[photo]', photo);

    return SamaritanFetch.v3(`intake_session_tokens/${token}`, {
        method: 'PUT',
        body: body,
        city: city
    });
};
