import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { TouchpointQuestions } from '../../types';

import CloseButton from '../_shared/buttons/CloseButton';
import LeftChevronButton from '../_shared/buttons/LeftChevronButton';

import { resetIntake } from '../../redux/actions/intake';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        position: 'sticky',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#FBFBFF',
        zIndex: 3
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px 0 5px 0',
        width: '100%',
        zIndex: 1
    },
    headerTitle: {
        margin: 0,
        '@media (max-width: 500px)': {
            fontSize: '20px'
        }
    },
    backButton: {
        position: 'absolute',
        left: 0,
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '40px',
        paddingInline: '5%',
        '@media (max-width: 500px)': {
            fontSize: '30px'
        }
    },
    progressBar: {
        display: 'flex',
        justifyContent: 'center',
        gap: '4px',
        listStyleType: 'none',
        margin: '10px 0',
        maxWidth: '500px',
        padding: '0',
        width: '100%'
    },
    progressBarItem: {
        borderRadius: '12px',
        backgroundColor: '#E3E4FA',
        height: '12px',
        width: '80px',
        '@media (max-width: 500px)': {
            width: '60px'
        }
    },
    progressBarItemPrevious: {
        backgroundColor: '#7279E8'
    },
    progressBarItemActive: {
        backgroundColor: '#E3E4FA',
        borderRadius: '12px',
        transition: 'width 0.25s ease-in-out',
        width: '160px',
        '@media (max-width: 500px)': {
            width: '120px'
        }
    },
    progressBarStatus: {
        position: 'absolute',
        backgroundColor: '#7279E8',
        borderRadius: '12px',
        height: '12px',
        transition:
            'width 0.25s ease-in-out, background-color 0.25s ease-in-out'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '40px',
        height: '100%',
        paddingInline: '5%',
        '@media (max-width: 500px)': {
            fontSize: '30px'
        }
    }
});

type Props = {
    decrementStep: (step: number | null) => void;
    intakeAgreed: boolean;
    intakeBirthDay: string;
    intakeDeploymentId?: number | null;
    intakeEmail: string;
    intakeGoals: string[];
    // intakeNeeds: string[];
    // intakeOrgId: string;
    intakePhoneNo: string;
    intakePrivateFullName: string;
    intakeProfileName: string;
    intakeProfilePic: string;
    intakeProgressWidth: { step: number; width: number }[];
    intakeRelevantId: string;
    intakeSteps: string[];
    intakeTouchpoints: {
        answer: string;
        questionId: number;
    }[];
    is_showing_intake_guides: boolean;
    maxStep: number;
    minStep: number;
    progressBarWidth: number;
    setProgressBarWidth: (width: number) => void;
    step: number;
    touchpointQuestions: TouchpointQuestions[];
};

const IntakeHeader: React.FC<Props> = ({
    decrementStep,
    intakeSteps,
    maxStep,
    minStep,
    step,
    ...props
}) => {
    const classes = useStyles();

    const {
        intakeAgreed,
        intakeBirthDay,
        intakeDeploymentId,
        intakeEmail,
        intakeGoals,
        intakePhoneNo,
        intakePrivateFullName,
        intakeProfileName,
        intakeProfilePic,
        intakeProgressWidth,
        intakeRelevantId,
        intakeTouchpoints,
        is_showing_intake_guides,
        progressBarWidth,
        setProgressBarWidth,
        touchpointQuestions
    } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const backButton = () => {
        if (step === minStep) return null;
        return (
            <LeftChevronButton
                dark
                onClick={() => {
                    if (!is_showing_intake_guides) {
                        decrementStep(step);
                    } else {
                        decrementStep(null);
                    }
                }}
                className={classes.backButton}
                aria-label="Back"
            />
        );
    };

    const closeButton = () => {
        return (
            <CloseButton
                className={classes.closeBtn}
                onClick={() => {
                    dispatch(resetIntake());
                    history.push(`/`);
                }}
            />
        );
    };

    const dynamicIntakeSteps = useCallback(() => {
        if (!intakeSteps) {
            return [
                `Setting Action Steps`,
                `Account`,
                `Status Capture`,
                `Confirming Terms of Service`
            ];
        }

        const titles = intakeSteps.map((step) => {
            switch (step) {
                case `Action Steps`:
                    return `Setting Action Steps`;
                case `Private Info`:
                    return `Account`;
                case `Status Capture`:
                    return `Status Capture`;
                default:
                    return `Invalid Step: ${step}`;
            }
        });

        return [...titles, `Confirming Terms of Service`];
    }, [intakeSteps]);

    const renderPreviousProgressBarWidth = (step: number) => {
        const minWidth = 80;
        const maxWidth = 160;
        const intakeWidth =
            intakeProgressWidth.find((width) => width.step === step + 1)
                ?.width || 40;

        const previousStatusWidth = (intakeWidth / maxWidth) * minWidth;

        return previousStatusWidth;
    };

    const renderProgressBarClass = (index: number) => {
        if (step === index) {
            return `${classes.progressBarItemActive}`;
        }

        if (step >= index) {
            return `${classes.progressBarItem}`;
        }

        return `${classes.progressBarItem}`;
    };

    const renderTitle = useCallback(
        (step: number) => {
            const dynamicSteps = dynamicIntakeSteps();

            if (step > 0 && step <= dynamicSteps.length) {
                return dynamicSteps[step - 1];
            }

            return ``;
        },
        [dynamicIntakeSteps]
    );

    const renderProgressBarWidth = useCallback(
        (step: number, screenWidth: number) => {
            const title = renderTitle(step);
            const maxWidth = screenWidth <= 500 ? 120 : 160;
            let width = 0;

            switch (title) {
                case 'Account':
                    if (intakePrivateFullName) width++;
                    if (intakeBirthDay) width++;
                    if (intakeDeploymentId) width++;
                    if (intakeRelevantId) width++;
                    if (intakeEmail) width++;
                    if (intakePhoneNo) width++;
                    if (intakeProfileName) width++;
                    if (intakeProfilePic) width++;

                    if (intakeDeploymentId) {
                        width *= maxWidth / 8;
                    } else {
                        width *= maxWidth / 7;
                    }

                    break;
                case 'Setting Action Steps':
                    if (intakeGoals.length > 1) width++;
                    width *= maxWidth;
                    break;
                case 'Status Capture':
                    width = intakeTouchpoints.length;
                    width *= maxWidth / touchpointQuestions.length;
                    break;
                case 'Confirming Terms of Service':
                    if (intakeAgreed) width++;
                    width *= maxWidth;
                    break;
                default:
                    width *= maxWidth;
                    break;
            }

            setProgressBarWidth(width);
            return width;
        },
        [
            intakeAgreed,
            intakeBirthDay,
            intakeDeploymentId,
            intakeEmail,
            intakeGoals,
            intakePhoneNo,
            intakePrivateFullName,
            intakeProfileName,
            intakeProfilePic,
            intakeRelevantId,
            intakeTouchpoints,
            renderTitle,
            setProgressBarWidth,
            touchpointQuestions
        ]
    );

    useEffect(() => {
        const handleResize = () => {
            renderProgressBarWidth(step, window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        if (step > 0) {
            renderProgressBarWidth(step, window.innerWidth);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [step, renderProgressBarWidth]);

    return (
        <div className={classes.root} id="intake-header">
            <div className={classes.headerTitleContainer}>
                {backButton()}
                {step < maxStep ? (
                    <h2 className={classes.headerTitle}>
                        {renderTitle(step)} ({step} / {maxStep - 1})
                    </h2>
                ) : (
                    <h2 className={classes.headerTitle}>{renderTitle(step)}</h2>
                )}
                {closeButton()}
            </div>
            {step < maxStep ? (
                <div className={classes.progressBar}>
                    {Array.from({ length: maxStep - minStep }, (_, i) => (
                        <div
                            style={{ position: 'relative', display: 'flex' }}
                            key={i + minStep}
                        >
                            <span
                                className={renderProgressBarClass(i + minStep)}
                            />
                            {i + minStep === step && (
                                <span
                                    className={classes.progressBarStatus}
                                    style={{
                                        width: progressBarWidth + 'px'
                                    }}
                                />
                            )}
                            {i + minStep < step && (
                                <span
                                    className={classes.progressBarStatus}
                                    style={{
                                        width: renderPreviousProgressBarWidth(i)
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default IntakeHeader;
