import { CTAButton2 } from '../buttons';

import Modal from '../Modal';

import classes from './RequestErrorModal.module.scss';

/**
 * Modal with message and button to show that an api request has failed and to retry.
 * Has a retry button that will call an (optionally) supplied retryFunc prop.
 * Optional message will show a message in the modal.
 */
type Props = {
    message: string;
    retryFunc?: () => void;
    open: boolean;
    onClose: () => void;
};

export default function RequestErrorModal({
    message,
    retryFunc,
    open,
    onClose
}: Props): JSX.Element {
    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.error}>
                <span>Something went wrong:</span>
                <span style={{ fontWeight: 'bold' }}>{message}</span>
                {!!retryFunc && (
                    <>
                        <span>Click below to retry.</span>
                        <div style={{ textAlign: 'center' }}>
                            <CTAButton2 onClick={retryFunc}>Retry</CTAButton2>
                        </div>
                    </>
                )}
                <span>
                    If this error continues contact{' '}
                    <a href="mailto:support@samaritan.com">support.</a>
                </span>
            </div>
        </Modal>
    );
}
