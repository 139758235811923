import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Card from '../components/Card';
import Input from '../components/Input';
import LoadingCircle from 'components/_shared/LoadingCircle';
import Modal from 'components/_shared/Modal';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { checkCurrentMonth, formatLongDate, getShortMonthName } from '../utils';

import useRemoteCopy from 'hooks/useRemoteCopy';

const useStyles = makeStyles((theme) => ({
    actionTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: '800',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        position: 'absolute',
        top: '-40px',
        padding: '8px 20px',
        borderRadius: '100px',
        background: '#8882D8',
        color: '#fff'
    },
    actionBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '12px 0px',
        fontSize: '28px',
        '& span': {
            padding: '10px 0'
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px'
    },
    saveButton: {
        fontWeight: '800',
        fontSize: '1.25rem',
        color: '#fff',
        width: '240px',
        borderRadius: '100px',
        background: 'linear-gradient(90deg, #9897E3 0%, #B8C2FF 100%)',
        padding: '15px 0',
        margin: '0',
        textTransform: 'none'
    },
    saveButtonDisabled: {
        background: 'none'
    },
    deleteButton: {
        fontWeight: '700',
        fontSize: '1.25rem',
        color: '#F5577A',
        borderRadius: '100px',
        padding: '15px 30px',
        background: 'transparent',
        marginTop: '10px',
        textTransform: 'none'
    },
    inputTitle: {
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '0.15em',
        color: '#1F1F51',
        opacity: '50%',
        textTransform: 'uppercase'
    },
    inputTitleDisabled: {
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '0.15em',
        color: '#1F1F51',
        textTransform: 'uppercase'
    },
    bonusTooltip: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: '300',
        border: '1px solid #E3E3FA',
        borderRadius: '8px',
        padding: '12px !important',
        color: '#1F2152',
        width: 'fit-content',
        '& i': {
            marginRight: '10px',
            fontSize: '24px',
            color: '#7378E8'
        }
    }
}));

const Edit = ({
    actionStep,
    catchUpID,
    dateISO,
    setDateISO,
    deleteAction,
    isEditing,
    need,
    remainingBonusAmount,
    saveAction,
    type
}) => {
    const classes = useStyles();
    const editRef = useRef(null);
    const refetchCount = useRef(0);
    const suggestions = useRemoteCopy(
        type === 'action step' ? 'action_step_suggestions' : 'need_suggestions'
    );

    const actionType = type.charAt(0).toUpperCase() + type.slice(1);

    const { amount, created_at, description, due_at, match_value } =
        need || actionStep;

    const editableAmount = match_value + remainingBonusAmount;

    const [editDate, setEditDate] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editAmount, setEditAmount] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const dueDateInThePast =
        new Date(due_at) < new Date() && new Date(editDate) < new Date();

    const handleSaveEdit = () => {
        saveAction(
            {
                amount: editAmount,
                description: editDescription,
                dateISO: dateISO,
                due_at: editDate,
                goal: actionStep ? actionStep.goal : null,
                quality_of_life_measures: actionStep
                    ? actionStep.quality_of_life_measures
                    : null
            },
            catchUpID
        );
    };

    const inputTitleClass = (input) => {
        return input
            ? classes.inputTitle
            : `${classes.inputTitle} ${classes.inputTitleDisabled}`;
    };

    const renderBonusTooltip = () => {
        if (!checkCurrentMonth(created_at)) {
            return `Because this action step's bonus was assigned from ${getShortMonthName(
                created_at
            )}'s bonus pool, a larger bonus can't be selected.`;
        }

        if (remainingBonusAmount >= 0) {
            return `$${editableAmount} remaining from ${getShortMonthName()}'s bonus pool.`;
        }

        if (remainingBonusAmount <= 0 && match_value === 0) {
            return `${getShortMonthName()}'s bonus pool was fully assigned. Bonuses will be assignable starting next month!`;
        }

        return `Edge case error with the bonus amount!`;
    };

    useEffect(() => {
        if (editRef.current) {
            editRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }, [editRef]);

    useEffect(() => {
        if (suggestions.isError && refetchCount.current < 5) {
            refetchCount.current++;
            suggestions.refetch();
        }

        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [suggestions]);

    useEffect(() => {
        setEditDate(formatLongDate(new Date(due_at)));
        setDateISO(due_at);
        setEditDescription(description);
        setEditAmount(
            amount !== undefined
                ? amount === 0
                    ? 'No Amount'
                    : `$${amount.split('.')[0]}`
                : match_value !== undefined
                ? match_value === 0
                    ? 'No Bonus'
                    : `$${match_value}`
                : ''
        );
    }, [
        amount,
        description,
        due_at,
        match_value,
        setDateISO,
        setEditAmount,
        setEditDate,
        setEditDescription
    ]);

    useEffect(() => {
        if (
            editDescription.length &&
            !checkCurrentMonth &&
            remainingBonusAmount <= 0
        ) {
            setEditAmount('No Bonus');
        }
    }, [editDescription, remainingBonusAmount]);

    if (isErrored) {
        return (
            <Modal
                open={isErrored}
                onClose={() => {
                    setIsErrored(false);
                }}
            >
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Card id="edit-card">
            <div style={{ position: 'relative' }} ref={editRef}>
                {!suggestions.isLoaded ? (
                    <LoadingCircle />
                ) : (
                    <>
                        <div className={classes.actionBody}>
                            <Input
                                edit
                                intakeInput={editDescription}
                                setIntakeInput={setEditDescription}
                                inputTitleClass={inputTitleClass}
                                suggestions={suggestions.remoteCopy}
                                type={'descriptions'}
                            />
                            <Input
                                edit
                                createdAt={created_at}
                                editableAmount={editableAmount}
                                intakeInput={editAmount}
                                setIntakeInput={setEditAmount}
                                inputTitleClass={inputTitleClass}
                                label={need ? 'it costs' : 'bonus'}
                                remainingBonusAmount={remainingBonusAmount}
                                setShowTooltip={setShowTooltip}
                                suggestions={suggestions.remoteCopy}
                                type={need ? 'cost' : 'bonus'}
                            />
                            {showTooltip && (
                                <span className={classes.bonusTooltip}>
                                    <i className="fas fa-info-circle" />
                                    {renderBonusTooltip()}
                                </span>
                            )}
                            <Input
                                edit
                                dateISO={due_at}
                                setDateISO={setDateISO}
                                intakeInput={editDate}
                                setIntakeInput={setEditDate}
                                inputTitleClass={inputTitleClass}
                                label={
                                    need ? 'need it latest by' : 'completed by'
                                }
                                suggestions={suggestions.remoteCopy}
                                type={'due_dates'}
                                dueDateInThePast={dueDateInThePast}
                            />
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                className={classes.saveButton}
                                disabled={dueDateInThePast}
                                onClick={() => handleSaveEdit()}
                                style={{
                                    opacity: dueDateInThePast ? '0.5' : '1'
                                }}
                            >
                                {isEditing ? (
                                    <LoadingCircle />
                                ) : (
                                    `Save ${actionType}`
                                )}
                            </Button>
                            <Button
                                className={classes.deleteButton}
                                onClick={() => deleteAction()}
                            >
                                Delete
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

Edit.propTypes = {
    actionStep: PropTypes.object,
    deleteAction: PropTypes.func,
    need: PropTypes.object,
    saveAction: PropTypes.func,
    type: PropTypes.string.isRequired
};

export default Edit;
