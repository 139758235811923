import { MemberHistory, PexHistory } from 'types';

import HistoryItem from './HistoryItem';
import React from 'react';

type HistoryListProps = {
    classes: {
        [key: string]: string;
    };
    historyItems: MemberHistory[] | PexHistory[];
};

const HistoryList: React.FC<HistoryListProps> = ({ classes, historyItems }) => {
    const groupItemsByMonth = (historyItems: PexHistory[]) => {
        const groupedItems = {} as {
            [key: string]: PexHistory[];
        };

        historyItems.forEach((item) => {
            const date = new Date(item.date || item.TransactionTime);
            const monthYear = `${date.getMonth()}-${date.getFullYear()}`;

            if (!groupedItems[monthYear]) {
                groupedItems[monthYear] = [];
            }

            groupedItems[monthYear].push(item);
        });

        return groupedItems;
    };

    const renderMonthHeader = (monthYear: string) => {
        const [month, year] = monthYear.split('-');
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        if (
            parseInt(month, 10) === currentMonth &&
            parseInt(year, 10) === currentYear
        ) {
            return <div className={classes.historyTitle}>This Month</div>;
        } else {
            return (
                <div className={classes.historyTitle}>{`${
                    monthNames[parseInt(month, 10)]
                } ${year}`}</div>
            );
        }
    };

    const groupedItems = groupItemsByMonth(historyItems as PexHistory[]);

    return (
        <>
            {Object.keys(groupedItems).map((monthYear, index) => (
                <div
                    key={index}
                    className={classes.historyBody}
                    style={{ marginTop: '0px' }}
                >
                    {renderMonthHeader(monthYear)}
                    {groupedItems[monthYear].map((item, index) => (
                        <HistoryItem
                            key={index}
                            classes={classes}
                            item={item}
                        />
                    ))}
                </div>
            ))}
        </>
    );
};

export default HistoryList;
