import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    messageNotice: {
        flexDirection: 'row !important',
        alignItems: 'center !important',
        margin: '20px',
        padding: '12px 20px',
        backgroundColor: '#EFF0FF',
        borderRadius: '15px',
        '& span': {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#1F1F51'
        },
        '& i': {
            marginRight: '15px',
            color: '#1F1F51',
            fontSize: '22px',
            lineHeight: '22px'
        }
    }
}));

const Notice = ({ memberName, samaritanName, startRef }) => {
    const classes = useStyles();

    return (
        <div className={classes.messageNotice} ref={startRef}>
            <i className="far fa-exclamation-circle" />
            {memberName && samaritanName ? (
                <span>
                    This is {memberName}'s conversation with a samaritan,{' '}
                    {samaritanName}. With their permission, you may reply on
                    behalf of the Samaritan Member. Report any unwelcomed
                    messages with an email to support@samaritan.city.
                </span>
            ) : (
                <span>
                    Your messages are sent through Samaritan via email/text to
                    Samaritan Members. Your contact info is never shared.
                </span>
            )}
        </div>
    );
};

Notice.propTypes = {
    memberName: PropTypes.string,
    samaritanName: PropTypes.string
};

export default Notice;
