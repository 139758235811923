import * as api from 'api/catch_up_items';
import * as constants from '../constants/constants';

import { CatchUpItems } from 'types';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from '.';

export function catchUpItemFailure(catchUpItemId: number) {
    return {
        type: constants.CATCH_UP_ITEM_FAILURE,
        payload: catchUpItemId
    };
}

export function catchUpItemSubmit(catchUpItemId: number) {
    return {
        type: constants.CATCH_UP_ITEM_SUBMIT,
        payload: catchUpItemId
    };
}

export function catchUpItemSuccess(catchUpItemId: number) {
    return {
        type: constants.CATCH_UP_ITEM_SUCCESS,
        payload: catchUpItemId
    };
}

export function completeItem(catchUpItemId: number) {
    return {
        type: constants.COMPLETE_CATCH_UP_ITEM,
        payload: catchUpItemId
    };
}

function fetchCatchUpItems() {
    return {
        type: constants.FETCH_CATCH_UP_ITEMS
    };
}

export function resetCatchUpItemStatus() {
    return {
        type: constants.RESET_CATCH_UP_ITEM_STATUS
    };
}

function setCatchUpItems(items: CatchUpItems[]) {
    return {
        type: constants.SET_CATCH_UP_ITEMS,
        payload: items
    };
}

function snoozeItem(catchUpItemId: number, snoozeTill?: string) {
    return {
        type: constants.SNOOZE_CATCH_UP_ITEM,
        payload: { catchUpItemId, snoozeTill }
    };
}

export function submitCatchUpItem(catchUpItemId: number) {
    return {
        type: constants.CATCH_UP_ITEM_SUBMIT,
        payload: { catchUpItemId }
    };
}

export function completeItemRequest(catchUpItemId: number) {
    return (dispatch: Function) => dispatch(completeItem(catchUpItemId));
}

export function fetchCatchUpItemsRequest(
    memberId: number,
    status: string[] = ['active']
) {
    return (dispatch: Function) => {
        dispatch(fetchCatchUpItems());

        return api
            .fetchCatchUpItems(memberId, status)
            .then(handleSamaritanApiResponse)
            .then((response: { member_catch_up_items: CatchUpItems[] }) => {
                const items = response.member_catch_up_items;

                // Check if there is an `action_step_due` item with `record.type === 'lifecareable'`
                const hasLifecareableActionStep = items.some(
                    (item) =>
                        item.type === 'action_step_due' &&
                        item.relations?.record?.action_type === 'lifecarable'
                );

                // Filter the items to exclude `no_status_capture_in_last_30_days` if there is a `lifecareable` item
                const filteredItems = items.filter((item) => {
                    if (item.type === 'no_status_capture_in_last_30_days') {
                        return !hasLifecareableActionStep;
                    }
                    return true;
                });

                dispatch(setCatchUpItems(filteredItems));
            })
            .catch(handleSamaritanApiError);
    };
}

export function snoozeItemRequest(catchUpItemId: number) {
    return (dispatch: Function) => {
        return api
            .snoozeCatchUpItem(catchUpItemId)
            .then(handleSamaritanApiResponse)
            .then((res: { member_catch_up_item: { snooze_till: string } }) => {
                dispatch(
                    snoozeItem(
                        catchUpItemId,
                        res.member_catch_up_item.snooze_till
                    )
                );
            })
            .catch(handleSamaritanApiError);
    };
}