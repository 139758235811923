import Lottie from 'react-lottie';

import * as animationData from './samaritan-confetti.json';

const Confetti: React.FC<{ popupStyle?: boolean; stopConfetti: boolean }> = ({
    popupStyle,
    stopConfetti
}) => {
    const confettiStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        paddingTop: popupStyle ? '48px' : 0,
        pointerEvents: 'none',
        width: '100%',
        zIndex: 2
    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={confettiStyle}>
            <Lottie options={defaultOptions} isStopped={stopConfetti} />
        </div>
    );
};

export default Confetti;
