import { useEffect, useState, useRef } from 'react';

import { NewGoalActions } from 'types';

import ActionStepForm from 'components/intake/Actions/components/ActionStepForm';
import ActionCardOverlay from './ActionCardOverlay';
import Card from '../../components/Card';
import LoadingCircle from 'components/_shared/LoadingCircle';
import Modal from 'components/_shared/Modal';

import { useActionTemplate, useRemoteCopy } from 'hooks';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionTitle: {
            position: 'absolute',
            top: '-40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#8882D8',
            borderRadius: '100px',
            color: '#fff',
            fontSize: '1rem',
            fontWeight: 800,
            letterSpacing: '0.1em',
            padding: '8px 20px',
            textTransform: 'uppercase'
        },
        actionBody: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '28px',
            padding: '12px 0px',
            '& span': {
                padding: '10px 0'
            }
        },
        addButton: {
            background: 'linear-gradient(90deg, #9897E3 0%, #B8C2FF 100%)',
            borderRadius: '100px',
            color: '#fff',
            fontSize: '1.25rem',
            fontWeight: 800,
            margin: '0',
            padding: '10px 20px',
            textTransform: 'none'
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px'
        },
        cancelButton: {
            background: 'transparent',
            borderRadius: '100px',
            color: '#F5577A',
            fontSize: '1.25rem',
            fontWeight: 700,
            marginTop: '10px',
            padding: '15px 30px',
            textTransform: 'none'
        },
        disabled: {
            background: '#E3E4FA',
            color: '#fff !important'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        inputTitle: {
            color: '#1F1F51',
            fontSize: '14px',
            fontWeight: 700,
            letterSpacing: '0.15em',
            opacity: '50%',
            textTransform: 'uppercase'
        },
        inputTitleDisabled: {
            color: '#1F1F51',
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: '0.15em',
            textTransform: 'uppercase'
        },
        bonusTooltip: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            color: '#1F2152',
            fontSize: '16px',
            fontWeight: 300,
            padding: '12px !important',
            width: 'fit-content',
            '& i': {
                color: '#7378E8',
                fontSize: '24px',
                marginRight: '10px'
            }
        }
    })
);

type AddProps = {
    addFailed: boolean;
    cancelAction: (value: boolean) => void;
    failedAction: NewGoalActions | null;
    from: string;
    holder: {
        id: number;
    };
    orgID: number;
    profileCompletionType?: null | string;
    remainingBonusAmount: number;
    setAddFailed: (value: boolean) => void;
    setFailedAction: (value: any) => void;
    setShowActionOverlay: (value: boolean) => void;
    showActionOverlay: boolean;
    userID: number;
};

const AddActionStep: React.FC<AddProps> = ({
    addFailed,
    cancelAction,
    failedAction,
    from,
    holder,
    orgID,
    remainingBonusAmount,
    setAddFailed,
    setFailedAction,
    setShowActionOverlay,
    showActionOverlay
}) => {
    const addRef = useRef<HTMLDivElement>(null);
    const actionStepFormRef = useRef<{ handleAddForProfile: () => void }>(null);
    const classes = useStyles();
    const suggestionsRefetchCount = useRef(0);
    const suggestions = useRemoteCopy('action_step_suggestions');
    const templatesRefetchCount = useRef(0);

    const { id: holder_id } = holder;

    const {
        isError: templateActionsError,
        isLoaded: templateActionsLoaded,
        refetch: templateRefetch,
        templateActions
    } = useActionTemplate(orgID);

    const [formComplete, setFormComplete] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const callChildAddForProfile = () => {
        if (actionStepFormRef.current) {
            actionStepFormRef.current.handleAddForProfile();
        }
    };

    const handleCancelClick = () => {
        cancelAction(false);
    };

    const renderDisabled = () => {
        if (!formComplete) {
            return {
                classes: `${classes.addButton} ${classes.disabled}`,
                disabled: true
            };
        } else {
            return {
                classes: classes.addButton,
                disabled: false
            };
        }
    };

    useEffect(() => {
        if (addRef && addRef.current) {
            addRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }, [addRef, suggestions]);

    useEffect(() => {
        if (suggestions.isError && suggestionsRefetchCount.current < 5) {
            suggestionsRefetchCount.current++;
            suggestions.refetch();
        }

        if (suggestionsRefetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [suggestions]);

    useEffect(() => {
        if (templateActionsError && templatesRefetchCount.current < 5) {
            templatesRefetchCount.current++;
            templateRefetch();
        }

        if (templatesRefetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [templateActionsError, templateActionsLoaded, templateRefetch]);

    if (isErrored) {
        return (
            <Modal
                open={isErrored}
                onClose={() => {
                    setIsErrored(false);
                }}
            >
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Card id="add-card" style={{ position: 'relative' }}>
            {addFailed && failedAction && showActionOverlay && (
                <ActionCardOverlay
                    setAddFailed={setAddFailed}
                    setFailedAction={setFailedAction}
                    setShowActionOverlay={setShowActionOverlay}
                >
                    <i className="fas fa-exclamation-circle" />
                    <span>{failedAction.error}</span>
                </ActionCardOverlay>
            )}
            <div style={{ position: 'relative', width: '100%' }} ref={addRef}>
                {!suggestions.isLoaded ||
                !suggestions ||
                !templateActionsLoaded ? (
                    <LoadingCircle />
                ) : (
                    <div className={classes.formContainer}>
                        <ActionStepForm
                            actionSuggestions={suggestions.remoteCopy}
                            addFailed={addFailed}
                            failedAction={failedAction}
                            from={from}
                            holderID={holder_id}
                            ref={actionStepFormRef}
                            remainingBonusAmount={remainingBonusAmount}
                            setFormComplete={setFormComplete}
                            setShowForm={cancelAction}
                            templateActions={templateActions}
                        />
                        <div className={classes.buttonContainer}>
                            <Button
                                className={renderDisabled().classes}
                                disabled={renderDisabled().disabled}
                                onClick={callChildAddForProfile}
                            >
                                Add Action Step
                            </Button>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => handleCancelClick()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default AddActionStep;
