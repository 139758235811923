import { ErrorBoundary } from '@sentry/react';

import logo from 'assets/logo.png';

const AppErrorBoundary = ({ children }: { children: React.ReactNode }) => (
    <ErrorBoundary fallback={ErrorBoundaryComponent}>{children}</ErrorBoundary>
);

export default AppErrorBoundary;

type ErrorBoundaryComponentProps = {
    error: Error;
    eventId: string;
};

const ErrorBoundaryComponent = ({
    error,
    eventId
}: ErrorBoundaryComponentProps) => (
    <div role="alert" style={{ padding: 50, display: 'flex' }}>
        <div style={{ width: 200 }}>
            <img
                src={logo}
                alt="Samaritan"
                style={{ filter: 'invert(100%)' }}
                height={100}
            />
        </div>
        <div>
            <h3>Something went wrong</h3>
            <h4>Error ID: {eventId}</h4>
            <pre>{error.message}</pre>
            <br />
            <h4>Please refresh and try again.</h4>
            <br />
            <div>
                If this problem persists please contact{' '}
                <a
                    href={`mailto:support@samaritan.city?subject=Error report for error ${eventId}`}
                >
                    Samaritan Support at support@samaritan.city
                </a>
                .
            </div>
            <br />
            <div>
                In your email, make sure to include:
                <ul>
                    <li>
                        Your organization name and Samaritan account email
                        address
                    </li>
                    <li>What you were doing when this error occurred</li>
                </ul>
            </div>
        </div>
    </div>
);
