import AddButton from '../../../CaseManagement/components/AddButton';
import Answer from './Answer';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        newPrompt: {
            display: 'flex',
            alignSelf: 'flex-start',
            gap: '8px',
            color: '#999DFF',
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'none',
            padding: '4px'
        },
        prompt: {
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            color: '#1F2152'
        },
        promptText: {}
    })
);

type QuestionBodyProps = {
    answer:
        | {
              answer: string;
              created_at: string;
          }
        | undefined;
    handleAddClick: () => void;
    prompt: string;
    promptRefresh: boolean;
    questionsLength?: number;
    randomQuestionIndex?: number;
    setAddAnswer?: (addAnswer: boolean) => void;
    setRandomQuestionIndex?: (randomQuestionNumber: number) => void;
};

const QuestionBody: React.FC<QuestionBodyProps> = ({
    answer,
    handleAddClick,
    prompt,
    promptRefresh,
    questionsLength,
    randomQuestionIndex,
    setAddAnswer,
    setRandomQuestionIndex
}) => {
    const classes = useStyles();

    const handleNewPrompt = () => {
        if (
            questionsLength !== undefined &&
            randomQuestionIndex !== undefined &&
            setRandomQuestionIndex
        ) {
            let newRandomQuestionIndex = randomQuestionIndex;

            while (newRandomQuestionIndex === randomQuestionIndex) {
                newRandomQuestionIndex = Math.floor(
                    Math.random() * questionsLength
                );
            }

            setRandomQuestionIndex(newRandomQuestionIndex);
        }
    };

    return (
        <div
            className={classes.prompt}
            style={{ gap: promptRefresh ? '8px' : '16px' }}
        >
            <span className={classes.promptText}>
                <b>Prompt:</b> {prompt}
            </span>
            {promptRefresh && (
                <Button className={classes.newPrompt} onClick={handleNewPrompt}>
                    <span>Get New Prompt</span>
                    <i className="fas fa-random" />
                </Button>
            )}
            {!answer ? (
                <AddButton
                    action={() => handleAddClick()}
                    style={{
                        backgroundColor: 'transparent',
                        padding: '24px',
                        position: 'relative',
                        justifyContent: 'flex-start',
                        zIndex: 5,
                        fontSize: '16px',
                        fontWeight: 700
                    }}
                    type="facts"
                />
            ) : (
                <Answer
                    answer={answer.answer}
                    date={answer.created_at}
                    setAddAnswer={setAddAnswer}
                />
            )}
        </div>
    );
};

export default QuestionBody;
