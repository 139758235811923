import { Fragment } from 'react';

import { TouchpointAnswers, TouchpointQuestions } from 'types';

import {
    formatDate,
    getColor
} from '../../../profile_2.0/CaseManagement/SDOHStatus/utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        descriptionBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            margin: '0 40px 0 10px'
        },
        divider: {
            width: '100%',
            height: '1px',
            backgroundColor: '#E3E3FA',
            borderRadius: '1px',
            margin: '5px 0'
        },
        sdohItem: {
            padding: '10px 0 15px 0'
        },
        statusBar: {
            minWidth: '3px',
            borderRadius: '3px'
        },
        statusButton: {
            fontSize: '15px',
            fontWeight: 800,
            color: '#7378E8',
            lineHeight: '24px',
            cursor: 'pointer',
            textTransform: 'none',
            padding: '10px 20px',
            borderRadius: '36px',
            '&:hover': {
                color: '#918ED7'
            }
        },
        statusDate: {
            fontSize: '12px',
            fontWeight: 400,
            color: '#A6A6BF',
            lineHeight: '24px'
        },
        statusDescription: {
            display: 'flex',
            fontSize: '14px',
            fontWeight: 400,
            color: '#1F2152',
            lineHeight: '24px',
            width: '100%'
        },
        statusIcon: {
            marginRight: '12px',
            fontSize: '22px',
            color: '#1F2152'
        },
        statusText: {
            fontSize: '16px',
            fontWeight: 700,
            color: '#1F2152',
            lineHeight: '24px'
        },
        statusTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        statusWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '15px'
        },
        titleIconContainer: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

type StatusProps = {
    popupOpen?: boolean;
    setUpdateStatus?: (value: boolean) => void;
    setUpdateStatusID?: (value: number) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
};

const Status: React.FC<StatusProps> = ({
    popupOpen,
    setUpdateStatus,
    setUpdateStatusID,
    touchpointAnswers,
    touchpointQuestions
}) => {
    const classes = useStyles();

    const statusQuestions = touchpointQuestions.filter(
        (question) =>
            question.type === 'StatusQuestion' ||
            question.type === 'QualityOfLifeMeasureQuestion'
    );

    const handleUpdateStatus = (answer: TouchpointAnswers) => {
        if (!setUpdateStatus) return;

        setUpdateStatus(true);

        if (answer.question_type === 'StatusQuestion') {
            setUpdateStatusID?.(answer.touchpoint_question_id);
        } else {
            // this may still break
            setUpdateStatusID?.(answer.quality_of_life_measure_id || 0);
        }
    };

    const renderColor = (answer: {
        answer_index: number;
        classified_statuses: TouchpointAnswers['classified_statuses'];
        question_type: string;
    }) => {
        if (answer && answer.question_type === 'StatusQuestion') {
            const answers =
                answer.classified_statuses[0]?.classified_status_answer_matches;

            if (answers && answers.length) {
                const highestAnswer = answers.reduce((prev, curr) =>
                    curr.rank > prev.rank ? curr : prev
                );

                return getColor(highestAnswer.rank - 1, 'primary', 5);
            }
            return '#999DFF';
        }

        if (answer.answer_index !== null) {
            return getColor(answer.answer_index, 'primary', 5);
        } else {
            return '#999DFF';
        }
    };

    const renderStatus = () => {
        return statusQuestions.map((question, questionIndex) => {
            const { friendly_name, icon_name, quality_of_life_measure_id } =
                question;
            const answerArr = [] as {
                answer: string | null;
                answers?: any;
                quality_of_life_measure_id?: number;
            }[];

            if (touchpointAnswers.length && question) {
                const matchingAnswers = touchpointAnswers.filter(
                    (answer) =>
                        answer.quality_of_life_measure_id ===
                        quality_of_life_measure_id
                );

                if (matchingAnswers.length) {
                    answerArr.push(...matchingAnswers);
                } else {
                    answerArr.push({
                        answer: null,
                        quality_of_life_measure_id: quality_of_life_measure_id
                    });
                }
            } else {
                answerArr.push({
                    answer: null,
                    quality_of_life_measure_id: quality_of_life_measure_id
                });
            }

            return answerArr?.map((answer, index) => {
                const hasStatus = !!answer.answers || !!answer.answer;
                let statusAnswer = answer.answers ? answer.answers[0] : answer;

                const statusBarColor = hasStatus
                    ? renderColor(statusAnswer)
                    : '#E3E3FA';

                const statusDescription = hasStatus ? (
                    <div className={classes.descriptionBody}>
                        {statusAnswer.question_type !== 'StatusQuestion' &&
                        statusAnswer.answer_slider_option
                            ? statusAnswer.answer_slider_option[0]?.description
                            : statusAnswer.answer}
                        <span className={classes.statusDate}>
                            On {formatDate(statusAnswer.created_at)}
                        </span>
                    </div>
                ) : (
                    <span className={classes.descriptionBody}>
                        No Status Set
                    </span>
                );

                return (
                    <Fragment key={index}>
                        <div className={classes.sdohItem} key={question.id}>
                            <div className={classes.statusTitle}>
                                <div className={classes.titleIconContainer}>
                                    <i
                                        className={`far fa-${icon_name} ${classes.statusIcon}`}
                                    />
                                    <span className={classes.statusText}>
                                        {friendly_name}
                                    </span>
                                </div>
                            </div>
                            <div className={classes.statusWrapper}>
                                <div className={classes.statusDescription}>
                                    <div
                                        className={classes.statusBar}
                                        style={{
                                            backgroundColor: statusBarColor
                                        }}
                                    />
                                    {statusDescription}
                                </div>
                                {popupOpen && (
                                    <Button
                                        className={classes.statusButton}
                                        onClick={() =>
                                            handleUpdateStatus(statusAnswer)
                                        }
                                    >
                                        {hasStatus ? 'Update' : 'Add'}
                                    </Button>
                                )}
                            </div>
                        </div>
                        {questionIndex < statusQuestions.length - 1 && (
                            <div className={classes.divider} />
                        )}
                    </Fragment>
                );
            });
        });
    };

    return <div>{renderStatus()}</div>;
};

export default Status;
