import React, { useEffect, useRef, useState } from 'react';

import EditIcon from './EditIcon';
import PopUp from 'components/_shared/PhotoPopup';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    editProfilePictureContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    profilePicture: {},
    profilePictureContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        '& img': {
            borderRadius: '50%',
            height: 'auto',
            maxWidth: '320px',
            objectFit: 'cover',
            width: '100%',
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& input': {
            display: 'none'
        }
    }
}));

type EditProfilePictureProps = {
    editProfilePicture: boolean;
    newProfilePicture: File | null;
    handleSavePhoto: () => void;
    photo: string;
    setEditProfilePicture: (value: boolean) => void;
    setNewProfilePicture: (value: File | null) => void;
};

const EditProfilePicture: React.FC<EditProfilePictureProps> = ({
    editProfilePicture,
    handleSavePhoto,
    newProfilePicture,
    photo,
    setEditProfilePicture,
    setNewProfilePicture
}) => {
    const classes = useStyles();
    const imgRef = useRef(null);

    const [popUp, setPopUp] = useState(false);

    const handleImageClick = () => {
        setPopUp(true);
    };

    const handleProfilePictureChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const img = e.target.files?.[0];

        if (img) {
            setNewProfilePicture(img);
        }
    };

    useEffect(() => {
        if (newProfilePicture) {
            handleSavePhoto();
        }
    }, [newProfilePicture, handleSavePhoto]);

    return (
        <div className={classes.editProfilePictureContainer}>
            <div className={classes.profilePictureContainer}>
                {newProfilePicture && (
                    <img
                        alt="profile"
                        className={classes.profilePicture}
                        src={URL.createObjectURL(newProfilePicture)}
                        onClick={handleImageClick}
                    />
                )}
                {!newProfilePicture && (
                    <img
                        alt="profile"
                        className={classes.profilePicture}
                        src={photo}
                        onClick={handleImageClick}
                    />
                )}
                <input
                    type="file"
                    accept="image/*"
                    ref={imgRef}
                    onChange={handleProfilePictureChange}
                    id="photo-upload"
                    title=""
                />
                <EditIcon
                    edit={editProfilePicture}
                    setEdit={setEditProfilePicture}
                    handleImageClick={handleImageClick}
                    newProfilePicture={newProfilePicture}
                    picturePosition={{ bottom: '8px', right: '24px' }}
                />
            </div>
            {popUp && (
                <PopUp
                    handleModalClick={() => setPopUp(false)}
                    setProfilePicture={setNewProfilePicture}
                />
            )}
        </div>
    );
};

export default EditProfilePicture;
