import axios from 'axios';

import { SAMARITAN_URL } from '../settings';

async function getToken(
    username: string,
    password: string,
    callback: Function
) {
    let userLoginRoute = `${SAMARITAN_URL}/api/v2/users/sign_in`;
    let credentials = {
        user: {
            email: username,
            password: password
        }
    };

    return axios
        .post(userLoginRoute, credentials)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.error(error);
            callback(error);
        });
}

export { getToken };
