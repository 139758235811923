import { useState } from 'react';

import LoadingCircle from './LoadingCircle';

import { Button, TextareaAutosize } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        contextContainer: {
            position: 'relative',
            width: '100%',
            zIndex: 5
        },
        input: {
            width: '94%',
            padding: '16px',
            borderRadius: '16px',
            border: '1px solid #E3E3FA',
            fontSize: '16px',
            fontFamily: 'Manrope',
            fontWeight: 400,
            color: '#4F4F4F',
            resize: 'none',
            '&::placeholder': {
                color: '#BDBDBD'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        submitBtnContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px'
        },
        submitBtn: {
            fontWeight: 800,
            fontSize: '16px',
            color: '#fff',
            width: '100%',
            backgroundColor: '#7378E8',
            padding: '12px 32px',
            borderRadius: '50px',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#999DFF'
            }
        }
    })
);

type Props = {
    context: string;
    setContext: (value: string) => void;
    handleModalClick: (value: boolean) => void;
    handleSubmit: (setSuccess: (value: boolean) => void) => void;
    loading?: boolean;
    placeholder?: boolean;
};

const ContextField: React.FC<Props> = ({
    context,
    setContext,
    handleSubmit,
    loading,
    placeholder
}) => {
    const classes = useStyles();

    const [success, setSuccess] = useState<boolean | null>(null);

    const placeholderText = placeholder
        ? `Tell us more......

    - What...... Example prompt 1?
    - Why........ Example prompt 2?
    - How........ Example prompt 3?
    - When..... Example prompt 3?`
        : 'Write your answer here.....';

    const renderButtonText = () => {
        if (loading) return <LoadingCircle />;
        if (success) return 'Success!';

        return 'Submit';
    };

    return (
        <div className={classes.contextContainer}>
            <TextareaAutosize
                aria-label="minimum height"
                className={classes.input}
                maxRows={8}
                minRows={8}
                onChange={(e) => setContext(e.target.value)}
                value={context}
                placeholder={placeholderText}
            />
            <div className={classes.submitBtnContainer}>
                <Button
                    className={classes.submitBtn}
                    disabled={(!context.length || loading || success) ?? false}
                    onClick={() => handleSubmit(setSuccess)}
                    style={{
                        background: success
                            ? 'linear-gradient(180deg, #7FE8B2 0%, #7FE8B2 100%)'
                            : '#7378E8',
                        color: '#fff',
                        opacity: !context?.length ? '0.4' : '1'
                    }}
                >
                    {renderButtonText()}
                </Button>
            </div>
        </div>
    );
};

export default ContextField;
