import EmptyMemberList from 'assets/EmptyMemberList.png';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        emptyListContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '40px auto',
            width: '100%',
            '& img': {
                width: '25%'
            },
            '@media (min-width: 1650px)': {
                justifyContent: 'center'
            }
        },
        emptyListText: {
            color: '#5A6A81',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '22px',
            margin: '0 auto',
            maxWidth: '600px',
            textAlign: 'center'
        }
    })
);

const EmptyList = () => {
    const classes = useStyles();

    return (
        <div className={classes.emptyListContainer}>
            <img src={EmptyMemberList} alt="Empty Member List" />
            <h1>No Members Yet</h1>
            <p className={classes.emptyListText}>
                Sign-up a client with a Samaritan Membership using the button
                above to get started!
            </p>
        </div>
    );
};

export default EmptyList;
