import * as CONSTANTS from '../constants/constants';

import { ChatMessages, ChatRooms } from 'types';

const initialState = {
    holderId: null,
    isFetchingChatRooms: false,
    hasFetchedChatRooms: false,
    chatRooms: [],
    isFetchingChatMessages: false,
    hasFetchedChatMessages: false,
    allChatMessages: [],
    chatMessages: [],
    recipients: [],
    isSendingMessage: false,
    isResendingMessage: false
} as {
    holderId: number | null;
    isFetchingChatRooms: boolean;
    hasFetchedChatRooms: boolean;
    chatRooms: ChatRooms[];
    isFetchingChatMessages: boolean;
    hasFetchedChatMessages: boolean;
    allChatMessages: {
        recipientId: number;
        messages: ChatMessages[];
    }[];
    chatMessages: ChatMessages[];
    recipients: number[];
    isSendingMessage: boolean;
    isResendingMessage: boolean;
};

export default function messages_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_CHAT_ROOMS:
            return {
                ...state,
                isFetchingChatRooms: true
            };
        case CONSTANTS.SET_CHAT_ROOM_RECIPIENTS:
            return {
                ...state,
                recipients: action.payload
            };
        case CONSTANTS.GET_CHAT_ROOMS_SUCCESS:
            return {
                ...state,
                isFetchingChatRooms: false,
                hasFetchedChatRooms: true,
                chatRooms: action.payload
            };
        case CONSTANTS.GET_CHAT_MESSAGES:
            return {
                ...state,
                isFetchingChatMessages: true
            };
        case CONSTANTS.GET_CHAT_MESSAGES_SUCCESS:
            return {
                ...state,
                isFetchingChatMessages: false,
                hasFetchedChatMessages: true,
                chatMessages: action.payload
            };
        case CONSTANTS.SET_ALL_CHAT_MESSAGES:
            const chatRoom = state.allChatMessages.find(
                (chatRoom: { recipientId: number }) =>
                    chatRoom.recipientId === action.payload[0].recipientId
            );

            if (!chatRoom) {
                return {
                    ...state,
                    allChatMessages: [
                        ...state.allChatMessages,
                        ...action.payload
                    ]
                };
            } else {
                return {
                    ...state
                };
            }
        case CONSTANTS.SEND_MESSAGES:
            return {
                ...state,
                isSendingMessage: true
            };
        case CONSTANTS.RESEND_MESSAGE:
            return {
                ...state,
                isResendingMessage: true
            };
        case CONSTANTS.RESEND_MESSAGE_SUCCESS:
            return {
                ...state,
                isResendingMessage: false
            };
        case CONSTANTS.SEND_MESSAGES_SUCCESS:
            const recipientId = action.payload.recipient_id;
            const chatRoomIndex = state.allChatMessages.findIndex(
                (chatRoom: { recipientId: number }) =>
                    chatRoom.recipientId === parseInt(recipientId)
            );

            if (chatRoomIndex !== -1) {
                const newChatRoom = {
                    ...state.allChatMessages[chatRoomIndex],
                    messages: [
                        ...state.allChatMessages[chatRoomIndex].messages,
                        action.payload
                    ]
                };

                const newAllChatMessages = [
                    ...state.allChatMessages.slice(0, chatRoomIndex),
                    newChatRoom,
                    ...state.allChatMessages.slice(chatRoomIndex + 1)
                ];

                return {
                    ...state,
                    allChatMessages: newAllChatMessages,
                    isSendingMessage: false,
                    isResendingMessage: false
                };
            } else {
                return {
                    ...state,
                    chatMessages: [...state.chatMessages, action.payload],
                    isSendingMessage: false,
                    isResendingMessage: false
                };
            }
        case CONSTANTS.DELETE_MESSAGE:
            const messageId = action.payload.id;
            const chatMessageIndex = state.chatMessages.findIndex(
                (message: { id: number }) => message.id === messageId
            );

            const newChatMessages = [
                ...state.chatMessages.slice(0, chatMessageIndex),
                ...state.chatMessages.slice(chatMessageIndex + 1)
            ];

            return {
                ...state,
                chatMessages: newChatMessages
            };
        case CONSTANTS.SET_MESSAGE_HOLDER_ID:
            return {
                ...state,
                holderId: action.payload
            };
        case CONSTANTS.RESET_MESSAGE_INFO:
            return initialState;
        default:
            return state;
    }
}
