import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 20px',
        borderTop: '2px solid #EDEDFA',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.05)'
    },
    inputLabel: {
        fontSize: '16px',
        fontWeight: '800',
        lineHeight: '22px',
        paddingBottom: '10px',
        color: '#1F1F51'
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1',
        position: 'relative',
        '& input': {
            width: '100%',
            border: '1px solid #A5A6BE',
            outline: 'none',
            fontSize: '16px',
            lineHeight: '22px',
            color: '#1F1F51',
            borderRadius: '22px',
            padding: '10px 40px 10px 20px',
            '&::placeholder': {
                color: '#A6A6BF'
            }
        }
    },
    active: {
        cursor: 'pointer',
        color: '#7279E8'
    },
    inactive: {
        cursor: 'none',
        color: '#A5A6BE'
    }
}));

const Input = ({
    label,
    memberName,
    message,
    samaritanId,
    scrollToBottom,
    setMessage,
    submitMessage
}) => {
    const classes = useStyles();

    const sendMessage = () => {
        if (message.length) {
            samaritanId ? submitMessage(samaritanId) : submitMessage();
            scrollToBottom && scrollToBottom();
            setMessage('');
        }
    };

    return (
        <div className={classes.inputContainer}>
            {label && memberName && (
                <span className={classes.inputLabel}>
                    Replying on behalf of {memberName}
                </span>
            )}
            <div className={classes.input}>
                <input
                    placeholder="Write your message here..."
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                    value={message}
                />
                <IconButton
                    component="span"
                    disabled={!message.length}
                    onClick={() => sendMessage()}
                    style={{
                        position: 'absolute',
                        right: '0px',
                        padding: '10px'
                    }}
                    size="large">
                    <i
                        className={
                            message.length
                                ? `${classes.active} fas fa-arrow-circle-up`
                                : `${classes.inactive} fas fa-arrow-circle-up`
                        }
                        style={{ fontSize: '24px' }}
                    />
                </IconButton>
            </div>
        </div>
    );
};

Input.propTypes = {
    label: PropTypes.bool,
    memberName: PropTypes.string,
    message: PropTypes.string,
    samaritanId: PropTypes.number,
    scrollToBottom: PropTypes.func,
    setMessage: PropTypes.func,
    submitMessage: PropTypes.func
};

export default Input;
