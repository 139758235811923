import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    statusLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        gap: '5px',
        borderRadius: '200px',
        fontSize: '12px',
        fontWeight: 700,
        margin: '4px',
        padding: '4px 8px'
    },
    privateLabel: {
        backgroundColor: '#E7EFFE',
        color: '#062D7A'
    },
    sharedLabel: {
        backgroundColor: '#E9FBF2',
        color: '#1B9757'
    }
});

type StatusLabelProps = {
    isPrivate: boolean;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ isPrivate }) => {
    const classes = useStyles();

    const icon = isPrivate ? 'fas fa-lock' : 'fas fa-users';
    const label = isPrivate ? 'Private Information' : 'Shared Information';

    const labelClass = classNames({
        [classes.statusLabel]: true,
        [classes.privateLabel]: isPrivate,
        [classes.sharedLabel]: !isPrivate
    });

    return (
        <div className={labelClass}>
            <i className={icon} />
            <span>{label}</span>
        </div>
    );
};

export default StatusLabel;
