import PropType from 'prop-types';

import StatusCard from './StatusCard';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    currentContainer: {
        marginBottom: '30px'
    },
    currentTitleContainer: {
        padding: '24px 20px'
    },
    currentTitle: {
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '24px',
        color: '#1F1F51',
        marginBottom: '6px'
    },
    currentSubtitle: {
        fontWeight: '300',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#A6A6BF'
    }
}));

const Current = ({
    currentStatus,
    holderName,
    questionOptions,
    setClassifiedInfo,
    setUpdateStatusInfo,
    statusCardRef,
    statusName
}) => {
    const classes = useStyles();

    const name = holderName.split(`'`)[0];

    return (
        <div className={classes.currentContainer}>
            <div className={classes.currentTitleContainer}>
                <div className={classes.currentTitle}>Current</div>
                <span className={classes.currentSubtitle}>
                    Last answered {statusName} status from {name}
                </span>
            </div>
            <StatusCard
                questionOptions={questionOptions}
                setClassifiedInfo={setClassifiedInfo}
                setUpdateStatusInfo={setUpdateStatusInfo}
                status={currentStatus}
                statusCardRef={statusCardRef}
            />
        </div>
    );
};

Current.propTypes = {
    // currentStatus: PropType.array.isRequired,
    holderName: PropType.string.isRequired,
    statusName: PropType.string.isRequired
};

export default Current;
