import PropTypes from 'prop-types';

import EmptyStatus from 'assets/empty_status.png';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    emptyContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        height: '500px'
    },
    emptyBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px'
    },
    bodyContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '50px',
        color: '#1F2152'
    },
    bodyTitle: {
        fontWeight: '800',
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '10px'
    },
    bodySubtitle: {
        fontWeight: '300',
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center'
    },
    statusAsset: {
        height: '200px',
        width: 'auto',
        marginBottom: '20px'
    },
    addBtn: {
        background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
        borderRadius: '30px',
        padding: '16px 20px',
        color: '#FFFFFF',
        fontWeight: '800',
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'none',
        '&:hover': {
            color: '#1F2152'
        }
    }
}));

const Empty = ({ holderName, setAddStatus, statusName }) => {
    const classes = useStyles();

    return (
        <div className={classes.emptyContainer}>
            <div className={classes.emptyBody}>
                <img
                    className={classes.statusAsset}
                    src={EmptyStatus}
                    alt="two people talking"
                />
                <div className={classes.bodyContent}>
                    <span className={classes.bodyTitle}>No Status Set</span>
                    <span className={classes.bodySubtitle}>
                        Set {holderName} {statusName.toLowerCase()} status to
                        begin tracking their progress
                    </span>
                </div>
                <Button
                    className={classes.addBtn}
                    onClick={() => setAddStatus(true)}
                >
                    Add new status
                </Button>
            </div>
        </div>
    );
};

Empty.propTypes = {
    holderName: PropTypes.string.isRequired,
    setAddStatus: PropTypes.func.isRequired,
    statusName: PropTypes.string.isRequired
};

export default Empty;
