import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { DebitCard, GoalActions } from 'types';

import ActionButtons from './ActionButtons';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            width: '100%'
        },
        actionTitle: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1rem'
        },
        buttonDrawerContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '32px',
            padding: '0 20px'
        },
        btnHidden: {
            opacity: 0,
            transition: 'opacity 0.5s'
        },
        btnVisible: {
            opacity: 1,
            transition: 'opacity 0.5s'
        },
        dottedLine: {
            flex: 'none',
            flexGrow: 1,
            order: 0,
            border: '1px dashed #A6A6BF',
            borderRadius: '1px',
            boxSizing: 'border-box',
            width: '100px',
            height: '1px',
            '@media (max-width: 768px)': {
                width: '50px'
            }
        }
    })
);

type ButtonDrawerProps = {
    clickedItem: null | string;
    debitInfo: DebitCard;
    drawerOpen: boolean;
    id: number;
    isLoading: boolean;
    quickCatchUp: boolean;
    record?: GoalActions;
    setCatchUpItemIndex: (value: number) => void;
    // setSummaryState: (prevState: {
    //     clickedItem: null | string;
    //     drawerOpen: boolean;
    //     infoModal: boolean;
    //     infoType: null | string;
    //     overlayType: null | string;
    //     popUpOpen: boolean;
    //     removeItem: boolean;
    // }) => {
    //     clickedItem: null | string;
    //     drawerOpen: boolean;
    //     infoModal: boolean;
    //     infoType: null | string;
    //     overlayType: null | string;
    //     popUpOpen: boolean;
    //     removeItem: boolean;
    // } | void;
    setCompleteAS: (value: boolean) => void;
    setDebitAssign: (value: boolean) => void;
    setDebitRedeem: (value: boolean) => void;
    setDebitTransfer: (value: boolean) => void;
    setEditAS: (value: boolean) => void;
    setRemindAS: (value: boolean) => void;
    setSummaryState: any;
    type: string;
    updateGoalActionStatus: (id: number, status: 'completed') => void;
};

const ButtonDrawer: React.FC<ButtonDrawerProps> = ({
    clickedItem,
    debitInfo,
    drawerOpen,
    id,
    isLoading,
    quickCatchUp,
    record,
    setCatchUpItemIndex,
    setSummaryState,
    setCompleteAS,
    setDebitAssign,
    setDebitRedeem,
    setDebitTransfer,
    setEditAS,
    setRemindAS,
    type,
    updateGoalActionStatus
}) => {
    const classes = useStyles();

    const actionTitleClass = classNames(classes.actionTitle, {
        [classes.btnVisible]: !clickedItem,
        [classes.btnHidden]: clickedItem
    });

    const [animationDone, setAnimationDone] = useState(true);

    const commonButtons = ['add', 'skip'];

    const itemButtons = {
        action_step_due:
            record?.action_type === 'lifecarable'
                ? ['assessment', 'skip']
                : ['complete', 'edit', 'reminder', 'skip'],
        funds_above_25_dollars: [
            debitInfo.account_id ? 'transfer' : 'assign',
            'redeem',
            'skip'
        ],
        no_contact_info: commonButtons,
        no_date_of_birth: commonButtons,
        no_full_name: commonButtons,
        no_insurance_id: commonButtons,
        no_profile_photo: commonButtons,
        no_status_capture_in_last_30_days: commonButtons,
        no_supplemental_answers: commonButtons
    } as {
        [key: string]: string[];
    };

    const drawerSpring = useSpring({
        config: { tension: 150, friction: 50 },
        delay: drawerOpen ? 0 : 800,
        onRest: () => setAnimationDone(true),
        opacity: drawerOpen ? 1 : 0,
        transform: drawerOpen ? 'translateY(0%)' : 'translateY(-100%)'
    });

    useEffect(() => {
        if (drawerOpen) {
            setSummaryState((prevState: any) => ({
                ...prevState,
                clickedItem: null,
                infoModal: false,
                infoType: null,
                overlayType: null,
                removeItem: false
            }));

            setEditAS(false);
            setRemindAS(false);
        }
    }, [drawerOpen, setSummaryState, setEditAS, setRemindAS]);

    useEffect(() => {
        if (!drawerOpen) {
            setAnimationDone(false);
        }
    }, [drawerOpen]);

    return (
        <animated.div
            style={drawerSpring}
            className={classes.buttonDrawerContainer}
        >
            <div className={actionTitleClass}>
                <span className={classes.dottedLine} />
                <span>Choose one action</span>
                <span className={classes.dottedLine} />
            </div>
            <div className={classes.actionButtonContainer}>
                {itemButtons[type].map((button, index) => (
                    <ActionButtons
                        key={index}
                        animationDone={animationDone}
                        buttonStyle={
                            clickedItem && clickedItem !== button
                                ? classes.btnHidden
                                : classes.btnVisible
                        }
                        buttonType={button}
                        drawerOpen={drawerOpen}
                        isLoading={isLoading}
                        itemID={id}
                        itemType={type}
                        quickCatchUp={quickCatchUp}
                        setCatchUpItemIndex={setCatchUpItemIndex}
                        setCompleteAS={setCompleteAS}
                        setDebitAssign={setDebitAssign}
                        setDebitRedeem={setDebitRedeem}
                        setDebitTransfer={setDebitTransfer}
                        setEditAS={setEditAS}
                        setRemindAS={setRemindAS}
                        setSummaryState={setSummaryState}
                        updateGoalActionStatus={updateGoalActionStatus}
                    />
                ))}
            </div>
        </animated.div>
    );
};

export default ButtonDrawer;
