import { differenceInDays } from 'date-fns';
import { useState } from 'react';

import { CTAButton1 } from 'components/_shared/buttons';

import { calculateDate, renderDateTitle } from '../utils';

import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0'
    },
    datePickerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        padding: '0 20px',
        color: '#1F2152'
    },
    dateRange: {
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: '0.39px'
    },
    filterBtn: {
        fontSize: '16px',
        width: '300px',
        height: '50px'
    },
    filterTypeContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 20px',
        gap: '20px'
    },
    selector: {},
    selectorBtn: {
        fontSize: '24px',
        color: '#A6A6BF',
        padding: '0',
        minWidth: 'auto',
        borderRadius: '50%'
    },
    type: {},
    typeItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        color: '#1F2152',
        fontWeight: '600',
        fontSize: '20px'
    },
    typeTitle: {}
}));

const formatDateForFilter = (date: Date) => {
    if (!date) {
        return null;
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

type DatesToFilter = {
    [key: string]: number;
};

type FilterProps = {
    filterByType: 'date' | 'type';
    filterDate: string | null;
    filterType: string | null;
    handleModalClick: () => void;
    historyFilters: any[];
    setDateButtonActive: (active: boolean) => void;
    setFilterEndDate: (date: string | null) => void;
    setFilterDate: (date: string | null) => void;
    setFilterType: (type: string | null) => void;
    setTypeButtonActive: (active: boolean) => void;
};

const Filter = ({
    filterByType,
    filterDate,
    filterType,
    handleModalClick,
    historyFilters,
    setDateButtonActive,
    setFilterEndDate,
    setFilterDate,
    setFilterType,
    setTypeButtonActive
}: FilterProps) => {
    const classes = useStyles();

    const defaultFilterType = 'action_step';

    const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
    const [dateStart, setDateStart] = useState<Date | null>(null);
    const [filterByDate, setFilterByDate] = useState<null | string>(
        filterDate ? renderDateTitle(filterDate) : null
    );
    const [filterItemType, setFilterItemType] = useState<null | string>(
        filterType ? filterType : ''
    );

    const dates = [
        { title: 'Last Week', value: 7 },
        { title: 'Last Month', value: 30 },
        { title: 'Last 6 Months', value: 180 },
        {
            title: 'Custom Range',
            value: Math.abs(
                differenceInDays(
                    dateStart ? dateStart : 0,
                    dateEnd || new Date()
                )
            )
        }
    ];

    const datesToFilterType: DatesToFilter = {
        'Last Week': 7,
        'Last Month': 30,
        'Last 6 Months': 180,
        'Custom Range': Math.abs(
            differenceInDays(dateStart ? dateStart : 0, dateEnd || new Date())
        )
    };

    const getItemTypes = () =>
        filterByType === 'date' ? dates : historyFilters;

    const handleDateEndChange = (newValue: Date) => {
        const difference = differenceInDays(
            newValue,
            dateStart ? dateStart : 0
        );

        if (difference < 0) {
            setDateStart(newValue);
            setDateEnd(null);
        } else {
            setDateEnd(newValue);
        }
    };

    const handleDateStartChange = (newValue: Date) => {
        const difference = differenceInDays(dateEnd || new Date(), newValue);

        if (difference <= 0) {
            setDateStart(newValue);
            setDateEnd(null);
        } else {
            setDateStart(newValue);
        }
    };

    const handleTypeSelect = (type: {
        description: string;
        friendly_name: string;
        title: string;
    }) => {
        if (filterByType === 'date') {
            if (filterByDate === type.title) {
                setFilterByDate(null);
            } else {
                setFilterByDate(type.title);
            }
        } else {
            if (filterItemType === type.description) {
                setFilterItemType(null);
            } else {
                setFilterItemType(type.description);
            }
        }
    };

    const handleFilterSelect = () => {
        const selectedFilterType =
            filterByType === 'date'
                ? datesToFilterType[filterByDate as string]
                : historyFilters.find(
                      (item) => item.description === filterItemType
                  )?.description || defaultFilterType;

        if (filterByDate === 'Custom Range') {
            if (!dateEnd) {
                setFilterEndDate(formatDateForFilter(dateStart as Date));
            }

            setFilterEndDate(formatDateForFilter(dateEnd as Date));
            setFilterDate(formatDateForFilter(dateStart as Date));
        } else if (!filterByDate && setFilterDate) {
            setFilterDate(null);
            setDateButtonActive(false);
        } else if (!filterItemType && setFilterType) {
            setFilterType(null);
            setTypeButtonActive(false);
        } else if (filterByType === 'date') {
            setFilterEndDate(calculateDate(0));
            setFilterDate(calculateDate(selectedFilterType));
        } else {
            setFilterType(selectedFilterType);
        }

        handleModalClick();
    };

    const renderDisabled = () => {
        if (filterByType === 'date') {
            return !filterByDate;
        }

        if (filterByType === 'type') {
            return !filterItemType;
        }
    };

    const renderFilterTypeItem = (
        {
            description,
            friendly_name,
            title,
            value
        }: {
            description: string;
            friendly_name: string;
            title: string;
            value: number;
        },
        index: number
    ) => (
        <div className={classes.typeItem} key={index}>
            <div className={classes.type}>
                <div className={classes.typeTitle}>
                    {friendly_name || title}
                </div>
                {filterByType === 'date' && (
                    <div className={classes.dateRange}>
                        {title !== 'Custom Range'
                            ? calculateDate(value, true)
                            : 'Select Date Range'}
                    </div>
                )}
            </div>
            <Button
                className={classes.selectorBtn}
                onClick={() =>
                    handleTypeSelect({
                        description,
                        friendly_name,
                        title
                    })
                }
            >
                {filterItemType === description || filterByDate === title ? (
                    <i
                        className="fas fa-check-circle"
                        style={{ color: '#7378E8' }}
                    />
                ) : (
                    <i className="fal fa-circle" />
                )}
            </Button>
        </div>
    );

    return (
        <div className={classes.filterTypeContainer}>
            {getItemTypes().map(renderFilterTypeItem)}
            {filterByDate === 'Custom Range' && (
                <div className={classes.datePickerContainer}>
                    <DatePicker
                        label="Start Date"
                        value={dateStart}
                        onChange={(newValue) =>
                            handleDateStartChange(newValue as Date)
                        }
                        disableFuture
                    />
                    to
                    <DatePicker
                        label="End Date"
                        disabled={!dateStart}
                        value={dateEnd}
                        onChange={(newValue) =>
                            handleDateEndChange(newValue as Date)
                        }
                        disableFuture
                    />
                </div>
            )}
            <div className={classes.btnContainer}>
                <CTAButton1
                    className={classes.filterBtn}
                    disabled={renderDisabled()}
                    onClick={handleFilterSelect}
                >
                    Filter
                </CTAButton1>
            </div>
        </div>
    );
};

export default Filter;
