import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import { Homeless } from 'types';

import LoadingCircle from 'components/_shared/LoadingCircle';

import {
    resetUnenrollmentState,
    setUnenrollmentState
} from 'redux/actions/unenrollment';
import { unenrollHomelessRequest } from 'redux/actions/homeless';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    wrapper: {
        padding: '20px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#EDEDFA',
        color: '#000'
    },
    headerTitle: {
        fontWeight: 800,
        fontSize: '24px',
        lineHeight: '32px',
        color: '#1F2152',
        marginBottom: '8px'
    },
    headerTitleDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    headerSubTitleDiv: {
        marginBottom: '1.5em'
    },
    headerSubTitle: {
        fontWeight: 300,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#1F2152'
    },
    closeBtn: {
        padding: 0,
        minWidth: '30px',
        minHeight: '30px',
        color: '#7378E8'
    },
    closeBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    cardContainer: {
        padding: '10px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        margin: '20px 0'
    },
    cardContent: {
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    cardContentTitle: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#7378E8'
    },
    stepTitle: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
        color: '#7378E8'
        // marginBottom: '2px'
    },
    stepSubTitle: {
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#1F2152',
        marginTop: '2px'
    },
    stepContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 25px',
        height: 'auto',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        margin: '20px 0',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FBFBFF'
        }
    },
    stepContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    balance: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#1F2152'
    },
    icon: {
        marginRight: '24px',
        color: '#7378E8',
        fontSize: '20px'
    },
    chevronIcon: {
        alignSelf: 'center',
        color: '#7378E8',
        fontSize: '16px'
    },
    checkIcon: {
        alignSelf: 'center',
        color: '#7FE8B2',
        fontSize: '24px'
    },
    unenrollButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '15px 0'
    },
    unenrollButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '8px',
        padding: '20px 16px',
        color: '#A6A6BF',
        cursor: 'pointer',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '500',
        textTransform: 'none',
        minWidth: '50%',
        textAlign: 'left',
        '& i': {
            fontSize: '18px'
        },
        '& .active': {
            backgroundColor: '#7378E8',
            color: '#FFFFFF',
            border: '1px solid #7378E8'
        }
    },
    iconStatus: {
        display: 'flex',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#7378E8',
        color: '#FFFFFF',
        border: '1px solid #7378E8',
        '&:hover': {
            backgroundColor: '#999DFF',
            color: '#FFFFFF',
            border: '1px solid #999DFF'
        }
    },
    disabled: {
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#FFFFFF'
        }
    },
    unenrollContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',
        '&:first-child': {
            paddingTop: '20px'
        },
        '&:not(:first-child)': {
            borderBottom: '1px solid #E3E3FA'
        }
    },
    textInput: {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#1F2152',
        width: '100%',
        border: 'none',
        resize: 'none',
        paddingTop: '12px',
        overflow: 'auto',
        '&:placeholder-shown': {
            opacity: '0.5',
            fontWeight: '100'
        },
        '&:focus': {
            outline: 'none'
        }
    },
    submitBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '60px 0 15px 0'
    },
    submitBtn: {
        fontFamily: 'Manrope',
        fontWeight: '700',
        fontSize: '16px',
        color: '#fff',
        width: '50%',
        backgroundColor: '#7378E8',
        padding: '20px 8px',
        margin: '0',
        cursor: 'pointer',
        borderRadius: '100px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#999DFF'
        }
    }
}));

type HistoryState = {
    from: string;
};

type UnenrollMemberProps = {
    holder: Homeless;
    isUnenrollingHomeless: boolean;
    setShowUnenrollMember: (value: boolean) => void;
    externalContextState: string;
    internalContextState: string;
    redeemStatus: boolean;
    sdohStatus: boolean;
    successStatusActiveState: boolean;
    unenrollStatusState: string;
    unenrollmentSuccess: boolean;
};

function UnenrollMember({
    holder,
    isUnenrollingHomeless: isLoading,
    setShowUnenrollMember,
    unenrollmentSuccess,
    ...props
}: UnenrollMemberProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const optionalStepRef = useRef<HTMLDivElement | null>(null);

    const {
        externalContextState,
        internalContextState,
        redeemStatus,
        sdohStatus,
        successStatusActiveState,
        unenrollStatusState
    } = props;

    const [externalContext, setExternalContext] =
        useState(externalContextState);
    const [internalContext, setInternalContext] =
        useState(internalContextState);
    const [successStatusActive, setSuccessStatusActive] = useState(
        successStatusActiveState
    );
    const [unenrollStatus, setUnenrollStatus] = useState(unenrollStatusState);

    const handleStatusClick = (
        status: 'SUCCESSFUL' | 'UNSUCCESSFUL',
        isActive: boolean
    ) => {
        setUnenrollStatus(status);
        setSuccessStatusActive(isActive);
    };

    const handleSubmitUnenroll = () => {
        const formData = new FormData();

        formData.append('samaritan_member[profile_stage]', 'EXITED');
        formData.append(
            'samaritan_member[profile_stage_status]',
            unenrollStatus
        );

        if (internalContext)
            formData.append(
                'samaritan_member[profile_stage_internal_context]',
                internalContext
            );

        if (externalContext)
            formData.append(
                'samaritan_member[profile_stage_external_context]',
                externalContext
            );

        document
            .querySelector('#unenroll-container')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        dispatch(unenrollHomelessRequest(holder.id, formData));
    };

    const navigateToStep = (step: string, routeState: HistoryState) => {
        dispatch(
            setUnenrollmentState(
                step,
                externalContext,
                internalContext,
                successStatusActive,
                unenrollStatus
            )
        );

        history.push(`${match.url}/${step}`, routeState);
    };

    const renderStepContainer = (
        title: string,
        subTitle: string,
        condition: boolean,
        icon: string,
        onClick: () => void
    ) => (
        <div
            className={
                condition
                    ? classes.stepContainer
                    : `${classes.stepContainer} ${classes.disabled}`
            }
            onClick={onClick}
        >
            <div className={classes.cardContent}>
                <i className={`${classes.icon} ${icon}`} />
                <div className={classes.stepContent}>
                    <div className={classes.stepTitle}>{title}</div>
                    <span className={classes.stepSubTitle}>{subTitle}</span>
                </div>
            </div>
            <i
                className={
                    condition
                        ? `${classes.checkIcon} fas fa-check-circle`
                        : `${classes.chevronIcon} fal fa-chevron-right`
                }
            />
        </div>
    );

    const renderStatusButton = (
        status: 'SUCCESSFUL' | 'UNSUCCESSFUL',
        isActive: boolean,
        text: string,
        icon: string
    ) => (
        <Button
            className={
                isActive
                    ? `${classes.unenrollButton} ${classes.active}`
                    : classes.unenrollButton
            }
            variant="outlined"
            onClick={() => handleStatusClick(status, isActive)}
        >
            <div className={classes.iconStatus}>
                <i className={`far ${icon}`} />
                <span
                    style={{
                        fontWeight: isActive ? '600' : 'normal',
                        margin: '0 8px'
                    }}
                >
                    {text}
                </span>
            </div>
            <i className={isActive ? 'fas fa-check-circle' : 'far fa-circle'} />
        </Button>
    );

    useEffect(() => {
        const { from } = (history.location.state as HistoryState) || {};

        if (optionalStepRef.current && (from === 'qol' || from === 'redeem')) {
            optionalStepRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [history]);

    useEffect(() => {
        if (unenrollmentSuccess) {
            setTimeout(() => {
                setShowUnenrollMember(false);
            }, 2000);
        }
    }, [unenrollmentSuccess, setShowUnenrollMember]);

    return (
        <div className={classes.wrapper} id="unenroll-container">
            <div className={classes.headerTitleDiv}>
                <h2 className={classes.headerTitle}>
                    Unenrolling {holder.name}
                </h2>
            </div>
            {isLoading && !unenrollmentSuccess ? (
                <div className={classes.cardContainer}>
                    <LoadingCircle />
                </div>
            ) : unenrollmentSuccess ? (
                <div className={classes.cardContainer}>
                    <h2
                        className={classes.headerTitle}
                        style={{ textAlign: 'center', margin: '20px 0' }}
                    >
                        Member unenrolled!
                    </h2>
                </div>
            ) : (
                <>
                    <div className={classes.headerSubTitleDiv}>
                        <span className={classes.headerSubTitle}>
                            We'd love to learn more about why{' '}
                            {holder.first_name || holder.name} no longer needs
                            their membership.
                        </span>
                    </div>
                    <div className={classes.cardContainer}>
                        <div className={classes.unenrollContent}>
                            <span className={classes.cardContentTitle}>
                                Why are you unenrolling{' '}
                                {holder.first_name || holder.name}?
                            </span>
                            <div className={classes.unenrollButtonContainer}>
                                {renderStatusButton(
                                    'SUCCESSFUL',
                                    successStatusActive,
                                    `Member was successful and no longer needs their membership`,
                                    'fa-smile'
                                )}
                                {renderStatusButton(
                                    'UNSUCCESSFUL',
                                    !successStatusActive,
                                    `${
                                        holder.first_name || holder.name
                                    } might still need it, but should no longer be a member`,
                                    'fa-frown'
                                )}
                            </div>
                        </div>
                        <div className={classes.unenrollContent}>
                            <div className={classes.cardContentTitle}>
                                Additional Context
                            </div>
                            <TextareaAutosize
                                className={classes.textInput}
                                value={internalContext}
                                name="internalContext"
                                placeholder={`These details will only be seen by ${
                                    holder.first_name || holder.name
                                }'s Care Providers.`}
                                onChange={(e) =>
                                    setInternalContext(e.target.value)
                                }
                            />
                        </div>
                        <div
                            className={classes.unenrollContent}
                            style={{ margin: '30px 0 20px 0' }}
                        >
                            <div className={classes.cardContentTitle}>
                                Final Update to Supporters
                            </div>
                            <TextareaAutosize
                                className={classes.textInput}
                                value={externalContext}
                                name="externalContext"
                                placeholder={`We'll share this parting message with ${
                                    holder.first_name || holder.name
                                }'s supporters.`}
                                onChange={(e) =>
                                    setExternalContext(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={classes.headerSubTitle}
                        ref={optionalStepRef}
                        style={{ marginTop: '50px' }}
                    >
                        <span className={classes.headerSubTitle}>
                            Here are some recommended but optional steps in
                            closing out {holder.first_name || holder.name}'s
                            membership.
                        </span>
                    </div>
                    {renderStepContainer(
                        'Quality of Life Assessment',
                        "Capture a final snapshot of your client's progress",
                        sdohStatus,
                        'fal fa-tachometer-alt',
                        () => navigateToStep('qol', { from: 'exitQol' })
                    )}
                    {renderStepContainer(
                        'Spend Remaining Funds',
                        `Help your client spend the remainder of their balance. Balance: $${holder.balance}`,
                        redeemStatus,
                        'fal fa-money-bill',
                        () => navigateToStep('redeem', { from: 'exitRedeem' })
                    )}
                    <div className={classes.submitBtnContainer}>
                        <Button
                            className={classes.submitBtn}
                            onClick={() => {
                                delete history.location.state;
                                dispatch(resetUnenrollmentState());
                                handleSubmitUnenroll();
                            }}
                        >
                            <span>
                                Unenroll {holder.first_name || holder.name}
                            </span>
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    externalContextState: state.unenrollment.externalContext,
    internalContextState: state.unenrollment.internalContext,
    isUnenrollingHomeless: state.homeless.isUnenrollingHomeless,
    redeemStatus: state.unenrollment.redeemStatus,
    sdohStatus: state.unenrollment.sdohStatus,
    successStatusActiveState: state.unenrollment.successStatusActive,
    unenrollStatusState: state.unenrollment.unenrollStatus,
    unenrollmentSuccess: state.homeless.unenrollmentSuccess
});

export default connect(mapStateToProps)(UnenrollMember);
