import PropTypes from 'prop-types';

import StatusCard from './StatusCard';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    historyContainer: {
        marginBottom: '30px'
    },
    historyTitleContainer: {
        padding: '24px 20px'
    },
    historyTitle: {
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '24px',
        color: '#1F1F51',
        marginBottom: '6px'
    },
    historySubtitle: {
        fontWeight: '300',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#A6A6BF'
    },
    historyListContainer: {}
}));

const History = ({
    holderName,
    questionOptions,
    setClassifiedInfo,
    setUpdateStatusInfo,
    statusCardRef,
    statusHistory
}) => {
    const classes = useStyles();

    return (
        <div className={classes.historyContainer}>
            <div className={classes.historyTitleContainer}>
                <div className={classes.historyTitle}>History</div>
                <span className={classes.historySubtitle}>
                    Previously saved statuses recording {holderName} progress
                </span>
            </div>
            <div className={classes.historyListContainer}>
                {statusHistory.map((status, index) => (
                    <StatusCard
                        key={index}
                        questionOptions={questionOptions}
                        setClassifiedInfo={setClassifiedInfo}
                        setUpdateStatusInfo={setUpdateStatusInfo}
                        statusCardRef={statusCardRef}
                        status={status}
                    />
                ))}
            </div>
        </div>
    );
};

History.propTypes = {
    holderName: PropTypes.string.isRequired,
    statusHistory: PropTypes.array.isRequired
};

export default History;
