import { CTAButton1 } from 'components/_shared/buttons';

import logo from 'assets/logo.png';
import slide from 'assets/slide-light-after-transparent.png';

import classes from './FourOhFour.module.scss';
import { Link, useHistory } from 'react-router-dom';

const FourOhFour = () => {
    const history = useHistory();

    return (
        <div className={classes.root}>
            <img
                src={logo}
                alt="Samaritan logo"
                height={120}
                className={classes.logo}
            />
            <div>
                <span className={classes.title}>404</span>
                <span className={classes.subtitle}>page not found</span>
            </div>
            <div className={classes.buttonsDiv}>
                <CTAButton1 onClick={() => history.goBack()}>
                    <i className="far fa-arrow-left" />
                    &emsp;go back
                </CTAButton1>
                <Link to="/" className={classes.homeButton}>
                    <CTAButton1>home</CTAButton1>
                </Link>
            </div>
            <div className={classes.slideContainer}>
                <img src={slide} alt="decoration" className={classes.slide} />
            </div>
        </div>
    );
};

export default FourOhFour;
