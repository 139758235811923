import { useEffect, useRef } from 'react';

import EditIcon from './EditIcon';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    editNameContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px'
    },
    nameContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        fontSize: 'clamp(1.5rem, 2.5vw + 1rem, 2.5rem)',
        fontWeight: '500',
        letterSpacing: '2.79px',
        lineHeight: 'clamp(36px, 3.5vw + 1rem, 57px)',
        maxWidth: '100%',
        paddingRight: '10px',
        width: 'auto'
    },
    nameInput: {},
    name: {
        width: '100%',
        height: 'auto',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        color: '#1F2152',
        textAlign: 'center'
    }
}));

type EditNameProps = {
    editName: boolean;
    handleSaveName: () => void;
    name: string;
    newName: string;
    setEditName: (edit: boolean) => void;
    setNewName: (name: string) => void;
};

const EditName: React.FC<EditNameProps> = ({
    name,
    editName,
    setEditName,
    newName,
    setNewName,
    handleSaveName
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (editName && inputRef.current) {
            const input = inputRef.current.querySelector(
                'input'
            ) as HTMLInputElement | null;

            input?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            input?.focus();
        }
    }, [editName]);

    return (
        <div className={classes.editNameContainer}>
            <div className={classes.nameContainer}>
                {!editName ? (
                    <div className={classes.name}>
                        {newName ? newName : name}
                    </div>
                ) : (
                    <TextField
                        ref={inputRef}
                        className={classes.nameInput}
                        name="name"
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                color: '#1F2152',
                                fontFamily: 'Manrope',
                                padding: '0 !important',
                                border: 'none',
                                fontSize: '42px',
                                fontWeight: '500',
                                lineHeight: '57px',
                                letterSpacing: '2.79px',
                                background: 'transparent',
                                resize: 'none',
                                height: 'auto',
                                wordWrap: 'break-word'
                            }
                        }}
                        variant="standard"
                    />
                )}
                <EditIcon
                    edit={editName}
                    setEdit={setEditName}
                    handleSave={handleSaveName}
                />
            </div>
        </div>
    );
};

export default EditName;
