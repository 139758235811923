import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// TODO: Fix ts-ignore NeedCard
// @ts-ignore
import NeedCard from './NeedCard';
// TODO: Fix ts-ignore OtherCard
// @ts-ignore
import OtherCard from './OtherCard';

import { completeNeed } from '../../../../../redux/actions/needs';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        needContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EDEDFA',
            color: '#1F2152',
            fontSize: '20px',
            fontWeight: 800,
            lineHeight: '27px',
            padding: '24px 0',
            width: '100%',
            '& span': {
                width: '90%'
            }
        },
        subheader: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '22px',
            marginTop: '6px'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            gap: '16px',
            padding: '36px',
            width: '85%'
        },
        btnContainer: {
            position: 'sticky',
            bottom: '0',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#FFFFFF',
            marginTop: '10px',
            width: '90%'
        },
        updateBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            textTransform: 'none',
            lineHeight: '22px',
            margin: '10px 0 30px 0',
            padding: '16px 40px'
        },
        cancelBtn: {
            background: 'transparent',
            border: '1px solid #7378E8',
            color: '#7378E8'
        }
    })
);

// TODO: Fix any types
type NeedProps = {
    amount: number;
    handleCloseClick: () => void;
    name: string;
    needs: any[];
};

const Need: React.FC<NeedProps> = ({
    amount,
    handleCloseClick,
    name,
    needs
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pendingNeeds = needs.filter((need) => need.status === 'pending');
    const needsLength = useRef<number>(pendingNeeds.length);

    const [activeNeedList, setActiveNeedList] = useState<any[]>([]);

    const handleSaveClick = () => {
        activeNeedList.forEach((need) => {
            dispatch(completeNeed(need));
        });

        handleCloseClick();
    };

    useEffect(() => {
        if (
            needsLength.current !== pendingNeeds.length &&
            !activeNeedList.includes(pendingNeeds[pendingNeeds.length - 1].id)
        ) {
            setActiveNeedList([
                ...activeNeedList,
                pendingNeeds[pendingNeeds.length - 1].id
            ]);
            needsLength.current = pendingNeeds.length;
        }
    }, [activeNeedList, pendingNeeds]);

    return (
        <div className={classes.needContainer}>
            <div className={classes.header}>
                <span>Which need did this charge help {name} meet?</span>
                <span className={classes.subheader}>
                    Select all that apply.
                </span>
            </div>
            <div className={classes.body}>
                {pendingNeeds.map((need, index) => (
                    <NeedCard
                        key={index}
                        activeNeedList={activeNeedList}
                        setActiveNeedList={setActiveNeedList}
                        need={need}
                    />
                ))}
                <OtherCard amount={amount} />
            </div>
            <div className={classes.btnContainer}>
                <Button
                    className={`${classes.updateBtn} ${classes.cancelBtn}`}
                    onClick={() => handleCloseClick()}
                >
                    Skip
                </Button>
                <Button
                    className={classes.updateBtn}
                    onClick={() => handleSaveClick()}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default Need;
