import { useEffect, useRef, useState } from 'react';

import AddInfo from 'components/_shared/PopUp2/components/AddInfo';
import LoadingOverlay from './LoadingOverlayProfile';
import ManagerList from './ManagerList';
import PopUp2 from 'components/_shared/PopUp2';
import ReferOrgCard from './ReferOrgCard';
import ReferOrgItem from './ReferOrgItem';

import { Deployments, DeploymentOrgs, DeploymentUsers } from 'types';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            position: 'relative',
            background: '#FBFBFF',
            boxSizing: 'border-box',
            padding: '0 24px',
            width: '100%'
        },
        bodyItems: {
            position: 'relative',
            top: '-56px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '16px',
            color: '#1F2152',
            paddingTop: '64px'
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '50%'
        },
        photoContainer: {
            position: 'absolute',
            left: 'calc(50% - 50px)',
            top: '4px',
            borderRadius: '50%',
            height: '100px',
            width: '100px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        popupOverlay: {
            position: 'absolute',
            top: '48px',
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            borderRadius: '16px',
            height: 'calc(100% - 48px)',
            width: '100%'
        },
        questionButton: {
            position: 'absolute',
            top: '64px',
            left: '16px',
            background:
                'linear-gradient(111.56deg, #FFFFFF -2.6%, rgba(255, 255, 255, 0.17) 89.17%)',
            border: '1px solid #999DFF',
            borderRadius: '100px',
            boxShadow: '5px 6px 20px 0px #00000033',
            color: '#999DFF',
            minWidth: '40px',
            minHeight: '40px',
            '& .MuiButton-label': {
                fontWeight: 700
            }
        },
        subtitle: {
            fontSize: '20px',
            fontWeight: 200,
            padding: '0 16px 72px 16px',
            textAlign: 'center',
            '& b': {
                fontWeight: 900
            }
        }
    })
);

type ReferBodyProps = {
    deployments: Deployments[];
    deploymentOrgs: DeploymentOrgs[];
    deploymentUsers: DeploymentUsers[];
    isReferringMember: boolean;
    managerPopup: boolean;
    memberID: number;
    mobile_photo: string;
    name: string;
    note: string;
    notePopUp: boolean;
    orgIDs: number;
    referMember: () => Promise<void>;
    referMemberError: boolean | null;
    referralManager: DeploymentUsers | null;
    referralOrg: DeploymentOrgs | null;
    setManagerPopup: (open: boolean) => void;
    setNote: (note: string) => void;
    setNotePopUp: (open: boolean) => void;
    setReferMemberPopUp: (open: boolean) => void;
    setOrgIDs: (orgIDs: number) => void;
    setReferralManager: (referral: DeploymentUsers | null) => void;
    setReferralOrg: (referral: DeploymentOrgs | null) => void;
};

const ReferBody: React.FC<ReferBodyProps> = ({
    deploymentOrgs,
    deploymentUsers,
    isReferringMember,
    managerPopup,
    memberID,
    mobile_photo,
    name,
    note,
    notePopUp,
    orgIDs,
    referMember,
    referMemberError,
    referralManager,
    referralOrg,
    setManagerPopup,
    setNote,
    setNotePopUp,
    setReferMemberPopUp,
    setOrgIDs,
    setReferralManager,
    setReferralOrg
}) => {
    const classes = useStyles();
    const bodyRef = useRef<HTMLDivElement>(null);
    const bodyItemsRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const popUpStyles = {
        modalContent: { margin: '0' }
    };

    const { name: manager_name } = referralManager || {};
    const { name: organization_name } = referralOrg || {};

    const [loading, setLoading] = useState(false);
    const [referralType, setReferralType] = useState<string>('');
    const [success, setSuccess] = useState<boolean | null>(false);

    const referOrgCardProps = {
        isReferringMember,
        note,
        memberID,
        referMember,
        referMemberError,
        referralManager,
        referralOrg: referralOrg!,
        setLoading,
        setNotePopUp,
        setManagerPopup,
        setOrgIDs,
        setSuccess,
        setReferMemberPopUp,
        setReferralManager,
        setReferralOrg,
        setReferralType,
        success
    };

    useEffect(() => {
        const bodyItemsHeight = bodyItemsRef.current?.offsetHeight;

        if (bodyItemsHeight && !referralOrg) {
            bodyRef.current!.style.height = `${bodyItemsHeight - 40}px`;
        }

        if (bodyItemsHeight && referralOrg) {
            bodyRef.current!.style.height = `${bodyItemsHeight}px`;
        }

        if (bodyItemsHeight && note.length) {
            bodyRef.current!.style.height = `${bodyItemsHeight}px`;
        }
    }, [note, managerPopup, referralOrg]);

    useEffect(() => {
        const contentHeight = contentRef.current?.offsetHeight;

        if (contentHeight && overlayRef.current) {
            overlayRef.current!.style.height = `${contentHeight}px`;
        }
    });

    return (
        <div className="refer-body-container">
            {/* <Button className={classes.questionButton}>
                <i className="fas fa-question" />
            </Button> */}
            <div
                className={classes.photoContainer}
                style={loading || success ? { zIndex: 3 } : {}}
            >
                {(loading || success) && <span className={classes.overlay} />}
                <img src={mobile_photo} alt="profile" />
            </div>
            <div className={classes.content} ref={contentRef}>
                {(loading || success) && (
                    <LoadingOverlay
                        loading={loading}
                        managerName={manager_name!}
                        organizationName={organization_name!}
                        referralType={referralType}
                        success={success!}
                    />
                )}
                <h2 style={{ margin: '1rem 0 0.5rem 0' }}>Referring {name}</h2>
                <span className={classes.subtitle}>
                    Which team should {name} connect with? Creating this
                    referral will alert {name} and the team receiving the
                    referral
                </span>
                <div className={classes.body} ref={bodyRef}>
                    <div className={classes.bodyItems} ref={bodyItemsRef}>
                        {!referralOrg && (
                            <>
                                {deploymentOrgs.map((org) => (
                                    <ReferOrgItem
                                        key={org.id}
                                        deployment={org}
                                        setReferralOrg={setReferralOrg}
                                    />
                                ))}
                            </>
                        )}
                        {referralOrg && <ReferOrgCard {...referOrgCardProps} />}
                    </div>
                </div>
                {managerPopup && (
                    <span className={classes.popupOverlay} ref={overlayRef} />
                )}
            </div>
            {managerPopup && (
                <ManagerList
                    deploymentUsers={deploymentUsers}
                    orgIDs={orgIDs}
                    referralManager={referralManager}
                    setManagerPopup={setManagerPopup}
                    setReferralManager={setReferralManager}
                />
            )}
            {notePopUp && (
                <PopUp2
                    customClasses={popUpStyles}
                    modalClose={true}
                    modalTitle="Add Referral Notes"
                    open={notePopUp}
                    onClose={() => setNotePopUp(false)}
                    title="Add a Note"
                >
                    <AddInfo
                        info={note}
                        infoTitle={
                            'Write any additional notes or instructions for this referral. Include relevant details that may help with case management'
                        }
                        infoType={'notes'}
                        saveClick={() => setNotePopUp(false)}
                        setInfo={setNote}
                    />
                </PopUp2>
            )}
        </div>
    );
};

export default ReferBody;
