import { Deployments } from '../../../../../types';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    wrapper: {
        height: 'auto'
    },
    skills: {
        marginTop: '22px',
        display: 'flex',
        flexWrap: 'wrap'
    },
    inputFields: {
        width: '100%',
        border: '0',
        padding: '5px 0px 0px 0px',
        fontFamily: 'Manrope',
        fontSize: '1.5rem',
        fontWeight: 500,
        color: '#1f1f51',
        borderRadius: '6px',
        letterSpacing: '-0.21px',
        background: 'transparent',
        resize: 'none',
        '&:placeholder-shown': {
            fontStyle: 'italic'
        },
        '&:focus': {
            outline: 'none !important'
        }
    },
    menuItem: {
        fontFamily: 'Manrope',
        fontWeight: 500,
        fontSize: '1rem',
        color: '#1f2152',
        letterSpacing: '-0.21px',
        display: 'flex',
        alignItems: 'center'
    },
    itemSelect: {
        display: 'flex',
        alignItems: 'center'
    },
    orgLogo: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginRight: '12px'
    },
    name: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '33px',
        color: '#1F1F51',
        margin: 0,
        marginBottom: '2rem'
    }
});

type Props = {
    deployments: Deployments[];
    handleDepSelect: (e: any) => void;
    newDeployment: number;
};

const OrgSelect: React.FC<Props> = ({
    deployments,
    handleDepSelect,
    newDeployment
}) => {
    const classes = useStyles();

    const renderValue = () => {
        const foundDeployment = deployments.find(
            (dep) => dep.id === newDeployment
        );

        if (foundDeployment) {
            return foundDeployment.id;
        } else {
            return '';
        }
    };

    return (
        <Select
            className={classes.inputFields}
            disableUnderline
            onChange={(e) => handleDepSelect(e)}
            value={renderValue()}
            variant="standard"
        >
            {deployments.length > 0
                ? deployments.map((dep, index) => {
                      return (
                          <MenuItem
                              className={classes.menuItem}
                              disabled={dep.is_grandfathered}
                              key={index}
                              value={dep.id}
                          >
                              <div className={classes.itemSelect}>
                                  {dep.name}
                              </div>
                          </MenuItem>
                      );
                  })
                : null}
        </Select>
    );
};

export default OrgSelect;
