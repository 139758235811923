import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AddStatus from './components/AddStatus';

import Card from '../../../../../_shared/Card';
import Empty from './components/Empty';
import InfoPopup from './components/InfoPopup';
import LoadingCircle from '../../../../../_shared/LoadingCircle';
import Nav from './components/Nav';
import Progress from './components/Progress';
import Selector from './components/Selector';

import { getHolderName, removeAccess } from './utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        position: 'relative'
    },
    overlay: {
        display: 'flex',
        alignItems: 'flex-end',
        background: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        position: 'absolute',
        zIndex: '5',
        borderRadius: '16px'
    },
    loadingContainer: {
        padding: '40px 0'
    }
}));

const SDOHProgress = ({
    fetchTouchpointAnswersById,
    handleShowSdohProgress,
    holder,
    isFetchingTouchpointAnswersById,
    qolId,
    setQolId,
    sdohId,
    touchpointAnswers,
    touchpointAnswersById,
    touchpointQuestions
}) => {
    const classes = useStyles();

    const [activeStatus, setActiveStatus] = useState(sdohId);
    const [addStatus, setAddStatus] = useState(false);
    const [classifiedInfo, setClassifiedInfo] = useState(false);
    const [progress, setProgress] = useState([]);
    const [statusHistory, setStatusHistory] = useState([]);
    const [updateStatusInfo, setUpdateStatusInfo] = useState(false);

    useEffect(() => {
        setStatusHistory(
            touchpointAnswersById[activeStatus] ||
                touchpointAnswersById[qolId] ||
                []
        );
    }, [activeStatus, touchpointAnswersById, qolId]);

    useEffect(() => {
        const multipleAnswers = touchpointAnswers.find(
            (answer) => answer.answers?.length
        );

        if (multipleAnswers) {
            const answers = multipleAnswers.answers;

            setProgress([...answers]);
        } else {
            const answers = touchpointAnswers.filter(
                (answer) =>
                    answer.touchpoint_question_id === activeStatus &&
                    answer.answer
            );

            setProgress([...answers]);
        }
    }, [activeStatus, touchpointAnswers]);

    const holderName = getHolderName(holder.name);
    const statusName = removeAccess(
        touchpointQuestions.find((question) => question.id === activeStatus)
            ?.friendly_name
    );

    const handleStatusChange = (id, qol_id) => {
        fetchTouchpointAnswersById(qol_id);
        setActiveStatus(id);
        setStatusHistory(touchpointAnswersById[id]);
        setAddStatus(false);
        setQolId(qol_id);
    };

    const renderLoading = () => (
        <div className={classes.loadingContainer}>
            <LoadingCircle />
        </div>
    );

    const renderProgress = () => {
        const questionOptions = [
            'In Crisis',
            'Vulnerable',
            'Semi-Stable',
            'Safe',
            'Flourishing'
        ].reverse();

        if (isFetchingTouchpointAnswersById) {
            return renderLoading();
        }

        if (
            (statusHistory && statusHistory.length) ||
            (progress.length &&
                progress.filter(
                    (answer) => answer.touchpoint_question_id === activeStatus
                ).length)
        ) {
            return (
                <Progress
                    holderName={holderName}
                    questionOptions={questionOptions}
                    classifiedInfo={classifiedInfo}
                    setClassifiedInfo={setClassifiedInfo}
                    updateStatusInfo={updateStatusInfo}
                    setUpdateStatusInfo={setUpdateStatusInfo}
                    statusHistory={statusHistory}
                    statusName={statusName}
                />
            );
        }

        return (
            <Empty
                holderName={holderName}
                setAddStatus={setAddStatus}
                statusName={statusName}
            />
        );
    };

    return (
        <>
            {!addStatus ? (
                <Card className={classes.progressContainer}>
                    {(classifiedInfo || updateStatusInfo) && (
                        <div className={classes.overlay}>
                            <InfoPopup
                                classifiedInfo={classifiedInfo}
                                setClassifiedInfo={setClassifiedInfo}
                                updateStatusInfo={updateStatusInfo}
                                setUpdateStatusInfo={setUpdateStatusInfo}
                            />
                        </div>
                    )}
                    <Nav
                        closeProgress={handleShowSdohProgress}
                        holderName={holderName}
                    />
                    <Selector
                        activeStatus={activeStatus}
                        handleStatusChange={handleStatusChange}
                        setStatusHistory={setStatusHistory}
                        touchpointQuestions={touchpointQuestions}
                    />
                    {renderProgress()}
                </Card>
            ) : (
                <AddStatus
                    fetchTouchpointAnswersById={fetchTouchpointAnswersById}
                    id={holder.id}
                    name={holder.name}
                    setAddStatus={setAddStatus}
                    statusId={activeStatus}
                    questions={touchpointQuestions}
                />
            )}
        </>
    );
};

SDOHProgress.propTypes = {
    fetchTouchpointAnswersById: PropTypes.func.isRequired,
    handleShowSdohProgress: PropTypes.func.isRequired,
    holder: PropTypes.object.isRequired,
    isFetchingTouchpointAnswersById: PropTypes.bool.isRequired,
    sdohId: PropTypes.number.isRequired,
    touchpointAnswers: PropTypes.array || PropTypes.object.isRequired,
    touchpointAnswersById: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]).isRequired,
    touchpointQuestions: PropTypes.array.isRequired
};

export default SDOHProgress;
