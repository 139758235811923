import classNames from 'classnames';
import ReactGA from 'react-ga4';

import { DeploymentOrgs } from 'types';
import { SaveMemberProps } from 'types/interfaces';

import Menu from 'components/_shared/PopUp2/components/Menu';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonLabel: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            '& i': {
                fontSize: '16px'
            }
        },
        saveMemberButton: {
            alignSelf: 'flex-end',
            borderRadius: '40px',
            border: '1.6px solid #E3E3FA',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            minHeight: '38.5px',
            padding: '6px 20px',
            fontWeight: 500,
            textTransform: 'none',
            zIndex: 4,
            '& i': {
                marginRight: '10px'
            },
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        }
    })
);

type ReferPopUpProps = SaveMemberProps & {
    deploymentOrgs: DeploymentOrgs[];
    isSaved: boolean;
    setMemberMenu: (value: boolean) => void;
    setReferMemberPopUp: (value: boolean) => void;
};

const ReferPopUp: React.FC<ReferPopUpProps> = ({
    activeReferral,
    caseload,
    caseloadID,
    createMemberCaseload,
    deploymentOrgs,
    hasViewedTooltip,
    isSaved,
    memberID,
    removeMemberFromMyMembers,
    saved,
    saveMemberToMyMembers,
    setMemberMenu,
    setReferMemberPopUp,
    showTooltip,
    setSaved
}) => {
    const classes = useStyles();

    const buttonText = saved ? 'Saved' : 'Save';

    const iconClass = classNames({
        'fas fa-bookmark': saved,
        'far fa-bookmark': !saved
    });

    const handleReferClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Refer Member Click'
        });

        setMemberMenu(false);
        setReferMemberPopUp(true);
    };

    const handleSaveClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Save Member Click'
        });

        setSaved(!saved);

        if (!caseloadID) {
            createMemberCaseload(memberID);
            return;
        }

        if (saved) {
            removeMemberFromMyMembers(caseloadID);
            return;
        }

        const acceptReferral = activeReferral ? true : undefined;
        saveMemberToMyMembers(caseloadID, memberID, acceptReferral);
    };

    const renderSaveMemberButtonText = () => {
        if (activeReferral && !isSaved) {
            return (
                <div className={classes.buttonLabel}>
                    <i className={iconClass} />
                    <span>Accept Referral</span>
                </div>
            );
        }

        if (isSaved) {
            return (
                <div className={classes.buttonLabel}>
                    <i className="fas fa-bookmark" />
                    <span>Remove from "My Member"</span>
                </div>
            );
        }

        return (
            <div className={classes.buttonLabel}>
                <i className={iconClass} />
                <span>{buttonText} as "My Member"</span>
            </div>
        );
    };

    const menuButtons = [
        ...(deploymentOrgs.length > 0
            ? [
                  {
                      label: (
                          <div className={classes.buttonLabel}>
                              <i className={`far fa-user-plus`} />
                              <span>Refer Member</span>
                          </div>
                      ),
                      onClick: handleReferClick
                  }
              ]
            : []),
        {
            disabled: !caseload && !hasViewedTooltip && showTooltip,
            label: renderSaveMemberButtonText(),
            onClick: handleSaveClick
        },
        {
            label: <span>Cancel</span>,
            onClick: () => setMemberMenu(false)
        }
    ];

    return <Menu menuButtons={menuButtons} />;
};

export default ReferPopUp;
