import SamaritanFetch from './httpClient';

export const fetchTemplateActions = (org_id: number) =>
    SamaritanFetch.v3(
        `/action_step_templates?action_step_templates[organization_id]=${org_id}`
    );

export const fetchTemplateBeacons = () =>
    SamaritanFetch.v2('/homelesses', {
        query: {
            profile_stage: ['TEMPLATE']
        }
    });

export const submitIntake = (data: any, beaconId: string) => {
    const url = `/homelesses${beaconId ? `/${beaconId}` : ''}`;

    return SamaritanFetch.v2(url, {
        body: data,
        method: beaconId ? 'PUT' : 'POST',
        query: !beaconId
            ? {
                  is_virtual: true
              }
            : {}
    });
};
