import { useRef } from 'react';

import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '64px',
        width: '100%'
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #E3E3FA',
        borderRadius: '10px',
        height: 'auto',
        margin: '16px 0',
        padding: '8px 16px',
        width: '100%'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%'
        }
    },
    searchIcon: {
        color: '#A6A6BF',
        pointerEvents: 'none'
    },
    cancelIcon: {
        color: '#7378E8',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        padding: '4px'
    }
}));

type ManagerSearchProps = {
    query: string;
    setQuery: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setState: (state: { query: string }) => void;
};

const ManagerSearch: React.FC<ManagerSearchProps> = ({
    query,
    setQuery,
    setState
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleCancelClick = () => {
        setState({ query: '' });

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <div className={classes.searchBarContainer}>
            <div className={classes.searchBar}>
                <div className={classes.iconContainer}>
                    <div className={classes.searchIcon}>
                        <i className={'far fa-search'} />
                    </div>
                    <InputBase
                        onChange={setQuery}
                        placeholder="Search by name: e.g. Jane"
                        ref={inputRef}
                        value={query}
                    />
                </div>
                {query.length !== 0 && (
                    <div
                        className={classes.cancelIcon}
                        onClick={handleCancelClick}
                    >
                        <i className={'fas fa-times'} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManagerSearch;
