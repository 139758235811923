import { SAMARITAN_URL } from '../settings';
import AuthManager from '../auth';

export async function fetchOrganization(orgId: number) {
    const token = AuthManager.getInstance().getToken();

    let base_url = `${SAMARITAN_URL}/api/v3/organizations/${orgId}`;
    let params = `?SESSION_TOKEN=${token}`;
    let url = `${base_url}${params}`;

    return fetch(url).catch((error) => {
        console.log('Fetching organization error:', error);
        throw error;
    });
}

export async function fetchAllOrganizations() {
    let base_url = `${SAMARITAN_URL}/api/v3/organizations`;

    return fetch(base_url).catch((error) => {
        console.log('Fetching all organizations error:', error);
        throw error;
    });
}

export async function fetchOrganizationsByCity(city: string) {
    let base_url = `${SAMARITAN_URL}/api/v3/organizations`;
    let params = `?subdomain=${city}`;
    let url = `${base_url}${params}`;

    return fetch(url).catch((error) => {
        console.log('Fetching organizations by city error:', error);
        throw error;
    });
}
