import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import * as api from '../../api/newsfeed';

function fetchingNewsfeed() {
    return {
        type: CONSTANTS.GET_NEWSFEED
    };
}

function setNewsfeed(response: any) {
    return {
        type: CONSTANTS.SET_NEWSFEED,
        payload: response
    };
}

export function getNewsfeed(_token: string, userId: number) {
    return (dispatch: Function) => {
        dispatch(fetchingNewsfeed());

        return api
            .fetchNewsfeed(userId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: any) => {
                return dispatch(setNewsfeed(responseJson));
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}
