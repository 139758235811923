import SamaritanFetch from './httpClient';

export const getFAQs = () => {
    return SamaritanFetch.v3.get(
        `/sticky_tiles?sticky_tiles[is_faq]=true&sticky_tiles[on_partner_app]=true`,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
};
