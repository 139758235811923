import * as CONSTANTS from '../constants/constants';

import { Goals } from 'types';

const initialState = {
    isFetchingHomelessGoals: false,
    homelessGoals: [],
    newHomelessGoals: []
};

export default function goals_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_HOMELESS_GOALS:
            return {
                ...state,
                isFetchingHomelessGoals: true
            };
        case CONSTANTS.SET_HOMELESS_GOALS:
            return {
                ...state,
                isFetchingHomelessGoals: false,
                homelessGoals: action.payload,
                newHomelessGoals: []
            };
        case CONSTANTS.POSTING_NEW_HOMELESS_GOAL:
            return {
                ...state,
                newHomelessGoals: [
                    ...state.newHomelessGoals,
                    {
                        status: 'loading',
                        error: null,
                        tempRef: action.tempRef,
                        data: action.payload
                    }
                ]
            };
        case CONSTANTS.POSTED_NEW_HOMELESS_GOAL: {
            const filteredHG = state.newHomelessGoals.filter(
                (g: Goals) => g.tempRef !== action.tempRef
            );
            return {
                ...state,
                homelessGoals: [...state.homelessGoals, action.newGoal],
                newHomelessGoals: filteredHG
            };
        }
        case CONSTANTS.FAILED_NEW_HOMELESS_GOAL: {
            const mappedHG = state.newHomelessGoals.map((goal: Goals) => {
                if (goal.tempRef === action.tempRef) {
                    return {
                        ...goal,
                        status: 'failed',
                        error: action.error
                    };
                }
                return goal;
            });
            return {
                ...state,
                newHomelessGoals: mappedHG
            };
        }
        default:
            return state;
    }
}
