import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';

import { CatchUpItems } from 'types';

import CardButton from './CardButton';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        catchupItem: {
            position: 'absolute',
            zIndex: 3,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px #0000000D',
            minHeight: '171px',
            width: '100%'
        },
        catchupItemBody: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            gap: '16px',
            fontSize: '1rem',
            height: '100%'
        },
        catchupItemContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '21px',
            height: '100%'
        },
        catchupItemTitle: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            backgroundColor: '#062D7A',
            borderRadius: '16px 16px 0 0',
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: 800,
            padding: '12px 16px'
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#E7EFFE',
            borderRadius: '12px',
            color: '#062D7A',
            fontSize: '12px',
            height: '24px',
            width: '24px'
        },
        stack2: {
            position: 'absolute',
            zIndex: 2,
            background: '#EBEBEB',
            borderRadius: '16px',
            height: '171px'
        },
        stack3: {
            position: 'absolute',
            zIndex: 1,
            background: '#D9D9D9',
            borderRadius: '16px',
            height: '171px'
        },
        statusPill: {
            position: 'absolute',
            right: '16px',
            backgroundColor: '#F5577A',
            borderRadius: '100px',
            fontSize: '0.75rem',
            padding: '2px 12px',
            textAlign: 'center',
            textTransform: 'uppercase'
        },
        title: {
            marginRight: '56px'
        }
    })
);

type CatchUpItemProps = {
    catchUpItemIndex: number;
    children: React.ReactNode;
    iconName: string;
    items: CatchUpItems[];
    nextItem: CatchUpItems | null;
    nextItemContent: JSX.Element | null;
    nextItemType: {
        content?: string[];
        contentIcons?: { [key: string]: string };
        height?: number;
        icon: string;
        title: string;
    } | null;
    quickCatchUp: boolean;
    removeItem: boolean;
    snoozed: null | string;
    title: string;
};

const calculateBottomPosition = (
    items: CatchUpItems[],
    stackLevel: number,
    quickCatchUp: boolean,
    quickItemIndex: number
) => {
    const isFirefox =
        typeof navigator !== 'undefined' &&
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const itemCount = !quickCatchUp
        ? items.length
        : items.length - quickItemIndex;

    let bottomValue;

    switch (stackLevel) {
        case 3:
            bottomValue =
                itemCount >= 3 ? '0px' : itemCount === 2 ? '42px' : '0px';
            break;
        case 2:
            bottomValue =
                itemCount >= 3 ? (isFirefox ? '15px' : '21px') : '0px';
            break;
        case 1:
            bottomValue = '0px';
            break;
        default:
            bottomValue = '0px';
            break;
    }

    return bottomValue;
};

const CatchUpItem: React.FC<CatchUpItemProps> = ({
    catchUpItemIndex,
    children,
    iconName,
    items,
    nextItem,
    // nextItemContent,
    nextItemType,
    quickCatchUp,
    removeItem,
    snoozed,
    title
}) => {
    const classes = useStyles();
    const iconClass = classNames(classes.icon, iconName);
    const nextItemIconClass = classNames(
        classes.icon,
        `fas ${nextItemType?.icon}`
    );

    const isProfilePhoto = title === 'Missing Profile Photo';
    const nextIsProfilePhoto = nextItemType?.title === 'Missing Profile Photo';

    const [slideOutCompleted, setSlideOutCompleted] = useState(false);

    const [fadeIn, fadeInApi] = useSpring(() => ({
        config: { ...config.stiff, duration: 1500 },
        opacity: 0,
        top: calculateBottomPosition(items, 2, quickCatchUp, catchUpItemIndex)
    }));

    const [slideOut, slideOutApi] = useSpring(() => ({
        config: { ...config.stiff, duration: 500 },
        immediate: true,
        onStart: () => {
            fadeInApi.start({
                opacity: 0,
                top: calculateBottomPosition(
                    items,
                    2,
                    quickCatchUp,
                    catchUpItemIndex
                )
            });
            setSlideOutCompleted(false);
        },
        opacity: 1,
        transform: 'translateX(0%)'
    }));

    const handleSnooze = useCallback(() => {
        setTimeout(() => {
            slideOutApi.start({
                transform: 'translateX(-100%) rotate(-30deg)',
                opacity: 0
            });
        }, 1000);

        setTimeout(() => {
            setSlideOutCompleted(true);
        }, 1500);
    }, [slideOutApi]);

    useEffect(() => {
        if (removeItem) {
            handleSnooze();
        } else {
            slideOutApi.start({
                transform: 'translateX(0%) rotate(0deg)',
                opacity: 1,
                immediate: true
            });
        }
    }, [removeItem, slideOutApi, handleSnooze]);

    useEffect(() => {
        if (slideOutCompleted) {
            fadeInApi.start({
                opacity: 1,
                top: calculateBottomPosition(
                    items,
                    1,
                    quickCatchUp,
                    catchUpItemIndex
                )
            });
        }
    }, [catchUpItemIndex, fadeInApi, items, quickCatchUp, slideOutCompleted]);

    return (
        <div className={classes.catchupItemContainer}>
            {slideOutCompleted && nextItemType && (
                <animated.div
                    className={classes.catchupItem}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        ...fadeIn
                    }}
                >
                    <div className={classes.catchupItemTitle}>
                        <i className={nextItemIconClass} />
                        <span className={classes.title}>
                            {nextItemType?.title}
                        </span>
                        {!nextItem?.snooze_till && (
                            <span className={classes.statusPill}>New</span>
                        )}
                    </div>
                    <div
                        className={classes.catchupItemBody}
                        style={{
                            padding: nextIsProfilePhoto ? 0 : '16px'
                        }}
                    >
                        {nextItemType && nextItemType.content
                            ? nextItemType.content?.map((content, index) => (
                                  <CardButton
                                      key={index}
                                      icon={
                                          nextItemType.contentIcons
                                              ? nextItemType.contentIcons[
                                                    content
                                                ]
                                              : nextItemType.icon
                                      }
                                      infoType={''}
                                      inputType={content}
                                  />
                              ))
                            : null}
                    </div>
                </animated.div>
            )}
            {((!quickCatchUp && items.length >= 2) ||
                items.length - catchUpItemIndex >= 2) && (
                <span
                    className={classes.stack2}
                    style={{
                        bottom: calculateBottomPosition(
                            items,
                            2,
                            quickCatchUp,
                            catchUpItemIndex
                        ),
                        width: '96%'
                    }}
                />
            )}
            {((!quickCatchUp && items.length >= 3) ||
                items.length - catchUpItemIndex >= 3) && (
                <span
                    className={classes.stack3}
                    style={{
                        bottom: calculateBottomPosition(
                            items,
                            3,
                            quickCatchUp,
                            catchUpItemIndex
                        ),
                        width: '92%'
                    }}
                />
            )}
            <animated.div
                className={classes.catchupItem}
                style={{
                    top: calculateBottomPosition(
                        items,
                        1,
                        quickCatchUp,
                        catchUpItemIndex
                    ),
                    ...slideOut
                }}
            >
                <div className={classes.catchupItemTitle}>
                    <i className={iconClass} />
                    <span className={classes.title}>{title}</span>
                    {!snoozed && (
                        <span className={classes.statusPill}>New</span>
                    )}
                </div>
                <div
                    className={classes.catchupItemBody}
                    style={{
                        padding: isProfilePhoto ? 0 : '16px'
                    }}
                >
                    {children}
                </div>
            </animated.div>
        </div>
    );
};

export default CatchUpItem;