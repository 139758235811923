/**
 * This component designed using
 * https://www.figma.com/file/rTUYMYWdGLtfA3nepR5ZZe/Samaritan-(Design-System)?node-id=180%3A43
 */

import classNames from 'classnames';

import { Button } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(
    (theme: {
        colors: {
            LightGrey03: string;
            LightGrey05: string;
            OffWhite01: string;
            Purple01: string;
        };
    }) =>
        createStyles({
            button: {
                // color
                color: 'white',
                backgroundColor: theme.colors.Purple01,

                // font
                fontSize: '0.75rem',
                fontWeight: 'bold',
                textTransform: 'none',

                // box
                padding: '10px 2.5rem',
                borderRadius: 1000,
                margin: 1,
                minWidth: 150,

                // psudoclasses
                '&:hover': {
                    backgroundColor: theme.colors.Purple01
                },

                // selectors
                '&.Mui-disabled': {
                    backgroundColor: theme.colors.LightGrey03,
                    color: 'white'
                }
            },
            tight: {
                padding: '5px 1rem',
                minWidth: 'unset'
            },
            icon: {
                marginLeft: 10
            },
            invert: {
                backgroundColor: theme.colors.OffWhite01,
                color: theme.colors.Purple01,
                '&:hover': {
                    backgroundColor: theme.colors.LightGrey05
                }
            }
        })
);

type CTAButton1Props = {
    arrow?: boolean;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    iconName?: string;
    invert?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    tight?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export default function CTAButton1({
    arrow,
    children,
    className,
    disabled,
    iconName,
    invert,
    tight,
    ...rest
}: CTAButton1Props): JSX.Element {
    const theme = useTheme();
    const styles = useStyles(theme);

    if (arrow) {
        iconName = 'chevron-right';
    }

    const buttonClassName = classNames(
        styles.button,
        tight && styles.tight,
        invert && styles.invert,
        className
    );

    const iconClassName = classNames('far', `fa-${iconName}`, styles.icon);

    return (
        <Button className={buttonClassName} {...rest} disabled={disabled}>
            <>{children}</>
            {iconName && <i className={iconClassName} />}
        </Button>
    );
}
