import PropTypes from 'prop-types';

import Card from '../../../CaseManagement/components/Card';
import NeedButtons from './NeedButtons';

import {
    calculateDateDifference,
    extractNumbersAndAgo,
    renderClasses
} from '../../../CaseManagement/utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    needsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    needs: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    description: {
        fontSize: '18px',
        lineHeight: '25px',
        fontWeight: '700'
    },
    amount: {
        padding: '6px 16px',
        borderRadius: '20px',
        backgroundColor: '#E3E3FA',
        width: 'fit-content',
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: '800'
    },
    needsBottom: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '4px'
    },
    date: {
        color: '#7A4406',
        backgroundColor: '#FEF3E7',
        padding: '6px 16px',
        borderRadius: '200px',
        '& i': {
            marginRight: '8px'
        }
    },
    noDueDate: {
        color: '#1F2152',
        backgroundColor: '#F0F0FF'
    },
    dueDateWarning: {
        color: '#A80A2D',
        backgroundColor: '#FEE7EC'
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const NeedCard = ({
    need,
    setCompleteNeed,
    setEdit,
    setHasQOLMeasures,
    setNeedID,
    touchpointQuestionQOLs,
    updatedNeedIDs
}) => {
    const classes = useStyles();

    const { amount, description, due_at } = need;
    const isUpdated = updatedNeedIDs.includes(need.id);

    const renderNeedDate = (dateTime) => {
        if (!dateTime) {
            return 'No Due Date';
        }

        const dateDifference = calculateDateDifference(dateTime);
        const { number, hasAgo } = extractNumbersAndAgo(dateDifference);

        if (!hasAgo) {
            if (number === 'NaN days') {
                return `Needed ${dateDifference}`;
            } else {
                return `Needed in ${dateDifference}`;
            }
        } else {
            return `Needed ${dateDifference}`;
        }
    };

    return (
        <Card id="need-card" backgroundColor={isUpdated ? '#FBFBFF' : null}>
            <div className={classes.needs}>
                <div className={classes.top}>
                    <div className={classes.description}>{description}</div>
                    {!isUpdated && (
                        <div className={classes.amount}>
                            {amount && amount !== '0.0'
                                ? `$${amount.split('.')[0]}`
                                : `No Amount`}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={classes.needsBottom}
                style={isUpdated ? { justifyContent: 'flex-end' } : null}
            >
                {!isUpdated && (
                    <div className={renderClasses(classes, due_at).dateClass}>
                        <i className="fal fa-clock" />
                        {renderNeedDate(due_at)}
                    </div>
                )}
                <NeedButtons
                    complete={setCompleteNeed}
                    edit={setEdit}
                    need={need}
                    setHasQOLMeasures={setHasQOLMeasures}
                    setNeedID={setNeedID}
                    touchpointQuestionQOLs={touchpointQuestionQOLs}
                    updatedNeedIDs={updatedNeedIDs}
                />
            </div>
        </Card>
    );
};

NeedCard.propTypes = {
    need: PropTypes.object.isRequired,
    setCompleteNeed: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
    setNeedID: PropTypes.func.isRequired,
    updatedNeedIDs: PropTypes.array.isRequired
};

export default NeedCard;
