import Card from '../../../../_shared/Card';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        cardStyle: {
            marginTop: '50px',
            width: '100%',
            '@media (max-width: 768px)': {
                margin: '50px 0 0 0',
                padding: '0'
            }
        },
        contentWrapper: {
            padding: '20px'
        },
        title: {
            color: '#1F1F51',
            fontSize: '1.25rem',
            fontWeight: 600,
            letterSpacing: '0.15em',
            marginBottom: '16px',
            textTransform: 'uppercase'
        },
        amountContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            '@media (max-width: 768px)': {
                flexDirection: 'column'
            }
        },
        writeBtn: {
            border: '2px dashed #EAEBFB',
            borderRadius: '100px',
            color: '#1F1F51',
            fontSize: '1.25rem',
            padding: '8px 16px',
            whiteSpace: 'nowrap'
        },
        bonusAmount: {
            background: '#EAEBFB',
            borderRadius: '30px',
            color: '#1F1F51',
            fontSize: '1.25rem',
            padding: '10px 20px',
            textAlign: 'center'
        }
    })
);

const Bonus = () => {
    const classes = useStyles();

    const bonusAmounts = [`$5`, `$10`, `$20`, `No Bonus`];

    return (
        <Card className={classes.cardStyle}>
            <div className={classes.contentWrapper}>
                <span className={classes.title}>Bonus ...</span>
                <div className={classes.amountContainer}>
                    <span className={classes.writeBtn}>Write your own</span>
                    {bonusAmounts.map((amount, index) => (
                        <span className={classes.bonusAmount} key={index}>
                            {amount}
                        </span>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default Bonus;
