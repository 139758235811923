import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    doneButton: {
        position: 'relative',
        alignSelf: 'flex-end',
        border: '3px solid #E3E4FA',
        borderRadius: '36px',
        color: '#1F1F51',
        fontSize: '14px',
        fontWeight: '800',
        marginRight: '20px',
        padding: '4px 28px 4px 14px',
        textTransform: 'none'
    },
    checkButton: {
        position: 'absolute',
        right: '-44px',
        top: '-18px',
        background:
            'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3',
        borderRadius: '100%',
        boxShadow: '-2.44929e-16px 4px 20px rgba(0, 0, 0, 0.121569)',
        color: '#FFFFFF',
        fontSize: '18px',
        padding: '22px 0',
        '&:hover': {
            background:
                'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3'
        }
    }
}));

type DoneButtonProps = {
    handleClick: () => void;
};

const DoneButton: React.FC<DoneButtonProps> = ({ handleClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.doneButton}>
            <span>Done</span>
            <Button
                className={classes.checkButton}
                onClick={() => handleClick()}
            >
                <i className="fas fa-check" />
            </Button>
        </div>
    );
};

export default DoneButton;
