import { formattedDate } from 'util/index';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        unenrolledBannerContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            width: '100%'
        },
        unenrolledBanner: {
            display: 'flex',
            justifyContent: 'flex-end',
            background: '#FEF3E7',
            color: '#7A4406',
            padding: '10px 20px',
            fontWeight: 700,
            fontSize: '20px',
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        },
        reenrollButton: {
            alignSelf: 'flex-end',
            padding: '4px 12px',
            color: '#7378E8',
            border: '1px solid #7378E8',
            borderRadius: '20px',
            fontWeight: 700,
            textTransform: 'none',
            margin: '10px 30px',
            zIndex: 1,
            '& i': {
                marginLeft: '10px'
            },
            '@media (max-width: 768px)': {
                padding: '4px 8px',
                fontSize: '12px',
                margin: '10px'
            }
        }
    })
);

type UnenrollHeaderProps = {
    unenrolled_on: null | string;
    setReenroll: (value: boolean) => void;
};

const UnenrollHeader: React.FC<UnenrollHeaderProps> = ({
    unenrolled_on,
    setReenroll
}) => {
    const classes = useStyles();

    return (
        <div className={classes.unenrolledBannerContainer}>
            <span className={classes.unenrolledBanner}>
                Unenrolled on{' '}
                {formattedDate(
                    unenrolled_on ? new Date(unenrolled_on) : 'Not Unenrolled'
                )}
            </span>
            <Button
                className={classes.reenrollButton}
                onClick={() => setReenroll(true)}
            >
                Re-Enroll
                <i className="fas fa-chevron-right" />
            </Button>
        </div>
    );
};

export default UnenrollHeader;
