import { useRef, useState } from 'react';

import EditButton from './buttons/EditButton';
import PhotoBorder from 'assets/border-photo.svg';
import PhotoPopup from '../../../_shared/PhotoPopup';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        photoCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${PhotoBorder})`,
            borderRadius: '100%',
            height: '180px',
            width: '180px',
            '& i': {
                color: '#7378E8',
                cursor: 'pointer',
                fontSize: '40px',
                paddingBottom: '10px'
            },
            '& span': {
                alignSelf: 'center',
                color: '#7378E8',
                cursor: 'pointer',
                fontFamily: 'Manrope',
                fontSize: '18px',
                fontWeight: 800
            }
        },
        photoCircleUploaded: {
            backgroundImage: 'none'
        },
        photoContainer: {
            display: 'flex',
            alignItems: 'center',
            padding: '25px 0 10px 0',
            position: 'relative'
        },
        profilePhoto: {
            borderRadius: '100%',
            height: '100%',
            objectFit: 'cover',
            width: '100%'
        },
        editBtnContainer: {
            alignSelf: 'flex-end',
            paddingLeft: '160px',
            position: 'absolute',
            '& div': {
                margin: '0',
                padding: '0'
            }
        },
        fileUpload: {
            background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
            borderRadius: '1000px',
            color: '#fff',
            cursor: 'pointer',
            display: 'flex',
            fontSize: '14px',
            fontWeight: 700,
            padding: '0.5rem 1rem',
            textTransform: 'none',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
                boxShadow: '0px 4px 10px rgba(115, 121, 232, 0.5)'
            },
            '& input[type="file"]': {
                display: 'none'
            }
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        uploadContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    })
);

type ProfilePhotoProps = {
    inputTitleClass: (name: string) => string;
    profilePicture: File | null;
    remoteCopy: {
        label: string;
        required: boolean;
    };
    setGuide: (guide: boolean) => void;
    setProfilePicture: (file: File | null) => void;
};

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({
    inputTitleClass,
    profilePicture,
    remoteCopy,
    setGuide,
    setProfilePicture
}) => {
    const classes = useStyles();
    const isFirstRender = useRef(true);

    const { label, required } = remoteCopy;

    const [showPhotoPopup, setShowPhotoPopup] = useState(false);

    const handleClosePhotoPopup = () => {
        setGuide(false);
        setShowPhotoPopup(false);
    };

    const handleRemoveUpload = () => {
        const files = document.querySelector(
            `#photo-upload`
        ) as HTMLInputElement;

        if (files) {
            files.value = ``;
        }

        setProfilePicture(null);
    };

    const handleUploadClick = () => {
        if (isFirstRender.current) {
            setGuide(true);
            isFirstRender.current = false;
        }

        setShowPhotoPopup(true);
    };

    const photoCircleClass = () => {
        if (profilePicture) {
            return `${classes.photoCircle} ${classes.photoCircleUploaded}`;
        }

        return classes.photoCircle;
    };

    return (
        <div className="profile-photo-component" style={{ padding: '40px 0' }}>
            <span
                className={inputTitleClass(
                    profilePicture ? 'has-profile-picture' : ''
                )}
            >
                {label}
                <span
                    className={
                        classes[required ? 'requiredLabel' : 'optionalLabel']
                    }
                >
                    {`(${required ? 'REQUIRED' : 'OPTIONAL'})`}
                </span>
            </span>
            <div
                className={classes.photoContainer}
                style={!profilePicture ? { justifyContent: 'center' } : {}}
            >
                <div className={photoCircleClass()}>
                    {!profilePicture ? (
                        <div
                            className={classes.uploadContainer}
                            onClick={handleUploadClick}
                        >
                            <i className="far fa-camera" />
                            <span>Upload a photo</span>
                        </div>
                    ) : (
                        <img
                            alt="profile"
                            className={classes.profilePhoto}
                            src={URL.createObjectURL(profilePicture)}
                        />
                    )}
                </div>
                <div className={classes.editBtnContainer}>
                    {profilePicture && (
                        <EditButton
                            handleEditClick={() => handleRemoveUpload()}
                        />
                    )}
                </div>
            </div>
            {showPhotoPopup && (
                <PhotoPopup
                    handleModalClick={() => handleClosePhotoPopup()}
                    intakeStyle
                    setGuide={setGuide}
                    setProfilePicture={setProfilePicture}
                />
            )}
        </div>
    );
};

export default ProfilePhoto;
