import { useState } from 'react';
import { Document, Page } from 'react-pdf';

import DeleteWarnModal from '../DeleteWarnModal';
import Modal from '../../../../../../../_shared/Modal';
import { CTAButton2 } from '../../../../../../../_shared/buttons';

import classes from './DocumentPreview.module.scss';

/**
 * uses image tag onError to manage flow since we can't use filename to introspect file type.
 */
type Props = {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    url: string;
    fileName: string;
};

export default function DocumentPreview({
    open,
    onClose,
    url,
    fileName,
    onDelete
}: Props): JSX.Element {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [showImg, setShowImg] = useState(true);

    // states for pdf
    const [numPages, setNumPages] = useState<number | null>(null);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <DeleteWarnModal
                fileName={fileName}
                onDelete={onDelete}
                modalOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            />
            <div style={{ padding: 5 }}>
                {showImg ? (
                    <img
                        src={url}
                        alt={fileName}
                        className={classes.img}
                        onError={() => setShowImg(false)}
                    />
                ) : (
                    <div className={classes.pdfContainer}>
                        <Document
                            file={url}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array(numPages)
                                .fill(0)
                                .map((_, i) => (
                                    <Page
                                        key={i}
                                        pageNumber={i + 1}
                                        width={500}
                                    />
                                ))}
                        </Document>
                    </div>
                )}
                <div className={classes.buttonContainer}>
                    <a
                        href={url}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <CTAButton2 iconName="file-download">
                            Download
                        </CTAButton2>
                    </a>
                    <CTAButton2 iconName="trash-alt" onClick={onDelete}>
                        Delete
                    </CTAButton2>
                </div>
            </div>
        </Modal>
    );
}
