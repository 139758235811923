import * as CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import * as api from '../../api/messages';

function getChatRooms() {
    return {
        type: CONSTANTS.GET_CHAT_ROOMS
    };
}

// TODO: Fix any types
function setChatRoomRecipients(recipients: any) {
    return {
        type: CONSTANTS.SET_CHAT_ROOM_RECIPIENTS,
        payload: recipients
    };
}

function getChatRoomsSuccess(chatRooms: any) {
    return {
        type: CONSTANTS.GET_CHAT_ROOMS_SUCCESS,
        payload: chatRooms
    };
}

function setMessageHolderId(id: number) {
    return {
        type: CONSTANTS.SET_MESSAGE_HOLDER_ID,
        payload: id
    };
}

export function resetMessageInfo() {
    return {
        type: CONSTANTS.RESET_MESSAGE_INFO
    };
}

export function getChatRoomsRequest(
    memberId: number,
    chatPage: number,
    memberPage: number
) {
    return async (dispatch: Function) => {
        dispatch(getChatRooms());
        dispatch(setMessageHolderId(memberId));

        try {
            const response = await api.getChatRooms(memberId, chatPage);
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            const recipients = responseJson.encouragement_chat_rooms.map(
                (chatRoom: { recipient: { id: number } }) => {
                    return chatRoom.recipient.id;
                }
            );

            if (recipients.length > 0) {
                dispatch(setChatRoomRecipients(recipients));

                const allChatResponses = await Promise.all(
                    recipients.map((recipientId: number) =>
                        api.getMemberMessages(memberId, recipientId, memberPage)
                    )
                );

                const allChatResponsesJson = await Promise.all(
                    allChatResponses.map((response) =>
                        handleSamaritanApiResponse(response, dispatch)
                    )
                );

                const allChatMessages = allChatResponsesJson.map((response) => {
                    const recipientId =
                        response.encouragement_chat_room.recipient.id;

                    return {
                        recipientId,
                        messages: response.encouragement_chat_room.messages
                    };
                });

                dispatch(setAllChatMessages(allChatMessages));

                dispatch(
                    getChatRoomsSuccess(responseJson.encouragement_chat_rooms)
                );
            } else {
                dispatch(getChatRoomsSuccess([]));
            }
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
}

function getChatMessages() {
    return {
        type: CONSTANTS.GET_CHAT_MESSAGES
    };
}

function getChatMessagesSuccess(chatMessages: any) {
    return {
        type: CONSTANTS.GET_CHAT_MESSAGES_SUCCESS,
        payload: chatMessages
    };
}

function setAllChatMessages(chatMessages: any) {
    return {
        type: CONSTANTS.SET_ALL_CHAT_MESSAGES,
        payload: chatMessages
    };
}

export function getChatMessagesRequest(
    memberId: number,
    recipientId: number,
    page: number
) {
    return async (dispatch: Function) => {
        dispatch(getChatMessages());
        // dispatch(setMessageHolderId(memberId));

        try {
            const response = await api.getMemberMessages(
                memberId,
                recipientId,
                page
            );
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            dispatch(
                getChatMessagesSuccess(
                    responseJson.encouragement_chat_room.messages
                )
            );
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
}

function sendMessages() {
    return {
        type: CONSTANTS.SEND_MESSAGES
    };
}

function resendingMessage() {
    return {
        type: CONSTANTS.RESEND_MESSAGE
    };
}

function resendingMessageSuccess() {
    return {
        type: CONSTANTS.RESEND_MESSAGE_SUCCESS
    };
}

export function resendMessage(id: number, data: any) {
    return async (dispatch: Function, getState: any) => {
        const { messages } = getState();
        const { chatMessages } = messages;

        const resending = chatMessages.find(
            (message: { id: number }) => message.id === id
        );

        dispatch(resendingMessage());

        try {
            const response = await api.sendMessage(data);
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            if (resending) {
                dispatch(resendingMessageSuccess());

                return;
            } else {
                dispatch(sendMessagesSuccess(responseJson.chat_room_message));
            }
        } catch (error: any) {
            if (!resending) {
                throw new Error(`Message with ID ${id} not found`);
            }

            handleSamaritanApiError(error, dispatch);
        }
    };
}

function sendMessagesSuccess(chatMessages: any) {
    return {
        type: CONSTANTS.SEND_MESSAGES_SUCCESS,
        payload: chatMessages
    };
}

export function sendMessagesRequest(data: any) {
    return async (dispatch: Function) => {
        dispatch(sendMessages());

        try {
            const response = await api.sendMessage(data);
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            dispatch(sendMessagesSuccess(responseJson.chat_room_message));
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
}

export function deleteMessage(id: number) {
    return {
        type: CONSTANTS.DELETE_MESSAGE,
        payload: { id }
    };
}
