import classes from './LeftChevronButton.module.scss';

type LeftChevronButtonProps = {
    onClick: () => void;
    label?: string;
    dark?: boolean;
    className?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export default function LeftChevronButton({
    onClick,
    label,
    dark,
    className,
    ...rest
}: LeftChevronButtonProps): JSX.Element {
    return (
        <button
            className={`${className} ${classes.backChevron} ${
                dark ? 'dark' : ''
            }`}
            onClick={onClick}
            {...rest}
        >
            <i className="far fa-chevron-left" />
            {label && <span className="font-base">&emsp;{label}&emsp;</span>}
        </button>
    );
}
