import { useSpring, animated, config } from 'react-spring';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import classes from './FormInputTextWithLabelSpecial.module.scss';

const labelSpringConfig = {
    ...config.stiff,
    clamp: true
};

type FormInputTextWithLabelSpecialProps = {
    autoComplete?: string;
    error?: boolean;
    id: string;
    inputRef?: React.MutableRefObject<HTMLInputElement | null>;
    label: string;
    minLength?: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    type: string;
    value: string;
};

const FormInputTextWithLabelSpecial = ({
    label,
    value,
    error = false,
    inputRef: inputRefProp,
    onChange,
    ...rest
}: FormInputTextWithLabelSpecialProps) => {
    const theme = useTheme<Theme & { colors: { [key: string]: string } }>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [state, setState] = useState(() => {
        if (value) return 'withInput';
        return 'withoutInput';
    });

    useEffect(() => {
        if (error) return setState('error');
        if (state === 'error') return;
        if (state === 'focused') return;
        if (value) return setState('withInput');
        return setState('withoutInput');
    }, [value, state, error]);

    const labelStyle = useSpring(
        // self calling function to set style on every render
        (() => {
            switch (state) {
                case 'error':
                    return {
                        top: 'calc(0% + 0.8em)',
                        left: 'calc(1.5em + 2px - 0.5em)',
                        background: theme.colors.Red01,
                        color: theme.colors.OffWhite01,
                        config: labelSpringConfig
                    };
                case 'focused':
                    return {
                        top: 'calc(0% + 0.9em)',
                        left: 'calc(1.3em + 2px - 0.5em)',

                        background: theme.colors.Purple01,
                        color: theme.colors.OffWhite01,
                        config: labelSpringConfig
                    };
                case 'withoutInput':
                    return {
                        top: 'calc(50% + 0.4em)',
                        left: 'calc(1.5em + 2px - 0.5em)',

                        background: '#fff',
                        color: theme.colors.Purple03,
                        config: labelSpringConfig
                    };
                case 'withInput':
                    return {
                        top: 'calc(0% + 0.9em)',
                        left: 'calc(1.3em + 2px - 0.5em)',

                        color: theme.colors.DarkPurple02,
                        background: theme.colors.Purple03,
                        config: labelSpringConfig
                    };

                default:
                    return {};
            }
        })()
    );

    const inputStyle = useSpring(
        // self calling function to set style on every render
        (() => {
            switch (state) {
                case 'focused':
                    return {
                        borderColor: theme.colors.Purple01
                    };
                case 'error':
                    return {
                        borderColor: theme.colors.Red01
                    };
                default:
                    return {
                        borderColor: theme.colors.Purple03
                    };
            }
        })()
    );

    const focusInput = () => {
        // if the component is in error state, keep it in error state
        if (state !== 'error') setState('focused');
        // guarantee focus is put into input element
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const blurInput = () => {
        if (value) setState('withInput');
        else setState('withoutInput');
    };

    return (
        <animated.div style={{ position: 'relative', fontSize: '1rem' }}>
            <animated.label
                className={classes.label}
                style={labelStyle}
                htmlFor={label}
                onClick={focusInput}
            >
                {label}
            </animated.label>
            <animated.input
                className={classes.input}
                {...rest}
                style={inputStyle}
                ref={(ref) => {
                    inputRef.current = ref;
                    if (inputRefProp) {
                        inputRefProp.current = ref;
                    }
                }}
                name={label}
                value={value}
                onChange={(e) => {
                    // if the state is error and there is a modification reset error state
                    if (state === 'error' && e.target.value)
                        setState('withInput');
                    onChange(e);
                }}
                onFocus={focusInput}
                onBlur={blurInput}
                onInvalidCapture={() => setState('error')}
            />
        </animated.div>
    );
};

export default FormInputTextWithLabelSpecial;
