import EditIcon from 'assets/icon-edit.png';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            cursor: 'pointer',
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 10px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        savedInput: {
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            overflowWrap: 'anywhere'
        },
        skippedContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px'
        },
        skippedInput: {
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 700
        }
    })
);

type EditButtonProps = {
    date?: boolean;
    handleEditClick: () => void;
    inputValState?: string | number;
    saveInputState?: boolean;
    skipState?: boolean;
};

const formatDate = (date: string) => {
    const dateArray = date.split('-');
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    return `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;
};

const EditButton: React.FC<EditButtonProps> = ({
    date,
    handleEditClick,
    inputValState,
    saveInputState,
    skipState
}) => {
    const classes = useStyles();

    return (
        <div className={classes.skippedContainer}>
            {skipState && <div className={classes.skippedInput}>{`-`}</div>}
            {saveInputState && !skipState && (
                <div className={classes.savedInput}>
                    {date ? formatDate(inputValState as string) : inputValState}
                </div>
            )}
            <div className={classes.editIconContainer}>
                <img
                    alt="edit-button"
                    className={classes.editIcon}
                    onClick={() => handleEditClick()}
                    src={EditIcon}
                />
                <div className={classes.editUnderline} />
            </div>
        </div>
    );
};

export default EditButton;
