import { setSamaritanUrl } from 'settings';
import { CTAButton2 } from 'components/_shared/buttons';

import { Cities } from 'types';

import classes from './index.module.scss';

type CityButtonProps = {
    cities: Cities[];
    onWide: () => void;
    setStage: (stage: string) => void;
    setCity: (city: Cities) => void;
};

export const CityButtons: React.FC<CityButtonProps> = ({
    cities,
    onWide,
    setStage,
    setCity
}) => (
    <div className={classes.buttonGroupContainer}>
        select your region
        <div className={classes.buttonGroup}>
            {cities.map((c) => (
                <CTAButton2
                    key={c.name}
                    onClick={(e) => {
                        e.preventDefault();
                        if (onWide) onWide();
                        setStage('inputs');
                        setSamaritanUrl(c.subdomain_name);
                        setCity(c);
                    }}
                >
                    {c.name}
                </CTAButton2>
            ))}
        </div>
    </div>
);
