import classNames from 'classnames';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        referMemberButton: {
            alignSelf: 'flex-end',
            borderRadius: '40px',
            border: '1.6px solid #E3E3FA',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            minHeight: '38.5px',
            minWidth: '0px',
            padding: '6px 12px',
            fontWeight: 500,
            textTransform: 'none',
            zIndex: 4,
            '& i': {
                marginRight: '10px'
            },
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        },
        small: {
            padding: '6px 10px',
            minWidth: '0px'
        }
    })
);

type ReferMemberProps = {
    isSaved: boolean;
    setReferMemberPopup: (value: boolean) => void;
};

const ReferMember: React.FC<ReferMemberProps> = ({
    isSaved,
    setReferMemberPopup
}) => {
    const classes = useStyles();

    const buttonClass = classNames({
        [classes.referMemberButton]: true,
        [classes.small]: !isSaved
    });

    const handleReferClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Refer Member Click'
        });

        setReferMemberPopup(true);
    };

    return (
        <Button className={buttonClass} onClick={handleReferClick}>
            {!isSaved ? (
                <i className={`fas fa-user-plus`} style={{ margin: '0' }} />
            ) : (
                <>
                    <i className={`far fa-user-plus`} />
                    Refer Member
                </>
            )}
        </Button>
    );
};

export default ReferMember;
