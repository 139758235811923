import CONSTANTS from "../constants/constants";

const initialState = {
    isFetchingOrganizationPayments: false,
    organizationPayments: {},
    isFetchingMemberPayments: false,
    memberPayments: [],
    fullMemberPayments: []
};

export default function payments_reducer(state = initialState, action: any) {
  switch (action.type) {
    case CONSTANTS.GET_ORGANIZATION_PAYMENTS:
      return {
        ...state,
        isFetchingOrganizationPayments: true,
      };
    case CONSTANTS.SET_ORGANIZATION_PAYMENTS:
      return {
        ...state,
        isFetchingOrganizationPayments: false,
        organizationPayments: action.payload,
      };
    case CONSTANTS.GET_MEMBER_PAYMENTS:
      return {
        ...state,
        isFetchingMemberPayments: true,
      };
    case CONSTANTS.SET_MEMBER_PAYMENTS:
      return {
        ...state,
        isFetchingMemberPayments: false,
        memberPayments: action.payload,
      };
    case CONSTANTS.SET_FULL_MEMBER_PAYMENTS:
      return {
        ...state,
        isFetchingMemberPayments: false,
        fullMemberPayments: action.payload,
      };
    case CONSTANTS.RESET_FULL_MEMBER_PAYMENTS:
      return {
        ...state,
        fullMemberPayments: []
      };
    default:
      return state;
  }
}
