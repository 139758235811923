import CONSTANTS from '../constants/constants';

import { CatchUpItems } from 'types';

type ItemStatusState = {
    [id: number]: {
        loading: boolean;
        success: boolean;
        error: boolean;
    };
};

type State = {
    catchUpItems: CatchUpItems[];
    catchUpItemStatus: ItemStatusState;
    isFetchingCatchUpItems: boolean;
};

const initialState: State = {
    catchUpItems: [],
    catchUpItemStatus: {},
    isFetchingCatchUpItems: false
};

const updateCatchUpItem = (
    items: CatchUpItems[],
    catchUpItemId: number,
    updates: string
) =>
    items.map((item) =>
        item.id === catchUpItemId ? { ...item, status: updates } : item
    );

export default function catch_up_items_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.CATCH_UP_ITEM_FAILURE:
            return {
                ...state,
                catchUpItemStatus: {
                    ...state.catchUpItemStatus,
                    [action.payload]: {
                        loading: false,
                        success: false,
                        error: true
                    }
                }
            };
        case CONSTANTS.CATCH_UP_ITEM_SUBMIT:
            return {
                ...state,
                catchUpItemStatus: {
                    ...state.catchUpItemStatus,
                    [action.payload]: {
                        loading: true,
                        success: false,
                        error: false
                    }
                }
            };
        case CONSTANTS.CATCH_UP_ITEM_SUCCESS:
            return {
                ...state,
                catchUpItemStatus: {
                    ...state.catchUpItemStatus,
                    [action.payload]: {
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            };
        case CONSTANTS.COMPLETE_CATCH_UP_ITEM:
            return {
                ...state,
                catchUpItems: updateCatchUpItem(
                    state.catchUpItems,
                    action.payload,
                    'completed'
                )
            };
        // case CONSTANTS.COMPLETE_CATCH_UP_ITEM:
        //     const completedCatchUpItems = state.catchUpItems?.map((item) => {
        //         if (item.id === action.payload.catchUpItemId) {
        //             return {
        //                 ...item,
        //                 status: 'completed', // remove this when the endpoint is live
        //                 snooze_till: action.payload.snoozeTill
        //             };
        //         }
        //         return item;
        //     });

        //     return {
        //         ...state,
        //         catchUpItems: completedCatchUpItems
        //     };
        case CONSTANTS.FETCH_CATCH_UP_ITEMS:
            return {
                ...state,
                isFetchingCatchUpItems: true
            };
        case CONSTANTS.RESET_CATCH_UP_ITEM_STATUS:
            return {
                ...state,
                catchUpItemStatus: {}
            };
        case CONSTANTS.SET_CATCH_UP_ITEMS:
            return {
                ...state,
                catchUpItems: action.payload,
                isFetchingCatchUpItems: false
            };
        case CONSTANTS.SNOOZE_CATCH_UP_ITEM:
            const updatedCatchUpItems = state.catchUpItems?.map((item) => {
                if (item.id === action.payload.catchUpItemId) {
                    return {
                        ...item,
                        status: 'snoozed', // remove this when the endpoint is live
                        snooze_till: action.payload.snoozeTill
                    };
                }
                return item;
            });

            return {
                ...state,
                catchUpItems: updatedCatchUpItems
            };
        default:
            return state;
    }
}
