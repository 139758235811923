import * as constants from '../constants/constants';

import { setSentryUser } from '../../settings';

const initialState = {
    state: 'idle',
    user: {
        is_showing_intake_guides: true,
        members_enrolled: 0
    },
    languages: [],
    maxBonusAmount: null
};

export default function user_reducer(state = initialState, action: any) {
    switch (action.type) {
        case constants.INCREMENT_ENROLLED_MEMBERS:
            return {
                ...state,
                user: {
                    ...state.user,
                    members_enrolled: state.user.members_enrolled + 1
                }
            };
        case constants.SET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        case constants.SET_USER:
            const user = action.payload;

            // set sentry user
            setSentryUser({
                email: user.email,
                id: user.id
            });
            return {
                ...state,
                state: 'success',
                user: user,
                maxBonusAmount: user
                    ? user.partner?.deployment?.monthly_bonus_pool_max || 40
                    : 40
            };
        case constants.UNSET_USER:
            return initialState;
        case constants.UPDATE_LANGUAGE_SETTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    language_preference: action.payload
                }
            };
        case constants.UPDATE_SHOW_GUIDE_SETTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    is_showing_intake_guides: action.payload
                }
            };
        default:
            return state;
    }
}
