import CONSTANTS from '../constants/constants';

import {
    DebitCard,
    DebitCardHistory,
    DebitCardOptions,
    PexHistory
} from 'types';

import { updateObject } from '../../util';

// TODO: Add types for history when implemented
const initialState = {
    debitCardOptions: null,
    debitHistory: [],
    debitInfo: {} as DebitCard,
    error: null,
    fullDebitHistory: [],
    isErrored: false,
    isFetchingDebitCardOptions: false,
    isFetchingDebitHistory: false,
    isFetchingDebitInfo: false,
    isLoading: false,
    isSuccessful: false
} as {
    debitCardOptions: DebitCardOptions[] | null;
    debitHistory: PexHistory[] | DebitCardHistory[];
    debitInfo: DebitCard;
    error: null | string;
    fullDebitHistory: PexHistory[] | DebitCardHistory[];
    isErrored: boolean;
    isFetchingDebitCardOptions: boolean;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
    isLoading: boolean;
};

export default function debitCard_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.ASSIGN_DEBIT_CARD:
            return {
                ...state,
                isLoading: true,
                isSuccessful: false
            };
        case CONSTANTS.CLOSE_DEBIT_CARD:
            return {
                ...state,
                isLoading: true,
                isSuccessful: false
            };
        case CONSTANTS.FETCH_DEBIT_CARD_HISTORY:
            return {
                ...state,
                isFetchingDebitHistory: true
            };
        case CONSTANTS.GET_DEBIT_CARD_INFO:
            return {
                ...state,
                isFetchingDebitInfo: true
            };
        case CONSTANTS.GET_DEBIT_CARD_OPTIONS:
            return {
                ...state,
                isFetchingDebitCardOptions: true
            };
        case CONSTANTS.RESET_DEBIT_ERROR: {
            return {
                ...state,
                error: null,
                isErrored: false,
                isLoading: false,
                isSuccessful: false
            };
        }
        case CONSTANTS.SET_DEBIT_CARD_BALANCE:
            return {
                ...state,
                debitInfo: action.payload
            };
        case CONSTANTS.SET_DEBIT_CARD_ERROR:
            return {
                ...state,
                error: action.payload,
                isErrored: true,
                isLoading: false,
                isSuccessful: false
            };
        case CONSTANTS.SET_DEBIT_CARD_FULL_HISTORY:
            return {
                ...state,
                fullDebitHistory: action.payload,
                isFetchingDebitHistory: false
            };
        case CONSTANTS.SET_DEBIT_CARD_HISTORY:
            return {
                ...state,
                debitHistory: action.payload,
                isFetchingDebitHistory: false
            };
        case CONSTANTS.SET_DEBIT_CARD_INFO:
            const { samaritan_member, success } = action.payload;

            if (samaritan_member && action.payload !== state.debitInfo) {
                const {
                    card_account_id,
                    account_id,
                    card_last4,
                    card_type,
                    card_total_balance,
                    total_balance,
                    card_available_balance,
                    available_balance
                } = samaritan_member;

                return {
                    ...state,
                    debitInfo: {
                        account_id: card_account_id || account_id,
                        card_last4,
                        card_type,
                        total_balance:
                            card_total_balance !== null &&
                            card_total_balance !== undefined
                                ? card_total_balance
                                : total_balance,
                        available_balance:
                            card_available_balance !== null &&
                            card_available_balance !== undefined
                                ? card_available_balance
                                : available_balance
                    },
                    isFetchingDebitInfo: false,
                    isLoading: false,
                    isSuccessful: success || false
                };
            }

            if (!samaritan_member) {
                return {
                    ...state,
                    debitInfo: action.payload,
                    isFetchingDebitInfo: false,
                    isLoading: false,
                    isSuccessful: success || false
                };
            }

            return state;
        case CONSTANTS.SET_DEBIT_CARD_OPTIONS:
            return {
                ...state,
                isFetchingDebitCardOptions: false,
                debitCardOptions: action.payload
            };
        case CONSTANTS.SET_SUCCESSFUL_DEBIT_RESPONSE:
            return {
                ...state,
                isLoading: false,
                isSuccessful: true
            };
        case CONSTANTS.TRANSFER_TO_DEBIT_CARD:
            return {
                ...state,
                isLoading: true,
                isSuccessful: false
            };
        case CONSTANTS.UPDATE_DEBIT_CARD_BALANCE:
            return {
                ...state,
                debitInfo: updateObject(
                    state.debitInfo,
                    'available_balance',
                    action.payload.balance
                ),
                isLoading: false,
                isSuccessful: true
            };
        case CONSTANTS.UPDATE_DEBIT_CARD_PIN:
            return {
                ...state,
                isLoading: true
            };
        case CONSTANTS.UPDATE_DEBIT_CARD_PIN_ERROR:
            return {
                ...state,
                error: action.payload,
                isErrored: true,
                isLoading: false,
                isSuccessful: false
            };
        case CONSTANTS.UPDATE_DEBIT_CARD_PIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessful: true
            };
        default:
            return state;
    }
}
