import { useEffect, useRef, useState } from 'react';

import DateInput from 'components/_shared/DateInput';
import EditButton from './EditButton';
import FormInput from 'components/_shared/FormInput';

import writeBtnBorder from 'assets/border-write.svg';

import { checkCurrentMonth } from '../utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        customBtn: {
            background: `url(${writeBtnBorder})`,
            borderRadius: '100px',
            color: '#1F1F51',
            fontSize: '16px',
            padding: '8px 24px',
            textTransform: 'none'
        },
        doneBtn: {
            borderRadius: '100px',
            color: '#7378E8',
            fontSize: '16px',
            fontWeight: 800,
            padding: '0 20px',
            textTransform: 'none'
        },
        dueDateReminder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: '300',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            padding: '8px !important',
            color: '#1F2152',
            width: 'fit-content'
        },
        inputContainer: {
            marginBottom: '10px'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            textDecoration: 'underline',
            textDecorationColor: '#EAEBFB',
            textDecorationThickness: '3px',
            width: '100%',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        suggestion: {
            background: '#EAEBFB',
            borderRadius: '100px',
            color: '#1F1F51',
            fontSize: '16px',
            padding: '8px 24px',
            textTransform: 'none'
        },
        suggestionContainer: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '16px',
            paddingTop: '10px'
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

type InputProps = {
    add?: boolean;
    edit?: boolean;
    createdAt?: string;
    dueDateInThePast?: boolean;
    editableAmount?: number;
    label?: string;
    customGoal?: string;
    setCustomGoal?: (value: string) => void;
    intakeInput: string;
    setIntakeInput: (value: string) => void;
    dateISO?: string;
    setDateISO?: (value: string) => void;
    qolIDs?: string[];
    setQolIDs?: (value: string[]) => void;
    savedQOLs?: string[];
    setSavedQOLs?: (value: string[]) => void;
    inputTitleClass: (input: string) => string;
    remainingBonusAmount?: number;
    setShowTooltip?: (value: boolean) => void;
    suggestions?: any;
    type: string;
    useHistory?: boolean;
};

const Input: React.FC<InputProps> = ({
    add,
    edit,
    createdAt,
    dueDateInThePast,
    editableAmount,
    label,
    customGoal,
    setCustomGoal,
    intakeInput,
    setIntakeInput,
    setDateISO,
    inputTitleClass,
    remainingBonusAmount,
    setShowTooltip,
    suggestions,
    type,
    useHistory
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const [input, setInput] = useState('');
    const [dateInput, setDateInput] = useState<Date | string>(tomorrow);
    const [saveCustom, setSaveCustom] = useState(
        type === 'qol' && !customGoal && edit ? true : false
    );
    const [showCustom, setShowCustom] = useState(true);
    const [savedInput, setSavedInput] = useState(
        add && !input && !useHistory ? false : true
    );
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showInput, setShowInput] = useState(false);

    const suggestionType =
        (suggestions && suggestions[type] && suggestions[type].default) ||
        (suggestions && type === 'qol' && suggestions);

    const handleCustomClick = () => {
        if (type === 'due_dates') {
            if (edit) {
                setSaveCustom(false);
            }

            setShowCustom(false);
            setShowDatePicker(true);
            setSaveCustom(true);
            return;
        }

        if (add) {
            setShowInput(true);
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }

        setInput('');
        setIntakeInput('');
        setSaveCustom(true);
        setShowCustom(false);
    };

    const handleDateChange = (date: string) => {
        if (setDateISO) {
            setDateISO(date);
        }

        setDateInput(date);
        setInput(date as any);
        setIntakeInput(date);
        setSavedInput(true);
        setShowDatePicker(false);
    };

    const handleDoneClick = () => {
        if (!input.length) {
            return;
        }

        if (type === 'qol' && setCustomGoal) {
            setCustomGoal(input);
        }

        setIntakeInput(input);
        setSavedInput(true);
        setIntakeInput(input);
        setShowCustom(false);
        setSaveCustom(true);
    };

    const handleEditClick = () => {
        if (type === 'bonus' && setShowTooltip) {
            setShowTooltip(true);
        }

        setSavedInput(false);
        setShowCustom(true);
    };

    const handleInputChange = (input: string) => {
        if (type === 'cost') {
            input = input.replace(/[^0-9]/g, '');

            if (input && input[0] === '0') {
                input = input.slice(1);
            }

            if (input === '') {
                input = 'No Amount';
            }

            setInput(input);
        }

        setInput(input);
    };

    const handleInputClick = (
        suggestion: { name: string; date: string } | number | string
    ) => {
        let newInput = '';
        let newIntakeInput = '';

        switch (type) {
            case 'cost':
                newInput = suggestion === 0 ? 'No Amount' : `$${suggestion}`;
                newIntakeInput = newInput;
                setShowTooltip?.(false);
                break;

            case 'bonus':
                newInput = suggestion === 0 ? 'No Bonus' : `$${suggestion}`;
                newIntakeInput = newInput;
                setShowTooltip?.(false);
                break;

            case 'due_dates':
                if (typeof suggestion === 'object') {
                    newIntakeInput = suggestion.name;
                    newInput = suggestion.date;
                    setDateISO?.(newInput);
                }
                break;

            default:
                newInput = newIntakeInput = String(suggestion);
                break;
        }

        if (type === 'due_dates') {
            setIntakeInput(newInput);
        } else {
            setIntakeInput(newIntakeInput);
        }

        setInput(newInput);
        setSavedInput(true);
        setSaveCustom(false);
        setShowCustom(true);
    };

    const renderFormInput = () => {
        const isAddAndShowInput = add && showInput;
        const isEditAndNotDueDates = edit && type !== 'due_dates';
        const isEditAndNotBonus = edit && type !== 'bonus';
        const isTypeQOL = type === 'qol';

        return (
            isAddAndShowInput ||
            (isEditAndNotDueDates && isEditAndNotBonus) ||
            isTypeQOL
        );
    };

    const renderPlaceholder = () => {
        if (type === 'qol') {
            return 'Write your own goal';
        } else {
            return 'Write your own';
        }
    };

    // TODO: Fix any type
    const renderSuggestion = (
        suggestion: { name: string; date: string } | number | string | any
    ) => {
        if (type === 'cost' || type === 'bonus') {
            if (suggestion === 0) {
                if (type === 'cost') {
                    return 'No Amount';
                } else {
                    return 'No Bonus';
                }
            }

            return `$${suggestion}`;
        } else if (type === 'due_dates') {
            return suggestion.name;
        } else if (type === 'qol') {
            return suggestion.name;
        } else {
            return suggestion;
        }
    };

    useEffect(() => {
        if (add && showInput && type !== 'due_dates') {
            inputRef.current?.focus();
        }
    }, [add, showInput, type]);

    useEffect(() => {
        if (add && type === 'descriptions' && intakeInput.length) {
            setSavedInput(true);
        }
    }, [add, intakeInput, type]);

    useEffect(() => {
        if (
            add &&
            type === 'bonus' &&
            remainingBonusAmount &&
            remainingBonusAmount <= 0
        ) {
            setSavedInput(true);
        }
    }, [add, remainingBonusAmount, type]);

    return (
        <div className={classes.inputContainer}>
            <span className={inputTitleClass(intakeInput)}>
                {label ? `${label} ...` : ``}
            </span>
            {!savedInput ? (
                <div style={{ display: 'flex' }}>
                    {showDatePicker && (
                        <DateInput
                            dateInput={dateInput as Date}
                            disablePast
                            open={showDatePicker}
                            setDateInput={handleDateChange as any}
                            setDisplayBtnState={setSavedInput}
                            setOpen={setShowDatePicker}
                        />
                    )}
                    {renderFormInput() ? (
                        <FormInput
                            className={classes.inputFields}
                            name={label ? label : ''}
                            onChange={(e) => handleInputChange(e.target.value)}
                            onClick={() => {
                                setSavedInput(false);
                                setSaveCustom(true);
                            }}
                            placeholder={renderPlaceholder()}
                            ref={inputRef}
                            value={input}
                        />
                    ) : null}
                    {saveCustom && type !== 'due_dates' && (
                        <Button
                            className={classes.doneBtn}
                            disabled={!input.length}
                            onClick={handleDoneClick}
                        >
                            Done
                        </Button>
                    )}
                </div>
            ) : (
                <EditButton
                    handleEditClick={handleEditClick}
                    inputValState={intakeInput}
                    remainingBonusAmount={remainingBonusAmount}
                    saveInputState={savedInput}
                    type={type}
                />
            )}
            {dueDateInThePast && (
                <span className={classes.dueDateReminder}>
                    Remember to set a new future due date!
                </span>
            )}
            {((!savedInput && type !== 'qol') ||
                (!savedInput && add && type !== 'qol')) && (
                <div className={classes.suggestionContainer}>
                    {label !== 'bonus' && showCustom && (
                        <Button
                            className={classes.customBtn}
                            onClick={() => handleCustomClick()}
                        >
                            Write your own
                        </Button>
                    )}
                    {suggestionType.map((suggestion: any, index: number) => {
                        // TODO: Fix any type
                        const lessThanAmount =
                            suggestion <= parseInt(intakeInput.split('$')[1]);
                        const isInvalidAddBonus =
                            add &&
                            type === 'bonus' &&
                            remainingBonusAmount &&
                            remainingBonusAmount < suggestion;

                        if (
                            edit &&
                            type === 'bonus' &&
                            createdAt &&
                            editableAmount
                        ) {
                            const lessThanCurrentAmount = checkCurrentMonth(
                                createdAt
                            )
                                ? suggestion <= editableAmount
                                : lessThanAmount;

                            if (lessThanCurrentAmount) {
                                return (
                                    <Button
                                        key={index}
                                        className={classes.suggestion}
                                        onClick={() =>
                                            handleInputClick(suggestion)
                                        }
                                    >
                                        {renderSuggestion(suggestion)}
                                    </Button>
                                );
                            }

                            if (suggestion === 0) {
                                return (
                                    <Button
                                        key={index}
                                        className={classes.suggestion}
                                        onClick={() =>
                                            handleInputClick(suggestion)
                                        }
                                    >
                                        {renderSuggestion(suggestion)}
                                    </Button>
                                );
                            }
                        } else if (!isInvalidAddBonus) {
                            return (
                                <Button
                                    key={index}
                                    className={classes.suggestion}
                                    onClick={() => handleInputClick(suggestion)}
                                >
                                    {renderSuggestion(suggestion)}
                                </Button>
                            );
                        }

                        return null;
                    })}
                </div>
            )}
        </div>
    );
};

export default Input;
