import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            background: '#FBFBFF',
            border: 'none',
            borderBottom: '1px solid #A6A6BF',
            borderRadius: '0',
            color: '#1F1F51',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
            padding: '20px 36px',
            textTransform: 'capitalize',
            width: '100%',
            '&:hover': {
                backgroundColor: '#F2F2FD'
            },
            '& input[type="file"]': {
                display: 'none'
            }
        }
    })
);

type MenuProps = {
    menuButtons: {
        disabled?: boolean;
        label: JSX.Element;
        onClick: () => void;
    }[];
};

const Menu: React.FC<MenuProps> = ({ menuButtons }) => {
    const classes = useStyles();

    return (
        <>
            {menuButtons.map((button, index) => (
                <Button
                    key={index}
                    className={classes.btn}
                    disabled={button.disabled}
                    onClick={button.onClick}
                >
                    {button.label}
                </Button>
            ))}
        </>
    );
};

export default Menu;
