import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    divider: {
        background: '#F0F0FF',
        height: '5px',
        width: '100%'
    }
}));

const Divider = () => {
    const classes = useStyles();

    return <div className={classes.divider} />;
};

export default Divider;
