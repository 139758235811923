import { connect } from 'react-redux';
import actions from '../redux/actions';

// TODO: Fix ts-ignore Messaging
// @ts-ignore
import Messaging from '../components/holder_profile/profile/Messaging';

function mapStateToProps(state: any) {
    const {
        chatRooms,
        isFetchingChatRooms,
        hasFetchedChatRooms,
        allChatMessages,
        chatMessages,
        isFetchingChatMessages,
        hasFetchedChatMessages,
        isSendingMessage,
        isResendingMessage,
        recipients,
        holderId
    } = state.messages;

    return {
        chatRooms,
        isFetchingChatRooms,
        hasFetchedChatRooms,
        allChatMessages,
        chatMessages,
        isFetchingChatMessages,
        hasFetchedChatMessages,
        isSendingMessage,
        isResendingMessage,
        recipients,
        holderId
    };
}

const mapDispatchToProps = {
    getChatRooms: actions.getChatRoomsRequest,
    getChatMessages: actions.getChatMessagesRequest,
    deleteMessage: actions.deleteMessage,
    sendMessages: actions.sendMessagesRequest,
    resendMessage: actions.resendMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Messaging);
