import { connect } from 'react-redux';
import actions from '../redux/actions';
import StatusCapture from '../components/holder_profile/profile/SDOH_3';

interface CustomClasses {
    [key: string]: {
        [key: string]: string;
    };
}

function mapStateToProps(
    state: any,
    ownProps: { catchUpItemID?: number; customClasses?: CustomClasses }
) {
    const { homelessInfo, isFetchingHomelessInfo, notFoundHomelessInfo } =
        state.homeless;
    const {
        isFetchingTouchpointQuestions,
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs
    } = state.touchpointQuestions;
    const {
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isSubmittingStatusCapture,
        isSubmittingTouchpointAnswers,
        touchpointAnswers,
        touchpointAnswersById
    } = state.touchpointAnswers;
    const { sdohStatus } = state.unenrollment;

    return {
        homelessInfo,
        isFetchingHomelessInfo,
        isFetchingTouchpointAnswers,
        isFetchingTouchpointAnswersById,
        isFetchingTouchpointQuestions,
        isSubmittingStatusCapture,
        isSubmittingTouchpointAnswers,
        notFoundHomelessInfo,
        sdohStatus,
        touchpointQuestions,
        touchpointQuestionIds,
        touchpointQuestionQOLs,
        touchpointAnswers,
        touchpointAnswersById,
        ...ownProps
    };
}

const mapDispatchToProps = {
    getTouchpointAnswersRequest: actions.getTouchpointAnswersRequest2,
    setSdohStatus: actions.setSdohStatus,
    submitStatusCapture: actions.submitStatusCaptureRequest,
    submitTouchpointAnswer: actions.submitTouchpointAnswer,
    updateGoalActionStatus: actions.updateGoalActionStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusCapture);