import { useHistory } from 'react-router';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            background: '#5a628b',
            color: '#fff',
            width: '100%',
            height: 'auto',
            padding: '20px',
            borderRadius: '10px',
            zIndex: 4
        },
        secondaryMessage: {
            position: 'relative',
            top: '-30px',
            right: '-15px',
            background: '#a1a7d0',
            color: '#fff',
            width: '100%',
            height: 'auto',
            padding: '20px',
            borderRadius: '10px',
            '& ul li': {
                margin: '14px 0'
            }
        },
        btnContainer: {
            paddingTop: '20px'
        },
        profileBtn: {
            textTransform: 'none',
            fontSize: '24px',
            fontWeight: 700,
            color: '#FBFBFF',
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            padding: '12px 102px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.08)',
            '&:hover': {
                background: 'linear-gradient(180deg, #8571DF 0%, #918ED7 100%)'
            }
        }
    })
);

type Props = {
    ctpName: string;
    intakeId: number;
    memberName: string;
    orgName: string;
};

const CongratsMessage: React.FC<Props> = ({
    ctpName,
    intakeId,
    memberName,
    orgName
}) => {
    const classes = useStyles();
    const history = useHistory();

    const possessiveMemberName = (name: string) => {
        if (!name) return '';

        if (name.toLowerCase().endsWith('s')) {
            return `${name}'`;
        } else {
            return `${name}'s`;
        }
    };

    return (
        <>
            <span className={classes.message}>
                Great work {ctpName} and {memberName}!{' '}
                {possessiveMemberName(memberName)} Samaritan Membership is now
                live.
            </span>
            <span className={classes.message}>
                Share these final reminders with them:
            </span>
            <div className={classes.secondaryMessage}>
                <ul>
                    <li>
                        Samaritan will gather support for your needs while you
                        work towards your action steps.
                    </li>
                    <li>
                        Keep connected with your case manager at {orgName},
                        they'll help you spend funds and earn bonuses.
                    </li>
                </ul>
            </div>
            <div className={classes.btnContainer}>
                <Button
                    className={classes.profileBtn}
                    variant="contained"
                    onClick={() => history.push(`/holders/${intakeId}`)}
                >
                    <span>Go to Profile</span>
                </Button>
            </div>
        </>
    );
};

export default CongratsMessage;
