import * as CONSTANTS from '../constants/constants';

import { Needs } from 'types';

const initialState = {
    isFetchingHomelessNeeds: false,
    hasFetchedHomelessNeeds: false,
    homelessNeeds: [],
    newHomelessNeeds: [],
    isEditingNeed: false
} as {
    isFetchingHomelessNeeds: boolean;
    hasFetchedHomelessNeeds: boolean;
    homelessNeeds: Needs[];
    newHomelessNeeds: {
        data: {
            need: {
                amount: number;
                description: string;
                due_at: string;
                homeless_id: number;
            };
        };
        error: null | string;
        status: string;
        tempRef: string;
    }[];
    isEditingNeed: boolean;
};

export default function needs_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_HOMELESS_NEEDS:
            return {
                ...state,
                isFetchingHomelessNeeds: true
            };
        case CONSTANTS.SET_HOMELESS_NEEDS:
            return {
                ...state,
                isFetchingHomelessNeeds: false,
                hasFetchedHomelessNeeds: true,
                homelessNeeds: action.payload,
                newHomelessNeeds: []
            };
        case CONSTANTS.REMOVE_HOMELESS_NEED:
            const removeNeedPayload = state.homelessNeeds.filter((need) => {
                return need.id !== action.payload;
            });
            return {
                ...state,
                homelessNeeds: removeNeedPayload
            };

        case CONSTANTS.CHANGE_LOCAL_NEED_STATUS:
            const mappedNeeds = state.homelessNeeds.map((n) => {
                if (n.id === action.need_id) {
                    return {
                        ...n,
                        status: action.status
                    };
                }
                return n;
            });

            return {
                ...state,
                homelessNeeds: mappedNeeds
            };

        case CONSTANTS.POSTING_NEW_HOMELESS_NEED:
            return {
                ...state,
                newHomelessNeeds: [
                    ...state.newHomelessNeeds,
                    {
                        status: 'loading',
                        error: null,
                        tempRef: action.tempRef,
                        data: action.payload
                    }
                ]
            };

        case CONSTANTS.POSTED_NEW_HOMELESS_NEED: {
            const filteredNeeds = state.newHomelessNeeds.filter(
                (n) => n.tempRef !== action.tempRef
            );
            return {
                ...state,
                homelessNeeds: [...state.homelessNeeds, action.newNeed],
                newHomelessNeeds: filteredNeeds
            };
        }
        case CONSTANTS.FAILED_NEW_HOMELESS_NEED: {
            const mappedNeeds = state.newHomelessNeeds.map((n) => {
                if (n.tempRef === action.tempRef) {
                    return {
                        ...n,
                        status: 'failed',
                        error: action.error
                    };
                }
                return n;
            });
            return {
                ...state,
                newHomelessNeeds: mappedNeeds
            };
        }
        case CONSTANTS.EDITING_HOMELESS_NEED:
            return {
                ...state,
                isEditingNeed: true
            };
        case CONSTANTS.EDITED_HOMELESS_NEED:
            return {
                ...state,
                isEditingNeed: false,
                homelessNeeds: state.homelessNeeds.map((need) => {
                    if (need.id === action.newNeed.id) {
                        return {
                            ...need,
                            ...action.newNeed
                        };
                    }
                    return need;
                })
            };
        default:
            return state;
    }
}
