import { useState } from 'react';

import Card from '../../../../CaseManagement/components/Card';
import DocumentPreview from './DocumentPreview';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        fileInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
        },
        fileName: {
            fontSize: '1.75rem',
            fontWeight: 700
        },
        updated: {
            fontSize: '1rem',
            fontWeight: 300,
            color: '#A6A6BF'
        },
        chevronButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#7378E8',
            borderRadius: '20px',
            height: '32px',
            minWidth: '32px',
            '& i': {
                fontSize: '1rem',
                color: '#FFFFFF',
                fontWeight: 900
            }
        }
    })
);

type Props = {
    deleteFile: (fileId: number) => void;
    doc: {
        created_at: string;
        file_url: string;
        homeless_id: number;
        id: number;
        name: string;
        updated_at: string;
    };
};

const DocumentItem: React.FC<Props> = ({ deleteFile, doc }) => {
    const classes = useStyles();

    const { file_url, id, name, updated_at } = doc;

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const formatDate = (date: string) => {
        const dateObj = new Date(date);
        const month = dateObj.toLocaleString('default', { month: 'long' });
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        return `${day} ${month} ${year}`;
    };

    return (
        <>
            <Card
                active
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                onClick={() => setShowPreview(true)}
            >
                <div className={classes.fileInfoContainer}>
                    <span className={classes.fileName}>{name}</span>
                    <span className={classes.updated}>
                        Updated: {formatDate(updated_at)}
                    </span>
                </div>
                <span className={classes.chevronButton}>
                    <i className="fas fa-chevron-right" />
                </span>
            </Card>
            {showPreview && (
                <DocumentPreview
                    open={showPreview}
                    onClose={() => setShowPreview(false)}
                    onDelete={() => deleteFile(id)}
                    url={file_url}
                    fileName={name}
                />
            )}
        </>
    );
};

export default DocumentItem;
