import Card from '../../holder_profile/profile_2.0/CaseManagement/components/Card';

import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { createStyles, makeStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        settingCard: {
            margin: '0 auto',
            width: '100%'
        },
        title: {
            fontWeight: 800,
            marginBottom: 0
        },
        description: {
            color: '#A6A6BF',
            fontWeight: 300
        },
        cardBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            margin: '2rem 0',
            fontWeight: 300
        },
        settingItem: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        settingButton: {
            border: '1px solid #7378E8',
            borderRadius: '20px',
            color: '#7378E8',
            fontWeight: 700,
            marginLeft: '0.5rem',
            padding: '2px 12px',
            textTransform: 'none'
        }
    })
);

const PurpleSwitch = withStyles({
    switchBase: {
        color: '#FBFBFF',
        '&$checked': {
            color: '#7378E8'
        },
        '&$checked + $track': {
            backgroundColor: '#7378E8'
        }
    },
    checked: {},
    track: {}
})(Switch);

type CardTypeValues = {
    [key: string]: {
        title: string;
        description: string;
        fields: {
            button?: string;
            label: string;
            setter?: (value: boolean) => void;
            value: boolean | number | string;
        }[];
    };
};

type Props = {
    cardType: string;
    setToggle?: (value: boolean) => void;
    setUpdateLanguage?: (value: boolean) => void;
    toggle?: boolean;
    updateUserSettings?: (
        id: number,
        settings: {
            [key: string]: boolean | string;
        }
    ) => void;
    user: {
        id: number;
        email: string;
        frequency: number;
        holders_notifications_subscription: boolean;
        is_showing_intake_guides: boolean;
        language_preference: string;
        last_name: string;
        name: string;
        phone_number: string;
    };
};

const SettingsCard: React.FC<Props> = ({
    cardType,
    setToggle,
    setUpdateLanguage,
    toggle,
    updateUserSettings,
    user
}) => {
    const classes = useStyles();

    const {
        email,
        // frequency,
        name,
        holders_notifications_subscription,
        id,
        is_showing_intake_guides,
        language_preference,
        last_name,
        phone_number
    } = user;

    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ')-' + match[2] + '-' + match[3];
        }

        return 'Invalid phone number';
    };

    const handleToggle = () => {
        if (cardType === 'tips') {
            updateUserSettings &&
                updateUserSettings(id, { intakeGuide: !toggle });
        } else {
            // post request to update user.holders_notifications_subscription
        }

        setToggle && setToggle(!toggle);
    };

    const cardTypeValues: CardTypeValues = {
        account: {
            title: 'Account Information',
            description: 'Modify or delete information regarding your account',
            fields: [
                {
                    label: 'Name',
                    value: `${name} ${last_name}`
                },
                {
                    label: 'Email',
                    value: email
                },
                {
                    label: 'Phone',
                    value:
                        phone_number && phone_number.length
                            ? formatPhoneNumber(phone_number)
                            : 'Invalid phone number'
                },
                {
                    button: 'Reset',
                    label: 'Password',
                    setter: () =>
                        window.open(
                            'https://www.samaritan.city/users/password/new',
                            '_blank'
                        ),
                    value: '••••••'
                },
                {
                    button: 'Update',
                    label: 'Comm Language',
                    setter: (value: boolean) =>
                        setUpdateLanguage && setUpdateLanguage(value),
                    value:
                        language_preference && language_preference.length
                            ? language_preference.charAt(0).toUpperCase() +
                              language_preference.slice(1)
                            : 'English'
                }
            ]
        },
        notifications: {
            title: 'Notifications',
            description:
                'Adjust the settings regarding your notification preferences',
            fields: [
                {
                    label: 'Can receive notifications?',
                    value: holders_notifications_subscription
                }
                // {
                //     label: 'Frequency',
                //     value: frequency
                // }
            ]
        },
        tips: {
            title: 'Tips & Guide',
            description:
                'Toggle the settings to receive on-the-spot tips and step-by-step guidance',
            fields: [
                {
                    label: 'Member Registration Guide',
                    value: is_showing_intake_guides
                        ? is_showing_intake_guides
                        : true
                }
                // {
                //     label: 'General Tool Tips',
                //     value: true
                // }
            ]
        }
    };

    return (
        <div className={classes.settingCard}>
            <Card
                style={{
                    margin: '24px auto',
                    maxWidth: '600px'
                }}
            >
                <h2 className={classes.title}>
                    {cardTypeValues[cardType].title}
                </h2>
                <span className={classes.description}>
                    {cardTypeValues[cardType].description}
                </span>
                <div className={classes.cardBody}>
                    {cardTypeValues[cardType].fields.map((field, index) => (
                        <div className={classes.settingItem} key={index}>
                            <span>{field.label}</span>
                            <span>
                                {field.value === true ||
                                field.value === false ? (
                                    <PurpleSwitch
                                        checked={toggle}
                                        onChange={() => handleToggle()}
                                        name={field.label}
                                    />
                                ) : (
                                    <>
                                        {field.value}
                                        {field.button && field.setter && (
                                            <Button
                                                className={
                                                    classes.settingButton
                                                }
                                                onClick={() =>
                                                    field.setter &&
                                                    field.setter(true)
                                                }
                                            >
                                                {field.button}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default SettingsCard;
