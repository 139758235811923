import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';

import LoadingCircle from 'components/_shared/LoadingCircle';

import { sendGoalActionReminder } from 'redux/actions/goal_actions';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        reminderContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EDEDFA',
            color: '#1F2152',
            fontSize: '20px',
            fontWeight: 800,
            lineHeight: '27px',
            padding: '24px 0',
            width: '100%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1F2152',
            gap: '4px',
            fontSize: '16px',
            padding: '36px'
        },
        action: {
            fontWeight: 800
        },
        reminderBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(180deg, #8571DF 0%, #7378E8 100%)',
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            margin: '10px 0 30px 0',
            padding: '16px 40px',
            textTransform: 'none',
            '& i': {
                fontSize: '20px',
                marginRight: '12px'
            }
        }
    })
);

type ReminderProps = {
    action: string;
    catchUpItemID?: number;
    id: number;
    name: string;
    setPopUpOpen?: (open: boolean) => void;
    setReminder: (reminder: boolean) => void;
};

const Reminder: React.FC<ReminderProps> = ({
    action,
    catchUpItemID,
    id,
    name
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSendReminder = async (id: number) => {
        try {
            setLoading(true);

            dispatch(sendGoalActionReminder(id, catchUpItemID));

            setSuccess(true);
        } catch (error) {
            console.log(error);
            setSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    const renderSuccess = () => {
        if (success) {
            return (
                <>
                    <i
                        className="fas fa-check-circle"
                        style={{ color: '#7FE8B2' }}
                    />
                    Reminder sent!
                </>
            );
        } else {
            return (
                <>
                    <i className="fas fa-bell" />
                    Send Reminder
                </>
            );
        }
    };

    return (
        <div className={classes.reminderContainer}>
            <div className={classes.header}>Send {name} a reminder</div>
            <div className={classes.body}>
                Remind {name} about their action step to:{' '}
                <span className={classes.action}>
                    <ReactMarkdown>{action}</ReactMarkdown>
                </span>
            </div>
            <Button
                className={classes.reminderBtn}
                onClick={() => handleSendReminder(id)}
            >
                {loading ? <LoadingCircle /> : renderSuccess()}
            </Button>
        </div>
    );
};

export default Reminder;
