import PropTypes from 'prop-types';

import { formatDate, getColor } from '../utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    statusCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '16px',
        boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
        padding: '20px 30px',
        margin: '0 10px 15px 10px',
        position: 'relative',
        cursor: 'pointer',
        '&:last-child': {
            marginBottom: '0'
        },
        '&:active': {
            transform: 'scale(0.98)'
        }
    },
    statusTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },
    score: {
        fontWeight: '800',
        fontSize: '12px',
        lineHeight: '14px',
        height: '24px',
        width: '24px',
        flexShrink: '0',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    date: {
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#A6A6BF',
        paddingTop: '10px'
    },
    infoIconContainer: {
        position: 'absolute',
        top: '8px',
        right: '12px'
    },
    infoIcon: {
        color: '#FFFFFF',
        backgroundColor: '#999DFF',
        padding: '3px 6px',
        borderRadius: '100%',
        fontSize: '10px',
        cursor: 'pointer'
    }
}));

const StatusCard = ({
    questionOptions,
    setClassifiedInfo,
    setUpdateStatusInfo,
    status: {
        answer,
        answer_index,
        classified_statuses,
        created_at,
        id,
        question_type,
        // relations,
        answer_slider_option
    },
    statusCardRef
}) => {
    const classes = useStyles();

    const date = new Date(created_at);
    const unclassifiedStatus =
        classified_statuses &&
        !classified_statuses[0].classified_status_answer_matches.length;
    const oldStatus = question_type !== 'StatusQuestion';

    const getScoreColor = () => {
        const score =
            question_type === 'StatusQuestion'
                ? renderScore() - 1
                : answer_index;
        const backgroundColor = getColor(
            score,
            'secondary',
            questionOptions.length
        );

        if (isNaN(score)) {
            return {
                background: '#ededfa',
                color: '#a6a6bf',
                fontWeight: '800'
            };
        }

        const color = getColor(score, 'primary', questionOptions.length);

        return { backgroundColor, color };
    };

    const renderAnswer = () => {
        if (question_type === 'StatusQuestion') {
            return answer;
        }

        if (oldStatus && answer_slider_option) {
            return (
                answer_slider_option[0]?.description ||
                'No description for this answer'
            );
        }

        return questionOptions[answer_index]?.description;
    };

    const renderScore = () => {
        if (question_type === 'StatusQuestion') {
            const answers =
                classified_statuses[0].classified_status_answer_matches;

            if (answers.length) {
                const answer = answers.reduce((prev, curr) => {
                    if (curr.rank > prev.rank) {
                        return curr;
                    }
                    return prev;
                });

                return answer.rank;
            } else {
                return '?';
            }
        }

        if (oldStatus && answer) {
            return answer_index + 1;
        } else {
            return '?';
        }
    };

    const showInfo = () => {
        if (unclassifiedStatus) {
            setClassifiedInfo(true);
        }

        if (oldStatus) {
            setUpdateStatusInfo(true);
        }
    };

    return (
        <div
            className={classes.statusCard}
            id={`status-card-${id}`}
            ref={statusCardRef}
            onClick={() => showInfo()}
        >
            {unclassifiedStatus && (
                <div className={classes.infoIconContainer}>
                    <i className={`${classes.infoIcon} fas fa-info`} />
                </div>
            )}
            <div className={classes.statusTitle}>
                <span className={classes.score} style={getScoreColor()}>
                    {renderScore()}
                </span>
                <span>{renderAnswer()}</span>
            </div>
            <div className={classes.date}>{formatDate(date)}</div>
        </div>
    );
};

StatusCard.propTypes = {
    questionOptions: PropTypes.array.isRequired
    // status: PropTypes.shape({
    //     answer_index: PropTypes.number.isRequired,
    //     created_at: PropTypes.string.isRequired
    // })
};

export default StatusCard;
