import Card from '../../../../_shared/Card';
import PhotoBorder from 'assets/border-photo.svg';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        cardStyle: {
            marginTop: '50px',
            width: '100%',
            '@media (max-width: 768px)': {
                margin: '50px 0 0 0',
                padding: '0'
            }
        },
        contentWrapper: {
            padding: '20px',
            width: '100%',
            '@media (max-width: 768px)': {
                width: '80%'
            }
        },
        title: {
            color: '#1F1F51',
            fontSize: '1.25rem',
            fontWeight: 600,
            letterSpacing: '0.15em',
            marginBottom: '16px'
        },
        photoCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${PhotoBorder})`,
            borderRadius: '100%',
            height: '180px',
            margin: '10px auto',
            width: '180px',
            '& i': {
                color: '#7378E8',
                fontSize: '40px',
                paddingBottom: '10px'
            },
            '& span': {
                alignSelf: 'center',
                fontFamily: 'Manrope',
                fontSize: '18px',
                fontWeight: 800,
                color: '#7378E8'
            }
        },
        photoContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }
    })
);

const ProfilePhoto = () => {
    const classes = useStyles();

    return (
        <Card className={classes.cardStyle}>
            <div className={classes.contentWrapper}>
                <span className={classes.title}>Profile Photo</span>
                <div className={classes.photoCircle}>
                    <div className={classes.photoContainer}>
                        <i className="far fa-camera" />
                        <span>Upload a photo</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ProfilePhoto;
