import { useState } from 'react';
import ReactGA from 'react-ga4';

import Card from '../../../CaseManagement/components/Card';
import PopUp from '../../../PopUp';
import QuestionBody from './QuestionBody';

type Props = {
    answers: [
        { answer: string; created_at: string; touchpoint_question_id: number }
    ];
    homelessID: number;
    prompt: string;
    questionID: number;
};

const QuestionCard: React.FC<Props> = ({
    answers,
    homelessID,
    prompt,
    questionID
}) => {
    const [addAnswer, setAddAnswer] = useState(false);

    const handleAddClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Add Supplemental Answer Click',
            label: `Answering ${prompt}`
        });

        setAddAnswer(true);
    };

    const answer: { answer: string; created_at: string } | undefined = answers
        ? answers.find(
              (answer: { touchpoint_question_id: number }) =>
                  answer.touchpoint_question_id === questionID
          )
        : undefined;

    return (
        <Card
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '360px'
            }}
        >
            <QuestionBody
                answer={answer}
                handleAddClick={handleAddClick}
                prompt={prompt}
                promptRefresh={false}
                setAddAnswer={setAddAnswer}
            />
            {addAnswer && (
                <PopUp
                    content="supplemental question"
                    answer={answer?.answer}
                    handleModalClick={() => setAddAnswer(false)}
                    id={homelessID}
                    prompt={prompt}
                    questionID={questionID}
                />
            )}
        </Card>
    );
};

export default QuestionCard;
