export const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();

    return `${month} ${day}`;
};
