import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { snoozeItemRequest } from 'redux/actions/catch_up_items';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionButton: {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            borderRadius: 100,
            boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#1F2152 !important',
            fontSize: '16px',
            fontWeight: 600,
            padding: '16px 24px',
            textTransform: 'none',
            width: '100%',
            '& i': {
                position: 'absolute',
                left: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '12px',
                color: '#FFFFFF',
                fontSize: '12px',
                height: '24px',
                width: '24px'
            }
        },
        disabled: {
            background: '#D9D9D9',
            color: '#FFFFFF !important'
        }
    })
);

type ActionButtonProps = {
    animationDone: boolean;
    buttonStyle: string;
    buttonType: string;
    drawerOpen: boolean;
    isLoading: boolean;
    itemID: number;
    itemType: string;
    quickCatchUp: boolean;
    setCatchUpItemIndex: any;
    setCompleteAS: (completeAS: boolean) => void;
    setDebitAssign: (debitAssign: boolean) => void;
    setDebitRedeem: (debitRedeem: boolean) => void;
    setDebitTransfer: (debitTransfer: boolean) => void;
    setEditAS: (editAS: boolean) => void;
    setRemindAS: (remindAS: boolean) => void;
    setSummaryState: any;
    updateGoalActionStatus: (
        id: number,
        status: 'completed',
        catchUpItemID?: number
    ) => void;
};

const ActionButton: React.FC<ActionButtonProps> = ({
    animationDone,
    buttonStyle,
    buttonType,
    drawerOpen,
    isLoading,
    itemID,
    itemType,
    quickCatchUp,
    setCatchUpItemIndex,
    setCompleteAS,
    setDebitAssign,
    setDebitRedeem,
    setDebitTransfer,
    setEditAS,
    setRemindAS,
    setSummaryState
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const buttonClasses = classNames(classes.actionButton, buttonStyle, {
        [classes.disabled]: isLoading
    });

    const inputTypeToAddInfoType = {
        no_contact_info: 'contact_info',
        no_date_of_birth: 'birthday',
        no_full_name: 'full_name',
        no_insurance_id: 'member_id'
    } as {
        [key: string]: string;
    };

    const renderAddButtonType = () => {
        switch (itemType) {
            case 'no_contact_info':
                return 'Add contact details';
            case 'no_date_of_birth':
                return 'Add date of birth';
            case 'no_full_name':
                return 'Add full Name';
            case 'no_insurance_id':
                return 'Add insurance ID';
            case 'no_profile_photo':
                return 'Take picture';
            case 'no_status_capture_in_last_30_days':
                return 'Add updates';
            case 'no_supplemental_answers':
                return 'Add fun fact';
            default:
                return 'Incorrect action button type';
        }
    };

    const handleButtonClick = () => {
        switch (buttonType) {
            case 'add':
                setSummaryState((prevState: any) => ({
                    ...prevState,
                    infoModal: true,
                    infoType: inputTypeToAddInfoType[itemType]
                }));

                if (
                    itemType === 'no_profile_photo' ||
                    itemType === 'no_status_capture_in_last_30_days' ||
                    itemType === 'no_supplemental_answers'
                ) {
                    setSummaryState((prevState: any) => ({
                        ...prevState,
                        popUpOpen: true
                    }));
                }

                break;
            case 'assessment':
                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            case 'assign':
                setDebitAssign(true);
                setDebitRedeem(false);
                setDebitTransfer(false);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            case 'complete':
                setCompleteAS(true);
                setEditAS(false);
                setRemindAS(false);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            case 'edit':
                setCompleteAS(false);
                setEditAS(true);
                setRemindAS(false);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            case 'redeem':
                const currentRoute = history.location.pathname;

                setDebitAssign(false);
                setDebitRedeem(true);
                setDebitTransfer(false);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    drawerOpen: false
                }));
                history.push(`${currentRoute}/redeem`, {
                    from: 'catchUp',
                    id: itemID
                });
                break;
            case 'reminder':
                setCompleteAS(false);
                setEditAS(false);
                setRemindAS(true);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            case 'skip':
                setSummaryState((prevState: any) => ({
                    ...prevState,
                    clickedItem: buttonType,
                    drawerOpen: false,
                    overlayType: 'skip',
                    removeItem: true
                }));

                setTimeout(() => {
                    dispatch(snoozeItemRequest(itemID));

                    if (quickCatchUp) {
                        setCatchUpItemIndex(
                            (prevIndex: number) => prevIndex + 1
                        );
                    }
                }, 2000);
                break;
            case 'transfer':
                setDebitAssign(false);
                setDebitRedeem(false);
                setDebitTransfer(true);

                setSummaryState((prevState: any) => ({
                    ...prevState,
                    popUpOpen: true
                }));
                break;
            default:
                console.log('Incorrect Action Button Type');
        }
    };

    const buttons = {
        add: {
            backgroundColor: '#999DFF',
            button: 'fas fa-pencil',
            title: `${renderAddButtonType()}`
        },
        assessment: {
            backgroundColor: '#999DFF',
            button: 'fas fa-clipboard-list',
            title: 'Complete assessment'
        },
        assign: {
            backgroundColor: '#3C8DB0',
            button: 'fas fa-credit-card',
            title: 'Assign debit card'
        },
        complete: {
            backgroundColor: '#36D993',
            button: 'fas fa-check',
            title: 'Mark as complete'
        },
        edit: {
            backgroundColor: '#999DFF',
            button: 'fas fa-pencil',
            title: 'Edit action step'
        },
        redeem: {
            backgroundColor: '#999DFF',
            button: 'fas fa-clipboard-list',
            title: 'Purchase request'
        },
        reminder: {
            backgroundColor: '#E8BE7F',
            button: 'fas fa-bell',
            title: 'Send reminder'
        },
        skip: {
            backgroundColor: '#E87F7F',
            button: 'fas fa-arrow-right',
            title: 'Skip catch-up item'
        },
        transfer: {
            backgroundColor: '#3C8DB0',
            button: 'fas fa-credit-card',
            title: 'Transfer to debit card'
        }
    } as {
        [key: string]: {
            backgroundColor: string;
            button: string;
            title: string;
        };
    };

    return (
        <Button
            className={buttonClasses}
            disabled={!animationDone || !drawerOpen || isLoading}
            onClick={handleButtonClick}
        >
            <i
                className={buttons[buttonType].button}
                style={{ backgroundColor: buttons[buttonType].backgroundColor }}
            />{' '}
            {buttons[buttonType].title || 'Incorrect action button type'}
        </Button>
    );
};

export default ActionButton;
