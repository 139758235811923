import CONSTANTS from '../constants/constants';

const initialState = {
    externalContext: ``,
    internalContext: ``,
    redeemStatus: null,
    route: null,
    sdohStatus: null,
    successStatusActive: true,
    unenrollStatus: `SUCCESSFUL`,
};

const unenrollment_reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CONSTANTS.RESET_UNENROLLMENT_STATE:
            return initialState;
        case CONSTANTS.SET_UNENROLLMENT_REDEEM_STATUS:
            return {
                ...state,
                redeemStatus: action.payload.redeemStatus
            };
        case CONSTANTS.SET_UNENROLLMENT_SDOH_STATUS:
            return {
                ...state,
                sdohStatus: action.payload.sdohStatus
            };
        case CONSTANTS.SET_UNENROLLMENT_STATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default unenrollment_reducer;
