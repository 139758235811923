import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
            backgroundColor: '#FFFFFF',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#1F2152',
            cursor: 'pointer',
            height: 'auto',
            maxWidth: '500px',
            padding: '10px 0',
            width: '100%',
            '&:active': {
                transform: 'scale(0.98)'
            }
        },
        category: {
            fontSize: '20px',
            fontWeight: 800,
            lineHeight: '29px',
            padding: '10px 20px',
            textAlign: 'center'
        },
        thumbnail: {
            display: 'flex',
            alignItems: 'center',
            height: '100px',
            overflow: 'hidden',
            padding: '10px',
            '& img': {
                height: '100px'
            }
        },
        title: {
            padding: '10px 20px',
            textAlign: 'center'
        }
    })
);

type RedemptionCardProps = {
    category: string;
    thumbnail: string;
    title: JSX.Element | string;
    onClick?: () => void;
};

const RedemptionCard: React.FC<RedemptionCardProps> = ({
    category,
    thumbnail,
    title,
    onClick
}) => {
    const classes = useStyles();

    return (
        <div className={classes.card} onClick={onClick}>
            <span className={classes.category}>{category}</span>
            <div className={classes.thumbnail}>
                <img src={thumbnail} alt={`${category}-icon`} />
            </div>
            {typeof title === 'string' ? (
                <span className={classes.title}>{title}</span>
            ) : (
                title
            )}
        </div>
    );
};

export default RedemptionCard;
