import ProfileBanner from 'assets/profile-banner.png';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        banner: {
            height: '107px',
            width: '100%',
            objectFit: 'cover'
        },
        hand: {
            position: 'absolute',
            left: '124px',
            top: '-72px',
            height: '32px',
            width: '32px',
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '44px',
            transform: 'rotate(-17.02deg)'
        },
        iconButton: {
            position: 'absolute',
            top: '-60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            backgroundColor: '#E3E3FA',
            border: '2px solid #FFFFFF',
            borderRadius: '60px',
            fontSize: '32px',
            height: '90px',
            width: '90px'
        },
        insurance: {
            fontSize: '13px',
            fontWeight: 500
        },
        name: {
            fontSize: '24px',
            fontWeight: 700
        },
        memberDetailsContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            color: '#1F2152',
            paddingTop: '24px',
            width: '100%'
        },
        outline: {
            position: 'absolute',
            left: '4px',
            top: '-72px',
            border: '3px dashed #FFD351',
            borderRadius: '50%',
            height: '106px',
            width: '109px'
        },
        overlay: {
            position: 'absolute',
            bottom: 0,
            background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 53.5%, #FFFFFF 100%)',
            borderRadius: '0px 0px 16px 16px',
            height: '110px',
            // width: '345px'
            width: '100%'
        },
        photoContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            padding: '16px'
        }
    })
);

type PhotoProps = {
    insurance: null | string;
    name: string;
    setPhotoPopup: (value: boolean) => void;
};

const Photo: React.FC<PhotoProps> = ({ insurance, name, setPhotoPopup }) => {
    const classes = useStyles();

    return (
        <>
            <img className={classes.banner} src={ProfileBanner} alt="header" />
            <div className={classes.photoContainer}>
                <span className={classes.outline} />
                <Button
                    className={classes.iconButton}
                    onClick={() => setPhotoPopup(true)}
                >
                    📷
                </Button>
                <span className={classes.hand}>👈</span>
                <div className={classes.memberDetailsContainer}>
                    <span className={classes.name}>{name}</span>
                    <span className={classes.insurance}>
                        Insurance ID: {insurance ? insurance : '-'}
                    </span>
                </div>
                <span className={classes.overlay} />
            </div>
        </>
    );
};

export default Photo;
