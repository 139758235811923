import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useTransition, animated, config, useSpring } from 'react-spring';
import { useLocalStorage, useMeasure } from 'react-use';

import classes from './index.module.scss';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { CityButtons } from './CityButtons';
import { LoginInputs } from './LoginInputs';

import SignUpInputs from './SignUpInputs';

import { fetchOrganizationsByCity } from '../../../api/organization';

type LoginFormProps = {
    cities: any[];
    loading: boolean;
    loginError: boolean;
    signUpError: boolean;
    onSubmit: (credentials: { email: string; password: string }) => void;
    onWide: () => void;
    // onSignUp: (credentials: {
    //     email: string;
    //     password: string;
    //     passwordConfirmation: string;
    //     name: string;
    //     lastName: string;
    //     orgId: number | null;
    //     joinCode: string;
    //     phoneNumber: string;
    // }) => void;
    onSignUp: any;
};

const LoginForm = ({
    cities,
    loading,
    loginError,
    onSubmit,
    onSignUp,
    onWide,
    signUpError
}: LoginFormProps) => {
    const isFirstRender = useRef(true);

    const [city, setCity] = useLocalStorage('samaritan::city', {
        name: '',
        subdomain_name: ''
    });
    const [inputsVisible, setInputsVisible] = useState(false);
    const [stage, setStage] = useState('cities');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // sign up state
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [orgName, setOrgName] = useState('');
    const [orgList, setOrgList] = useState([]);
    const [orgId, setOrgId] = useState<null | number>(null);
    const [joinCode, setJoinCode] = useState('');

    useEffect(() => {
        // should only switch to inputs if city exists and the component is in view
        if (city?.name && inputsVisible) {
            if (onWide) onWide();
            setStage('inputs');
        }
    }, [city, inputsVisible, onWide]);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (!city?.subdomain_name || !city) return;

            fetchOrganizationsByCity(city.subdomain_name)
                .then((res) => res.json())
                .then((data) => setOrgList(data.organizations));
        } else {
            isFirstRender.current = false;
        }
    }, [city]);

    const passwordIsValid = useCallback((password: string) => {
        const passwordHasNumberOrSymbol = () => {
            const regex = /[0-9!@#$%^&*]/g;
            return regex.test(password);
        };

        const passwordHasUppercase = () => {
            const regex = /(?=.*[A-Z])(?=.*[a-z])/;
            return regex.test(password);
        };

        return (
            password.length >= 8 &&
            passwordHasNumberOrSymbol() &&
            passwordHasUppercase()
        );
    }, []);

    const citySelector = useMemo(
        () => (
            <ReactVisibilitySensor onChange={setInputsVisible} active={true}>
                <CityButtons
                    setCity={setCity}
                    setStage={setStage}
                    cities={cities}
                    onWide={onWide}
                />
            </ReactVisibilitySensor>
        ),
        [cities, onWide, setCity]
    );

    const inputsRef = useRef(null);

    const inputs = useMemo(
        () => (
            <LoginInputs
                name={city?.name}
                subdomain={city?.subdomain_name}
                setCity={setCity}
                stage={stage}
                setStage={setStage}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                inputsRef={inputsRef}
                loading={loading}
                error={loginError}
            />
        ),
        [
            city?.name,
            city?.subdomain_name,
            setCity,
            stage,
            email,
            password,
            loading,
            loginError
        ]
    );

    const signUpInputs = useMemo(
        () => (
            <SignUpInputs
                {...{
                    inputsRef,
                    setStage,
                    cityName: city?.name,
                    setCity,
                    email,
                    setEmail,
                    password,
                    setPassword,
                    passwordConfirmation,
                    setPasswordConfirmation,
                    name,
                    setName,
                    lastName,
                    setLastName,
                    phoneNumber,
                    setPhoneNumber,
                    orgList,
                    orgName,
                    setOrgName,
                    orgId,
                    setOrgId,
                    joinCode,
                    setJoinCode,
                    passwordIsValid,
                    loading,
                    error: signUpError
                }}
            />
        ),
        [
            city?.name,
            email,
            lastName,
            loading,
            name,
            orgList,
            orgName,
            orgId,
            joinCode,
            password,
            passwordConfirmation,
            phoneNumber,
            setCity,
            signUpError,
            passwordIsValid
        ]
    );

    const citySelectorTransition = useTransition(stage === 'cities', {
        from: { opacity: 0 },
        initial: { opacity: 1 },
        enter: { opacity: 1, zIndex: 1 },
        leave: { opacity: 0, zIndex: 0 },
        config: {
            ...config.stiff,
            clamp: true
        },
        unique: true
    });

    // signin inputs an signup inputs use the same animation
    const inputsTransition = useTransition(
        stage === 'inputs' || stage === 'signUp',
        {
            from: {
                transform: 'translateX(50%)',
                opacity: 0
            },
            enter: {
                transform: 'translateX(-50%)',
                opacity: 1,
                zIndex: 1,
                left: '50%'
            },
            leave: {
                transform: 'translateX(20%)',
                opacity: 0,
                zIndex: 0
            },
            config: {
                ...config.stiff,
                clamp: true
            },
            unique: true
        }
    );

    const onSubmitCallback = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;

        if (stage === 'signUp') {
            return onSignUp({
                email,
                password,
                passwordConfirmation,
                name,
                lastName,
                orgId,
                joinCode,
                phoneNumber
            });
        }
        return onSubmit({ email, password });
    };

    // make the height and width use spring
    const [measureRef, { height, width }] = useMeasure();
    const formStyle = useSpring({
        height,
        width: width || 200,
        config: { clamp: true, tension: 300 }
    });

    return (
        <animated.form
            className={classes.form}
            onSubmit={onSubmitCallback}
            autoComplete="off"
            style={formStyle}
        >
            {citySelectorTransition(
                (props, item) =>
                    item && (
                        <animated.div style={props} ref={measureRef as any}>
                            {citySelector}
                        </animated.div>
                    )
            )}
            {inputsTransition(
                stage === 'signUp'
                    ? (props, item) =>
                          item && (
                              <animated.div
                                  style={props}
                                  key={'a'}
                                  className={classes.animatedForm}
                                  ref={measureRef as any}
                              >
                                  {signUpInputs}
                              </animated.div>
                          )
                    : (props, item) =>
                          item && (
                              <animated.div
                                  style={props}
                                  key={'a'}
                                  className={classes.animatedForm}
                                  ref={measureRef as any}
                              >
                                  {inputs}
                              </animated.div>
                          )
            )}
        </animated.form>
    );
};

export default LoginForm;
