import CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import * as api from 'api/touchpointAnswers';
import { fetchQOLMeasures } from 'api/quality_of_life_measures';

import {
    catchUpItemFailure,
    catchUpItemSubmit,
    catchUpItemSuccess,
    completeItemRequest
} from './catch_up_items';
import { updateProfileCompletedItems } from './homeless';

export function getTouchpointAnswers() {
    return {
        type: CONSTANTS.GET_TOUCHPOINT_ANSWERS
    };
}

function getTouchpointAnswersById() {
    return {
        type: CONSTANTS.GET_TOUCHPOINT_ANSWERS_BY_ID
    };
}

const getSupplementalQuestionAnswers = () => {
    return {
        type: CONSTANTS.GET_SUPPLEMENTAL_QUESTION_ANSWERS
    };
};

// fix any
function setTouchpointAnswers(touchpointAnswers: any) {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_ANSWERS,
        payload: touchpointAnswers
    };
}

function setTouchpointAnswersById(touchpointAnswers: any) {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_ANSWERS_BY_ID,
        payload: touchpointAnswers
    };
}

const setSupplementalQuestionAnswers = (answers: object) => {
    return {
        type: CONSTANTS.SET_SUPPLEMENTAL_QUESTION_ANSWERS,
        payload: answers
    };
};

export const updateSupplementalQuestionAnswers = (answer: object) => {
    return {
        type: CONSTANTS.UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS,
        payload: answer
    };
};

export function updateTouchpointAnswers(answers: any) {
    return {
        type: CONSTANTS.UPDATE_TOUCHPOINT_ANSWERS,
        payload: {
            answers
        }
    };
}

export function resetTouchpointAnswers() {
    return {
        type: CONSTANTS.RESET_TOUCHPOINT_ANSWERS
    };
}

export function incrementImprovedStatusCount() {
    return {
        type: CONSTANTS.INCREMENT_IMPROVED_STATUS_COUNT
    };
}

export function decrementImprovedStatusCount() {
    return {
        type: CONSTANTS.DECREMENT_IMPROVED_STATUS_COUNT
    };
}

export function setImprovedTouchpointStatus(status: object) {
    return {
        type: CONSTANTS.SET_IMPROVED_TOUCHPOINT_STATUS,
        payload: status
    };
}

export function setReducedTouchpointStatus(status: object) {
    return {
        type: CONSTANTS.SET_REDUCED_TOUCHPOINT_STATUS,
        payload: status
    };
}

function submittingSupplementalQuestionAnswer() {
    return {
        type: CONSTANTS.SUBMITTING_SUPPLEMENTAL_QUESTION_ANSWER
    };
}

function submitTouchpointAnswers() {
    return {
        type: CONSTANTS.SUBMIT_TOUCHPOINT_ANSWERS
    };
}

function submitStatusCapture() {
    return {
        type: CONSTANTS.SUBMIT_STATUS_CAPTURE
    };
}

export function getTouchpointAnswersRequest(
    _token: string,
    tpQuestionId: string,
    page = 1
) {
    return async (dispatch: Function) => {
        dispatch(getTouchpointAnswers());
        return api
            .fetchTouchpointAnswers(tpQuestionId, page)
            .then((response) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson) => {
                dispatch(setTouchpointAnswers(responseJson.touchpoint_answers));
            })
            .catch((error) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}

export function getTouchpointAnswersRequest2(
    homelessId: number,
    questionIds: number[],
    qolIds: number[]
) {
    return async (dispatch: Function) => {
        dispatch(getTouchpointAnswers());

        try {
            const promises = qolIds.map((id, index) => {
                return fetchQOLMeasures(id, homelessId)
                    .then((response: object) =>
                        handleSamaritanApiResponse(response, dispatch)
                    )
                    .then(
                        (responseJson: {
                            touchpoint_answers: {
                                quality_of_life_measure_id: number;
                                touchpoint_question_id: number;
                            }[];
                        }) => {
                            const touchpointAnswers =
                                responseJson.touchpoint_answers;

                            touchpointAnswers.map((answer) => {
                                answer.quality_of_life_measure_id = id;
                                return answer;
                            });

                            if (touchpointAnswers.length > 1) {
                                return {
                                    answers: [...touchpointAnswers],
                                    quality_of_life_measure_id: id,
                                    touchpoint_question_id:
                                        touchpointAnswers[0]
                                            .touchpoint_question_id
                                };
                            } else if (touchpointAnswers.length === 1) {
                                return {
                                    ...touchpointAnswers[0],
                                    quality_of_life_measure_id: id,
                                    touchpoint_question_id:
                                        touchpointAnswers[0]
                                            .touchpoint_question_id
                                };
                            } else {
                                return {
                                    answer: null,
                                    answer_index: 0,
                                    quality_of_life_measure_id: id,
                                    touchpoint_question_id: questionIds[index]
                                };
                            }
                        }
                    )
                    .catch((error: any) =>
                        handleSamaritanApiError(error, dispatch)
                    );
            });

            const answers = await Promise.all(promises);
            const noAnswers = answers.every(
                (answer: { answer: string }) => answer.answer === null
            );

            if (noAnswers) {
                dispatch(setTouchpointAnswers([]));
            } else {
                dispatch(setTouchpointAnswers(answers));
            }
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
}

export function getTouchpointAnswersByIdRequest(
    homelessId: number,
    questionId: number
) {
    return async (dispatch: Function) => {
        dispatch(getTouchpointAnswersById());

        try {
            const response = await fetchQOLMeasures(questionId, homelessId);

            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );
            const touchpointAnswers = responseJson.touchpoint_answers;
            touchpointAnswers.map(
                (answer: { quality_of_life_measure_id: number }) => {
                    answer.quality_of_life_measure_id = questionId;
                    return answer;
                }
            );

            const touchpointAnswerWithID = [...touchpointAnswers];

            if (touchpointAnswers.length) {
                dispatch(
                    setTouchpointAnswersById({
                        [touchpointAnswers[0].touchpoint_question_id]:
                            touchpointAnswers,
                        [questionId]: touchpointAnswerWithID,
                        quality_of_life_measure_id: questionId
                    })
                );
            } else {
                dispatch(
                    setTouchpointAnswersById({
                        [questionId]: []
                    })
                );
            }
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
}

export const submitTouchpointAnswer = (
    answer: {
        quality_of_life_measure_id: number;
    },
    catchUpItemID?: number
) => {
    return async (dispatch: Function) => {
        dispatch(submitTouchpointAnswers());

        if (catchUpItemID) {
            dispatch(catchUpItemSubmit(catchUpItemID));
        }

        try {
            const response = await api.submitTouchpointAnswer(answer);
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            responseJson.touchpoint_answer.quality_of_life_measure_id =
                answer.quality_of_life_measure_id;

            dispatch(updateTouchpointAnswers(responseJson.touchpoint_answer));

            if (catchUpItemID) {
                dispatch(catchUpItemSuccess(catchUpItemID));

                setTimeout(() => {
                    dispatch(completeItemRequest(catchUpItemID));
                }, 2500);
            }
        } catch (error: any) {
            if (catchUpItemID) {
                dispatch(catchUpItemFailure(catchUpItemID));
            }

            handleSamaritanApiError(error, dispatch);
        }
    };
};

export const submitStatusCaptureRequest = (
    id: number,
    answer: [],
    catchUpItemID?: number
) => {
    return async (dispatch: Function) => {
        dispatch(submitStatusCapture());

        if (catchUpItemID) {
            dispatch(catchUpItemSubmit(catchUpItemID));
        }

        try {
            const response = await api.submitStatusCapture(id, answer);
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );
            const answers = responseJson.touchpoint.answers;

            const updatedAnswers = answers.map(
                (submittedAnswer: {
                    question: {
                        id: number;
                        quality_of_life_measure_id: number;
                    };
                }) => {
                    return answer.map(
                        (ans: {
                            quality_of_life_measure_id: number;
                            touchpoint_question_id: number;
                        }) => {
                            if (
                                ans.touchpoint_question_id ===
                                submittedAnswer.question.id
                            ) {
                                submittedAnswer.question.quality_of_life_measure_id =
                                    ans.quality_of_life_measure_id;
                            }

                            return {
                                ...ans,
                                created_at: responseJson.touchpoint.created_at,
                                answer_index: null
                            };
                        }
                    );
                }
            );

            const flattenedUpdatedAnswers = updatedAnswers.flat();

            dispatch(updateTouchpointAnswers(flattenedUpdatedAnswers));

            if (catchUpItemID) {
                dispatch(catchUpItemSuccess(catchUpItemID));

                setTimeout(() => {
                    dispatch(completeItemRequest(catchUpItemID));
                }, 2500);
            }
        } catch (error: any) {
            if (catchUpItemID) {
                dispatch(catchUpItemFailure(catchUpItemID));
            }

            handleSamaritanApiError(error, dispatch);
        }
    };
};

export const getSupplementalQuestionAnswersRequest = (
    questionID: number[],
    homelessID: number
) => {
    return async (dispatch: Function) => {
        dispatch(getSupplementalQuestionAnswers());

        try {
            const promises = questionID.map(async (id) => {
                try {
                    const response = await api.fetchSupplementalQuestionAnswers(
                        id,
                        homelessID
                    );
                    const responseJson = await handleSamaritanApiResponse(
                        response,
                        dispatch
                    );

                    return responseJson.touchpoint_answers;
                } catch (error: any) {
                    return handleSamaritanApiError(error, dispatch);
                }
            });

            const answer = await Promise.all(promises);
            const answerArray = answer.flat();

            if (answer && answer.length > 0) {
                dispatch(setSupplementalQuestionAnswers(answerArray));
            } else {
                dispatch(setSupplementalQuestionAnswers([]));
            }
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);
        }
    };
};

export const submitSupplementalQuestionAnswerRequest = (
    answerData: object,
    setSuccess: (value: boolean) => void,
    catchUpID?: number,
    profileItemType?: string
) => {
    return async (dispatch: Function) => {
        dispatch(submittingSupplementalQuestionAnswer());

        if (catchUpID) {
            dispatch(catchUpItemSubmit(catchUpID));
        }

        try {
            const response = await api.submitSupplementalQuestionAnswer(
                answerData
            );
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            dispatch(
                updateSupplementalQuestionAnswers(
                    responseJson.touchpoint_answer
                )
            );

            if (profileItemType) {
                dispatch(updateProfileCompletedItems(profileItemType));
            } else {
                dispatch(updateProfileCompletedItems('supplemental_questions'));
            }

            if (catchUpID) {
                dispatch(catchUpItemSuccess(catchUpID));

                setTimeout(() => {
                    dispatch(completeItemRequest(catchUpID));
                }, 2500);
            }

            setSuccess(true);
        } catch (error: any) {
            handleSamaritanApiError(error, dispatch);

            if (catchUpID) {
                dispatch(catchUpItemFailure(catchUpID));
            }
        }
    };
};