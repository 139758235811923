import { useState } from 'react';

import ProfilePic from 'assets/webcam-ph.jpeg';
import BlurredPic from 'assets/webcam-ph-blurred.png';

import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            color: '#1F1F51',
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '33px'
        },
        subtitle: {
            color: '#1F1F51',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19px',
            margin: '12px 0'
        },
        filterContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            margin: '15px 0'
        },
        choiceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            '& i': {
                color: '#EDEDFA',
                fontSize: '24px'
            }
        },
        filterTitle: {
            color: '#1F1F51',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '21px'
        },
        filterPic: {
            borderRadius: '16px',
            height: '140px',
            objectFit: 'cover',
            width: '140px',
            '&:active': {
                transform: 'scale(0.95)'
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '@media (max-width: 768px)': {
                width: '120px',
                height: '120px'
            }
        },
        checkedIcon: {
            color: '#7378E8 !important'
        },
        circleIcon: {
            cursor: 'pointer',
            '&:active': {
                transform: 'scale(0.95)'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            paddingTop: '10px',
            '& i': {
                fontSize: '24px'
            }
        },
        uploadButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
            borderRadius: '36px',
            color: '#fff',
            fontSize: '16px',
            fontWeight: 700,
            padding: '0.5rem 4rem',
            textTransform: 'none'
        },
        disabledButton: {
            background: '#E3E3FA'
        }
    })
);

const BlurModal = ({
    setFilterType
}: {
    setFilterType: (filter: boolean) => void;
}) => {
    const classes = useStyles();

    const [blur, setBlur] = useState<boolean | null>(null);

    const renderButtonClass = () => {
        return blur !== null
            ? classes.uploadButton
            : `${classes.disabledButton} ${classes.uploadButton}`;
    };

    return (
        <>
            <span className={classes.title}>Take a member's picture</span>
            <span className={classes.subtitle}>
                If they'd like to opt for more privacy, please use the 'blur'
                option to mask their face in the picture
            </span>
            <div className={classes.filterContainer}>
                <div className={classes.choiceContainer}>
                    <span className={classes.filterTitle}>Regular</span>
                    <img
                        className={classes.filterPic}
                        src={ProfilePic}
                        alt="regular-profile"
                        onClick={() => setBlur(false)}
                    />
                    {blur === false ? (
                        <i
                            className={`fas fa-check-circle ${classes.checkedIcon}`}
                        />
                    ) : (
                        <i
                            className={`fal fa-circle ${classes.circleIcon}`}
                            onClick={() => setBlur(false)}
                        />
                    )}
                </div>
                <div className={classes.choiceContainer}>
                    <span className={classes.filterTitle}>Blurred</span>
                    <img
                        className={classes.filterPic}
                        src={BlurredPic}
                        alt="blurred-profile"
                        onClick={() => setBlur(true)}
                    />
                    {blur ? (
                        <i
                            className={`fas fa-check-circle ${classes.checkedIcon}`}
                        />
                    ) : (
                        <i
                            className={`fal fa-circle ${classes.circleIcon}`}
                            onClick={() => setBlur(true)}
                        />
                    )}
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <label htmlFor="filter-type">
                    <Button
                        className={renderButtonClass()}
                        component="span"
                        disabled={blur === null}
                        onClick={() => blur !== null && setFilterType(blur)}
                    >
                        Continue
                    </Button>
                </label>
            </div>
        </>
    );
};

export default BlurModal;
