import { connect } from 'react-redux';
import actions from '../redux/actions';
import Settings from '../components/app_settings';

const mapStateToProps = (state: any) => {
    const { user } = state.user;

    return {
        user
    };
};

const mapDispatchToProps = {
    setLayoutTitles: actions.setLayoutTitles,
    updateUserSettings: actions.updateUserSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
