import classes from './QuickAction.module.scss';

type QuickActionButtonProps = {
    children?: React.ReactNode;
    dark?: boolean;
    icon?: string;
    invert?: boolean;
    large?: boolean;
    light?: boolean;
    small?: boolean;
    solid?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const QuickActionButton = ({
    children,
    dark = false,
    solid = false,
    icon,
    invert = false,
    large = false,
    light = false,
    small = false,
    ...rest
}: QuickActionButtonProps) => {
    return (
        <button data-icon={icon} className={classes.quickAction} {...rest}>
            {children}
        </button>
    );
};

export default QuickActionButton;
