import CONSTANTS from "../constants/constants";

const initialState = {
    homelessTouchpoints: [],
    isFetchingHomelessTouchpoints: false
};

export default function touchpoints_reducer(state = initialState, action: any) {
  switch (action.type) {
      case CONSTANTS.GET_HOMELESS_TOUCHPOINTS:
          return {
              ...state,
              isFetchingHomelessTouchpoints: true
          };
      case CONSTANTS.SET_HOMELESS_TOUCHPOINTS:
          return {
              ...state,
              isFetchingHomelessTouchpoints: false,
              homelessTouchpoints: action.payload
          };
      // case CONSTANTS.GET_ORGANIZATION_TOUCHPOINTS:
      //   return {
      //     ...state,
      //     isFetchingOrganizationTouchpoints: true,
      //   };
      // case CONSTANTS.SET_ORGANIZATION_TOUCHPOINTS:
      //   return {
      //     ...state,
      //     isFetchingOrganizationTouchpoints: false,
      //     organizationTouchpoints: action.payload,
      //   };
      case CONSTANTS.RESET:
          return initialState;
      default:
          return state;
  }
}
