import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CTAButton2 } from '../../../../../_shared/buttons';
import PopUp from 'containers/PopUp';

import { numToDollars } from '../../../../../../util';
import beaconAsset from '../../../../../../assets/Illustration_Beacon.png';

import classes from './index.module.scss';

function RedeemDebrief({
    amount,
    transactionId,
    remainingBalance,
    category,
    title,
    closeView,
    name,
    photo
}) {
    const [showPopUp, setShowPopUp] = useState(true);

    return (
        <div className={classes.debrief}>
            <div className={classes.inner}>
                <img
                    src={beaconAsset}
                    alt="beacon"
                    className={classes.backgroundBeacon}
                />
                <div className={classes.portrait}>
                    <img src={photo} alt={name} />
                </div>
                <div className={classes.textContent}>
                    {numToDollars(amount)} was charged to {name} for {category}{' '}
                    - {title}
                    <div className={classes.transactionId}>
                        Transaction ID: {transactionId}
                    </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    Remaining Balance{' '}
                    <span className="font-xxl">
                        {numToDollars(remainingBalance)}
                    </span>
                </div>
                <div>
                    <CTAButton2 onClick={closeView}>
                        Return to Profile
                    </CTAButton2>
                </div>
            </div>
            {showPopUp && (
                <PopUp
                    content="update need"
                    amount={amount}
                    handleModalClick={() => setShowPopUp(false)}
                    name={name}
                />
            )}
        </div>
    );
}

RedeemDebrief.propTypes = {
    amount: PropTypes.number.isRequired,
    transactionId: PropTypes.string.isRequired,
    remainingBalance: PropTypes.number.isRequired,
    closeView: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    name: state.homeless.homelessInfo.name,
    photo: state.homeless.homelessInfo.photo
});

export default connect(mapStateToProps)(RedeemDebrief);
