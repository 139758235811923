import ReferCard from './ReferCard';

import { Caseload, Homeless } from 'types';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            paddingBottom: '20px',
            marginBottom: '24px',
            zIndex: 5
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            width: '100%',
            zIndex: 6
        },
        modalContent: {
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            margin: '24px',
            maxWidth: '420px',
            width: '100%',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        }
    })
);

type PopupReferMemberProps = {
    handleCloseClick: () => void;
    referCaseload: Caseload;
    referHolder: Homeless;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (caseloadID: number, memberID: number) => void;
};

const PopupReferMember: React.FC<PopupReferMemberProps> = ({
    handleCloseClick,
    referCaseload,
    referHolder,
    removeMemberFromMyMembers,
    saveMemberToMyMembers
}) => {
    const classes = useStyles();

    return (
        <Fade in={true}>
            <div className={classes.modal}>
                <div className={classes.modalContent}>
                    <ReferCard
                        handleCloseClick={handleCloseClick}
                        referCaseload={referCaseload}
                        referHolder={referHolder}
                        removeMemberFromMyMembers={removeMemberFromMyMembers}
                        saveMemberToMyMembers={saveMemberToMyMembers}
                    />
                </div>
                <div className={classes.btnContainer}>
                    <Button className={classes.btn} onClick={handleCloseClick}>
                        Close
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default PopupReferMember;
