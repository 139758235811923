import LoadingCircle from 'components/_shared/LoadingCircle';

import Card from './Card';

const LoadingCard = () => {
    return (
        <Card id="loading-card" backgroundColor="#FBFBFF">
            <LoadingCircle />
        </Card>
    );
};

export default LoadingCard;
