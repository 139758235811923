import React from "react";
import PropTypes from "prop-types";

import classes from "./index.module.scss";
import {
  CTAButton1,
  CTAButton2,
  SecondaryButton,
} from "../../../../../_shared/buttons";

export default function RedemptionConfirmation({
  formAnswers,
  onConfirm,
  onCancel,
  goBack,
}) {
  const { category, title, requestType, amount, ...answers } = formAnswers;

  return (
    <div className={classes.confirmation}>
      <div className={classes.requestType}>Confirm Details:</div>
      <div className={classes.content}>
        <div className={classes.heading}>
          <div className="font-md">{category}</div>
          <div className="font-base">{title}</div>
        </div>
        <div className={"font-md"} style={{ marginBottom: 20 }}>
          {requestType} Request
        </div>
        <div>
          <div className={classes.answer}>
            <div className="font-base-bold">Charge Amount</div>
            <div className={classes.amount}>
              <span style={{ fontSize: "50%" }}>$</span>
              {amount}
            </div>
          </div>
          {Object.entries(answers).map(([name, value]) => {
            return (
              <div className={classes.answer} key={name}>
                <div className="font-base">{name}</div>
                <div className={classes.answerValue}>{value}</div>
              </div>
            );
          })}
        </div>
        <div style={{ textAlign: "center" }}>
          <CTAButton2 onClick={goBack}>Make an Edit</CTAButton2>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <CTAButton1 onClick={onConfirm}>Charge Card</CTAButton1>
      </div>
      <div style={{ textAlign: "center" }}>
        <SecondaryButton onClick={onCancel}>Cancel Transaction</SecondaryButton>
      </div>
    </div>
  );
}

RedemptionConfirmation.propTypes = {
  formAnswers: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};
