import CONSTANTS from '../constants/constants';

interface DeploymentState {
    deploymentOrgs: object[] | null;
    deploymentUsers: object[] | null;
    deploymentUsersError: boolean | null;
    isFetchingDeploymentOrgs: boolean;
    isFetchingDeploymentUsers: boolean;
    isReferringMember: boolean;
    referMemberError: boolean | null;
}

const initialState: DeploymentState = {
    deploymentOrgs: null,
    deploymentUsers: null,
    deploymentUsersError: null,
    isFetchingDeploymentOrgs: false,
    isFetchingDeploymentUsers: false,
    isReferringMember: false,
    referMemberError: null
};

export default function deployments(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.GET_DEPLOYMENT_ORGS:
            return {
                ...state,
                isFetchingDeploymentOrgs: true
            };
        case CONSTANTS.GET_DEPLOYMENT_USERS:
            return {
                ...state,
                isFetchingDeploymentUsers: true
            };
        case CONSTANTS.REFER_MEMBER:
            return {
                ...state,
                isReferringMember: true,
                referMemberError: null
            };
        case CONSTANTS.REFER_MEMBER_FAIL:
            return {
                ...state,
                isReferringMember: false,
                referMemberError: true
            };
        case CONSTANTS.REFER_MEMBER_SUCCESS:
            return {
                ...state,
                isReferringMember: false,
                referMemberError: false
            };
        case CONSTANTS.SET_DEPLOYMENT_ORGS:
            const orgIds = state.deploymentOrgs
                ? state.deploymentOrgs.map((org: any) => org.id)
                : [];
            const newOrgs = action.payload.filter(
                (org: any) => !orgIds.includes(org.id)
            );

            return {
                ...state,
                deploymentOrgs: state.deploymentOrgs
                    ? [...state.deploymentOrgs, ...newOrgs]
                    : action.payload,
                isFetchingDeploymentOrgs: false
            };
        case CONSTANTS.SET_DEPLOYMENT_USERS:
            return {
                ...state,
                deploymentUsers: action.payload,
                isFetchingDeploymentUsers: false
            };
        default:
            return state;
    }
}
