import SamaritanFetch from './httpClient';

export const fetchDocumentsForId = (id: number) =>
    SamaritanFetch.v3(`/holder_documents?holder_documents[homeless_id]=${id}`);

export const uploadDocument = async (
    id: string,
    fileName: string,
    file: Blob
) => {
    if (
        file.type !== 'application/pdf' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg'
    )
        throw new Error(
            'incorrect file type. File must be of type .pdf, .jpeg or .jpg'
        );

    const formData = new FormData();

    formData.append('holder_document[file]', file);
    formData.append('holder_document[homeless_id]', id);
    formData.append('holder_document[name]', fileName);

    return SamaritanFetch.v3('/holder_documents', {
        method: 'POST',
        body: formData
    });
};

export const deleteDocument = (docId: number) =>
    SamaritanFetch.v3(`/holder_documents/${docId}`, {
        method: 'DELETE'
    });
