import { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        actionCardOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '16px',
            height: '100%',
            width: '100%',
            zIndex: 1
        },
        actionCardOverlayContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            color: '#F5577A',
            fontSize: '24px',
            fontWeight: 'bold',
            width: '80%'
        }
    })
);

type ActionCardOverlayProps = {
    children: React.ReactNode;
    setAddFailed: (addFailed: boolean) => void;
    setFailedAction: (failedAction: any) => void;
    setShowActionOverlay: (show: boolean) => void;
};

const ActionCardOverlay: React.FC<ActionCardOverlayProps> = ({
    children,
    setAddFailed,
    setFailedAction,
    setShowActionOverlay
}) => {
    const classes = useStyles();

    useEffect(() => {
        setTimeout(() => {
            setAddFailed(false);
            setFailedAction(null);
            setShowActionOverlay(false);
        }, 1500);
    }, [setAddFailed, setFailedAction, setShowActionOverlay]);

    return (
        <div className={classes.actionCardOverlay}>
            <div className={classes.actionCardOverlayContent}>{children}</div>
        </div>
    );
};

export default ActionCardOverlay;
