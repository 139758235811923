import * as CONSTANTS from '../constants/constants';

import { fetchHomelessInfo } from '../../api/homeless';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

import * as api from '../../api/pex';

function fetchHomelessPexInfo() {
    return {
        type: CONSTANTS.GET_HOMELESS_PEX_INFO
    };
}

function setHomelessPexInfo(response: any) {
    return {
        type: CONSTANTS.SET_HOMELESS_PEX_INFO,
        payload: response
    };
}

// function setHomelessPexBalance(response) {
//     return {
//         type: CONSTANTS.SET_HOMELESS_PEX_BALANCE,
//         payload: response
//     };
// }

function fetchingPexHistory() {
    return {
        type: CONSTANTS.FETCH_PEX_HISTORY
    };
}

function setPexHistory(response: any) {
    return {
        type: CONSTANTS.SET_PEX_HISTORY,
        payload: response
    };
}

function setFullPexHistory(response: any) {
    return {
        type: CONSTANTS.SET_FULL_PEX_HISTORY,
        payload: response
    };
}

export function updateHomelessPexBalance(balance: number) {
    return {
        type: CONSTANTS.UPDATE_HOMELESS_PEX_BALANCE,
        payload: { balance }
    };
}

export function getHomelessPexInfo(memberId: number) {
    return (dispatch: Function) => {
        dispatch(fetchHomelessPexInfo());

        fetchHomelessInfo(memberId)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { samaritan_member: { pex: any } }) => {
                return dispatch(
                    setHomelessPexInfo(responseJson.samaritan_member.pex)
                );
            })
            .catch((_error: any) => {
                return handleSamaritanApiError(
                    {
                        message: `Failed to fetch PEX info for member: ${memberId}`
                    },
                    dispatch
                );
            });
    };
}

export function getMemberPexHistory(id: number, full: boolean) {
    return (dispatch: Function) => {
        dispatch(fetchingPexHistory());

        const fetchFunction = full
            ? api.fetchPexHistory(id, true)
            : api.fetchPexHistory(id, false);

        return fetchFunction
            .then((response: any) =>
                handleSamaritanApiResponse(response, dispatch)
            )
            .then((responseJson: any) => {
                const actionCreator = full ? setFullPexHistory : setPexHistory;

                return dispatch(actionCreator(responseJson.message));
            })
            .catch((_error: any) =>
                handleSamaritanApiError(
                    {
                        message: `Failed to fetch PEX history for member: ${id}`
                    },
                    dispatch
                )
            );
    };
}
