import ReactGA from 'react-ga4';

import { DebitCard, MemberHistory, PexHistory } from 'types';

import Card from '../../CaseManagement/components/Card';
import HistoryItem from './components/HistoryItem';
import HistoryList from './components/HistoryList';
import LoadingCircle from '../../../../_shared/LoadingCircle';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        recentActivityContainer: {
            width: '100%'
        },
        add: {
            color: '#1B9757'
        },
        amount: {
            fontSize: '19px',
            fontWeight: 700,
            letterSpacing: '0.44px',
            lineHeight: '23px'
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        date: {
            color: '#A6A6BF',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '20px'
        },
        description: {
            display: 'flex',
            alignItems: 'center',
            color: '#1F2051',
            fontSize: '19px',
            fontWeight: 700,
            letterSpacing: '0.44px',
            lineHeight: '23px'
        },
        descriptionBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '2px'
        },
        expand: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '36px',
            color: '#7378E8',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '27px',
            padding: '10px 12px',
            textTransform: 'none'
        },
        expandIcon: {
            margin: '0 10px',
            fontSize: '16px'
        },
        fullHistoryBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            margin: '24px 0',
            paddingRight: '16px',
            maxHeight: '400px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-track': {
                background: '#E9EBF0'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#7378E8'
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 700,
            letterSpacing: '0.39px',
            lineHeight: '27px',
            marginBottom: '16px'
        },
        historyBody: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            margin: '24px 0'
        },
        historyTitle: {
            color: '#A6A6BF',
            fontSize: '20px',
            fontWeight: 700,
            letterSpacing: '0.39px',
            lineHeight: '27px'
        },
        icon: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            marginRight: '16px',
            width: '40px',
            height: '40px',
            borderRadius: '50%'
        },
        itemBody: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '16px',
            lineHeight: '22px'
        },
        minusIcon: {
            color: '#A80A2D',
            backgroundColor: '#FEE7EC'
        },
        plusIcon: {
            color: '#1B9757',
            backgroundColor: '#E9FBF2'
        },
        subtract: {
            color: '#A80A2D'
        },
        title: {
            color: '#A6A6BF'
        },
        transferIcon: {
            color: '#062D7A',
            backgroundColor: '#E7EFFE'
        }
    })
);

type HistoryProps = {
    card?: boolean;
    debitCard?: DebitCard | undefined;
    expanded: boolean;
    fullHistoryItems: MemberHistory[] | PexHistory[];
    historyItems: MemberHistory[] | PexHistory[] | null;
    loading: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const History: React.FC<HistoryProps> = ({
    card,
    debitCard,
    expanded,
    fullHistoryItems,
    historyItems,
    loading,
    setExpanded
}) => {
    const classes = useStyles();

    const hasHistoryItems = historyItems && historyItems.length > 0;
    const showAllActivity =
        expanded && fullHistoryItems && fullHistoryItems.length > 3;

    const handleExpandClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Clicked to expand activity history',
            label: 'Activity History'
        });

        setExpanded(!expanded);
    };

    const renderButtonContent = () => {
        return expanded ? (
            <>
                <i className={`${classes.expandIcon} far fa-chevron-left`} />
                Recent Activity
            </>
        ) : (
            <>
                See All
                <i className={`${classes.expandIcon} far fa-chevron-right`} />
            </>
        );
    };

    const renderHistoryItems = (items: any) => {
        if (showAllActivity) {
            return (
                items && (
                    <HistoryList
                        classes={classes}
                        historyItems={fullHistoryItems ? fullHistoryItems : []}
                    />
                )
            );
        }

        return (
            items &&
            items.map((item: any, index: any) => (
                <HistoryItem classes={classes} key={index} item={item} />
            ))
        );
    };

    const renderTitle = () => {
        if (!hasHistoryItems) {
            return <span className={classes.title}>No Activity</span>;
        }

        if (showAllActivity) {
            return (
                <span className={classes.title}>
                    {debitCard && debitCard.account_id
                        ? `Debit Balance History (${fullHistoryItems.length})`
                        : `Account Balance History (${fullHistoryItems.length})`}
                </span>
            );
        } else {
            return <span className={classes.title}>Recent Activity</span>;
        }
    };

    return (
        <div
            className={classes.recentActivityContainer}
            id="recent-activity-container"
        >
            <div className={classes.header}>{renderTitle()}</div>
            {card ? (
                <Card>
                    {loading ? (
                        <div style={{ padding: '20px 0' }}>
                            <LoadingCircle />
                        </div>
                    ) : (
                        <div
                            className={
                                showAllActivity
                                    ? classes.fullHistoryBody
                                    : classes.historyBody
                            }
                        >
                            {renderHistoryItems(
                                showAllActivity
                                    ? fullHistoryItems
                                    : historyItems
                            )}
                        </div>
                    )}
                    <div className={classes.btnContainer}>
                        {hasHistoryItems ? (
                            <Button
                                className={classes.expand}
                                onClick={() => handleExpandClick()}
                            >
                                {renderButtonContent()}
                            </Button>
                        ) : null}
                    </div>
                </Card>
            ) : (
                <>
                    {loading && historyItems ? (
                        <div style={{ padding: '20px 0' }}>
                            <LoadingCircle />
                        </div>
                    ) : (
                        <div
                            className={
                                showAllActivity
                                    ? classes.fullHistoryBody
                                    : classes.historyBody
                            }
                        >
                            {renderHistoryItems(
                                showAllActivity
                                    ? fullHistoryItems
                                    : historyItems
                            )}
                        </div>
                    )}
                    <div className={classes.btnContainer}>
                        {hasHistoryItems ? (
                            <Button
                                className={classes.expand}
                                onClick={() => handleExpandClick()}
                            >
                                {renderButtonContent()}
                            </Button>
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};

export default History;
