import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            alignSelf: 'flex-end',
            borderRadius: '100px',
            border: '1.6px solid #E3E3FA',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            minHeight: '38.5px',
            minWidth: '38.5px',
            padding: '6px 12px',
            fontWeight: 500,
            textTransform: 'none',
            zIndex: 4,
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        }
    })
);

type EllipsisProps = {
    setShowMemberMenu: (value: boolean) => void;
};

const Ellipsis: React.FC<EllipsisProps> = ({ setShowMemberMenu }) => {
    const classes = useStyles();

    const handleReferClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Open Refer / Save Member Menu Click'
        });

        setShowMemberMenu(true);
    };

    return (
        <Button className={classes.button} onClick={handleReferClick}>
            <i className="far fa-ellipsis-v" />
        </Button>
    );
};

export default Ellipsis;
