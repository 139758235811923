import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateHomelessPublicInfo } from 'api/homeless';
import { updateHomelessInfoField } from 'redux/actions/homeless';

import {
    catchUpItemFailure,
    catchUpItemSubmit,
    catchUpItemSuccess,
    completeItem
} from 'redux/actions/catch_up_items';

const useUpdatePublicProfile = () => {
    const dispatch = useDispatch();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const updatePublicProfile = useCallback(
        (memberId: number, data: any, catchUpID?: number) => {
            setIsLoading(true);
            setIsError(false);

            if (catchUpID) {
                dispatch(catchUpItemSubmit(catchUpID));
            }

            updateHomelessPublicInfo(memberId, data)
                .then((res: Response) => {
                    if (res && res.ok) return res.json();

                    if (catchUpID) {
                        dispatch(catchUpItemFailure(catchUpID));
                    }

                    const error = new Error('server error') as any;
                    error.response = res;
                    throw error;
                })
                .then((resJson: any) => {
                    setIsSuccess(true);
                    setIsError(false);
                    setIsLoading(false);
                    setError(null);

                    // update the redux store with the new data in an update
                    dispatch(
                        updateHomelessInfoField(
                            'mobile_photo',
                            `${
                                resJson.samaritan_member.mobile_photo
                            }?${Date.now()}`
                        )
                    );

                    dispatch(
                        updateHomelessInfoField(
                            'photo',
                            `${resJson.samaritan_member.photo}?${Date.now()}`
                        )
                    );

                    if (catchUpID) {
                        dispatch(catchUpItemSuccess(catchUpID));

                        setTimeout(() => {
                            dispatch(completeItem(catchUpID));
                        }, 2500);
                    }
                })
                .catch((err: Error) => {
                    setIsSuccess(false);
                    setIsError(true);
                    setIsLoading(false);
                    setError(err);
                });
        },
        [dispatch]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        updatePublicProfile,
        isSuccess,
        isError,
        isLoading,
        error,
        reset
    };
};

export default useUpdatePublicProfile;
