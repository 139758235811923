import { connect } from 'react-redux';

import PopUp from 'components/holder_profile/profile_2.0/PopUp';

function mapStateToProps(state: any) {
    const { homelessInfo } = state.homeless;
    const { homelessNeeds, isFetchingHomelessNeeds } = state.needs;

    return {
        homelessInfo,
        homelessNeeds,
        isFetchingHomelessNeeds
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
