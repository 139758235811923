import { withStyles } from '@mui/styles';

const styles = () => ({
    active: {
        display: 'flex',
        justifyContent: 'center',
        border: '1px #1F2051 solid',
        borderRadius: '1.5625rem',
        color: '#1F2051',
        fontSize: '0.8rem',
        height: '3rem',
        lineHeight: '3rem',
        minWidth: '9rem',
        padding: '0 1.4rem'
    },
    inactive: {
        display: 'flex',
        justifyContent: 'center',
        border: '1px #1F2051 solid',
        borderRadius: '1.5625rem',
        color: '#1F2051',
        fontSize: '0.8rem',
        height: '3rem',
        lineHeight: '3rem',
        minWidth: '9rem',
        padding: '0 1.4rem'
    }
});

type CommonButtonLightProps = {
    classes: {
        [key: string]: string;
    };
    children: React.ReactNode;
    active?: boolean;
    className?: string;
};

const CommonButtonLight: React.FC<CommonButtonLightProps> = ({
    classes,
    children,
    active,
    ...rest
}) => {
    if (active === undefined) active = true;
    let klass = active ? classes.active : classes.inactive;

    return (
        <div className={klass} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(styles)(CommonButtonLight);
