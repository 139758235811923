import Lottie from 'react-lottie';

import * as animationData from 'assets/lottie/checkbox-completion-illustration.json';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        completeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            height: '100%',
            padding: '0 20px'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 20px'
        },
        button: {
            background: '#999DFF',
            borderRadius: '100px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            color: '#FFFFFF',
            fontWeight: 800,
            padding: '16px 24px',
            textTransform: 'none',
            width: '232px',
            '@media (max-width: 600px)': {
                width: '180px',
                padding: '12px 16px'
            }
        },
        checklist: {
            maxHeight: '480px',
            maxWidth: '480px',
            '@media (max-width: 600px)': {
                maxWidth: '100%',
                maxHeight: 'auto'
            }
        },
        description: {
            color: '#1F2152',
            fontSize: '2rem',
            textAlign: 'center',
            '@media (max-width: 600px)': {
                fontSize: '1.5rem'
            }
        }
    })
);

type CompleteProps = {
    setCatchUpItemIndex: (index: number) => void;
    setQuickCatchUp: (value: boolean) => void;
    setShowCatchUp: (showCatchUp: boolean) => void;
};

const Complete: React.FC<CompleteProps> = ({
    setCatchUpItemIndex,
    setQuickCatchUp,
    setShowCatchUp
}) => {
    const classes = useStyles();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleBackToProfile = () => {
        setCatchUpItemIndex(0);
        setQuickCatchUp(false);
        setShowCatchUp(false);
    };

    return (
        <div className={classes.completeContainer}>
            <Lottie options={defaultOptions} height={480} width={480} />
            <div className={classes.body}>
                <span className={classes.description}>
                    Congratulations, you are all done! 🎉
                </span>
            </div>
            <Button className={classes.button} onClick={handleBackToProfile}>
                Back to Profile
            </Button>
        </div>
    );
};

export default Complete;
