import * as Sentry from '@sentry/react';

import { createTheme, DeprecatedThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const REACT_APP_ENV = Object.keys(process.env).includes('REACT_APP_ENV')
    ? process.env.REACT_APP_ENV
    : 'staging';

export let SAMARITAN_URL = '';

/**
 * Dynamically set subdomain for requests
 */
// get env vars
const SAMARITAN_DOMAIN = process.env.REACT_APP_SAMARITAN_DOMAIN;
const SAMARITAN_DEV_URL = process.env.REACT_APP_SAMARITAN_DEV_URL;

if (
    process.env.NODE_ENV === 'production' &&
    !SAMARITAN_DOMAIN &&
    !SAMARITAN_DEV_URL
) {
    throw new Error(
        'Neither REACT_APP_SAMARITAN_DOMAIN nor REACT_APP_SAMARITAN_DEV_URL are declared in env. Declare either and rebuild app.'
    );
}

if (
    process.env.NODE_ENV === 'development' &&
    !SAMARITAN_DOMAIN &&
    !SAMARITAN_DEV_URL
) {
    throw new Error(
        'Neither REACT_APP_SAMARITAN_DOMAIN nor REACT_APP_SAMARITAN_DEV_URL are declared in env. Declare either and restart dev server.'
    );
}

export const setSamaritanUrl = (subdomain = 'www') => {
    if (process.env.NODE_ENV === 'production' && !SAMARITAN_DEV_URL) {
        // only update samaritan url if app is production
        SAMARITAN_URL = `https://${subdomain}.${SAMARITAN_DOMAIN}`;
        Sentry.setTag('city', subdomain);
    }
};

// set initial values
if (SAMARITAN_DEV_URL) {
    SAMARITAN_URL = SAMARITAN_DEV_URL;
} else if (process.env.NODE_ENV === 'development') {
    SAMARITAN_URL = `https://staging.${SAMARITAN_DOMAIN}`;
} else if (process.env.NODE_ENV === 'production') {
    const storedCityString = window.localStorage.getItem('samaritan::city');

    if (storedCityString !== null) {
        try {
            const storedCity: { subdomain_name: string } =
                JSON.parse(storedCityString);
            setSamaritanUrl(storedCity.subdomain_name);
        } catch (error) {
            console.error('Error parsing stored city data:', error);
            window.localStorage.removeItem('samaritan::city');
            setSamaritanUrl('www');
        }
    } else {
        // Initialize with a valid city object
        const defaultCity = { subdomain_name: 'www' };
        window.localStorage.setItem(
            'samaritan::city',
            JSON.stringify(defaultCity)
        );
        setSamaritanUrl(defaultCity.subdomain_name);
    }
}

export const CHECKIN_CYCLE_DAYS = 30;
export const GOAL_MATCHING_AMOUNT = 10;

const samaritanFontFamilies = [
    'Manrope',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
].join(',');

interface CustomTypographyOptions extends TypographyOptions {
    fontWeight: string | number;
}

interface CustomThemeOptions extends DeprecatedThemeOptions {
    typography: CustomTypographyOptions;
    colors: {
        [key: string]: string;
    };
}

export const SAMARITAN_MATERIAL_UI_THEME = createTheme({
    typography: {
        fontFamily: samaritanFontFamilies,
        fontWeight: 300
    },
    colors: {
        White: 'white',

        Beige01: '#fcfaf7',
        OffWhite01: '#fbfbff',

        Purple01: '#7378e8',
        Purple02: '#b5b8eb',
        Purple03: '#a19ed6',
        Purple04: '#cacceb',
        Purple05: '#7378e815',
        Purple06: '#7378e806',
        Purple07: '#7279e8',

        DarkPurple01: '#1c1c47',
        DarkPurple02: '#1f2152',
        DarkPurple03: '#595985',
        DarkPurple04: '#3d3d6e',
        DarkPurple05: '#33305c',
        DarkPurple06: '#2b2e69',

        LightPurple01: '#f0f0ff',
        LightPurple02: '#d9d9ff',
        LightPurple03: '#ededfa',
        LightPurple04: '#f2f2fd',

        Grey01: '#9494a8',

        DarkGrey01: '#363636',

        LightGrey01: '#9696ba',
        LightGrey02: '#a6a6bf',
        LightGrey03: '#e3e3fa',
        LightGrey04: '#e3e3fa6a',
        LightGrey05: '#e3e3e3',

        Red01: '#f5577a',

        Green01: '#7fe8b2',

        Facebook: '#4073e5'
    }
} as CustomThemeOptions);

const FeatureToggleSets = {
    development: {
        dashboardOverview: true,
        organizationalMetrics: true,
        clientSnapshot: true,
        lifeCareVisit: true
    },
    production: {
        dashboardOverview: false,
        organizationalMetrics: false,
        clientSnapshot: false,
        lifeCareVisit: false
    },
    test: {
        dashboardOverview: false,
        organizationalMetrics: false,
        clientSnapshot: false,
        lifeCareVisit: false
    }
};

/**
 * Either use process.env.NODE_ENV or process.env.REACT_APP_FEATURE_TOGGLE_SET
 * to select the set of toggles to use
 */
export const FeatureToggles =
    FeatureToggleSets[
        (process.env.REACT_APP_TOGGLE_SET as keyof typeof FeatureToggleSets) ||
            (process.env.NODE_ENV as keyof typeof FeatureToggleSets)
    ];

/**
 * Set user for Sentry
 */
export function setSentryUser({ email, id }: { email: string; id: string }) {
    Sentry.setUser({ email, id });
}
