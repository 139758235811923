/**
 * This component designed using
 * https://www.figma.com/file/rTUYMYWdGLtfA3nepR5ZZe/Samaritan-(Design-System)
 */

import { useRef, useEffect } from 'react';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

// TODO: fix any for colors type
const useStyles = makeStyles((theme: { colors: any }) => ({
    button: {
        // color
        color: theme.colors.Purple01,
        backgroundColor: theme.colors.White,

        border: `2px solid ${theme.colors.Purple01}`,

        // font
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'none',

        // box
        padding: '10px 2.5rem',
        borderRadius: '0.375rem',
        margin: 1,
        minWidth: 150,
        boxSizing: 'border-box',

        '& .MuiTouchRipple-root': {
            'pointer-events': 'auto'
        },

        // psudoclasses
        '&:hover': {
            backgroundColor: theme.colors.White
        },

        // selectors
        '&.Mui-disabled': {
            borderColor: theme.colors.LightGrey03,
            color: theme.colors.LightGrey03
        }
    },
    unselected: {
        borderColor: theme.colors.LightGrey03,
        color: theme.colors.LightGrey03,
        '&:hover': {
            borderColor: theme.colors.Purple01,
            color: theme.colors.Purple01
        }
    },
    icon: {
        marginRight: 10
    }
}));

type CTAButton2Props = {
    arrow?: boolean;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    iconName?: string;
    onClick?: (_e: any) => void;
    style?: React.CSSProperties;
    unselected?: boolean;
};

/**
 * ### Important:
 * `CTAButton2` will always override border radius and set it to half of computed fontSize
 */
export default function CTAButton2({
    arrow = false,
    children,
    className = '',
    disabled = false,
    iconName = '',
    unselected = false,
    ...rest
}: CTAButton2Props) {
    const styles = useStyles();
    const buttonRef = useRef(null);

    // set border radius to be half of fontSize.
    useEffect(() => {
        if (buttonRef.current) {
            const computedFontSize = window.getComputedStyle(
                buttonRef.current
            ).fontSize;

            // extract fontSize as number
            const fontSizeNumber = Number(computedFontSize.slice(0, -2));

            (buttonRef.current as HTMLButtonElement).style.borderRadius =
                fontSizeNumber * 0.75 + 'px';
        }
    });

    // adds unselected style
    const baseClassNames = `${styles.button} ${
        unselected ? styles.unselected : ''
    }`;
    // adds all other style overrides
    const combinedClassName = `${baseClassNames} ${className ? className : ''}`;

    if (arrow) iconName = 'chevron-right';

    return (
        <Button
            ref={buttonRef}
            className={combinedClassName}
            {...rest}
            disabled={disabled}
        >
            {iconName && <i className={`far fa-${iconName} ${styles.icon}`} />}
            <div>{children}</div>
        </Button>
    );
}
