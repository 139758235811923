import AssignBody from './components/AssignBody';

import { DebitCardOptions } from 'types';

import { Button, Fade } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            borderRadius: '36px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            padding: '10px 20px',
            textTransform: 'none',
            width: '100%'
        },
        btnContainer: {
            position: 'relative',
            paddingBottom: '20px',
            marginBottom: '24px',
            zIndex: 5
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            color: '#FFFFFF',
            height: '100%',
            width: '100%',
            zIndex: 6
        },
        modalContent: {
            position: 'relative',
            borderRadius: '16px',
            boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
            maxWidth: '660px',
            overflow: 'auto',
            paddingTop: '48px',
            width: '80%',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        modalTitle: {
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '22px',
            marginTop: '24px',
            padding: '30px 0 10px 0'
        }
    })
);

type AssignCardPopUpProps = {
    debitCardOptions: DebitCardOptions[];
    handleModalClick: () => void;
    photo: string;
    setAssignPopUp?: (assignView: boolean) => void;
    setDebitType: (type: 'givecard' | 'pex' | null) => void;
    setReplaceView?: (replaceView: boolean) => void;
};

const AssignCardPopUp: React.FC<AssignCardPopUpProps> = ({
    debitCardOptions,
    handleModalClick,
    photo,
    setAssignPopUp,
    setDebitType,
    setReplaceView
}) => {
    const classes = useStyles();

    const handleUpdateModalClose = () => {
        handleModalClick();
    };

    return (
        <Fade in={true}>
            <div className={classes.modal}>
                <h2 className={classes.modalTitle}>Managing Debit Card</h2>
                <div className={classes.modalContent}>
                    <AssignBody
                        debitCardOptions={debitCardOptions}
                        photo={photo}
                        setAssignPopUp={setAssignPopUp}
                        setDebitType={setDebitType}
                        setReplaceView={setReplaceView}
                    />
                </div>
                <div className={classes.btnContainer}>
                    <Button
                        className={classes.btn}
                        onClick={() => handleUpdateModalClose()}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default AssignCardPopUp;
