import EditIcon from 'assets/icon-edit.png';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            cursor: 'pointer',
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            height: 'auto',
            margin: '0 0 0 10px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        savedInput: {
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            overflowWrap: 'anywhere'
        },
        skippedContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px'
        },
        skippedInput: {
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 700
        }
    })
);

type EditButtonProps = {
    handleEditClick: () => void;
    inputValState: string;
    remainingBonusAmount?: number;
    skipState?: boolean;
    saveInputState?: boolean;
    type?: string;
};

const EditButton: React.FC<EditButtonProps> = ({
    handleEditClick,
    inputValState,
    remainingBonusAmount,
    skipState,
    saveInputState,
    type
}) => {
    const classes = useStyles();

    const renderEditButton = () => {
        if (type === 'bonus' && inputValState === 'No Bonus') {
            return remainingBonusAmount === 0 ? false : true;
        } else {
            return true;
        }
    };

    return (
        <div className={classes.skippedContainer}>
            {skipState && <div className={classes.skippedInput}>{`-`}</div>}
            {saveInputState && !skipState && (
                <div className={classes.savedInput}>{inputValState}</div>
            )}
            {renderEditButton() && (
                <div className={classes.editIconContainer}>
                    <img
                        alt="edit-button"
                        className={classes.editIcon}
                        id="edit-button"
                        src={EditIcon}
                        onClick={() => handleEditClick()}
                    />
                    <div className={classes.editUnderline} />
                </div>
            )}
        </div>
    );
};

export default EditButton;
