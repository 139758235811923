import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    underline: {
        backgroundColor: '#E3E4FA',
        border: '6px solid #E3E4FA',
        borderRadius: '10px',
        height: '0',
        marginBottom: '20px'
    },
    underlineError: {
        backgroundColor: '#FF0000',
        border: '6px solid #FF0000'
    }
}));

const Underline = ({
    disabled
}: {
    disabled?: boolean | null | string;
}): JSX.Element => {
    const classes = useStyles();

    const underlineClass = disabled
        ? `${classes.underlineError} ${classes.underline}`
        : classes.underline;

    return <div className={underlineClass} />;
};

export default Underline;
