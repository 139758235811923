import * as CONSTANTS from '../constants/constants';

import * as api from '../../api/local';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';

export function setLocalTouchpoint(homeless_id: number) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT,
        payload: homeless_id
    };
}

export function removeLocalTouchpoint(homeless_id: number) {
    return {
        type: CONSTANTS.REMOVE_LOCAL_TOUCHPOINT,
        payload: homeless_id
    };
}

export function setLocalTouchpointDescription(
    homeless_id: number,
    description: string
) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT_DESCRIPTION,
        payload: { homeless_id, description }
    };
}

export function setLocalCompletionOnGoalAction(
    homeless_id: number,
    goal_action_id: number
) {
    return {
        type: CONSTANTS.SET_LOCAL_COMPLETION_ON_GOAL_ACTION,
        payload: { homeless_id, goal_action_id }
    };
}

export function setLocalCompletionOnNeed(homeless_id: number, need_id: number) {
    return {
        type: CONSTANTS.SET_LOCAL_COMPLETION_ON_NEED,
        payload: { homeless_id, need_id }
    };
}

// TODO: Check if this works
export function memoGoalActionStatusUpdated(goal_action_id: number) {
    return {
        type: CONSTANTS.MEMO_GOAL_ACTION_STATUS_UPDATE,
        goal_action_id
    };
}

// TODO: Check if this works
export function memoNeedStatusUpdated(need_id: number) {
    return {
        type: CONSTANTS.MEMO_NEED_STATUS_UPDATE,
        need_id
    };
}

export function setLocalTouchpointGoalActions(
    homeless_id: number,
    goal_actions: any[]
) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT_GOAL_ACTIONS,
        payload: { homeless_id, goal_actions }
    };
}

export function setRewardabilityOnGoalAction(
    homeless_id: number,
    goal_action_id: number
) {
    return {
        type: CONSTANTS.SET_REWARDABILITY_ON_GOAL_ACTION,
        payload: { homeless_id, goal_action_id }
    };
}

// TODO: Fix any types
export function setLocalTouchpointNeeds(homeless_id: number, needs: any) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT_NEEDS,
        payload: { homeless_id, needs }
    };
}

export function setLocalTouchpointAnswer(
    homeless_id: number,
    question_id: number,
    answer: string,
    colorRGB: string
) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT_ANSWER,
        payload: { homeless_id, question_id, answer, colorRGB }
    };
}

export function setLocalTouchpointAnswerContext(
    homeless_id: number,
    question_id: number,
    context: string
) {
    return {
        type: CONSTANTS.SET_LOCAL_TOUCHPOINT_ANSWER_CONTEXT,
        payload: { homeless_id, question_id, context }
    };
}

// TODO: Fix any types
export function setLocalPreviousTouchpoints(
    homeless_id: number,
    touchpoints: any[]
) {
    return {
        type: CONSTANTS.SET_LOCAL_PREVIOUS_TOUCHPOINTS,
        payload: { homeless_id, touchpoints }
    };
}

export function getLocalPreviousTouchpointAnswers(
    _apiToken: string,
    homeless_id: number
) {
    return (dispatch: Function) => {
        return api
            .fetchPreviousTouchpointAnswers(homeless_id)
            .then((response: any) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((responseJson: { touchpoints: any[] }) => {
                return dispatch(
                    setLocalPreviousTouchpoints(
                        homeless_id,
                        responseJson.touchpoints
                    )
                );
            })
            .catch((error: any) => {
                handleSamaritanApiError(error, dispatch);
            });
    };
}
