import Modal from '../../../../../../_shared/Modal';
import { CTAButton2 } from '../../../../../../_shared/buttons';

type Props = {
    modalOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    fileName: string;
};

const DeleteWarnModal = ({
    onClose,
    modalOpen,
    fileName,
    onDelete
}: Props): JSX.Element => (
    <Modal open={modalOpen} onClose={onClose}>
        <div
            style={{
                padding: 30
            }}
            data-testid="deleteModal"
        >
            <span>
                Are you sure you want to delete <u>{fileName}</u>? Deletions are{' '}
                <b>permanent</b>.
            </span>
            <div>
                <CTAButton2 onClick={onClose}>No</CTAButton2>
                <CTAButton2
                    onClick={onDelete}
                    style={{
                        color: 'red',
                        borderColor: 'red'
                    }}
                >
                    Yes, I'm sure
                </CTAButton2>
            </div>
        </div>
    </Modal>
);

export default DeleteWarnModal;
