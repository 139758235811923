import { useState, useEffect } from 'react';

import { Cities } from 'types';

import { fetchCities } from 'api/cities';

const useCities = () => {
    const [data, setData] = useState<null | object[]>(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCities()
            .then((res: Response) => res.json())
            .then((data: { cities: Cities[] }) => {
                // TODO: remove hard coded seattle/washington when backend adds it to list
                setData([
                    {
                        created_at: '2019-04-15T10:35:03.531-07:00',
                        id: 1000,
                        name: 'Washington',
                        subdomain_name: 'www',
                        updated_at: '2019-12-06T16:15:20.492-08:00'
                    },
                    ...data.cities
                ]);
                setError(null);
            })
            .catch(setError);
    }, []);

    return { data, error };
};

export default useCities;
