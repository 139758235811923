import { useEffect, useRef, useState } from 'react';

import GuideIcon from 'assets/icon-guide.png';
import GuideSettingsPopup from './components/GuideSettingsPopup';
import {
    GuidedMessage,
    IntroMessage,
    PrimaryMessage,
    SecondaryMessage
} from './components/Messages';
import MessageItems from './messages';

import Checkbox from '@mui/material/Checkbox';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        guideWrapper: {
            position: 'absolute',
            left: '0',
            top: '0',
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(47, 54, 91, 0.9)',
            height: '100%',
            overflow: 'auto',
            width: '100%',
            zIndex: 11,
            '& .MuiPaper-root': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        },
        guideContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            margin: '125px',
            zIndex: 5
        },
        iconButton: {
            position: 'relative',
            right: '-53%',
            top: '50px',
            display: 'flex',
            alignSelf: 'center',
            animation: '$pulse 2.5s infinite',
            background:
                'linear-gradient(45deg, rgba(53, 61, 97, 1), rgba(184, 194, 255, 0.6))',
            borderRadius: '100%',
            boxShadow: '0 0 0 rgba(255, 255, 255, 0.4)',
            height: '50px',
            width: '50px',
            padding: '10px',
            '& img': {
                height: '50px',
                width: '50px'
            },
            zIndex: 5,
            '@media (max-width: 768px)': {
                height: '40px',
                right: '-60%',
                top: '50px',
                width: '40px',
                '& img': {
                    height: '40px',
                    width: '40px'
                }
            }
        },
        '@keyframes pulse': {
            '0%': {
                boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.4)'
            },
            '70%': {
                boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)'
            },
            '100%': {
                boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)'
            }
        },
        messageContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            fontSize: '20px',
            margin: '0 auto',
            width: 'fit-content',
            '@media (max-width: 768px)': {
                right: '0',
                top: '-100px',
                width: '100%'
            }
        },
        proceedMessage: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            color: '#fff',
            fontSize: '20px'
        },
        showGuideMessage: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '20px',
            gap: '10px',
            marginBottom: '20px',
            '& .MuiCheckbox-root': {
                color: '#fff'
            }
        },
        listItem: {}
    })
);

type GuideProps = {
    ctpId: number;
    guide: boolean;
    intakeGuideMessages: string[];
    intakeGuideSteps: number[];
    intakeStep: number;
    intakeSteps: string[];
    members_enrolled: number;
    minIntakeStep: number;
    setGuide: (value: boolean) => void;
    setToggleGuide: (value: boolean) => void;
    setViewGuideMessage: (value: string) => void;
    toggleGuide: boolean;
    updateUserSettings: (
        id: number,
        settings: { [key: string]: boolean | string }
    ) => void;
};

const Guide: React.FC<GuideProps> = ({
    ctpId,
    guide,
    intakeGuideMessages,
    intakeGuideSteps,
    intakeStep,
    intakeSteps,
    members_enrolled,
    minIntakeStep,
    setGuide,
    setToggleGuide,
    setViewGuideMessage,
    toggleGuide,
    updateUserSettings
}) => {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const prevStepRef = useRef(intakeStep);

    const [showIntro, setShowIntro] = useState(
        intakeStep === 1 && !toggleGuide
    );
    const [showGuide, setShowGuide] = useState(!showIntro);
    const [showGuideSettingPopup, setShowGuideSettingPopup] = useState(
        members_enrolled === 1 && !toggleGuide
    );
    const [stopGuideMessages, setStopGuideMessages] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);

    const stepTitle = intakeSteps.filter(
        (step: string) => step !== 'Needs' && step !== 'Public Info'
    )[intakeStep - 1];

    const handleCheckboxClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();

        setStopGuideMessages(!stopGuideMessages);
    };

    const handleGuideClick = (step: string) => {
        if (showIntro) {
            setShowIntro(false);
            setShowGuide(true);
        }

        if (showGuide && !showIntro) {
            if (step !== 'Action Steps') {
                setShowGuide(false);
            } else {
                setStepTwo(true);
            }
        }

        if (guide) {
            setGuide(false);
            setViewGuideMessage(stepTitle);
        }

        if (step === 'Action Steps' && stepTwo) {
            setShowGuide(false);
            setStepTwo(false);
        }

        if (stopGuideMessages && showIntro) {
            updateUserSettings(ctpId, { intakeGuide: !stopGuideMessages });
        }

        if (toggleGuide) {
            if (step === 'Action Steps') {
                if (stepTwo) {
                    setToggleGuide(false);
                }
            } else {
                setToggleGuide(false);
            }
        }
    };

    useEffect(() => {
        if (prevStepRef.current !== intakeStep) {
            setShowGuide(true);
            setStepTwo(false);
            prevStepRef.current = intakeStep;
        }
    }, [intakeStep, setShowGuide]);

    useEffect(() => {
        if (containerRef.current && (showGuide || showIntro)) {
            containerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [showGuide, showIntro]);

    useEffect(() => {
        if (guide) {
            setShowGuide(true);
        }
    }, [guide]);

    return (
        <>
            {showGuideSettingPopup ? (
                <GuideSettingsPopup
                    ctpId={ctpId}
                    setShowGuideSettingPopup={setShowGuideSettingPopup}
                    updateUserSettings={updateUserSettings}
                />
            ) : (
                (showIntro ||
                    (showGuide && intakeGuideSteps.includes(intakeStep)) ||
                    (guide && intakeGuideMessages.includes(stepTitle))) && (
                    <div
                        className={classes.guideWrapper}
                        id="guide-wrapper"
                        onClick={() => handleGuideClick(stepTitle)}
                        ref={containerRef}
                    >
                        <div className={classes.guideContainer}>
                            <div className={classes.messageContainer}>
                                <div className={classes.iconButton}>
                                    <img src={GuideIcon} alt="Guide Icon" />
                                </div>
                                <IntroMessage showIntro={showIntro} />
                                <PrimaryMessage
                                    guide={guide}
                                    intakeStep={intakeStep}
                                    showGuide={showGuide}
                                    showIntro={showIntro}
                                    stepTitle={stepTitle}
                                    stepTwo={stepTwo}
                                    messageItems={MessageItems}
                                />
                                <SecondaryMessage
                                    guide={guide}
                                    intakeStep={intakeStep}
                                    showGuide={showGuide}
                                    showIntro={showIntro}
                                    stepTitle={stepTitle}
                                    stepTwo={stepTwo}
                                    messageItems={MessageItems}
                                />
                                {!toggleGuide && !showIntro && (
                                    <GuidedMessage
                                        guide={guide}
                                        intakeStep={intakeStep}
                                        messageItems={MessageItems}
                                        stepTitle={stepTitle}
                                    />
                                )}
                                {members_enrolled > 1 && showIntro && (
                                    <div className={classes.showGuideMessage}>
                                        <Checkbox
                                            checked={stopGuideMessages}
                                            onClick={handleCheckboxClick}
                                            inputProps={{
                                                'aria-label': 'show guide'
                                            }}
                                        />
                                        Don't show this guide again
                                    </div>
                                )}
                                {showIntro && intakeStep === minIntakeStep && (
                                    <div className={classes.proceedMessage}>
                                        <i className="far fa-chevron-left" />
                                        Click anywhere to proceed
                                        <i className="far fa-chevron-right" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default Guide;
