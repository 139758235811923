import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    base2: {
        position: 'absolute',
        top: -10,
        left: 10,
        height: '100%',
        width: '100%',
        backgroundColor: '#BFBCDC',
        borderRadius: '16px',
        zIndex: 0
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        borderRadius: '16px',
        zIndex: 1
    },
    overlay1: {
        background:
            'linear-gradient(0deg, #000 0%, #000 45%, rgba(0, 0, 0, 0.0001) 100%)',
        opacity: 0.5
    },
    overlay2: {
        background:
            'linear-gradient(0deg, #454981 0%, #454981 36%, rgba(69, 73, 129, 0.501961) 89.99%, rgba(69, 73, 129, 0.50) 100%)',
        opacity: 0.66
    },
    overlay3: {
        background: 'linear-gradient(0deg, #252946 0%, #252946 100%)',
        opacity: 0.4,
        mixBlendMode: 'multiply'
    },
    overlay4: {
        background:
            'linear-gradient(0deg, rgba(234, 239, 243, 0.0001) 0%, rgba(234, 239, 243, 0.0001) 14.99%, #EAEFF3 100%)',
        opacity: 0.6,
        mixBlendMode: 'soft-light'
    }
}));

const Stack2 = () => {
    const classes = useStyles();

    return (
        <div className={classes.base2}>
            <div className={`${classes.overlay} ${classes.overlay1}`} />
            <div className={`${classes.overlay} ${classes.overlay2}`} />
            <div className={`${classes.overlay} ${classes.overlay3}`} />
            <div className={`${classes.overlay} ${classes.overlay4}`} />
        </div>
    );
};

export default Stack2;
