const MessageItems = {
    'Private Info': {
        messages: (stepSequence: string) => [
            `${stepSequence}, let's gather some basic info about this Member to create their account.`,
            `Info marked as "private information" will only be visible to you and the team at your organization, while "public profile information" will be seen by the community supporting your Members.`
        ],
        guideMessage: [
            `A profile photo for your client is required. If they'd like to opt for more privacy, please use the 'blur' option to mask their face in the picture.`
        ],
        clicks: 1
    },
    'Public Info': {
        messages: () => [
            `Here your client can share more about themselves with their new support network.`,
            `Here is what your client needs to know:`
        ],
        guideMessage: [
            `A profile photo for your client is optional. If they'd like to opt for more privacy, please use the 'blur' option to mask their face in the picture.`
        ],
        secondaryMessage: [
            `The community can't wait to get to know and support you, you can share a bit about you here`,
            `Only share what you're comfortable with`,
            `Sensitive personal info should never be shared`
        ]
    },
    'Status Capture': {
        messages: () => [
            `Let's take a first snapshot of how your client is doing!`,
            `Sharing regular status updates will let us display your client's progress over time back to you`
        ]
    },
    'Action Steps': {
        messages: () => [
            `Using <b>Action Steps</b>, you can incentivize clients to complete to-do items from your case management plan!`,
            `Tip: Small steps to improve the statuses captured are a great place to start.`
        ],
        guideMessage: [
            `Use bigger bonuses on Action Steps most critical to your patient's progress. We've seen them impact completion rates!`,
            `Aim to set $40 in bonuses each month.`
        ],
        stepTwo: {
            messages: [`Please share the following with your client:`],
            secondaryMessage: [
                `Complete to-do items to <b>earn financial bonuses</b>`,
                `<b>Creating and completing to-dos</b> happens with your case manager`,
                `Make sure to <b>meet with them regularly</b> to do so`
            ]
        }
    },
    Needs: {
        messages: (stepSequence: string) => [
            `${stepSequence}, list your client's Needs so our community of supporters can donate directly to fund them.`,
            `Here's what your client needs to know:`
        ],
        // guideMessage: [`Examples of needs to share:`]
        secondaryMessage: [
            `<b>Supporters will donate</b> to fund what you need`,
            `We'll gather donations ASAP, but <b>larger needs may take longer</b>`,
            `<b>Sync with your case manager</b> to update your needs`
        ]
    },
    4: {
        messages: () => [
            `Lastly, read through this Terms of Service with your client. If accepted, their Membership will go live!`,
            `We'll send them a copy of the accepted terms too.`
        ]
    },
    5: {
        messages: () => [
            `Great work, [ctpName] & [memberName]! [memberName]'s Samaritan Membership is now live`,
            `Share these final reminders with them:`
        ],
        secondaryMessage: [
            `Samaritan will gather support for your needs while you work towards your action steps`,
            `Keep connected 
            with your case manager at [deployment], they'll help you spend funds and earn bonuses`
        ]
    }
};

export default MessageItems;
