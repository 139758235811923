import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    popUpContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: '2',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 50px',
        backgroundColor: '#EDEDFA',
        borderRadius: '16px 16px 0 0'
    },
    title: {
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '24px'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 50px 0px 50px',
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 16px 16px',
        fontSize: '16px',
        lineHeight: '16px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '35px 0'
    },
    btn: {
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '18px',
        borderRadius: '30px',
        padding: '18px 32px',
        width: '60%'
    },
    cancelBtn: {
        color: '#1F1F51',
        border: '1px solid #E3E3FA'
    }
}));

const InfoPopup = ({
    classifiedInfo,
    setClassifiedInfo,
    updateStatusInfo,
    setUpdateStatusInfo
}) => {
    const classes = useStyles();

    const unclassifiedAnswerTitle = 'Unclassified Status';
    const unclassifiedAnswerBody = `This status contains a custom answer which we haven't seen
    before. We'll read and sort this status into an appropriate
    level as soon as possible. Thanks for your patience.`;

    const updatedStatusTitle = 'Update to new status capture occurred';
    const updatedStatusBody = `To make updating statuses even more convenient for you, the status capture user experience was upgraded from the SDOH slider scale to a quickly clickable common statuses.`;

    const hidePopup = () => {
        if (classifiedInfo) {
            setClassifiedInfo(false);
        }

        if (updateStatusInfo) {
            setUpdateStatusInfo(false);
        }
    };

    return (
        <div className={classes.popUpContainer} id="info-popup">
            <div className={classes.header}>
                <span className={classes.title}>
                    {classifiedInfo
                        ? unclassifiedAnswerTitle
                        : updatedStatusTitle}
                </span>
            </div>
            <div className={classes.body}>
                <div style={{ marginBottom: '20px' }}>
                    {classifiedInfo
                        ? unclassifiedAnswerBody
                        : updatedStatusBody}
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        className={`${classes.btn} ${classes.cancelBtn}`}
                        onClick={() => hidePopup()}
                    >
                        Got it!
                    </Button>
                </div>
            </div>
        </div>
    );
};

InfoPopup.propTypes = {
    setClassifiedInfo: PropTypes.func.isRequired
};

export default InfoPopup;
