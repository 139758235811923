import { TouchpointQuestions } from 'types';

import { possessiveSuffix } from 'util/index';

const useSummaryItemTypes = (
    name?: string,
    touchpointQuestions?: TouchpointQuestions[]
) => {
    const summaryItemTypes = {
        action_step_due: {
            icon: 'fa-clock',
            title: 'Overdue Action Step',
            updateText: {
                add: 'Marked as Complete',
                edited: 'Action Step Edited',
                reminder: 'Reminder Sent'
            }
        },
        funds_above_25_dollars: {
            height: 56,
            icon: 'fa-hands-usd',
            title: 'Member Balance Available',
            updateText: {
                add: 'Debit card assigned',
                redeem: 'Funds Redeemed',
                transfer: 'Funds Transferred'
            }
        },
        no_contact_info: {
            content: ['Email', 'Phone Number'],
            contentIcons: {
                Email: 'fa-envelope',
                'Phone Number': 'fa-phone'
            },
            icon: 'fa-bell',
            title: 'Missing Contact Information',
            updateText: 'Contact Info Added'
        },
        no_date_of_birth: {
            content: ['Date of Birth'],
            icon: 'fa-birthday-cake',
            title: 'Missing Date of Birth',
            updateText: 'Date of Birth Added'
        },
        no_full_name: {
            content: ['Full Name'],
            icon: 'fa-pencil',
            title: 'Missing Full Name',
            updateText: 'Full Name Added'
        },
        no_insurance_id: {
            content: ['Insurance ID'],
            icon: 'fa-id-card',
            title: 'Missing Insurance ID',
            updateText: 'Insurance ID Added'
        },
        no_profile_photo: {
            height: 116,
            icon: 'fa-camera',
            title: 'Missing Profile Photo',
            updateText: 'Profile Photo Added'
        },
        no_status_capture_in_last_30_days: {
            height: touchpointQuestions ? touchpointQuestions.length * 78 : 78,
            icon: 'fa-clipboard-list',
            title: `Update ${
                name ? possessiveSuffix(name) : `member's`
            } Status`,
            updateText: 'Status Updated'
        },
        no_supplemental_answers: {
            height: 78,
            icon: 'fa-comment',
            title: 'Add Fun Fact',
            updateText: 'Fun Fact Added'
        }
    } as {
        [key: string]: {
            content?: string[];
            contentIcons?: { [key: string]: string };
            height?: number;
            icon: string;
            title: string;
            updateText: string | { [key: string]: string };
        };
    };

    return summaryItemTypes;
};

export default useSummaryItemTypes;
