import * as CONSTANTS from '../constants/constants';

import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from './index';
import * as api from '../../api/touchpointQuestions';

function getSupplementalQuestions() {
    return {
        type: CONSTANTS.GET_SUPPLEMENTAL_QUESTIONS
    };
}

function getTouchpointQuestions() {
    return {
        type: CONSTANTS.GET_TOUCHPOINT_QUESTIONS
    };
}

function setSupplementalQuestions(supplementalQuestions: object[]) {
    return {
        type: CONSTANTS.SET_SUPPLEMENTAL_QUESTIONS,
        payload: supplementalQuestions
    };
}

function setTouchpointQuestions(touchpointQuestions: object[]) {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_QUESTIONS,
        payload: touchpointQuestions
    };
}

function setSupplementalQuestionIds(supplementalQuestionIds: number[]) {
    return {
        type: CONSTANTS.SET_SUPPLEMENTAL_QUESTION_IDS,
        payload: supplementalQuestionIds
    };
}

function setTouchpointQuestionIds(touchpointQuestionIds: number[]) {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_QUESTION_IDS,
        payload: touchpointQuestionIds
    };
}

function setTouchpointQuestionQualityOfLifeMeasures(qolIds: number[]) {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_QUESTION_QUALITY_OF_LIFE_MEASURES,
        payload: qolIds
    };
}

function setTouchpointQuestionsError() {
    return {
        type: CONSTANTS.SET_TOUCHPOINT_QUESTIONS_ERROR
    };
}

export function getTouchpointQuestionsRequest() {
    return async (dispatch: Function) => {
        dispatch(getTouchpointQuestions());

        try {
            const response = await api.fetchTouchpointQuestions();
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );

            const touchpointQuestionIds: number[] = [];
            const touchpointQuestionQOLs: number[] = [];

            const statusQuestions = responseJson.touchpoint_questions.filter(
                (question: { type: string }) => {
                    return question.type === 'StatusQuestion';
                }
            );

            statusQuestions.forEach(
                (question: {
                    quality_of_life_measure_id: number;
                    id: number;
                }) => {
                    if (
                        !touchpointQuestionQOLs.includes(
                            question.quality_of_life_measure_id
                        ) &&
                        question.quality_of_life_measure_id !== null
                    ) {
                        touchpointQuestionQOLs.push(
                            question.quality_of_life_measure_id
                        );
                    }

                    if (!touchpointQuestionIds.includes(question.id)) {
                        touchpointQuestionIds.push(question.id);
                    }
                }
            );

            touchpointQuestionIds.sort((a, b) => a - b);
            touchpointQuestionQOLs.sort((a, b) => a - b);

            dispatch(
                setTouchpointQuestionQualityOfLifeMeasures(
                    touchpointQuestionQOLs
                )
            );
            dispatch(setTouchpointQuestionIds(touchpointQuestionIds));

            if (statusQuestions.length === 0) {
                dispatch(setTouchpointQuestionsError());
                dispatch(setTouchpointQuestions([{ id: null }]));
            } else {
                dispatch(setTouchpointQuestions(statusQuestions));
            }
        } catch (error: any) {
            dispatch(setTouchpointQuestionsError());
            handleSamaritanApiError(error, dispatch);
        }
    };
}

export function getSupplementalQuestionsRequest() {
    return async (dispatch: Function) => {
        dispatch(getSupplementalQuestions());

        try {
            const response = await api.fetchSupplementalQuestions();
            const responseJson = await handleSamaritanApiResponse(
                response,
                dispatch
            );
            const supplementalQuestions = responseJson.touchpoint_questions;
            const supplementalQuestionIds: number[] = supplementalQuestions.map(
                (question: { id: number }) => question.id
            );

            dispatch(setSupplementalQuestions(supplementalQuestions));
            dispatch(setSupplementalQuestionIds(supplementalQuestionIds));
        } catch (error: any) {
            dispatch(setTouchpointQuestionsError());
            handleSamaritanApiError(error, dispatch);
        }
    };
}
