import React from "react";
import PropTypes from "prop-types";

import classes from "./index.module.scss";

export default function TemplateListItem({ title, category, thumbnail = '', onClick }) {
    return (
        <div
            onClick={onClick}
            role="button"
            className={classes.card}
            aria-label={`form for ${title}`}
        >
            <div className={classes.category}>{category}</div>
            <div className={classes.thumbnail}>
                <img src={thumbnail} alt={title} />
            </div>
            <div className={classes.title}>{title}</div>
        </div>
    );
}

TemplateListItem.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
