import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';

import ChatRooms from './ChatRooms';
import LoadingCircle from '../../../_shared/LoadingCircle';
import Menu from './components/Menu';
import MessageMember from './MessageMember';
import Report from './popups/Report';
import Resend from './popups/Resend';
import ViewMessages from './ViewMessages';

import AuthManager from '../../../../auth';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    messageBtn: {
        position: 'fixed',
        right: '30px',
        bottom: '30px',
        height: '64px',
        width: '64px',
        borderRadius: '50px',
        boxShadow: '0px 8px 16px 4px rgba(0,0,0,0.15)',
        fontSize: '24px',
        lineHeight: '24px',
        cursor: 'pointer',
        zIndex: 5
    },
    messageBtnClose: {
        background: '#7279E8',
        color: '#FFFFFF',
        '&:hover': {
            background: '#918ed7'
        }
    },
    messageBtnOpen: {
        background: '#FFFFFF',
        color: '#7378E8',
        '&:hover': {
            background: '#f1f1f6'
        }
    },
    messageMenu: {
        position: 'fixed',
        right: '60px',
        bottom: '60px',
        width: '400px',
        borderRadius: '24px',
        boxShadow: '0px 8px 16px 4px rgba(0,0,0,0.1)',
        background: '#FFFFFF',
        zIndex: 4,
        marginBottom: '12px',
        overflow: 'hidden',
        maxHeight: 'calc(100vh - 200px)'
    }
}));

const Messaging = ({
    allChatMessages,
    chatMessages,
    chatRooms,
    deleteMessage,
    getChatMessages,
    getChatRooms,
    holder,
    isFetchingChatMessages,
    isFetchingChatRooms,
    isResendingMessage,
    resendMessage,
    sendMessages: sendChatMessages
}) => {
    const auth = AuthManager.getInstance();
    const classes = useStyles();

    const ctpInfo = {
        deployment: parseInt(auth.getUserDeploymentId()),
        id: parseInt(auth.getUserID()),
        org: parseInt(auth.getUserOrganizationId())
    };

    const [message, setMessage] = useState('');
    const [messageId, setMessageId] = useState('');
    const [reportName, setReportName] = useState('');
    const [reportPopup, setReportPopup] = useState(false);
    const [resendMessageContent, setResendMessageContent] = useState('');
    const [resendPopup, setResendPopup] = useState(false);
    const [sendMessages, setSendMessages] = useState(false);
    const [showChatRooms, setShowChatRooms] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [chatUserInfo, setChatUserInfo] = useState(null);
    const [viewMessages, setViewMessages] = useState(false);

    const fetchChatRooms = (id, chatPage, memberPage) => {
        if (!isFetchingChatRooms) {
            getChatRooms(id);
        }
    };

    const fetchChatMessages = useCallback(
        (id, ctpId, page) => getChatMessages(id, ctpId),
        [getChatMessages]
    );

    const handleChatButtonClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Message Button Clicked',
            label: showMenu ? 'Messages Opened' : 'Messages Closed'
        });

        setShowMenu(!showMenu);
        handleCloseMenu();
    };

    const handleCloseMenu = () => {
        setShowChatRooms(false);
        setSendMessages(false);
        setViewMessages(false);
        setMessage('');
    };

    const handleDeleteMessage = () => {
        deleteMessage(messageId);
        setResendPopup(false);
    };

    const handleMessageMember = () => {
        setSendMessages(!sendMessages);
        setShowChatRooms(false);
        setShowMenu(false);
        setViewMessages(false);
        setMessage('');
    };

    const handleReportPopup = (name) => {
        setReportPopup(!reportPopup);
        setReportName(name);
    };

    const handleResendPopup = (id, message) => {
        setResendPopup(!resendPopup);
        setMessageId(id);
        setResendMessageContent(message);
    };

    const handleResendMessage = () => {
        const messageData = {
            message: resendMessageContent,
            member_id: holder.id
        };

        resendMessage(messageId, messageData);
        setResendPopup(false);
    };

    const handleShowChatRooms = () => {
        setShowChatRooms(!showChatRooms);
        setSendMessages(false);
        setShowMenu(false);
        setViewMessages(false);
        setMessage('');
    };

    const handleShowMessages = () => {
        setViewMessages(!viewMessages);
        setSendMessages(false);
        setShowMenu(false);
        setShowChatRooms(true);
        setMessage('');
    };

    const renderBtnStyle = () => {
        if (showMenu) {
            return `${classes.messageBtn} ${classes.messageBtnClose}`;
        } else {
            return `${classes.messageBtn} ${classes.messageBtnOpen}`;
        }
    };

    const submitMessage = (recipientId) => {
        const messageData = {
            message: message,
            member_id: holder.id,
            user_id: recipientId
        };

        sendChatMessages(messageData);
    };

    const viewUserMessage = (userInfo) => {
        setShowChatRooms(false);
        setViewMessages(true);
        setChatUserInfo(userInfo);
    };

    return (
        <div className="messageButton_component">
            <div
                className={classes.messageMenu}
                style={
                    !showMenu &&
                    !sendMessages &&
                    !showChatRooms &&
                    !viewMessages
                        ? { boxShadow: 'none' }
                        : null
                }
            >
                {showMenu ? (
                    <Menu
                        ctpInfo={ctpInfo}
                        fetchChatMessages={fetchChatMessages}
                        fetchChatRooms={fetchChatRooms}
                        handleCloseMenu={handleCloseMenu}
                        handleMessageMember={handleMessageMember}
                        handleShowChatRooms={handleShowChatRooms}
                        holder={holder}
                    />
                ) : null}
                {sendMessages && (
                    <MessageMember
                        chatMessages={chatMessages}
                        closeView={handleMessageMember}
                        handleEllipsisClick={handleReportPopup}
                        handleResendPopup={handleResendPopup}
                        holder={holder}
                        LoadingCircle={LoadingCircle}
                        isFetchingChatMessages={isFetchingChatMessages}
                        isResendingMessage={isResendingMessage}
                        message={message}
                        setMessage={setMessage}
                        submitMessage={submitMessage}
                    />
                )}
                {showChatRooms && (
                    <ChatRooms
                        chatRooms={chatRooms}
                        closeView={handleShowChatRooms}
                        isFetchingChatRooms={isFetchingChatRooms}
                        LoadingCircle={LoadingCircle}
                        viewUserMessage={viewUserMessage}
                    />
                )}
                {viewMessages && (
                    <ViewMessages
                        allChatMessages={allChatMessages}
                        chatUserInfo={chatUserInfo}
                        closeView={handleShowMessages}
                        handleEllipsisClick={handleReportPopup}
                        holder={holder}
                        message={message}
                        setMessage={setMessage}
                        submitMessage={submitMessage}
                    />
                )}
                {reportPopup && (
                    <Report
                        handleEllipsisClick={handleReportPopup}
                        name={holder.name}
                        reportName={reportName}
                    />
                )}
                {resendPopup && (
                    <Resend
                        handleEllipsisClick={handleResendPopup}
                        deleteMessage={handleDeleteMessage}
                        resendMessage={handleResendMessage}
                    />
                )}
            </div>
            <Button
                className={renderBtnStyle()}
                onClick={() => handleChatButtonClick()}
            >
                {showMenu ? (
                    <i className="fas fa-times" />
                ) : (
                    <i className="far fa-comments-alt" />
                )}
            </Button>
        </div>
    );
};

Messaging.propTypes = {
    holder: PropTypes.object.isRequired
};

export default Messaging;