import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import MessageBubble from 'components/_shared/MessageBubble';

import Button from '@mui/material/Button';
import TextAreaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    questionBody: {
        marginBottom: '100px'
    },
    suggestionContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        borderRadius: '20px',
        padding: '20px 30px',
        width: '75%',
        margin: 'auto',
        boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
        position: 'relative'
    },
    suggestionTitle: {
        fontSize: '14px',
        fontWeight: '800',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
        background: '#8882D8',
        borderRadius: '32px',
        padding: '5px 20px',
        color: '#FFFFFF',
        position: 'relative',
        top: '0.5em',
        left: '11%',
        zIndex: '2'
    },
    suggestionList: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
        gap: '10px'
    },
    suggestion: {
        background: '#EAEBFB',
        color: '#1F1F51',
        width: 'fit-content',
        padding: '10px 20px',
        borderRadius: '32px',
        fontSize: '14px',
        letterSpacing: '0.01em',
        opacity: '0.7',
        cursor: 'pointer',
        textTransform: 'none'
    },
    customContainer: {},
    customInputContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    customInput: {
        fontFamily: 'Manrope',
        fontSize: '30px',
        fontWeight: '600',
        lineHeight: '45px',
        color: '#1F1F51',
        marginTop: '10px',
        padding: '12px 10px',
        border: 'none',
        outline: 'none',
        resize: 'none',
        textDecoration: 'underline',
        textDecorationColor: '#E3E4FA',
        '&::placeholder': {
            color: '#A8A8BF',
            opacity: '0.7',
            fontWeight: '300',
            letterSpacing: '0.02em',
            fontStyle: 'italic',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA'
        }
    },
    checkButton: {
        color: '#FFFFFF',
        background:
            'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3',
        borderRadius: '36px',
        padding: '22px',
        fontSize: '28px',
        position: 'absolute',
        right: '-20px',
        boxShadow: '-2.44929e-16px 4px 20px rgba(0, 0, 0, 0.121569)',
        '&:hover': {
            background:
                'linear-gradient(90deg, rgba(184, 194, 255, 0.0001) 0%, #B8C2FF 100%), #9897E3'
        }
    },
    customButton: {
        fontSize: '16px',
        fontWeight: '300',
        color: '#A8A8BF',
        letterSpacing: '0.01em',
        opacity: '0.7',
        marginTop: '10px',
        borderRadius: '40px',
        border: '2px dashed #EAEBFB',
        textTransform: 'none',
        padding: '5px 20px',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    doneButton: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '800',
        border: '3px solid #E3E4FA',
        borderRadius: '36px',
        padding: '4px 28px 4px 14px',
        color: '#1F1F51'
    }
}));

const useDebounce = (callback, delay) => {
    const debounceRef = useRef(null);

    const debouncedCallback = useCallback(
        (...args) => {
            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );

    return debouncedCallback;
};

const QuestionBody = ({
    setAnswers,
    name,
    questions,
    questionLength,
    questionNum
}) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const questionRef = useRef(null);

    const [customAnswer, setCustomAnswer] = useState('');
    const [customAnswerList, setCustomAnswerList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [showCustomInput, setShowCustomInput] = useState(false);

    const { suggestions, question, id, quality_of_life_measure_id } = questions;
    const suggestionList = useMemo(
        () => suggestions.split(', '),
        [suggestions]
    );

    const handleIntersectionDebounced = useDebounce(
        ([entry]) => {
            setIsActive(entry.isIntersecting);
        },
        200 // Adjust the debounce delay as needed
    );

    const renderSelectedAnswerStyle = (answer) => {
        if (selectedAnswer.includes(answer)) {
            return {
                background: '#9DA2F7',
                color: '#FFFFFF',
                opacity: '1'
            };
        }
    };

    const selectAnswer = useCallback((answer) => {
        setSelectedAnswer((prevSelectedAnswer) => {
            if (prevSelectedAnswer.includes(answer)) {
                return prevSelectedAnswer.filter(
                    (prevAnswer) => prevAnswer !== answer
                );
            } else {
                return [...prevSelectedAnswer, answer];
            }
        });
    }, []);

    const saveCustomInput = useCallback(() => {
        if (!customAnswer.length) {
            setShowCustomInput(false);
            return;
        }

        setCustomAnswerList((prev) => [...prev, customAnswer]);
        selectAnswer(customAnswer);
        setCustomAnswer('');
        setShowCustomInput(false);
    }, [customAnswer, selectAnswer]);

    const updateAnswers = useCallback(() => {
        setAnswers((prevAnswers) => {
            const existingAnswerIndex = prevAnswers?.findIndex(
                (prevAnswer) => prevAnswer.touchpoint_question_id === id
            );

            if (selectedAnswer.length === 0) {
                return prevAnswers.filter(
                    (prevAnswer) => prevAnswer.touchpoint_question_id !== id
                );
            } else if (existingAnswerIndex !== -1) {
                const updatedAnswers = [...prevAnswers];

                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    answer: selectedAnswer
                };

                return updatedAnswers;
            } else {
                return [
                    ...prevAnswers,
                    {
                        touchpoint_question_id: id,
                        quality_of_life_measure_id: quality_of_life_measure_id,
                        answer: selectedAnswer
                    }
                ];
            }
        });
    }, [id, quality_of_life_measure_id, selectedAnswer, setAnswers]);

    useEffect(() => {
        if (showCustomInput) {
            inputRef.current.focus();
        }
    }, [showCustomInput]);

    useEffect(() => {
        updateAnswers();
    }, [selectedAnswer, updateAnswers]);

    useEffect(() => {
        selectedAnswer.forEach((answer) => {
            if (
                !suggestionList.includes(answer) &&
                !customAnswerList.includes(answer)
            ) {
                setCustomAnswerList((prev) => [...prev, answer]);
            }
        });
    }, [selectedAnswer, suggestionList, customAnswerList, setCustomAnswerList]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersectionDebounced, {
            threshold: 0.7
        });
        const questionActive = questionRef.current;

        if (questionActive) {
            observer.observe(questionActive);
        }

        return () => {
            if (questionActive) {
                observer.unobserve(questionActive);
            }
        };
    }, [handleIntersectionDebounced]);

    return (
        <div
            className={classes.questionBody}
            ref={questionRef}
            id={id}
            style={{ opacity: isActive ? 1 : 0.4, transition: 'opacity 0.4s' }}
        >
            <MessageBubble
                name={name}
                question={question}
                questionLength={questionLength}
                questionNum={questionNum}
            />
            <span className={classes.suggestionTitle}>
                Click All That Apply
            </span>
            <div className={classes.suggestionContainer}>
                <div className={classes.suggestionList}>
                    {suggestionList.map((suggestion, index) => (
                        <Button
                            className={classes.suggestion}
                            key={index}
                            onClick={() => selectAnswer(suggestion)}
                            style={renderSelectedAnswerStyle(suggestion)}
                        >
                            {suggestion}
                        </Button>
                    ))}
                    {customAnswerList.map((answer, index) => (
                        <Button
                            className={classes.suggestion}
                            key={index}
                            onClick={() => selectAnswer(answer)}
                            style={renderSelectedAnswerStyle(answer)}
                        >
                            {answer}
                        </Button>
                    ))}
                </div>
                <div className={classes.customContainer}>
                    {!showCustomInput ? (
                        <Button
                            className={classes.customButton}
                            onClick={() => setShowCustomInput(true)}
                        >
                            Write your own...
                        </Button>
                    ) : (
                        <div className={classes.customInputContainer}>
                            <TextAreaAutosize
                                className={classes.customInput}
                                onChange={(e) =>
                                    setCustomAnswer(e.target.value)
                                }
                                placeholder={'Write your own...'}
                                ref={inputRef}
                                spellCheck={'false'}
                                type={'string'}
                                value={customAnswer}
                            />
                            <div className={classes.doneButton}>Done</div>
                            <Button
                                className={classes.checkButton}
                                onClick={() => saveCustomInput()}
                            >
                                <i className="fas fa-check" />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuestionBody;
