import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        listBody: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            color: '#739BE8',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 700,
            textTransform: 'none'
        },
        listButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '8px',
            fontSize: '16px',
            width: '100%'
        }
    })
);

type ListButtonProps = {
    children: string;
    icon: string;
    onClick: () => void;
};

const ListButton = ({ children, icon, onClick }: ListButtonProps) => {
    const classes = useStyles();

    return (
        <Button className={classes.listButton} onClick={onClick}>
            <span className={classes.listBody}>
                <i className={`far fa-${icon}`} />
                {children}
            </span>
            <i className="far fa-chevron-right" />
        </Button>
    );
};

export default ListButton;
