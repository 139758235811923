import { useState } from 'react';

import EditButton from './buttons/EditButton3';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        datePicker: {
            color: '#1f1f51',
            width: '100%',
            '& .MuiInputBase-root': {
                color: '#1f1f51',
                opacity: '0.6',
                fontFamily: 'Manrope',
                fontSize: '1.75rem',
                fontStyle: 'italic',
                '& input': {
                    padding: '10px 0 0 0px'
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    })
);

type DateInputProps = {
    dateInput: Date;
    disableFuture?: boolean;
    disablePast?: boolean;
    open: boolean;
    setDateInput: (date: Date | null | string) => void;
    setDisplayBtnState?: (state: boolean) => void;
    setOpen: (state: boolean) => void;
};

const addDays = (date: Date | string, days: number) => {
    if (typeof date !== 'string') {
        return date;
    }

    const newDate = new Date(`${date}T00:00:00`);
    newDate.setDate(newDate.getDate() + days);

    return newDate;
};

const convertDateSuggestions = (dateSuggestion: Date | string) => {
    switch (dateSuggestion) {
        case 'ASAP':
            return addDays(dateSuggestion, 4);
        case '7 days':
            return addDays(dateSuggestion, 8);
        case '2 weeks':
            return addDays(dateSuggestion, 15);
        case '1 month':
            return addDays(dateSuggestion, 31);
        default:
            return addDays(dateSuggestion, 0);
    }
};

const formatShortDate = (dateInput: Date | null | string) => {
    if (!dateInput) return '';

    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const DateInput: React.FC<DateInputProps> = ({
    dateInput,
    disableFuture = false,
    disablePast = false,
    open,
    setDateInput,
    setDisplayBtnState,
    setOpen
}) => {
    const classes = useStyles();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const [date, setDate] = useState<Date | string>(tomorrow);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [saved, setSaved] = useState(false);

    const handleDateChange = (newDate: Date | null | string) => {
        if (!newDate || (disablePast && newDate < new Date())) {
            return;
        }

        if (setDisplayBtnState) {
            setDisplayBtnState(false);
        }

        setDate(newDate);
        setDateInput(formatShortDate(newDate));
        setSaved(true);
    };

    const handleEditClick = () => {
        setDateInput(date);
        setSaved(false);
        setOpen(false);

        if (setDisplayBtnState) {
            setDisplayBtnState(true);
        }
    };

    return (
        <>
            {!saved ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        className={classes.datePicker}
                        disableFuture={disableFuture}
                        disableHighlightToday={disablePast ? true : false}
                        disablePast={disablePast}
                        format="MM-dd-yyyy"
                        minDate={disablePast ? tomorrow : null}
                        onOpen={() => setPickerOpen(true)}
                        onClose={() => setPickerOpen(false)}
                        open={pickerOpen || open}
                        slotProps={{
                            field: {
                                readOnly: true
                            },
                            textField: {
                                onClick: () => {
                                    if (!pickerOpen) {
                                        setPickerOpen(true);
                                    } else {
                                        setPickerOpen(false);
                                    }
                                }
                            }
                        }}
                        value={convertDateSuggestions(dateInput)}
                        onChange={(newDate) => handleDateChange(newDate)}
                    />
                </LocalizationProvider>
            ) : (
                <EditButton
                    date
                    handleEditClick={handleEditClick}
                    inputValState={dateInput as any}
                    saveInputState={saved}
                />
            )}
        </>
    );
};

export default DateInput;
