import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// TODO: Type reducers
// @ts-ignore
import rootReducer from '../reducers';

export default function configureStore() {
    let composeEnhancers = compose as any;

    if (process.env.NODE_ENV === 'development') {
        if ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
            // enables redux dev tools
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        }
    }

    return createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware // lets us dispatch() functions
            )
        )
    );
}
