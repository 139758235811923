import Card from '../../../_shared/Card';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: { colors: { DarkPurple02: string } }) => ({
    bonusCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        marginBottom: '20px',
        width: '100%'
    },
    bonusCardTracker: {
        position: 'relative',
        borderRadius: '20px',
        boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.10)',
        padding: '20px',
        width: '90%'
    },
    bonusCardTrackerTitle: {
        color: theme.colors.DarkPurple02,
        fontSize: '20px',
        fontWeight: '800'
    },
    progressBarContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        margin: '16px 0 4px 0'
    },
    progressBar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        height: '18px',
        width: '100%'
    },
    dot: {
        position: 'absolute',
        borderRadius: '50%',
        height: '12px',
        width: '12px'
    },
    progressBarOverlay: {
        maxWidth: '98%',
        marginLeft: '1%',
        height: '12px',
        borderRadius: '10px',
        position: 'absolute'
    },
    progressBarBackground: {
        backgroundColor: '#F3F3F3',
        borderRadius: '10px',
        height: '18px',
        width: '100%'
    },
    maxAmount: {
        whiteSpace: 'nowrap'
    },
    assigned: {
        display: 'flex',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    }
}));

type BonusTrackerCardProps = {
    maxBonusAmount: number;
    remainingBonusAmount: number;
};

const BonusTrackerCard: React.FC<BonusTrackerCardProps> = ({
    maxBonusAmount,
    remainingBonusAmount
}) => {
    const classes = useStyles();

    const assigned = maxBonusAmount - remainingBonusAmount;

    const getAssignedAmount = () => {
        if (remainingBonusAmount === 0) {
            return `🎊 All bonuses assigned`;
        } else {
            return `$${assigned} assigned`;
        }
    };

    const getMonth = () => {
        const date = new Date();
        const month = date.toLocaleString('default', { month: 'long' });

        return month;
    };

    const getProgressBarOverlayWidth = () => {
        const percentage = (assigned * 98) / maxBonusAmount;

        return `${percentage}%`;
    };

    return (
        <div className={classes.bonusCardContainer}>
            <Card className={classes.bonusCardTracker}>
                <span className={classes.bonusCardTrackerTitle}>
                    {getMonth()} Bonus Pool
                </span>
                <div className={classes.progressBarContainer}>
                    <div className={classes.progressBar}>
                        <span
                            className={classes.progressBarOverlay}
                            style={{
                                width: getProgressBarOverlayWidth(),
                                backgroundColor:
                                    remainingBonusAmount <= 0
                                        ? '#E7FEEB'
                                        : 'rgb(153,157,255, 0.7)'
                            }}
                        >
                            <span
                                className={classes.dot}
                                style={{
                                    backgroundColor:
                                        remainingBonusAmount <= 0
                                            ? '#0AA823'
                                            : '#999DFF',
                                    right: assigned <= 0 ? '-12px' : '0px'
                                }}
                            />
                        </span>
                        <span className={classes.progressBarBackground} />
                    </div>
                    <span className={classes.maxAmount}>
                        ${maxBonusAmount} max
                    </span>
                </div>
                <span
                    className={classes.assigned}
                    style={{
                        width:
                            assigned <= 0
                                ? '20%'
                                : getProgressBarOverlayWidth(),
                        paddingLeft:
                            getProgressBarOverlayWidth() === '98%'
                                ? '16px'
                                : '0',
                        justifyContent:
                            assigned <= 0 ? 'flex-start' : 'flex-end'
                    }}
                >
                    {getAssignedAmount()}
                </span>
            </Card>
        </div>
    );
};

export default BonusTrackerCard;
