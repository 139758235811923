import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';
import Input from './components/Input';
import Notice from './components/Notice';

import {
    groupMessagesByDate,
    renderMessagesByDate,
    scrollToBottom
} from './utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        height: 'calc(100vh - 500px)',
        overflow: 'auto',
        padding: '20px 20px 5px 20px',
        '& div': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    messages: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 0'
    },
    bubble: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '60%',
        width: 'fit-content',
        padding: '10px 16px',
        margin: '2px 0',
        borderRadius: '15px'
    },
    replyBubble: {
        alignSelf: 'flex-end',
        color: '#FFFFFF',
        backgroundColor: '#7279E8'
    },
    memberBubble: {
        alignItems: 'flex-start',
        color: '#1F1F51',
        backgroundColor: '#EFF0FF'
    },
    messageTime: {
        fontSize: '12px',
        lineHeight: '22px',
        color: '#A6A6BF',
        marginBottom: '12px'
    },
    dateHeading: {
        margin: '30px 0',
        '& span': {
            margin: 'auto',
            fontWeight: '800',
            fontSize: '18px',
            lineHeight: '20px',
            color: '#1F1F51'
        }
    }
}));

const ViewMessages = ({
    allChatMessages,
    chatUserInfo,
    closeView,
    handleEllipsisClick,
    holder,
    message,
    setMessage,
    submitMessage
}) => {
    const classes = useStyles();
    const memberName = holder.name.split(' ')[0];
    const messagesEndRef = useRef(null);
    const messagesStartRef = useRef(null);
    const { id: samaritanId, name: samaritanName } = chatUserInfo;

    useEffect(() => {
        scrollToBottom(messagesEndRef);
    }, [allChatMessages]);

    const renderMessages = (messages) => {
        const userMessages = messages?.find(
            (message) => message.recipientId === samaritanId
        )?.messages;

        if (userMessages?.length) {
            const sortedMessages = [...userMessages].sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            );
            const groupedMessages = groupMessagesByDate(sortedMessages);
            return renderMessagesByDate(
                groupedMessages,
                classes,
                messagesEndRef
            );
        }

        return null;
    };

    return (
        <div style={{ width: '100%' }}>
            <Header
                closeView={closeView}
                handleEllipsisClick={() => handleEllipsisClick(samaritanName)}
                memberName={memberName}
                samaritanName={samaritanName}
            />
            <div className={classes.messageContainer}>
                <div ref={messagesStartRef} />
                <Notice memberName={memberName} samaritanName={samaritanName} />
                {renderMessages(allChatMessages)}
            </div>
            <Input
                label
                memberName={memberName}
                message={message}
                samaritanId={samaritanId}
                scrollToBottom={() => scrollToBottom(messagesEndRef)}
                setMessage={setMessage}
                submitMessage={submitMessage}
            />
        </div>
    );
};

ViewMessages.propTypes = {
    allChatMessages: PropTypes.array.isRequired,
    chatUserInfo: PropTypes.object.isRequired,
    closeView: PropTypes.func.isRequired,
    handleEllipsisClick: PropTypes.func.isRequired,
    holder: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    submitMessage: PropTypes.func.isRequired
};

export default ViewMessages;
