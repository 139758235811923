import SamaritanFetch from './httpClient';

export const assignPexCard = (
    memberId: number,
    debitNums: string,
    token: string,
    externalID?: string
): Promise<Response> => {
    const pexNums = { last4: debitNums } as {
        external_id?: string;
        last4: string;
    };

    if (externalID) {
        pexNums.external_id = externalID;
    }

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberId}/pex/card/assign`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`
            },
            body: JSON.stringify(pexNums)
        }
    );
};

export const fetchPexHistory = (memberID: number, full: boolean) => {
    const query = {} as {
        page?: number;
        page_size?: number;
    };

    if (!full) {
        query.page = 1;
        query.page_size = 3;
    }

    return SamaritanFetch.v3(
        `/samaritan_members/${memberID}/pex/account/transaction_details`,
        {
            query
        }
    );
};

export const closePexCard = (memberID: number) =>
    SamaritanFetch.v3.post(`/samaritan_members/${memberID}/pex/card/close`);

export const transferPexFunds = (
    memberId: number,
    balance: number,
    token: string
) => {
    const transferBalance = { amount: balance };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberId}/pex/card/fund`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`
            },
            body: JSON.stringify(transferBalance)
        }
    );
};

export const updatePexPin = (memberId: number, pin: number, token: string) => {
    const newPin = { new_pin: pin };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberId}/pex/card/update_pin`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`
            },
            body: JSON.stringify(newPin)
        }
    );
};