import { useEffect, useState } from 'react';

import AddButton from '../../CaseManagement/components/AddButton';
import Card from '../../CaseManagement/components/Card';
import LifeStoryItem from './components/LifeStoryItem';
import PopUp from '../../PopUp';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    btn: {
        color: '#7378E8',
        fontSize: '24px'
    },
    lifeStoryCarousel: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '24px',
        overflowX: 'auto',
        paddingBottom: '24px',
        '& > :first-child': {
            paddingLeft: '24px'
        }
    },
    lifeStoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    lifeStoryBody: {
        position: 'relative',
        padding: '20px 0 10px 0'
    },
    stepper: {
        background: 'transparent',
        padding: '24px 0 8px 0'
    }
}));

type LifeStoryProps = {
    description: string;
    homelessLifeStorySuccess: boolean;
    id: number;
    isSubmittingHomelessLifeStory: boolean;
    lifeStory: Array<any>;
    name: string;
    updateHomelessLifeStory: (id: number, data: any) => void;
};

const LifeStory: React.FC<LifeStoryProps> = ({
    homelessLifeStorySuccess,
    id,
    isSubmittingHomelessLifeStory,
    lifeStory,
    name,
    updateHomelessLifeStory
}) => {
    const classes = useStyles();

    const [addUpdate, setAddUpdate] = useState(false);

    useEffect(() => {
        if (homelessLifeStorySuccess) {
            setAddUpdate(false);
        }
    }, [homelessLifeStorySuccess, lifeStory]);

    return (
        <div className={classes.lifeStoryContainer}>
            <Card info lifestory count={lifeStory.length} name={name} />
            {lifeStory.length > 0 ? (
                <div
                    className={
                        lifeStory.length === 0
                            ? classes.lifeStoryBody
                            : classes.lifeStoryCarousel
                    }
                    style={lifeStory.length === 0 ? { overflowX: 'auto' } : {}}
                >
                    {lifeStory.map((story) => (
                        <div className={classes.lifeStoryBody} key={story.id}>
                            <LifeStoryItem
                                item={story}
                                length={lifeStory.length}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
            <AddButton action={setAddUpdate} type="update" />
            {addUpdate && (
                <PopUp
                    content="life story"
                    id={id}
                    name={name}
                    handleModalClick={setAddUpdate}
                    isSubmitting={isSubmittingHomelessLifeStory}
                    updateHomelessLifeStory={updateHomelessLifeStory}
                />
            )}
        </div>
    );
};

export default LifeStory;
