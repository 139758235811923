import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            background: '#5a628b',
            borderRadius: '10px',
            color: '#fff',
            height: 'auto',
            padding: '24px',
            width: '100%',
            zIndex: 4
        },
        secondaryMessage: {
            position: 'relative',
            top: '-30px',
            right: '-15px',
            background: '#a1a7d0',
            borderRadius: '10px',
            color: '#fff',
            height: 'auto',
            padding: '24px',
            width: '100%',
            '& ul li': {
                margin: '14px 0'
            }
        },
        listItem: {}
    })
);

const IntroMessage = ({ showIntro }: { showIntro: boolean }) => {
    const classes = useStyles();

    const introMessage = {
        messages: [
            `Let's get your client a Samaritan Membership!`,
            `Let them know about the following benefits:`
        ],
        secondaryMessage: [
            `<b>Earn money</b> by finishing items on your to-do list`,
            `<b>Get donations</b> from the community to pay for things you need`,
            `<b>Get encouragement</b> as you work towards goals`,
            `<b>Spend your account</b> balance on things you need most`
        ]
    };

    return (
        <>
            {showIntro &&
                introMessage.messages.map((message, index) => (
                    <span key={index} className={classes.message}>
                        {message}
                    </span>
                ))}
            {showIntro && (
                <div className={classes.secondaryMessage}>
                    <ul style={{ padding: '0 20px' }}>
                        {introMessage.secondaryMessage.map((message, index) => (
                            <li
                                key={index}
                                className={classes.listItem}
                                dangerouslySetInnerHTML={{
                                    __html: message
                                }}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default IntroMessage;
