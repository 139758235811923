import CONSTANTS from '../constants/constants';

import { updateObject } from '../../util';

const profileItems = {
    action_steps: {
        status: 'incomplete',
        zIndex: 3
    },
    birthday: {
        status: 'incomplete',
        zIndex: 5
    },
    contact_info: {
        status: 'incomplete',
        zIndex: 3
    },
    full_name: {
        status: 'incomplete',
        zIndex: 1
    },
    member_id: {
        status: 'incomplete',
        zIndex: 0
    },
    name: {
        status: 'incomplete',
        zIndex: 6
    },
    needs: {
        status: 'incomplete',
        zIndex: 8
    },
    photo: {
        status: 'complete',
        zIndex: 2
    },
    status: {
        status: 'complete',
        zIndex: 4
    },
    supplemental_questions: {
        status: 'incomplete',
        zIndex: 7
    }
} as { [key: string]: { status: string; zIndex: number } };

const sortedProfileItems = Object.fromEntries(
    Object.entries(profileItems).sort(
        ([, itemA], [, itemB]) => itemA.zIndex - itemB.zIndex
    )
);

const initialState = {
    assignedBonusAmount: null,
    hasFetchedHomelessGoalActions: false,
    hasFetchedHomelessNeeds: false,
    historyFilters: [],
    homelessHistory: [],
    homelessInfo: {} as { [key: string]: any },
    homelessLifeStory: [],
    homelessLifeStorySuccess: false,
    isFetchingHistoryFilters: false,
    isFetchingHomelessHistory: false,
    isFetchingHomelessInfo: false,
    isFetchingHomelessLifeStory: false,
    isSubmittingHomelessLifeStory: false,
    isUnenrollingHomeless: false,
    maxBonusAmount: null,
    notFoundHomelessInfo: false,
    profileItems: sortedProfileItems,
    remainingBonusAmount: null,
    unenrollmentSuccess: false,
    updatedCatchUpItems: []
};

export default function homeless_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.FETCH_HOMELESS_LIFESTORY:
            return {
                ...state,
                isFetchingHomelessLifeStory: true
            };
        case CONSTANTS.FETCH_HISTORY_FILTERS:
            return {
                ...state,
                isFetchingHistoryFilters: true
            };
        case CONSTANTS.FETCH_MEMBER_HISTORY:
            return {
                ...state,
                isFetchingHomelessHistory: true
            };
        case CONSTANTS.GET_HOMELESS_INFO:
            return {
                ...state,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                isFetchingHomelessInfo: true
            };
        case CONSTANTS.REFRESH_HOMELESS_BALANCE:
            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    'balance',
                    action.payload
                )
            };
        case CONSTANTS.RESET:
            return initialState;
        case CONSTANTS.RESET_HOMELESS_INFO:
            return {
                ...state,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                homelessHistory: [],
                homelessInfo: {},
                isFetchingHomelessInfo: false,
                profileItems: initialState.profileItems,
                notFoundHomelessInfo: false
            };
        case CONSTANTS.SET_HISTORY_FILTERS:
            return {
                ...state,
                historyFilters: action.payload,
                isFetchingHistoryFilters: false
            };
        case CONSTANTS.SET_HOMELESS_INFO:
            return {
                ...state,
                assignedBonusAmount:
                    action.payload.action_step_bonus_monthly_total,
                homelessInfo: action.payload,
                isFetchingHomelessInfo: false,
                isUnenrollingHomeless: false,
                remainingBonusAmount:
                    state.maxBonusAmount !== null
                        ? state.maxBonusAmount -
                          action.payload.action_step_bonus_monthly_total
                        : 0,
                unenrollmentSuccess: false
            };
        case CONSTANTS.SET_HOMELESS_INFO_ERROR: {
            return {
                ...state,
                hasFetchedHomelessGoalActions: false,
                hasFetchedHomelessNeeds: false,
                isFetchingHomelessInfo: false,
                notFoundHomelessInfo: true
            };
        }
        case CONSTANTS.SET_HOMELESS_LIFESTORY:
            return {
                ...state,
                homelessLifeStory: action.payload,
                isFetchingHomelessLifeStory: false
            };
        case CONSTANTS.SET_MAX_BONUS_AMOUNT:
            return {
                ...state,
                maxBonusAmount: action.payload
            };
        case CONSTANTS.SET_MEMBER_HISTORY:
            return {
                ...state,
                homelessHistory: action.payload,
                isFetchingHomelessHistory: false
            };
        case CONSTANTS.SET_PROFILE_COMPLETED_ITEMS:
            const updatedProfileItems = Object.keys(state.profileItems).reduce(
                (acc, key) => {
                    if (action.payload.includes(key)) {
                        acc[key] = {
                            ...state.profileItems[key],
                            status: 'complete'
                        };
                    }
                    return acc;
                },
                { ...state.profileItems }
            );

            return {
                ...state,
                profileItems: updatedProfileItems
            };
        case CONSTANTS.SET_UNENROLLMENT_SUCCESS:
            return {
                ...state,
                isUnenrollingHomeless: false,
                unenrollmentSuccess: true
            };
        case CONSTANTS.SUBMIT_HOMELESS_LIFESTORY:
            return {
                ...state,
                homelessLifeStorySuccess: false,
                isSubmittingHomelessLifeStory: true
            };
        case CONSTANTS.UNENROLL_HOMELESS:
            return {
                ...state,
                isUnenrollingHomeless: true,
                unenrollmentSuccess: false
            };
        case CONSTANTS.UPDATE_BONUS_AMOUNT:
            return {
                ...state,
                assignedBonusAmount: state.assignedBonusAmount + action.payload,
                remainingBonusAmount:
                    (state.remainingBonusAmount ?? 0) - action.payload
            };
        case CONSTANTS.UPDATE_HOMELESS_BALANCE:
            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    'balance',
                    state.homelessInfo.balance + action.payload.balance
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_CARD_BALANCE:
            return {
                ...state,
                homelessInfo: {
                    ...state.homelessInfo,
                    card: updateObject(
                        state.homelessInfo.card,
                        'available_balance',
                        state.homelessInfo.card?.available_balance +
                            action.payload.balance
                    )
                }
            };
        case CONSTANTS.UPDATE_HOMELESS_INFO_FIELD:
            // const mapProfileItemToCatchUpItem = {
            //     contact_info: 'no_contact_info',
            //     birthday: 'no_date_of_birth',
            //     full_name: 'no_full_name',
            //     member_id: 'no_insurance_id',
            //     photo: 'no_profile_photo',
            //     supplemental_questions: 'no_supplemental_answers'
            // } as { [key: string]: string };

            // const updatedProfileItemToCatchUpItem =
            //     mapProfileItemToCatchUpItem[action.payload.field];

            // if (updatedProfileItemToCatchUpItem) {
            //     const updatedCatchUpItems = state.catchUpItems?.map((item) => {
            //         if (item.type === updatedProfileItemToCatchUpItem) {
            //             return {
            //                 ...item,
            //                 status: 'complete'
            //             };
            //         }
            //         return item;
            //     });

            //     return {
            //         ...state,
            //         catchUpItems: updatedCatchUpItems,
            //         homelessInfo: updateObject(
            //             state.homelessInfo,
            //             action.payload.field,
            //             action.payload.value
            //         )
            //     };
            // }

            return {
                ...state,
                homelessInfo: updateObject(
                    state.homelessInfo,
                    action.payload.field,
                    action.payload.value
                )
            };
        case CONSTANTS.UPDATE_HOMELESS_LIFESTORY:
            return {
                ...state,
                homelessLifeStory: [action.payload, ...state.homelessLifeStory],
                homelessLifeStorySuccess: true,
                isSubmittingHomelessLifeStory: false
            };
        case CONSTANTS.UPDATE_PROFILE_COMPLETED_ITEMS:
            return {
                ...state,
                profileItems: {
                    ...state.profileItems,
                    [action.payload]: {
                        status: 'pending'
                    }
                }
            };
        default:
            return state;
    }
}
