import SamaritanFetch from './httpClient';

export const fetchHomelessGoals = (id: number) =>
    SamaritanFetch.v3(`/goals`, {
        query: {
            'goals[status]': 'pending',
            'goals[homeless_id]': id
        }
    });

export const postHomelessGoal = ({
    homelessId,
    description
}: {
    homelessId: number;
    description: string;
}) =>
    SamaritanFetch.v2.post(`/goals`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            goal: {
                homeless_id: homelessId,
                description
            }
        })
    });
