import { connect } from 'react-redux';

import Login from 'components/Login';
import actions from 'redux/actions';

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {
    fetchDebitTypes: actions.fetchDebitCardOptionsRequest,
    resetAllState: actions.resetAllState,
    resetUnauthorized: actions.resetUnauthorized,
    setUser: actions.setUser,
    setMaxBonusAmount: actions.setMaxBonusAmount
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
