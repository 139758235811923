import { useDispatch } from 'react-redux';

import LoadingCircle from 'components/_shared/LoadingCircle';
import Logo from 'assets/logo-sm.png';

import { getDebitCardInfo } from 'redux/actions/debit_card';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        balance: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px'
        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '24px'
        },
        info: {
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #fff',
            borderRadius: '8px',
            fontWeight: 700,
            gap: '16px',
            marginTop: '16px',
            padding: '8px 12px',
            '& i': {
                fontSize: '20px'
            }
        },
        refreshBtn: {
            backgroundColor: '#062D7A',
            borderRadius: '100px',
            color: '#fff',
            fontSize: '14px',
            fontWeight: 700,
            padding: '4px 12px',
            textTransform: 'none',
            '& i': {
                marginRight: '8px'
            }
        },
        walletCard: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#739BE8',
            borderRadius: '16px',
            gap: '8px',
            maxWidth: '480px',
            padding: '20px'
        }
    })
);

type WalletCardProps = {
    available_balance: number;
    debitType: null | string;
    id: number;
    isFetchingDebitInfo: boolean;
    last4: string;
};

const WalletCard: React.FC<WalletCardProps> = ({
    available_balance,
    debitType,
    id,
    isFetchingDebitInfo,
    last4
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleRefreshBalance = () => {
        dispatch(getDebitCardInfo(id));
    };

    const renderDebitType = () => {
        if (debitType === 'givecard') {
            return 'GiveCard';
        }

        return 'PEX Card';
    };

    return (
        <div className={classes.walletCard}>
            <div className={classes.cardHeader}>
                <img src={Logo} alt="Logo" />
                <Typography variant="h6">
                    {renderDebitType()} ending in {last4}
                </Typography>
            </div>
            <span style={{ fontSize: '24px' }}>
                {isFetchingDebitInfo ? (
                    <LoadingCircle
                        style={{
                            justifyContent: 'flex-start',
                            padding: '8px 16px'
                        }}
                    />
                ) : (
                    <div className={classes.balance}>
                        <Typography variant="h2" style={{ fontWeight: 700 }}>
                            ${available_balance}{' '}
                        </Typography>
                        <Button
                            className={classes.refreshBtn}
                            onClick={handleRefreshBalance}
                        >
                            <i className="fas fa-sync" />
                            Refresh
                        </Button>
                    </div>
                )}
                Available Balance
            </span>
            <span className={classes.info}>
                <i className="fas fa-info-circle" />
                Donation and bonuses will be automatically transferred to this
                debit card. Transfer may take up to 5 minutes.
            </span>
        </div>
    );
};

export default WalletCard;
