import React, { useEffect, useRef, useState } from 'react';

import AgreeCheckBox from './AgreeCheckbox';
import Button from './Button';
import CardBackground from '../../../_shared/CardBackground';
import LoadingCircle from '../../../_shared/LoadingCircle';
import MessageBubble from '../../../_shared/MessageBubble';
import Modal from '../../../_shared/Modal';
import TermsCard from './TermsCard';

import useRemoteCopy from '../../../../hooks/useRemoteCopy';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '16px',
        height: 'fit-content',
        margin: '0 auto 50px auto',
        padding: '50px 0 0 0'
    },
    title: {
        color: '#7378E8',
        fontFamily: 'Manrope',
        fontSize: '1.5rem',
        fontWeight: 800,
        letterSpacing: '0.94px',
        margin: '2.375rem 0 0'
    },
    label: {
        color: '#1F1F51',
        fontFamily: 'Manrope',
        fontSize: '1.125rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '2rem',
        margin: '1.75rem 0 0.5rem'
    },
    description: {
        color: '#1F1F51',
        fontFamily: 'Manrope',
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.6875rem'
    },
    termsBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        width: '75%',
        gap: '2rem'
    }
});

type BodyProps = {
    agreed: boolean;
    org: string;
    profileName: string;
    setIcebreaker: () => void;
    submitHandler: () => void;
    toggleAgreed: () => void;
};

const Body: React.FC<BodyProps> = ({
    agreed,
    org,
    profileName,
    setIcebreaker,
    submitHandler,
    toggleAgreed
}) => {
    const classes = useStyles();
    const refetchCount = useRef(0);

    const [isErrored, setIsErrored] = useState(false);

    const privacy_policy = useRemoteCopy('privacy_policy');
    const terms_of_service = useRemoteCopy('terms_of_service');
    const welcome = useRemoteCopy('welcome_to_your_samaritan_membership');

    const bubbleMessage = `Can we run through and make sure you agree to Samaritan’s terms of services?`;

    const cardBody = {
        membership_benefits: {
            list: [
                'Earn bonuses for completing action steps set with your case manager',
                'Receive additional donations and messages of support from Samaritan’s community of supporters',
                'Funds earned or given can be used alongside your case manager'
            ]
        },
        terms_of_service: {
            list: [
                'Memberships last up to 12 months',
                `You must be actively working with the ${org} team, meeting at least once every 3 months`,
                'Treat care team with respect; zero tolerance for abuse and harassment',
                'If your eligibility changes, your Membership could end early',
                'Any breach in these terms will result in a termination of your Membership',
                'When your Membership ends, any unused funds will be redirected to other Members in need '
            ]
        },
        privacy_policy: {
            list: [
                'We’ll share your profile (including your picture, story, progress, and needs) with our network of supporters',
                'Your private and contact information is never shared publicly',
                'You can always opt out of sharing info on your public profile or any benefits'
            ]
        }
    };

    const renderTermCards = () => {
        const cards: React.ReactNode[] = [];

        Object.entries(cardBody).forEach((entry) => {
            const [key, value] = entry;

            cards.push(<TermsCard key={key} list={value.list} title={key} />);
        });

        return cards;
    };

    useEffect(() => {
        if (privacy_policy.isError && refetchCount.current < 5) {
            refetchCount.current++;
            privacy_policy.refetch();
        }
        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [privacy_policy]);

    useEffect(() => {
        if (terms_of_service.isError && refetchCount.current < 5) {
            refetchCount.current++;
            terms_of_service.refetch();
        }
        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [terms_of_service]);

    useEffect(() => {
        if (welcome.isError && refetchCount.current < 5) {
            refetchCount.current++;
            welcome.refetch();
        }
        if (refetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [welcome]);

    if (isErrored)
        return (
            <Modal open={isErrored}>
                <div style={{ padding: 20 }}>
                    <span className="font-lg">Something has gone wrong.</span>
                    <span>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </span>
                </div>
            </Modal>
        );

    if (
        !terms_of_service.isLoaded ||
        !privacy_policy.isLoaded ||
        !welcome.isLoaded
    ) {
        return <LoadingCircle />;
    }

    return (
        <div className={classes.root}>
            <CardBackground />
            <MessageBubble question={bubbleMessage} />
            <div className={classes.termsBody}>{renderTermCards()}</div>
            <AgreeCheckBox
                active={agreed}
                profileName={profileName}
                clickHandler={() => {
                    setIcebreaker();
                    toggleAgreed();
                }}
            />
            <Button active={agreed} clickHandler={submitHandler} />
        </div>
    );
};

export default Body;
