import { useEffect } from 'react';
import store from 'store2';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        overlay: {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 4
        },
        tooltipContainer: {
            position: 'absolute',
            width: '100%',
            zIndex: 4,
            '@media (max-width: 928px)': {
                top: 0
            }
        },
        triangle: {
            position: 'absolute',
            top: '62px',
            right: '33px',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid #FFFFFF'
        },
        tooltip: {
            position: 'absolute',
            top: '70px',
            right: '30px',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            padding: '8px 16px',
            marginLeft: '200px'
        }
    })
);

type TooltipProps = {
    name: string;
    userID: number;
    setShowTooltip: (value: boolean) => void;
};

const Tooltip: React.FC<TooltipProps> = ({ name, userID, setShowTooltip }) => {
    const classes = useStyles();

    const handleViewedTooltip = () => {
        setShowTooltip(false);
    };

    useEffect(() => {
        const storageValueDelay = setTimeout(() => {
            const tooltipValue = store.get('renderSaveMemberTooltip');

            if (tooltipValue && typeof tooltipValue === 'object') {
                tooltipValue[userID] = true;
                store.set('renderSaveMemberTooltip', tooltipValue);
            }
        }, 500);

        return () => clearTimeout(storageValueDelay);
    }, [userID]);

    useEffect(() => {
        const delay = setTimeout(() => {
            setShowTooltip(false);
        }, 5000);

        return () => clearTimeout(delay);
    }, [setShowTooltip]);

    return (
        <>
            <div
                className={classes.overlay}
                onClick={() => handleViewedTooltip()}
            />
            <div className={classes.tooltipContainer}>
                <span className={classes.triangle} />
                <span className={classes.tooltip}>
                    Click this button to add {name} to your "My Members" list
                </span>
            </div>
        </>
    );
};

export default Tooltip;
