import { useRef } from 'react';
import ReactGA from 'react-ga4';

import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    confirmMemberContainer: {
        position: 'absolute',
        top: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: '16px',
        fontWeight: 700,
        gap: '10px',
        background: '#E1625E',
        borderRadius: '0 40px 40px 0',
        color: '#FFFFFF',
        cursor: 'pointer',
        marginRight: '20px',
        minWidth: '440px',
        padding: '12px 20px 12px 20%',
        '&:hover': {
            background: '#58111a',
            transition: 'background 0.5s ease'
        }
    }
}));

type ConfirmMemberProps = {
    setConfirmMemberPopUp: (value: boolean) => void;
};

const ConfirmMember: React.FC<ConfirmMemberProps> = ({
    setConfirmMemberPopUp
}) => {
    const classes = useStyles();
    const nodeRef = useRef(null);

    const handleConfirmMemberClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Confirm Member Click'
        });

        setConfirmMemberPopUp(true);
    };

    return (
        <ButtonBase
            ref={nodeRef}
            className={classes.confirmMemberContainer}
            onClick={handleConfirmMemberClick}
        >
            <span>Confirm Membership</span>
            <i className="fas fa-chevron-right" />
        </ButtonBase>
    );
};

export default ConfirmMember;
