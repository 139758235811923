import * as constants from "../constants/constants";

const initialState = {
  error: null,
  redemptionList: [],
  status: "idle",
};

export default function redemptionReducer(state = initialState, action: any) {
  switch (action.type) {
    case constants.FAILED_GETTING_REDEMPTION_LIST:
      return {
        ...state,
        status: "failed",
        error: action.error,
      };
    case constants.GETTING_REDEMPTION_LIST:
      return {
        ...state,
        status: "loading",
      };
    case constants.GOT_REDEMPTION_LIST:
      return {
        ...state,
        status: "success",
        redemptionList: action.payload,
      };
    default:
      return state;
  }
}
