import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import LoadingCircle from 'components/_shared/LoadingCircle';
import PopUp from 'components/holder_profile/profile_2.0/PopUp';
import SettingsCard from './components/SettingsCard';

import { isObjectEmpty } from '../../util';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        settingsPage: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '2rem',
            height: '100vh',
            overflow: 'auto'
        },
        settingsBody: {
            width: '95%'
        }
    })
);

type Props = {
    fetchUser: () => void;
    setLayoutTitles: (title: string, subtitle: string) => void;
    updateUserSettings: (
        id: number,
        settings: { [key: string]: boolean | string }
    ) => void;
    user: {
        id: number;
        email: string;
        frequency: number;
        holders_notifications_subscription: boolean;
        is_showing_intake_guides: boolean;
        language_preference: string;
        last_name: string;
        name: string;
        phone_number: string;
    };
};

const Settings: React.FC<Props> = ({
    fetchUser,
    setLayoutTitles,
    updateUserSettings,
    user
}) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(isObjectEmpty(user));
    const [toggleGuide, setToggleGuide] = useState(
        user.is_showing_intake_guides || false
    );
    // const [toggleNotifications, setToggleNotifications] = useState(
    //     user.holders_notifications_subscription
    // );
    const [updateLanguage, setUpdateLanguage] = useState(false);

    useEffect(() => {
        if (!isObjectEmpty(user)) {
            setLoading(false);
            setLayoutTitles('', 'Settings');
        }
    }, [fetchUser, setLayoutTitles, user]);

    return (
        <div className={classes.settingsPage}>
            <Helmet title="Settings" />
            <h1 style={{ paddingTop: '10px' }}>Settings</h1>
            {loading ? (
                <LoadingCircle />
            ) : (
                <div className={classes.settingsBody}>
                    <SettingsCard
                        cardType="account"
                        setUpdateLanguage={setUpdateLanguage}
                        user={user}
                    />
                    <SettingsCard
                        cardType="tips"
                        setToggle={setToggleGuide}
                        toggle={toggleGuide}
                        updateUserSettings={updateUserSettings}
                        user={user}
                    />
                    {/* <SettingsCard
                        cardType="notifications"
                        setToggle={setToggleNotifications}
                        toggle={toggleNotifications}
                        updateUserSettings={updateUserSettings}
                        user={user}
                    /> */}
                </div>
            )}
            {updateLanguage && (
                <PopUp
                    content="update language"
                    handleModalClick={setUpdateLanguage}
                />
            )}
        </div>
    );
};

export default Settings;
