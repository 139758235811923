import SamaritanFetch from './httpClient';

// TODO: Fix any types
export const submitTouchpointAnswers = (payload: any) =>
    SamaritanFetch.v3.post('/touchpoints', {
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
