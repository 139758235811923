import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';

import './fonts';
import './index.css';

import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import { FeatureToggleProvider } from './components/_util_components/FeatureToggle';
import { FeatureToggles } from './settings';
import { SamaritanLight } from './components/_theme';

import configureStore from './redux/store/configure-store';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const container = document.getElementById('root');
const root = createRoot(container!);

// redux store
const store = configureStore();

// google analytics
ReactGA.initialize('G-PWRJ02CVLR');

// sentry
Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://4ee2107a5f924e5cb63606a2de6b3388@o511377.ingest.sentry.io/5608466',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
    ],
    normalizeDepth: 5,
    release: process.env.REACT_APP_VERSION,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    tracesSampleRate: 1.0
});

root.render(
    <CookiesProvider>
        <Provider store={store}>
            <FeatureToggleProvider defaults={FeatureToggles}>
                <SamaritanLight>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                    </LocalizationProvider>
                </SamaritanLight>
            </FeatureToggleProvider>
        </Provider>
    </CookiesProvider>
);

// let token = "d7FhKnFa6FM9Sz7uY4D_zQvxy2SrwpF6"
// store.dispatch(getTouchpointAnswersRequest(token, 1));

// store.dispatch(fetchPosts('reactjs')).then(() => console.log(store.getState()))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
