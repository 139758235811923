import { Link } from 'react-router-dom';

import { withStyles } from '@mui/styles';

const styles = () => ({
    root: {
        textDecoration: 'none'
    }
});

type CustomLinkProps = {
    children: React.ReactNode;
    classes: {
        root: string;
    };
    to: string;
};

const CustomLink: React.FC<CustomLinkProps> = ({
    children,
    classes,
    ...rest
}) => {
    const { to } = rest;

    return (
        <Link {...rest} className={classes.root} to={to}>
            {children}
        </Link>
    );
};

export default withStyles(styles)(CustomLink);
