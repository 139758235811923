import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    CatchUpItems,
    DebitCard,
    DebitCardOptions,
    Homeless,
    RecordType,
    SupplementalQuestions,
    TouchpointAnswers,
    TouchpointQuestions
} from 'types';

import AddInfo from '../../../ProfileCompletion/components/AddInfo';
import AssignCardPopUp from '../../../Funds/AssignCardPopUp';
import PhotoPopup from 'components/_shared/PhotoPopup';
import PopUp from '../../../PopUp';
import QuestionBody from '../../../PublicInfo/SupplementalQuestions/components/QuestionBody';
import UpdateStatusPopUp from '../UpdateStatusPopUp';
import SummaryHeading from './SummaryHeading';
import UpdateModal from 'components/_shared/UpdateModal';
import {
    ActionStep,
    Balance,
    ButtonDrawer,
    CardButton,
    CatchUpItem,
    Overlay,
    Photo,
    Status
} from '../index';

import { isObjectEmpty } from 'util/index';

import useSummaryItemTypes from './SummaryItemTypes';
import useSummaryPopUpState from './SummaryPopUpState';
import { useUpdatePublicProfile } from 'hooks';

import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        heading: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        headingContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            height: '213px'
        },
        line: {
            flex: 'none',
            flexGrow: 0,
            order: 1,
            background: '#A6A6BF',
            borderRadius: '1px',
            width: '70px',
            height: '2px'
        },
        summaryContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '32px',
            color: '#A6A6BF',
            fontSize: '1.75rem',
            fontWeight: 300,
            height: '100%',
            margin: 'auto',
            maxWidth: '560px',
            padding: '0 20px',
            '@media (max-width: 768px)': {
                gap: '16px'
            }
        },
        title: {
            color: '#A6A6BF',
            fontSize: '1.75rem',
            fontWeight: 300
        }
    })
);

// calculate height of catch up item card so that the position
// of the element is uniform throughout all of the renders
const calculateHeight = (
    items: CatchUpItems[],
    itemType: string,
    itemTypes: {
        [key: string]: {
            content?: string[];
            contentIcons?: { [key: string]: string };
            height?: number;
            icon: string;
            title: string;
        };
    },
    quickCatchUp: boolean,
    quickItemIndex: number
) => {
    const baseHeight = 171;
    const additionalHeightPerItem = 37.5;
    const shiftPerStackedCard = 21;
    const contentLength = itemTypes[itemType]?.content?.length || 0;
    const contentHeight = itemTypes[itemType]?.height || 0;

    const numberOfStackedCards =
        (!quickCatchUp && items.length >= 3) ||
        (quickCatchUp && items.length - quickItemIndex - 1 >= 3)
            ? 2
            : !quickCatchUp
            ? items.length - 1
            : items.length - quickItemIndex - 1;
    const stackedCardsShift = numberOfStackedCards * shiftPerStackedCard;

    return (
        baseHeight +
        (contentLength > 1 ? contentLength * additionalHeightPerItem : 0) +
        stackedCardsShift +
        contentHeight
    );
};

type SummaryProps = {
    catchUpItemIndex: number;
    catchUpItemsLength: number;
    debitInfo: DebitCard;
    debitCardOptions: DebitCardOptions[];
    getApiErrorResponse: { message?: string };
    holder: Homeless;
    isEditingGoalAction: boolean;
    items: CatchUpItems[];
    maxBonusAmount: number;
    name: string;
    quickCatchUp: boolean;
    remainingBonusAmount: number;
    setCatchUpItemIndex: (index: number) => void;
    supplementalQuestions: SupplementalQuestions[];
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    updateGoalActionStatus: (id: number, status: 'completed') => void;
    updateHomelessInfoField: (field: string, value: string) => void;
    updateProfileCompletedItems: (type: string) => void;
};

const Summary: React.FC<SummaryProps> = ({
    catchUpItemIndex,
    catchUpItemsLength,
    debitInfo,
    debitCardOptions,
    getApiErrorResponse,
    holder,
    isEditingGoalAction,
    items,
    maxBonusAmount,
    name,
    quickCatchUp,
    remainingBonusAmount,
    setCatchUpItemIndex,
    supplementalQuestions,
    touchpointAnswers,
    touchpointQuestions,
    updateGoalActionStatus,
    updateHomelessInfoField,
    updateProfileCompletedItems
}) => {
    const classes = useStyles();

    const firstItem = useMemo(
        () => items[quickCatchUp ? catchUpItemIndex : 0],
        [items, quickCatchUp, catchUpItemIndex]
    );
    const nextItem = useMemo(
        () => items[quickCatchUp ? catchUpItemIndex + 1 : 1] || null,
        [items, quickCatchUp, catchUpItemIndex]
    );

    const catchUpItemStatus = useSelector(
        (state: {
            catchUpItems: {
                catchUpItemStatus: {
                    [key: number]: {
                        error: boolean;
                        loading: boolean;
                        success: boolean;
                    };
                };
            };
        }) =>
            state.catchUpItems.catchUpItemStatus[firstItem.id] || {
                loading: false,
                success: false,
                error: false
            }
    );

    const { id, type, relations } = firstItem;
    const { relations: nextItemRelations } = nextItem || {};
    const {
        action_step_bonus_monthly_total,
        balance,
        first_name,
        id: homelessID,
        member_id,
        name: homelessName,
        photo
    } = holder;

    const [actionStepRecord, setActionStepRecord] = useState<RecordType>(
        relations.record
    );
    const [catchUpType, setCatchUpType] = useState<string>(type);
    const [summaryState, setSummaryState] = useState({
        clickedItem: null as null | string,
        currentHeight: 0,
        drawerOpen: true,
        infoModal: false,
        infoType: null as null | string,
        overlayType: null as null | string,
        nextHeight: 0,
        popUpOpen: false,
        removeItem: false
    });

    const {
        clickedItem,
        currentHeight,
        drawerOpen,
        infoModal,
        infoType,
        overlayType,
        nextHeight,
        popUpOpen,
        removeItem
    } = summaryState;

    const itemTypes = useSummaryItemTypes(
        first_name ? first_name : name,
        touchpointQuestions
    );
    const currentItemType = itemTypes[type];
    const nextItemType = itemTypes[nextItem?.type || ''];

    const {
        completeAS,
        dateISO,
        debitAssign,
        debitRedeem,
        debitTransfer,
        debitType,
        editAS,
        profilePicture,
        randomQuestionIndex,
        remindAS,
        resetSummaryPopUpState,
        setCompleteAS,
        setDateISO,
        setDebitAssign,
        setDebitRedeem,
        setDebitTransfer,
        setDebitType,
        setEditAS,
        setProfilePicture,
        setRandomQuestionIndex,
        setRemindAS
    } = useSummaryPopUpState();

    const { question, id: questionID } =
        supplementalQuestions[randomQuestionIndex];

    const { updatePublicProfile, isError, isLoading, isSuccess, reset } =
        useUpdatePublicProfile();

    const handleClosePopUp = () => {
        updateSummaryState({ popUpOpen: false });
    };

    const handleSaveProfilePicture = useCallback(() => {
        const changedState = {} as { [key: string]: Blob };
        const _formData = new FormData();

        if (profilePicture) {
            changedState['photo'] = profilePicture;
            updateProfileCompletedItems('photo');
        }

        if (!isObjectEmpty(changedState)) {
            for (const [key, value] of Object.entries(changedState)) {
                _formData.append(`samaritan_member[${key}]`, value);
            }

            updatePublicProfile(homelessID, _formData, id);
        } else {
            updateSummaryState({ popUpOpen: false });
        }
    }, [
        homelessID,
        id,
        profilePicture,
        updateProfileCompletedItems,
        updatePublicProfile
    ]);

    const calculatedHeight = useMemo(
        () =>
            calculateHeight(
                items,
                type,
                itemTypes,
                quickCatchUp,
                catchUpItemIndex
            ),
        [catchUpItemIndex, items, itemTypes, quickCatchUp, type]
    );
    const nextItemCalculatedHeight = useMemo(
        () =>
            calculateHeight(
                items,
                nextItem?.type || '',
                itemTypes,
                quickCatchUp,
                catchUpItemIndex + 1
            ),
        [catchUpItemIndex, items, itemTypes, nextItem, quickCatchUp]
    );

    const handleCloseModalClick = () => {
        updateSummaryState({ infoModal: false, infoType: null });
    };

    const renderContent = {
        action_step_due: (
            <ActionStep actionStep={actionStepRecord as RecordType} />
        ),
        funds_above_25_dollars: <Balance balance={balance} />,
        no_profile_photo: (
            <Photo
                insurance={member_id}
                name={homelessName}
                setPhotoPopup={() => updateSummaryState({ popUpOpen: true })}
            />
        ),
        no_status_capture_in_last_30_days: (
            <Status
                touchpointAnswers={touchpointAnswers}
                touchpointQuestions={touchpointQuestions}
            />
        ),
        no_supplemental_answers: (
            <QuestionBody
                answer={undefined}
                handleAddClick={() => updateSummaryState({ popUpOpen: true })}
                prompt={question}
                promptRefresh={true}
                questionsLength={supplementalQuestions.length}
                randomQuestionIndex={randomQuestionIndex}
                setRandomQuestionIndex={setRandomQuestionIndex}
            />
        )
    } as {
        [key: string]: null | JSX.Element;
    };

    const renderPopup = () => {
        if (!popUpOpen) return null;

        if (
            type === 'funds_above_25_dollars' &&
            !debitInfo.account_id &&
            !debitType
        ) {
            return (
                <AssignCardPopUp
                    debitCardOptions={debitCardOptions}
                    handleModalClick={() =>
                        updateSummaryState({ popUpOpen: false })
                    }
                    photo={photo}
                    setDebitType={setDebitType}
                />
            );
        }

        if (
            type === 'action_step_due' &&
            relations.record?.action_type === 'lifecarable'
        ) {
            return (
                <UpdateStatusPopUp
                    catchUpItemID={id}
                    onClose={() => updateSummaryState({ popUpOpen: false })}
                />
            );
        }

        switch (type) {
            case 'no_profile_photo':
                return (
                    <PhotoPopup
                        handleModalClick={() =>
                            updateSummaryState({ popUpOpen: false })
                        }
                        setProfilePicture={setProfilePicture}
                    />
                );
            case 'no_status_capture_in_last_30_days':
                return (
                    <UpdateStatusPopUp
                        catchUpItemID={id}
                        onClose={() => updateSummaryState({ popUpOpen: false })}
                    />
                );
            default:
                const contentType = () => {
                    switch (true) {
                        case completeAS:
                            return 'complete action step';
                        case editAS:
                            return 'edit action step';
                        case remindAS:
                            return 'reminder';
                        case type === 'funds_above_25_dollars':
                            return 'debit card';
                        case type === 'no_supplemental_answers':
                            return 'supplemental question';
                        default:
                            return '';
                    }
                };

                return (
                    <PopUp
                        content={contentType()}
                        actionStepID={relations.record.id}
                        answer={undefined}
                        balance={balance}
                        catchUpID={id}
                        debitInfo={debitInfo}
                        debitCardOptions={debitCardOptions}
                        debitType={debitType}
                        fromCatchUp={true}
                        handleModalClick={handleClosePopUp}
                        holder={holder}
                        id={holder.id}
                        maxBonusAmount={maxBonusAmount}
                        profileItemType={type}
                        prompt={question}
                        questionID={questionID}
                        questionIndex={randomQuestionIndex}
                        questionsLength={supplementalQuestions.length}
                        remainingBonusAmount={remainingBonusAmount}
                        reminderAction={relations.record.description}
                        setDebitType={setDebitType}
                        setQuestionIndex={setRandomQuestionIndex}
                        setReminder={setRemindAS}
                        touchpointAnswers={touchpointAnswers}
                        touchpointQuestions={touchpointQuestions}
                        totalBonusAmount={action_step_bonus_monthly_total}
                        {...editProps}
                    />
                );
        }
    };

    const renderTitle = () => {
        switch (infoType) {
            case 'action_steps':
                return 'Action Steps';
            case 'birthday':
                return 'Date of Birth';
            case 'contact_info':
                return 'Contact Information';
            case 'full_name':
                return 'Legal Name';
            case 'member_id':
                return 'Insurance ID';
            case 'name':
                return 'Nickname';
            case 'needs':
                return 'Immediate Needs';
            case 'photo':
                return 'Profile Photo';
            case 'status':
                return 'Status';
            case 'supplemental_questions':
                return 'Fun Facts';
            default:
                return '';
        }
    };

    const updateSummaryState = (newState: {
        [key: string]: string | boolean | number | null;
    }) => {
        setSummaryState((prevState) => ({
            ...prevState,
            ...newState
        }));
    };

    useEffect(() => {
        if (calculatedHeight !== currentHeight) {
            setSummaryState((prevState) => {
                if (prevState.currentHeight !== calculatedHeight) {
                    return {
                        ...prevState,
                        currentHeight: calculatedHeight
                    };
                }

                return prevState;
            });
        }

        if (clickedItem) {
            setSummaryState((prevState) => {
                if (prevState.nextHeight !== nextItemCalculatedHeight) {
                    return {
                        ...prevState,
                        nextHeight: nextItemCalculatedHeight
                    };
                }

                return prevState;
            });

            // Handle overlayType reset with timeout
            setTimeout(() => {
                updateSummaryState({ overlayType: null });
            }, 1500);

            // Handle clickedItem reset, drawer state update, and resetting the summary pop-up state
            setTimeout(() => {
                updateSummaryState({
                    clickItem: null,
                    currentHeight: nextHeight,
                    drawerOpen: true,
                    removeItem: false
                });
                resetSummaryPopUpState();
            }, 2000);
        }
    }, [
        calculatedHeight,
        currentHeight,
        clickedItem,
        nextHeight,
        nextItemCalculatedHeight,
        resetSummaryPopUpState
    ]);

    useEffect(() => {
        if (profilePicture) {
            handleSaveProfilePicture();
        }
    }, [handleSaveProfilePicture, profilePicture]);

    useEffect(() => {
        if (isLoading) {
            updateSummaryState({ overlayType: 'loading' });
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess) {
            setProfilePicture(null);
            updateHomelessInfoField('photo', 'true');
        } else if (isError) {
            console.log('error');
            reset();
        }
    }, [
        isError,
        isSuccess,
        profilePicture,
        reset,
        setProfilePicture,
        updateHomelessInfoField
    ]);

    useEffect(() => {
        if (catchUpItemStatus.error) {
            updateSummaryState({ overlayType: 'error' });
        }

        if (catchUpItemStatus.loading) {
            updateSummaryState({ overlayType: 'loading' });
        }

        if (catchUpItemStatus.success) {
            switch (type) {
                case 'action_step_due':
                    if (editAS) {
                        updateSummaryState({ overlayType: 'edit' });
                    } else if (remindAS) {
                        updateSummaryState({ overlayType: 'remind' });
                    } else {
                        updateSummaryState({ overlayType: 'add' });
                    }
                    break;
                case 'funds_above_25_dollars':
                    if (debitAssign) {
                        updateSummaryState({ overlayType: 'add' });
                    } else if (debitRedeem) {
                        updateSummaryState({ overlayType: 'redeem' });
                    } else if (debitTransfer) {
                        updateSummaryState({ overlayType: 'transfer' });
                    } else {
                        updateSummaryState({ overlayType: 'add' });
                    }
                    break;
                default:
                    updateSummaryState({ overlayType: 'add' });
            }

            setTimeout(() => {
                if (type === 'action_step_due' && editAS) {
                    updateSummaryState({ clickedItem: 'edit' });
                } else if (type === 'action_step_due' && remindAS) {
                    updateSummaryState({ clickedItem: 'remind' });
                } else {
                    updateSummaryState({ clickedItem: 'complete' });
                }

                updateSummaryState({ drawerOpen: false, popUpOpen: false });
            }, 1000);
        }

        if (
            !catchUpItemStatus.error &&
            !catchUpItemStatus.loading &&
            !catchUpItemStatus.success
        ) {
            updateSummaryState({ overlayType: null });
        }
    }, [
        catchUpItemStatus.error,
        catchUpItemStatus.loading,
        catchUpItemStatus.success,
        clickedItem,
        debitAssign,
        debitRedeem,
        debitTransfer,
        editAS,
        remindAS,
        type
    ]);

    useEffect(() => {
        if (removeItem) {
            const timeout = setTimeout(() => {
                setActionStepRecord(nextItemRelations?.record);
                setCatchUpType(nextItem?.type);
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [nextItem, nextItemRelations, removeItem]);

    const editProps = {
        dateISO,
        editAction: relations.record,
        isEditingGoalAction,
        setDateISO
    };

    return (
        <Fade in timeout={2000}>
            <div className={classes.summaryContainer}>
                <SummaryHeading
                    catchUpItem={firstItem}
                    catchUpItemIndex={catchUpItemIndex}
                    catchUpItemsLength={catchUpItemsLength}
                    items={items}
                    name={name}
                    quickCatchUp={quickCatchUp}
                    removeItem={removeItem}
                />
                <div
                    className={classes.itemContainer}
                    style={{
                        height: `${currentHeight}px`,
                        transition: 'height 0.5s ease-in-out'
                    }}
                >
                    {items.length > 0 && (
                        <CatchUpItem
                            catchUpItemIndex={catchUpItemIndex}
                            iconName={`fas ${currentItemType.icon}`}
                            items={items}
                            nextItem={nextItem}
                            nextItemContent={renderContent[catchUpType]}
                            nextItemType={nextItemType}
                            quickCatchUp={quickCatchUp}
                            removeItem={removeItem}
                            snoozed={
                                items[quickCatchUp ? catchUpItemIndex : 0]
                                    .snooze_till
                            }
                            title={currentItemType.title}
                        >
                            {overlayType && (
                                <Overlay
                                    itemType={type}
                                    message={getApiErrorResponse.message || ''}
                                    overlayType={overlayType}
                                />
                            )}
                            {currentItemType.content
                                ? currentItemType.content!.map(
                                      (content: string, index: number) => (
                                          <CardButton
                                              key={index}
                                              icon={
                                                  currentItemType.contentIcons
                                                      ? currentItemType.contentIcons![
                                                            content
                                                        ]
                                                      : currentItemType.icon
                                              }
                                              infoModal={infoModal}
                                              infoType={infoType}
                                              inputType={content}
                                              setSummaryState={setSummaryState}
                                          />
                                      )
                                  )
                                : renderContent[catchUpType]}
                        </CatchUpItem>
                    )}
                </div>
                <ButtonDrawer
                    clickedItem={clickedItem}
                    debitInfo={debitInfo}
                    drawerOpen={drawerOpen}
                    id={id}
                    isLoading={catchUpItemStatus.loading}
                    quickCatchUp={quickCatchUp}
                    record={relations.record}
                    setCatchUpItemIndex={setCatchUpItemIndex}
                    setCompleteAS={setCompleteAS}
                    setDebitAssign={setDebitAssign}
                    setDebitRedeem={setDebitRedeem}
                    setDebitTransfer={setDebitTransfer}
                    setEditAS={setEditAS}
                    setRemindAS={setRemindAS}
                    setSummaryState={setSummaryState}
                    type={type}
                    updateGoalActionStatus={updateGoalActionStatus}
                />
                {infoModal && infoType && (
                    <UpdateModal
                        closeModalClick={handleCloseModalClick}
                        title={infoType && renderTitle()}
                    >
                        <AddInfo
                            catchUpID={id}
                            id={homelessID}
                            // setType={setInfoType}
                            type={infoType || ''}
                            updateHomelessInfoField={updateHomelessInfoField}
                            updateProfileCompletedItems={
                                updateProfileCompletedItems
                            }
                        />
                    </UpdateModal>
                )}
                {popUpOpen && renderPopup()}
            </div>
        </Fade>
    );
};

export default Summary;
