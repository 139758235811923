import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { createStatusMap, getColor, getMonths } from '../utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        display: 'flex',
        marginBottom: '30px',
        overflowX: 'scroll',
        padding: '0 10px'
    },
    chart: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        paddingLeft: '120px'
    },
    chartLines: {
        width: 'calc(100% - 30px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        position: 'absolute'
    },
    yAxis: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
    },
    xAxis: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        paddingBottom: '15px'
    },
    rankNameContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '18px',
        width: '100px'
    },
    rankNames: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '200px',
        padding: '2px 6px',
        fontSize: '10px',
        width: 'fit-content'
    },
    months: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '16px',
        color: '#A6A6BF',
        width: '60px',
        textAlign: 'center'
    },
    scoreContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '12px',
        width: '60px',
        marginRight: '10px',
        '&:active': {
            transform: 'scale(0.95)'
        }
    },
    statusRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '18px'
    },
    score: {
        width: '18px',
        height: '18px',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative'
    },
    statusLineContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '18px'
    },
    statusLine: {
        height: '1px',
        flexGrow: '1',
        borderRadius: '4px',
        opacity: '0.4'
    },
    infoIcon: {
        fontSize: '10px',
        position: 'absolute',
        zIndex: 2
    },
    vertLine: {
        width: '1px',
        height: '400px',
        background: '#999DFF',
        position: 'absolute',
        zIndex: 1
    }
}));

const Chart = ({
    questionOptions,
    classifiedInfo,
    setClassifiedInfo,
    updateStatusInfo,
    setUpdateStatusInfo,
    setStatusCardID,
    statusHistory
}) => {
    const classes = useStyles();

    const [xAxis, setxAxis] = useState([]);

    const getStatusColor = (val, rev) => {
        if (val === '?') {
            return {
                background: '#ededfa',
                color: '#a6a6bf',
                fontWeight: '800'
            };
        }

        if (val === 'i') {
            return {
                background: '#999DFF',
                color: '#FFFFFF'
            };
        }

        const primary = rev ? 'primaryReverse' : 'primary';
        const secondary = rev ? 'secondaryReverse' : 'secondary';

        const background = getColor(val - 1, secondary, questionOptions.length);
        const color = getColor(val - 1, primary, questionOptions.length);

        return { background, color };
    };

    const yAxis = questionOptions;
    const statusMap = createStatusMap(statusHistory, yAxis);

    useEffect(() => {
        if (statusHistory && statusHistory.length > 0) {
            setxAxis(getMonths(statusHistory));
        }
    }, [statusHistory]);

    useEffect(() => {
        const infoPopup = document.getElementById('info-popup');

        if (infoPopup) {
            infoPopup.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [classifiedInfo, updateStatusInfo]);

    return (
        <div className={classes.chartContainer}>
            <div
                className={classes.yAxis}
                style={{ position: 'absolute', zIndex: '2' }}
            >
                {yAxis.map((rank, index) => (
                    <div className={classes.rankNameContainer} key={index}>
                        <div
                            className={classes.rankNames}
                            style={getStatusColor(index + 1, true)}
                        >
                            {rank}
                        </div>
                    </div>
                ))}
            </div>
            <div className={classes.chartLines}>
                {yAxis.map((_rank, index) => (
                    <div className={classes.statusLineContainer} key={index}>
                        <div
                            className={classes.statusLine}
                            style={{
                                borderBottom: `1px dashed ${getColor(
                                    index,
                                    'primaryReverse',
                                    questionOptions.length
                                )}`
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className={classes.chart}>
                {Object.entries(statusMap)
                    .reverse()
                    .map(([key, value], index) => (
                        <div
                            className={classes.statusRow}
                            key={`chart-${key}-${value}`}
                        >
                            {value.map((status, statusIndex) => (
                                <div
                                    className={classes.scoreContainer}
                                    key={statusIndex}
                                >
                                    {status.answer === '?' && (
                                        <div
                                            className={classes.score}
                                            style={getStatusColor(
                                                status.answer,
                                                true
                                            )}
                                            id={`unclassified-score-${statusIndex}`}
                                            onClick={() =>
                                                setClassifiedInfo(true)
                                            }
                                        >
                                            {status.answer}
                                        </div>
                                    )}
                                    {status.answer === 'i' && (
                                        <div
                                            className={classes.score}
                                            style={getStatusColor(
                                                status.answer,
                                                true
                                            )}
                                            id={`unclassified-score-${statusIndex}`}
                                            onClick={() =>
                                                setUpdateStatusInfo(true)
                                            }
                                        >
                                            <i
                                                className={`${classes.infoIcon} fas fa-info`}
                                            />
                                            <div className={classes.vertLine} />
                                        </div>
                                    )}
                                    {status.answer !== 0 &&
                                        status.answer !== '?' &&
                                        status.answer !== 'i' && (
                                            <div
                                                className={classes.score}
                                                style={getStatusColor(
                                                    index + 1,
                                                    true
                                                )}
                                                id={`score-${statusIndex}`}
                                                onClick={() =>
                                                    setStatusCardID(status.id)
                                                }
                                            >
                                                {status.answer}
                                            </div>
                                        )}
                                </div>
                            ))}
                        </div>
                    ))}
                <div className={classes.xAxis}>
                    {xAxis.map((month, index) => (
                        <div className={classes.months} key={index}>
                            {month}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Chart.propTypes = {
    questionOptions: PropTypes.array.isRequired,
    statusHistory: PropTypes.array.isRequired
};

export default Chart;
