import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    input: {
        boxSizing: 'border-box',
        fontSize: '1.2rem',
        fontWeight: 500,
        padding: '10px 15px',
        border: 'none',
        margin: 5,
        borderBottom: '2px solid #767cd85c',
        outline: 'none',
        '&::placeholder': {
            color: '#7279E84c',
            fontWeight: 'bold'
        },
        '&:focus': {
            borderBottom: '2px solid #7279E8'
        }
    }
}));

type Props = {
    className?: string;
    id: string;
    name?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    type?: 'text' | 'password' | 'email';
    value?: string;
};

export default function Input({ className, ...props }: Props): JSX.Element {
    const styles = useStyles();

    const joinedClassNames = classNames(styles.input, className);

    return <input className={joinedClassNames} {...props} />;
}
