import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from 'components/_shared/Modal';
import { CTAButton1 } from 'components/_shared/buttons';

import Routes from 'routes';

import AuthManager from '../../auth';

// TODO: Type out user / fix any
type NewUserModalProps = {
    user: any;
    state: string;
};

const NewUserModal = ({ user, state }: NewUserModalProps) => {
    const auth = AuthManager.getInstance();

    const org_id = auth.getUserOrganizationId();
    const org_name = auth.getUserOrganizationName();
    const deployment_id = auth.getUserDeploymentId();
    const deployment_name = auth.getUserDeploymentName();
    const deployment_org_ids = auth.getDeploymentOrgIds();

    if (user.is_non_profit && user.partner) {
        if (org_id === undefined) {
            auth.setUserOrganizationId(user.partner.id);
        }

        if (org_name === undefined) {
            auth.setUserOrganizationName(user.partner.name);
        }

        if (user.partner.hasOwnProperty('deployment')) {
            if (deployment_id === undefined) {
                auth.setUserDeploymentId(`${user.partner.deployment.id}`);
            }

            if (deployment_name === undefined) {
                auth.setUserDeploymentName(user.partner.deployment.name);
            }

            if (deployment_org_ids === undefined) {
                auth.setDeploymentOrgIds([
                    ...user.partner.deployment.organization_ids
                ]);
            }
        }
    }

    const modalOpen = () => {
        if (state !== 'idle') {
            if (!user.partner) {
                return true;
            }

            if (!user.is_non_profit) {
                return true;
            }
        }

        return false;
    };

    return (
        <Modal open={modalOpen()}>
            <div style={{ padding: 20 }}>
                <div className="font-lg">
                    Your nonprofit status is pending approval.{' '}
                </div>
                <div className="font-base-bold">
                    <br />
                    <br />
                    Please reach out to{' '}
                    <a href="mailto:support@samaritan.city">
                        support@samaritan.city
                    </a>
                    . If you haven’t received approval within one business day.{' '}
                    <br />
                    <br />
                    Once approved, refresh this page to access full Partner
                    functionality!
                </div>
                <div style={{ marginTop: 10 }}>
                    <Link to={Routes.logout} style={{ textDecoration: 'none' }}>
                        <CTAButton1>Logout</CTAButton1>
                    </Link>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user.user,
    state: state.user.state
});

export default connect(mapStateToProps)(NewUserModal);
