import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    btn: {
        background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
        borderRadius: '1000px',
        color: '#fff',
        padding: '0.5rem 1rem',
        textTransform: 'none',
        '&:hover': {
            background: 'linear-gradient(180deg, #8571DF 0%, #7279E8 100%)',
            boxShadow: '0px 4px 10px rgba(115, 121, 232, 0.5)'
        }
    },
    disabled: {
        background: '#E3E3FA',
        color: '#ffffff !important'
    }
}));

type IntakeButtonProps = {
    disabled?: boolean;
    handleButtonClick: () => void;
    id: string;
    text: string;
};

const IntakeButton: React.FC<IntakeButtonProps> = ({
    disabled,
    handleButtonClick,
    id,
    text
}) => {
    const classes = useStyles();

    return (
        <Button
            className={
                disabled ? `${classes.btn} ${classes.disabled}` : classes.btn
            }
            id={id}
            onClick={() => handleButtonClick()}
            disabled={disabled}
        >
            {text}
        </Button>
    );
};

export default IntakeButton;
