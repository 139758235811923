import * as constants from '../constants/constants';

import * as api from 'api/redemption';
import { handleSamaritanApiError } from './error';
import { handleSamaritanApiResponse } from '.';

function gettingRedemptionList() {
    return {
        type: constants.GETTING_REDEMPTION_LIST
    };
}

function gotRedemption(list: string[]) {
    return {
        type: constants.GOT_REDEMPTION_LIST,
        payload: list
    };
}

function failedGettingRedemptionList(error: string) {
    return {
        type: constants.FAILED_GETTING_REDEMPTION_LIST,
        error
    };
}

export function getRedemptionThunk() {
    return async (dispatch: Function) => {
        dispatch(gettingRedemptionList());

        return api
            .fetchRedemptionItems()
            .then((response: Response) => {
                return handleSamaritanApiResponse(response, dispatch);
            })
            .then((resJson: { templates: string[] }) => {
                return dispatch(gotRedemption(resJson.templates));
            })
            .catch((error: Error) => {
                dispatch(failedGettingRedemptionList(error.message));

                return handleSamaritanApiError(error, dispatch);
            });
    };
}
