import { compose } from 'redux';

import { CloseButton } from './buttons';

import { Dialog } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
    wrapper: {
        backgroundColor: 'transparent'
    },
    button: {
        width: 'min-content',
        marginLeft: 'auto',
        marginBottom: '8px',
        cursor: 'pointer'
    },
    root: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: '16px'
    },
    actions: {
        justifyContent: 'center',
        paddingBottom: '5%',
        '& i': {
            marginRight: '10px'
        }
    },
    header: {
        display: 'flex',
        padding: '10% 0 5%',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    headerTitle: {
        lineHeight: '2.2rem',
        fontSize: '1.8rem',
        maxWidth: '40%',
        fontWeight: 300,
        marginLeft: '10%'
    },
    body: {
        marginLeft: '10%',
        paddingLeft: 0,
        width: '80%'
    },
    bodyAmount: {
        color: '#7279E8',
        fontSize: '1.4rem',
        fontWeight: 700,
        width: '100%'
    },
    bodyDescription: {
        fontSize: '0.8rem',
        fontWeight: 700,
        color: '#a9a9a9',
        width: '100%',
        paddingBottom: '1.5rem'
    }
});

const dialogStyles = () => ({
    root: {
        width: '100%'
    },
    paper: {
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
        'div[role="dialog"][aria-hidden="true"] &': {
            display: 'none'
        }
    }
});

const StyledDialog = withStyles(dialogStyles)(Dialog);

type Props = {
    classes: {
        wrapper: string;
        button: string;
        root: string;
    };
    open: boolean;
    onClose?: () => void;
    children: React.ReactNode;
};

const Modal: React.FC<Props> = ({
    classes,
    open,
    onClose,
    children,
    ...rest
}) => {
    return (
        <StyledDialog {...rest} open={open} onClose={onClose}>
            <div className={classes.wrapper}>
                {onClose && (
                    <div className={classes.button}>
                        <CloseButton small onClick={onClose} />
                    </div>
                )}
                <div className={classes.root}>{children}</div>
            </div>
        </StyledDialog>
    );
};

export default compose(withStyles(styles as any))(
    Modal as React.FC<Omit<Props, 'classes'>>
);
