import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import classes from "./index.module.scss";
import { LeftChevronButton } from "../../../../../_shared/buttons";

function BeaconHolderBalance({
  name,
  balance,
  portraitUrl,
  goBack,
  chevronTitle = "",
}) {
  return (
    <div className={classes.container}>
      <LeftChevronButton
        style={{ fontSize: "3rem", paddingInline: "3%" }}
        title={chevronTitle}
        onClick={goBack}
        aria-label={chevronTitle}
      />
      <img src={portraitUrl} alt={name} className={classes.portrait} />
      <div className={classes.center}>
        <div className={classes.name}>{name}</div>
        <div className={classes.balance}>Balance: ${balance}</div>
      </div>
    </div>
  );
}

BeaconHolderBalance.propTypes = {
  goBack: PropTypes.func.isRequired,
  chevronTitle: PropTypes.string,
};

const mapStateToProps = (state) => ({
  name: state.homeless.homelessInfo.name,
  balance: state.homeless.homelessInfo.balance,
  portraitUrl: state.homeless.homelessInfo.photo,
});

export default connect(mapStateToProps)(BeaconHolderBalance);
