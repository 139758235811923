import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Chart from './Chart';
import Current from './Current';
import History from './History';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    progressWrapper: {
        padding: '20px 30px',
        position: 'relative'
    },
    progressContainer: {
        position: 'relative'
    },
    titleContainer: {
        marginBottom: '20px',
        padding: '20px'
    },
    progressTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '6px'
    },
    title: {
        fontWeight: '800',
        fontSize: '22px',
        lineHeight: '24px',
        color: '#1F1F51'
    },
    progressSubtitle: {
        fontWeight: '300',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#A6A6BF'
    },
    infoIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#7279E8',
        fontSize: '11px',
        cursor: 'pointer',
        width: '21px',
        height: '21px',
        backgroundColor: '#FFFFFF',
        borderRadius: '100%',
        boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)'
    }
}));

const Progress = ({
    holderName,
    questionOptions,
    classifiedInfo,
    setClassifiedInfo,
    updateStatusInfo,
    setUpdateStatusInfo,
    statusHistory,
    statusName
}) => {
    const classes = useStyles();
    const statusCardRef = useRef(null);
    const statusContainerRef = useRef(null);

    const [statusCardID, setStatusCardID] = useState(null);

    statusHistory.sort((a, b) => b.id - a.id);
    statusHistory = statusHistory.filter(
        (status) => status.answer_index < questionOptions.length
    );

    const currentStatus = statusHistory.length
        ? statusHistory[0]
        : statusHistory;
    const historyStatus = statusHistory.length
        ? statusHistory.slice(1)
        : statusHistory;

    useEffect(() => {
        if (statusCardID) {
            const statusCard = document.getElementById(
                `status-card-${statusCardID}`
            );

            statusCard.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });

            setStatusCardID(null);
        }
    }, [statusCardID]);

    useEffect(() => {
        if (statusContainerRef.current) {
            statusContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, []);

    return (
        <div className={classes.progressWrapper} ref={statusContainerRef}>
            <div className={classes.progressContainer}>
                <div className={classes.titleContainer}>
                    <div className={classes.progressTitle}>
                        <span className={classes.title}>Progress</span>
                    </div>
                    <span className={classes.progressSubtitle}>
                        Graph mapping {holderName} {statusName} journey over
                        time
                    </span>
                </div>
                <Chart
                    questionOptions={questionOptions}
                    classifiedInfo={classifiedInfo}
                    setClassifiedInfo={setClassifiedInfo}
                    updateStatusInfo={updateStatusInfo}
                    setUpdateStatusInfo={setUpdateStatusInfo}
                    setStatusCardID={setStatusCardID}
                    statusHistory={statusHistory}
                />
            </div>
            <Current
                currentStatus={currentStatus}
                holderName={holderName}
                questionOptions={questionOptions}
                setClassifiedInfo={setClassifiedInfo}
                setUpdateStatusInfo={setUpdateStatusInfo}
                statusCardRef={statusCardRef}
                statusName={statusName}
            />
            {historyStatus.length ? (
                <History
                    holderName={holderName}
                    questionOptions={questionOptions}
                    setClassifiedInfo={setClassifiedInfo}
                    setUpdateStatusInfo={setUpdateStatusInfo}
                    statusCardRef={statusCardRef}
                    statusHistory={historyStatus}
                />
            ) : null}
        </div>
    );
};

Progress.propTypes = {
    holderName: PropTypes.string.isRequired,
    questionOptions: PropTypes.array.isRequired,
    statusHistory: PropTypes.array.isRequired,
    statusName: PropTypes.string.isRequired
};

export default Progress;
