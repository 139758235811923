import Card from '../../../CaseManagement/components/Card';
import { MainGradient } from './backgroundColors';
import QuoteIcon from 'assets/icon-quote-alt.png';
import StoryIcon from 'assets/icon-story.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    body: {
        position: 'relative',
        fontSize: '24px',
        fontWeight: 600,
        height: '80%',
        letterSpacing: '0.5px',
        margin: '20px 10px',
        '& span': {
            position: 'relative',
            zIndex: '2'
        },
        overflow: 'auto'
    },
    card: {
        position: 'relative',
        background: '#7771B4',
        color: '#FFFFFF',
        width: '400px',
        overflowX: 'hidden'
    },
    descriptionHeader: {
        fontSize: '32px',
        lineHeight: '52px',
        marginBottom: '48px'
    },
    factBody: {
        display: 'flex',
        height: '100%',
        overflow: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        '&::-webkit-scrollbar': {
            width: 7
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#F3F3F3',
            borderRadius: '10px'
        }
    },
    lifeStory: {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        zIndex: 2
    },
    lifeStoryCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        padding: '2px',
        height: '480px'
    },
    photo: {
        borderRadius: '16px',
        height: '200px',
        objectFit: 'cover',
        width: '100%',
        zIndex: 2
    },
    quoteIcon: {
        position: 'absolute',
        right: '-58px',
        top: '16px',
        overflowX: 'hidden',
        zIndex: 1
    },
    storyIcon: {
        position: 'absolute',
        // top: -8,
        width: '80px',
        zIndex: 3
    },
    title: {
        fontSize: '24px',
        fontWeight: '800',
        letterSpacing: '8px',
        lineHeight: '40px',
        paddingLeft: '72px',
        zIndex: 2
    }
}));

type LifeStoryItemProps = {
    holderDescription?: string;
    item?: {
        description: string;
        photo: string;
        title: string;
    };
    length?: number;
};

const LifeStoryItem: React.FC<LifeStoryItemProps> = ({
    holderDescription,
    item,
    length
}) => {
    const classes = useStyles();

    const { description, photo, title } = item || {};

    const renderDescription = () => {
        if (holderDescription) {
            const descriptionHeader = `${holderDescription.replace(
                /^([\s\S]{70}[^\s]*)[\s\S]*/,
                '$1'
            )}...`;

            return (
                <div className={classes.lifeStory}>
                    <div className={classes.descriptionHeader}>
                        {descriptionHeader}
                    </div>
                    <div style={{ fontWeight: '300', fontSize: '24px' }}>
                        {holderDescription}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className={classes.factBody}
                    style={
                        title === 'Fun Fact'
                            ? {
                                  fontSize: '24px',
                                  fontWeight: 500
                              }
                            : {}
                    }
                >
                    <span style={{ height: '100%' }}>{description}</span>
                </div>
            );
        }
    };

    const renderStoryIconStyle = () => {
        if (typeof length === 'number') {
            return {
                top: -8
            };
        } else {
            return {
                top: -18,
                left: 12
            };
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <img
                alt="story"
                className={classes.storyIcon}
                src={StoryIcon}
                style={renderStoryIconStyle()}
            />
            <Card
                className={classes.card}
                style={
                    length
                        ? {
                              top: 10,
                              left: -10,
                              zIndex: '2'
                          }
                        : {}
                }
            >
                <MainGradient />
                <img
                    className={classes.quoteIcon}
                    src={QuoteIcon}
                    alt="quote"
                />
                <div className={classes.lifeStoryCard}>
                    <span className={classes.title}>
                        {title ? title : 'Life Story'}
                    </span>
                    {photo && (
                        <img
                            className={classes.photo}
                            src={photo}
                            alt="update"
                        />
                    )}
                    <div
                        className={classes.body}
                        style={photo ? { height: '25%' } : {}}
                    >
                        {renderDescription()}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default LifeStoryItem;
