import CONSTANTS from '../constants/constants';

export const resetUnenrollmentState = () => ({
    type: CONSTANTS.RESET_UNENROLLMENT_STATE
});

export const setRedeemStatus = (redeemStatus: boolean) => ({
    type: CONSTANTS.SET_UNENROLLMENT_REDEEM_STATUS,
    payload: {
        redeemStatus
    }
});

export const setSdohStatus = (sdohStatus: boolean) => ({
    type: CONSTANTS.SET_UNENROLLMENT_SDOH_STATUS,
    payload: {
        sdohStatus
    }
});

export const setUnenrollmentState = (
    route: string,
    externalContext: string,
    internalContext: string,
    successStatusActive: boolean,
    unenrollStatus: string
) => ({
    type: CONSTANTS.SET_UNENROLLMENT_STATE,
    payload: {
        route,
        externalContext,
        internalContext,
        successStatusActive,
        unenrollStatus
    }
});
