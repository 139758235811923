import classNames from 'classnames';

import { TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        inputFields: {
            width: '100%',
            maxWidth: '480px',
            margin: '0 auto',
            border: '0',
            padding: '10px 0px 0px 0px',
            fontFamily: 'Manrope',
            fontSize: '1.5rem',
            fontWeight: 500,
            color: '#1f1f51',
            borderRadius: '6px',
            letterSpacing: '-0.21px',
            background: 'transparent',
            resize: 'none',
            '&:placeholder-shown': {
                fontStyle: 'italic'
            },
            '&:focus': {
                outline: 'none !important'
            }
        },
        title: {
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '100%',
            display: 'flex',
            alignTtems: 'flex-end',
            letterSpacing: '0.15em',
            textTransform: 'uppercase',
            color: '#1F2051',
            opacity: 0.7,
            marginBottom: '0.5rem'
        },
        underline: {
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            margin: '0 auto 2rem auto',
            maxWidth: '480px',
            width: '100%'
        },
        underlineError: {
            height: '0',
            borderRadius: '10px',
            marginBottom: '2rem',
            border: '6px solid #FF0000'
        }
    })
);

type InputProps = {
    inputType: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    validator?: (input: string) => boolean;
    value: string;
};

const Input: React.FC<InputProps> = ({
    inputType,
    label,
    onChange,
    placeholder,
    validator,
    value
}) => {
    const classes = useStyles();

    const underlineClass = classNames({
        [classes.underline]: true,
        [classes.underlineError]: validator && !validator(value)
    });

    return (
        <>
            {label.length ? <h5 className={classes.title}>{label}</h5> : null}
            {inputType === 'birthday' ? (
                <TextField
                    className={classes.inputFields}
                    id={inputType}
                    name={inputType}
                    onChange={onChange}
                    placeholder={placeholder}
                    type="date"
                    value={value}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            color: value.length > 0 ? '#1F2152' : '#757575',
                            fontSize: '1.5rem',
                            fontStyle: value.length > 0 ? 'normal' : 'italic',
                            padding: '0'
                        }
                    }}
                    variant="standard"
                />
            ) : (
                <input
                    className={classes.inputFields}
                    id={inputType}
                    name={inputType}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={inputType}
                    value={value}
                />
            )}
            <div className={underlineClass} />
        </>
    );
};

export default Input;
