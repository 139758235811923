import AuthManager from '../auth';
import SamaritanFetch from './httpClient';

export const cancelNeed = (id: number) => {
    return updateNeed({ id, status: 'cancelled' });
};

export const completeNeed = (id: number) => {
    return updateNeed({ id, status: 'completed' });
};

export const editNeed = (payload: any, id: number) => {
    const token = AuthManager.getInstance().getToken();

    payload.SESSION_TOKEN = token;

    return SamaritanFetch.v3.put(`/needs/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    });
};

export const fetchNeedHistory = (id: number) => {
    return SamaritanFetch.v3(`/needs`, {
        query: {
            needs: {
                user_id: [id]
            },
            page: 1,
            per_page: 3
        }
    })
        .then((res: Response) => res.json())
        .then((data: { needs: any[] }) => data.needs);
};

export const fetchHomelessNeeds = (id: number) =>
    SamaritanFetch.v3('/needs', {
        query: {
            need: {
                relations: {
                    donations: '{}',
                    encouragements: '{}'
                }
            },
            needs: {
                homeless_id: [id],
                status: 'pending'
            }
        }
    });

// TODO: Fix any type
export const postHomelessNeed = (payload: any) => {
    return SamaritanFetch.v3.post('/needs', {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
};

const updateNeed = ({ id, status }: { id: number; status: string }) => {
    const token = AuthManager.getInstance().getToken();

    let params = {
        SESSION_TOKEN: token,
        need: {
            status
        }
    };
    return SamaritanFetch.v3.put(`/needs/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    });
};
