const Routes = {
    error: '/error',
    holder_list: '/holders',
    holder_profile: '/holders/:id',
    intake: '/intake',
    login: '/login',
    logout: '/logout',
    photo: '/intake/photo/upload',
    root: '/',
    settings: '/settings',
    wiki: process.env.REACT_APP_WIKI_URL || 'https://samaritan.frontkb.com/en'
};

export default Routes;
