import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        available: {
            alignSelf: 'flex-end',
            fontSize: '32px',
            fontWeight: 300,
            lineHeight: '39px',
            paddingBottom: '4px'
        },
        balance: {
            fontSize: '64px',
            fontWeight: 700,
            lineHeight: '79px'
        },
        balanceContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            padding: '4px'
        },
        balanceDetails: {
            display: 'flex',
            gap: '8px',
            color: '#7378E8'
        },
        subtitle: {
            color: '#1F2152',
            fontWeight: 400
        }
    })
);

type BalanceProps = {
    balance: number;
};

const Balance: React.FC<BalanceProps> = ({ balance }) => {
    const classes = useStyles();

    return (
        <div className={classes.balanceContainer}>
            <div className={classes.balanceDetails}>
                <span className={classes.balance}>${balance}</span>
                <span className={classes.available}>available</span>
            </div>
            <span className={classes.subtitle}>
                Help your client spend their available balance from donations
                and action step bonuses
            </span>
        </div>
    );
};

export default Balance;
