import { useEffect, useState } from 'react';

import DateInput from 'components/_shared/DateInput';
import FormInput from 'components/_shared/FormInput';
import { CommonButton } from 'components/_shared/buttons';

import { getShortMonthName } from '../../../holder_profile/profile_2.0/CaseManagement/utils';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    asDescription: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: '10px',
        margin: '20px 0',
        '@media (max-width: 600px)': {
            flexDirection: 'column'
        }
    },
    bonusTooltip: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #E3E3FA',
        borderRadius: '8px',
        color: '#1F2152',
        fontSize: '16px',
        fontWeight: '300',
        padding: '12px !important',
        width: 'fit-content',
        '& i': {
            color: '#7378E8',
            fontSize: '24px',
            marginRight: '10px'
        }
    },
    datePicker: {
        color: '#1f1f51',
        width: '100%',
        '& .MuiInputBase-root': {
            color: '#1f1f51',
            opacity: '0.6',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontStyle: 'italic',
            '& input': {
                padding: '10px 0 0 0px'
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    inputFields: {
        background: 'transparent',
        border: '0',
        borderRadius: '6px',
        color: '#1f1f51',
        fontFamily: 'Manrope',
        fontSize: '1.75rem',
        fontWeight: '600',
        height: '3.125rem',
        letterSpacing: '-0.21px',
        overflow: 'hidden',
        padding: '10px 0 10px 5px',
        resize: 'none',
        textOverflow: 'ellipsis',
        // width: 'fit-content',
        width: '100%',
        '&:placeholder-shown': {
            fontStyle: 'italic',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA',
            textDecorationThickness: '4px'
        },
        '&:focus': {
            border: '0 !important',
            outline: 'none !important',
            overflow: 'hidden',
            padding: '4px 8px',
            textAlign: 'left',
            textDecoration: 'underline',
            textDecorationColor: '#E3E4FA',
            textDecorationThickness: '4px',
            textOverflow: 'ellipsis',
            width: '100%'
        }
    },
    inputTitle: {
        color: '#A6A6BF',
        fontFamily: 'Manrope',
        fontWeight: '600',
        letterSpacing: '0.15em',
        textTransform: 'uppercase'
    },
    needInputWrapper: {},
    suggestBtn: {
        background: '#EAEBFB',
        color: '#1F1F51',
        fontFamily: 'Manrope',
        fontSize: '16px',
        margin: '0 8px 8px 0'
    },
    writeBtn: {
        background: 'transparent',
        border: '2px dashed #EAEBFB',
        borderRadius: '100px',
        color: '#1F1F51',
        fontFamily: 'Manrope',
        fontWeight: '500',
        fontSize: '16px',
        margin: '0 8px 8px 0',
        padding: '8px 16px'
    }
}));

type ActionStepInputProps = {
    actionSuggestions: any;
    displayBtnState: boolean;
    displayCustomDateInput?: boolean;
    displayDateState?: string;
    inputState: boolean;
    inputValState: any;
    intakeGoals?: any;
    needType: string;
    remainingBonusAmount?: number;
    saveAction?: () => void;
    setInputState: (state: boolean) => void;
    setInputValState: (state: any) => void;
    setDisplayBtnState: (state: boolean) => void;
    setDisplayDateState?: (state: string) => void;
    setDisplayCustomDateInput?: (state: boolean) => void;
    setGuide: (state: boolean) => void;
    showEdit: boolean;
    isEditing: {
        [key: string]: boolean;
    };
    setIsEditing: any;
};

const ActionStepInput: React.FC<ActionStepInputProps> = ({
    actionSuggestions,
    displayBtnState,
    displayCustomDateInput,
    displayDateState,
    inputState,
    inputValState,
    isEditing,
    intakeGoals,
    needType,
    remainingBonusAmount,
    saveAction,
    setDisplayBtnState,
    setDisplayCustomDateInput,
    setDisplayDateState,
    setGuide,
    setInputState,
    setInputValState,
    setIsEditing,
    showEdit
}) => {
    const classes = useStyles();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const inputTitles = {
        cost: `BONUS ...`,
        due_dates: `COMPLETED BY ...`
    } as {
        [key: string]: string;
    };

    const placeholder = {
        cost: '$20',
        descriptions: 'Write your own...'
    } as {
        [key: string]: string;
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        type: string
    ) => {
        let input = e.target.value;

        if (type === `cost`) {
            input = input.replace(/[^0-9]/g, ``);
            setInputValState(input);
        } else {
            setInputValState(e.target.value);
        }
    };

    const handleInputClick = () => {
        setDisplayBtnState(true);
    };

    const renderBtns = (needType: string) => {
        let costType;
        const totalAmount = showEdit
            ? remainingBonusAmount! + parseInt(inputValState)
            : remainingBonusAmount;

        if (needType === 'cost') {
            costType = 'bonus';
        } else {
            costType = needType;
        }

        const suggestionType = actionSuggestions;
        const buttons = suggestionType[costType]['default'];
        const values = suggestionType['bonus']['default'].filter(
            (num: number) => num <= totalAmount!
        );

        const handleClick = (label: { date: string; name: string }) => {
            if (needType === 'due_dates') {
                const date = label.date.substring(0, 10);
                const name = label.name;

                setInputValState(date);
                setDisplayDateState!(name);
                setDisplayCustomDateInput!(false);
            } else {
                setInputValState(label.toString());
            }

            if (
                needType === 'descriptions' &&
                intakeGoals &&
                intakeGoals.length === 1
            ) {
                if (setGuide) {
                    setGuide(true);
                }
            }

            if (!inputState) {
                setInputState(true);
            }

            setDisplayBtnState(false);
            setIsEditing((prev: { [key: string]: boolean }) => ({
                ...prev,
                [needType]: false
            }));
        };

        const renderButton = (label: string, index: number) => (
            <CommonButton
                key={index}
                className={classes.suggestBtn}
                onClick={() => handleClick(label as any)}
            >
                {renderLabel(needType, label)}
            </CommonButton>
        );

        if (costType !== 'bonus') {
            return buttons.map((label: string, index: number) =>
                renderButton(label, index)
            );
        } else {
            return values.map((label: string, index: number) =>
                renderButton(label, index)
            );
        }
    };

    const renderBonusTooltip = (remainingAmount: number) => {
        if (showEdit && remainingAmount > 0) {
            const totalAmount = remainingAmount + parseInt(inputValState);

            return `$${totalAmount} remaining from ${getShortMonthName()}'s bonus pool.`;
        }

        if (remainingAmount <= 0) {
            return `${getShortMonthName()}'s bonus pool was fully assigned. Bonuses will be assignable starting next month!`;
        }

        return `$${remainingAmount} remaining from ${getShortMonthName()}'s bonus pool.`;
    };

    const renderLabel = (type: string, label: any) => {
        if (type === `cost`) {
            if (label === 0) {
                return `No Amount`;
            } else {
                return `$${label}`;
            }
        } else if (type === `due_dates`) {
            return label.name;
        } else {
            return label;
        }
    };

    useEffect(() => {
        if (needType === `cost` && remainingBonusAmount === 0) {
            setInputValState(0);
            setInputState(true);
            setDisplayBtnState(false);
        }
    }, [
        remainingBonusAmount,
        needType,
        setDisplayBtnState,
        setInputState,
        setInputValState
    ]);

    useEffect(() => {
        if (
            needType === 'due_dates' &&
            inputValState &&
            inputValState.length > 0 &&
            !showEdit
        ) {
            saveAction!();
        }
    }, [inputValState, needType, saveAction, showEdit]);

    return (
        <div className={classes.needInputWrapper}>
            {inputTitles[needType] ? (
                <span className={classes.inputTitle}>
                    {inputTitles[needType]}
                </span>
            ) : null}
            <div
                className={classes.asDescription}
                style={needType === 'descriptions' ? { marginTop: '16px' } : {}}
            >
                {inputState && needType !== `due_dates` ? (
                    <FormInput
                        className={classes.inputFields}
                        disabled={
                            needType === `cost` &&
                            remainingBonusAmount! <= 0 &&
                            inputValState === 0
                        }
                        isEditing={isEditing}
                        name={needType}
                        onChange={(e) => {
                            if (needType === `cost`) {
                                return;
                            } else {
                                handleInputChange(e, needType);
                            }
                        }}
                        onClick={handleInputClick}
                        placeholder={
                            placeholder[needType] ? placeholder[needType] : ``
                        }
                        setIsEditing={setIsEditing}
                        template
                        value={
                            needType === 'cost'
                                ? inputValState === '0' || inputValState === 0
                                    ? 'No Bonus'
                                    : inputValState
                                    ? `$${inputValState}`
                                    : ''
                                : inputValState
                        }
                    />
                ) : null}
                {inputState &&
                needType === `due_dates` &&
                !displayCustomDateInput ? (
                    <FormInput
                        className={classes.inputFields}
                        isEditing={isEditing}
                        name="due_dates"
                        onChange={(e) => handleInputChange(e, needType)}
                        onClick={() => setDisplayBtnState(true)}
                        setIsEditing={setIsEditing}
                        template
                        value={displayDateState ? displayDateState : ``}
                    />
                ) : null}
                {inputState &&
                needType === `due_dates` &&
                displayCustomDateInput ? (
                    <DateInput
                        dateInput={inputValState}
                        disablePast
                        open={datePickerOpen}
                        setDateInput={setInputValState}
                        setDisplayBtnState={setDisplayBtnState}
                        setOpen={setDatePickerOpen}
                    />
                ) : null}
                <div style={inputState ? { width: '100%' } : {}}>
                    {(!inputState && needType !== 'cost') ||
                    (needType === `due_dates` && displayBtnState) ? (
                        <CommonButton
                            className={classes.writeBtn}
                            onClick={() => {
                                if (needType === `due_dates`) {
                                    setDatePickerOpen(true);
                                    setDisplayCustomDateInput!(true);
                                    setInputState(true);
                                } else {
                                    setInputState(true);
                                }

                                if (needType === `cost`) {
                                    setGuide(true);
                                }

                                setIsEditing(
                                    (prev: { [key: string]: boolean }) => ({
                                        ...prev,
                                        [needType]: true
                                    })
                                );
                            }}
                        >
                            Write your own...
                        </CommonButton>
                    ) : null}
                    {displayBtnState ? renderBtns(needType) : null}
                </div>
                {(needType === `cost` && displayBtnState) ||
                (needType === `cost` && remainingBonusAmount! <= 0) ? (
                    <div className={classes.bonusTooltip}>
                        <i className="fas fa-info-circle" />
                        {renderBonusTooltip(remainingBonusAmount!)}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ActionStepInput;
