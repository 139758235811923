export const formatDate = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    const day = new Date(date).getDate();
    let suffix;

    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }

    const formattedDateWithSuffix = formattedDate.replace(/\d+/, `$&${suffix}`);

    return formattedDateWithSuffix;
};

export const getColor = (index, colors, options) => {
    const primaryColor = [
        '#A9142A',
        '#A9610B',
        '#A98E0B',
        '#268D8D',
        '#0BA162'
    ];

    const secondaryColor = [
        '#FFE9EB',
        '#FFF4E7',
        '#FEFBE7',
        '#EBFAFB',
        '#E7FEF4'
    ];

    if (colors === 'primary') {
        colors = primaryColor;
    } else if (colors === 'secondary') {
        colors = secondaryColor;
    } else if (colors === 'primaryReverse') {
        colors = primaryColor.reverse();
    } else {
        colors = secondaryColor.reverse();
    }

    const segmentSize = 1 / (options - 1);
    const colorIndex = segmentSize * index;
    const color = colors[Math.floor(colorIndex * (colors.length - 1))];

    return color;
};

export const getHolderName = (name) => {
    const possessiveS = name.slice(-1) === 's' ? "'" : "'s";

    return name + possessiveS;
};

export const getMonth = (date) => {
    const month = new Date(date).toLocaleDateString('en-US', {
        month: 'short'
    });

    return month;
};

export const getNextMonth = (date) => {
    const nextMonth = new Date(date);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    return nextMonth;
};

export const getMonths = (data) => {
    data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    for (let index = data.length - 2; index >= 0; index--) {
        const currentMonth = data[index];
        const nextMonth = data[index + 1];

        if (
            currentMonth.question_type === 'StatusQuestion' &&
            nextMonth.question_type === 'SocialDeterminantQuestion' &&
            data.filter((status) => status.answer_index === 'i').length === 0 &&
            currentMonth.answer_index !== 'i'
        ) {
            const splitData = {
                question_type: 'StatusCaptureUpdate',
                created_at: nextMonth.created_at,
                answer_index: 'i'
            };

            data.splice(index + 1, 0, splitData);
        }
    }

    const months = data.map((status, index) => {
        const date = status.created_at
            ? new Date(status.created_at)
            : new Date(status.updated_at);
        const monthDay = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
        });
        const monthYear = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });

        if (status.answer_index === 'i') {
            return ' ';
        }

        if (index === 0) {
            const nextMonth = getNextMonth(date);
            const nextMonthName = nextMonth.toLocaleDateString('en-US', {
                month: 'short'
            });

            return [nextMonthName, monthDay];
        } else {
            if (monthYear.includes(new Date().getFullYear())) {
                return [monthDay];
            } else {
                return [monthYear];
            }
        }
    });

    const flatMonths = months
        .reduce((acc, val) => acc.concat(val), [])
        .reverse();

    return flatMonths;
};

export const createStatusMap = (data, statusName) => {
    const map = {};

    statusName.forEach((status, index) => {
        map[index + 1] = [];
    });

    statusName.forEach((status, index) => {
        data.forEach((answer, answerIndex) => {
            const { answer_index, classified_statuses, id, question_type } =
                answer;
            const answers =
                (classified_statuses &&
                    classified_statuses[0]?.classified_status_answer_matches) ||
                [];

            let statusAnswer = null;
            let nextStatusAnswer = null;

            const nextAnswer = data[answerIndex + 1];

            if (
                nextAnswer &&
                nextAnswer.classified_statuses &&
                nextAnswer.classified_statuses[0]
                    .classified_status_answer_matches.length
            ) {
                const nextAnswerStatuses =
                    nextAnswer.classified_statuses[0]
                        .classified_status_answer_matches;
                const highestAnswer = nextAnswerStatuses.reduce((prev, curr) =>
                    curr.rank > prev.rank ? curr : prev
                );

                nextStatusAnswer = highestAnswer.rank;
            }

            if (answers.length) {
                const highestAnswer = answers.reduce((prev, curr) =>
                    curr.rank > prev.rank ? curr : prev
                );

                statusAnswer = highestAnswer.rank;
            }

            if (statusAnswer && statusAnswer === index + 1) {
                if (!map[statusAnswer]) {
                    map[statusAnswer] = [];
                }

                map[statusAnswer].push({ answer: statusAnswer, id });
            } else if (
                !statusAnswer &&
                question_type === 'StatusQuestion' &&
                nextAnswer &&
                ((nextAnswer.answer_index &&
                    index + 1 === nextAnswer.answer_index + 1) ||
                    (nextStatusAnswer && index + 1 === nextStatusAnswer))
            ) {
                if (nextStatusAnswer) {
                    if (!map[nextStatusAnswer]) {
                        map[nextStatusAnswer] = [];
                    }

                    map[nextStatusAnswer].push({ answer: '?', id });
                } else {
                    if (!map[nextAnswer.answer_index + 1]) {
                        map[nextAnswer.answer_index + 1] = [];
                    }

                    map[nextAnswer.answer_index + 1].push({ answer: '?', id });
                }
            } else if (
                !statusAnswer &&
                question_type === 'StatusQuestion' &&
                ((data[answerIndex + 1] &&
                    !data[answerIndex + 1].answer_index) ||
                    !data[answerIndex + 1]) &&
                index + 1 === 3 &&
                !nextStatusAnswer
            ) {
                if (!map[3]) {
                    map[3] = [];
                }

                map[3].push({ answer: '?', id: 'unclassified answer' });
            } else if (answer_index === index) {
                if (!map[index + 1]) {
                    map[index + 1] = [];
                }

                map[index + 1].push({ answer: answer_index + 1, id });
            } else if (
                index + 1 === 5 &&
                question_type === 'StatusCaptureUpdate'
            ) {
                if (!map[5]) {
                    map[5] = [];
                }

                map[5].push({ answer: 'i', id: 'filling out' });
            } else {
                if (!map[index + 1]) {
                    map[index + 1] = [];
                }

                map[index + 1].push({ answer: 0, id: 'filling out' });
            }
        });
    });

    Object.values(map).forEach((statusArr) => {
        statusArr.reverse();
    });

    return map;
};

export const removeAccess = (question) => {
    if (question && question.includes('Access to ')) {
        return question.replace('Access to ', '');
    }

    return question;
};