import IconQuote from 'assets/icon-quote.png';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        messageBubble: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            background:
                'linear-gradient(137.41deg, rgba(121, 113, 180, 0.75) 4%, rgba(119, 113, 180, 0) 88.09%), linear-gradient(180deg, #1E256D 0%, #2E2E57 100%)',
            borderRadius: '20px',
            boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
            margin: '0 auto',
            padding: '30px 0',
            width: '65%',
            '@media (max-width: 768px)': {
                width: '80%'
            }
        },
        messageTitleContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        messageTitle: {
            display: 'flex',
            flexWrap: 'wrap',
            color: '#D1D5FF',
            fontSize: '20px',
            fontWeight: 800,
            letterSpacing: '0.09em',
            lineHeight: '21px',
            paddingLeft: '30px',
            textTransform: 'uppercase'
        },
        messageQuestion: {
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '24px',
            marginTop: '10px',
            padding: '0 30px'
        },
        ask: {
            alignSelf: 'flex-end',
            marginRight: '8px',
            textTransform: 'uppercase'
        },
        line: {
            alignSelf: 'center',
            background: '#A8ADE6',
            borderRadius: '5px',
            height: '3px',
            marginRight: '8px',
            width: '18px'
        },
        name: {
            alignSelf: 'flex-end',
            fontSize: '14px',
            lineHeight: '15px',
            textTransform: 'uppercase'
        },
        quoteIcon: {
            height: '80px'
        },
        messageTail: {
            position: 'relative',
            bottom: '10px',
            borderBottom: '20px solid transparent',
            borderLeft: '20px solid #3e3b6a',
            borderTop: '20px solid transparent',
            boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
            height: '0',
            margin: '0 auto',
            transform: 'rotate(90deg)',
            width: '0'
        },
        numberBubble: {
            position: 'absolute',
            left: '-30px',
            top: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#9293D4',
            borderRadius: '50%',
            boxShadow: '0px 10px 80px rgba(0, 0, 0, 0.1)',
            color: '#FFFFFF',
            fontSize: '15px',
            fontWeight: 800,
            height: '60px',
            textTransform: 'uppercase',
            width: '60px'
        },
        questionNum: {
            fontSize: '20px',
            fontWeight: 700
        },
        questionLength: {
            fontSize: '14px',
            fontWeight: 400
        }
    })
);

type MessageBubbleProps = {
    name?: string;
    question: string;
    questionLength?: number;
    questionNum?: number;
};

const MessageBubble: React.FC<MessageBubbleProps> = ({
    name,
    question,
    questionLength,
    questionNum
}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.messageBubble}>
                {questionNum && questionNum + 1 && questionLength ? (
                    <div className={classes.numberBubble}>
                        <span className={classes.questionNum}>
                            Q{questionNum + 1}
                        </span>
                        <span className={classes.questionLength}>
                            /{questionLength}
                        </span>
                    </div>
                ) : null}
                <div
                    className={classes.messageTitleContainer}
                    style={
                        questionNum && questionNum + 1
                            ? { paddingLeft: '12px' }
                            : {}
                    }
                >
                    <span className={classes.messageTitle}>
                        <div className={classes.line} />
                        <div className={classes.ask}>ASK </div>
                        <span className={classes.name}>
                            {name ? `${name} ...` : 'YOUR MEMBER ...'}
                        </span>
                    </span>
                    <span
                        className={classes.messageQuestion}
                        dangerouslySetInnerHTML={{ __html: question }}
                    />
                </div>{' '}
                <img
                    className={classes.quoteIcon}
                    src={IconQuote}
                    alt="quote"
                />
            </div>
            <div className={classes.messageTail} />
        </>
    );
};

export default MessageBubble;
