// TODO: Type formAnswers, fix any
export default function formatRedemptionFormAnswers(formAnswers: any) {
    let str = '';

    const { category, title, requestType, ...otherAnswers } = formAnswers;

    // validate formAnswers
    if (!category) throw new TypeError('formAnswers must include category');
    if (!title) throw new TypeError('formAnswers must include title');
    if (requestType !== 'Fulfillment' && requestType !== 'Reimbursement')
        throw new TypeError(
            "requestType must be 'Fulfillment' or 'Reimbursement'"
        );

    str += `${category} - ${title}\n\n`;

    Object.entries(otherAnswers).forEach(([itemName, answer]) => {
        str += `- ${itemName}:\n${answer}\n\n`;
    });

    str += `- Request Type:\n${requestType}`;

    str += '\n\n';

    return str;
}
