import Card from '../../../../_shared/Card';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        contentWrapper: {
            position: 'relative',
            padding: '20px'
        },
        title: {
            position: 'absolute',
            top: '-32px',
            background: '#7378E8',
            borderRadius: '30px',
            color: '#FFFFFF',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.15em',
            padding: '8px 20px',
            textTransform: 'uppercase'
        },
        suggestionContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '16px'
        },
        writeBtn: {
            fontSize: '1.25rem',
            border: '2px dashed #EAEBFB',
            borderRadius: '100px',
            color: '#1F1F51',
            padding: '8px 16px'
        },
        needSuggestion: {
            background: '#EAEBFB',
            borderRadius: '30px',
            color: '#1F1F51',
            fontSize: '1.25rem',
            padding: '10px 20px'
        }
    })
);

const Need = () => {
    const classes = useStyles();

    const suggestions = [
        `Bus Fare`,
        `Phone Plan`,
        `Interview Clothes`,
        `Application Fee`
    ];

    return (
        <Card style={{ marginTop: '50px', width: '600px' }}>
            <div className={classes.contentWrapper}>
                <span className={classes.title}>New Immediate Need</span>
                <div className={classes.suggestionContainer}>
                    <span className={classes.writeBtn}>Write your own</span>
                    {suggestions.map((need, index) => (
                        <span className={classes.needSuggestion} key={index}>
                            {need}
                        </span>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default Need;
