import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        infoContainer: {
            display: 'flex',
            gap: '30px',
            alignItems: 'center'
        },
        infoIcon: {
            backgroundColor: '#062D7A',
            color: '#FFFFFF',
            borderRadius: '50%',
            width: '34px',
            height: '34px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        info: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            color: '#062D7A',
            width: '100%'
        },
        infoTitle: {
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 700,
            letterSpacing: '1.6px'
        },
        infoDescription: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '18px',
            lineHeight: '25px'
        }
    })
);

type Props = {
    count?: number;
    exited?: boolean;
    name?: string;
    open?: boolean;
    type: string;
};

const InfoCard: React.FC<Props> = ({ exited, name, open, type }) => {
    const classes = useStyles();

    const possessiveSuffix = name
        ? name.endsWith('s') || name.endsWith('S')
            ? `${name}'`
            : `${name}'s`
        : `member's`;

    const infoMappings = {
        actions: {
            icon: 'fas fa-clipboard-list',
            title: 'Action Steps',
            description:
                'Reward Members for completing to-do items from your case management plan'
        },
        bonus: {
            icon: 'fas fa-chart-pie',
            title: 'Assignable Bonus Tracker',
            description: 'Assignable Bonus Tracker'
        },
        contact: {
            icon: 'fas fa-envelope',
            title: 'Contact Information',
            description: `We'll share updates about their Membership to the contact info included here`
        },
        debit: {
            icon: 'fas fa-hands-usd',
            title: 'Debit Balance',
            description: 'Funds earned or given to overcome financial barriers'
        },
        documents: {
            icon: 'fas fa-folder-open',
            title: 'Document Storage',
            description: `Securely store digital copies of ${possessiveSuffix} important documents`
        },
        enrollment: {
            icon: 'fas fa-id-card',
            title: 'Enrollment Status',
            description: !exited
                ? `If ${name} has graduated or no longer accesses services from your team, update their Membership status here`
                : `If you'd like to reinstate ${name}'s Membership, update their Membership status below`
        },
        facts: {
            icon: 'fas fa-question',
            title: 'Fun Facts',
            description: `Share some fun facts about ${possessiveSuffix} with their supporters`
        },
        funds: {
            icon: 'fas fa-hands-usd',
            title: 'Membership Balance',
            description: 'Funds earned or given to overcome financial barriers'
        },
        history: {
            icon: 'fas fa-history',
            title: 'Membership History',
            description: `View the timeline of all actions taken to ${possessiveSuffix} membership.`
        },
        lifestory: {
            icon: 'fas fa-book',
            title: 'Life Update',
            description: `Close the loop for supporters by sharing updates or pictures from ${possessiveSuffix} journey`
        },
        member: {
            icon: 'fas fa-user-lock',
            title: 'Member Information',
            description: `${possessiveSuffix} private information will not be shared with users outside of your team / deployment`
        },
        needs: {
            icon: 'fas fa-hands-heart',
            title: 'Needs',
            description: `Keep this list of needs up-to-date so ${possessiveSuffix} supporters can contribute donations to help purchase what’s needed`
        },
        profile: {
            icon: 'fas fa-info',
            title: 'Basic Info',
            description: 'Name and picture to share with supporting samaritans'
        },
        status: {
            icon: 'fas fa-clipboard-list',
            title: 'Status',
            description: !exited
                ? `Save updates throughout ${possessiveSuffix} Membership to track their progress over time`
                : `View ${possessiveSuffix} latest status and their progress throughout their membership`
        }
    };

    const { icon, description } =
        infoMappings[type as keyof typeof infoMappings] || {};

    return (
        <div className={classes.infoContainer}>
            <div className={classes.infoIcon}>
                {icon && <i className={icon} />}
            </div>
            <div className={classes.info}>
                <div className={classes.infoDescription}>
                    <span>{description}</span>
                    {type === 'bonus' && open && (
                        <i className="fas fa-chevron-up" />
                    )}
                    {type === 'bonus' && !open && (
                        <i className="fas fa-chevron-down" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default InfoCard;
