import Card from 'components/_shared/Card';
import { CTAButton1 } from 'components/_shared/buttons';

import { DeploymentOrgs, DeploymentUsers } from 'types';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonText: {
            '& i': {
                fontSize: '14px',
                marginRight: '8px'
            }
        },
        card: {
            position: 'relative',
            margin: '12px !important',
            width: '90%'
        },
        editButton: {
            background: '#FFFFFF',
            borderRadius: '100px',
            boxShadow: '0px 8px 16px 4px #0000000D',
            minHeight: '32px',
            minWidth: '32px !important',
            '& i': {
                fontSize: '14px',
                color: '#2B3165'
            }
        },
        error: {
            background: '#F5577A !important'
        },
        label: {
            position: 'absolute',
            left: 0,
            top: '32px',
            background: '#E7EFFE',
            borderRadius: '0 6px 6px 0',
            color: '#062D7A',
            fontWeight: 600,
            padding: '4px 12px',
            '& i': {
                marginRight: '8px'
            }
        },
        managerButton: {
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#FFFFFF',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            // boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#999DFF',
            fontSize: '14px',
            fontWeight: 600,
            marginBottom: '8px',
            padding: '16px',
            textTransform: 'none',
            width: '100%'
        },
        managerDetail: {
            display: 'flex',
            flexDirection: 'column'
        },
        managerName: {
            fontWeight: 600
        },
        managerOrg: {
            color: '#5A6A81',
            fontSize: '14px',
            fontWeight: 400
        },
        note: {
            fontSize: '14px',
            fontWeight: 300,
            padding: '0 16px 16px 16px'
        },
        noteContainer: {
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #E3E3FA',
            borderRadius: '8px',
            width: '100%'
        },
        noteTitle: {
            fontSize: '14px',
            fontWeight: 600,
            '& i': {
                marginRight: '8px'
            }
        },
        noteTitleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 16px 8px 16px'
        },
        org: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: 600,
            padding: '72px 0 32px 0'
        },
        orgDetail: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        orgPicture: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#EBEBFF',
            borderRadius: '50px',
            fontSize: '14px',
            height: '48px',
            marginRight: '16px',
            width: '48px',
            '& img': {
                borderRadius: '50%',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        referButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '8px'
        },
        sendButton: {
            position: 'relative',
            top: '26px',
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            borderRadius: '50px',
            boxShadow: '0px 8px 16px 4px #0000000D',
            color: '#FBFBFF',
            fontSize: '20px',
            fontWeight: 700,
            padding: '16px 24px',
            textTransform: 'none',
            width: '85%'
        },
        sendButtonContainer: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #E3E3FA',
            marginTop: '40px',
            width: '108%'
        }
    })
);

type ReferOrgCardProps = {
    isReferringMember: boolean;
    note: string;
    memberID: number;
    referMember: ({
        homeless_id,
        note,
        organization_id,
        referee_id
    }: {
        homeless_id: number;
        note?: string;
        organization_id?: number;
        referee_id?: number;
    }) => Promise<void>;
    referMemberError: boolean | null;
    referralManager: DeploymentUsers | null;
    referralOrg: DeploymentOrgs;
    setLoading: (loading: boolean) => void;
    setManagerPopup: (open: boolean) => void;
    setNotePopUp: (value: boolean) => void;
    setOrgIDs: (orgIDs: number) => void;
    setSuccess: (value: boolean | null) => void;
    setReferMemberPopUp: (value: boolean) => void;
    setReferralManager: (manager: DeploymentUsers | null) => void;
    setReferralOrg: (referral: DeploymentOrgs | null) => void;
    setReferralType: (referralType: string) => void;
    success: boolean | null;
};

const ReferOrgCard: React.FC<ReferOrgCardProps> = ({
    note,
    memberID,
    referMember,
    referMemberError,
    referralManager,
    referralOrg,
    setLoading,
    setManagerPopup,
    setNotePopUp,
    setOrgIDs,
    setSuccess,
    setReferMemberPopUp,
    setReferralManager,
    setReferralOrg,
    setReferralType
}) => {
    const classes = useStyles();

    const { name: managerName, organization_name } = referralManager || {};
    const { id, logo, name } = referralOrg;

    const handleCaseManagerClick = () => {
        setManagerPopup(true);
        setOrgIDs(id);
    };

    const handleEditOrgClick = () => {
        setReferralManager(null);
        setReferralOrg(null);
    };

    const handleEditNoteClick = () => {
        setNotePopUp(true);
    };

    const handleNoteClick = () => {
        setNotePopUp(true);
    };

    const handleReferralClick = async () => {
        const referral = {
            homeless_id: memberID
        } as {
            homeless_id: number;
            note?: string;
            organization_id?: number;
            referee_id?: number;
        };

        if (note.length > 0) {
            referral.note = note;
        }

        if (referralManager) {
            setReferralType('manager');
            referral.referee_id = referralManager.id;
        } else {
            setReferralType('org');
            referral.organization_id = id;
        }

        setLoading(true);

        try {
            await referMember(referral);

            if (referMemberError) {
                setLoading(false);
                setSuccess(null);
                return;
            }

            setLoading(false);
            setSuccess(true);

            setTimeout(() => {
                setLoading(false);
                setReferMemberPopUp(false);
                setSuccess(null);
            }, 2000);
        } catch (error) {
            console.error('Error sending referral:', error);
            setLoading(false);
            setSuccess(null);
        }
    };

    const renderReferral = () => {
        if (referralManager) {
            return (
                <div className={classes.orgDetail}>
                    <span className={classes.orgPicture}>
                        <img src={logo} alt="org-logo" />
                    </span>
                    <div className={classes.managerDetail}>
                        <span className={classes.managerName}>
                            {managerName}
                        </span>
                        <span className={classes.managerOrg}>
                            {organization_name}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.orgDetail}>
                <span className={classes.orgPicture}>
                    <img src={logo} alt="org-logo" />
                </span>
                <span>{name}</span>
            </div>
        );
    };

    const renderNote = () => {
        if (note.length > 0) {
            return (
                <div className={classes.noteContainer}>
                    <div className={classes.noteTitleContainer}>
                        <span className={classes.noteTitle}>
                            <i className="far fa-sticky-note" /> Additional
                            Notes:
                        </span>
                        <Button
                            className={classes.editButton}
                            onClick={handleEditNoteClick}
                        >
                            <i className="far fa-pencil" />
                        </Button>
                    </div>
                    <span className={classes.note}>{note}</span>
                </div>
            );
        } else {
            return (
                <Button
                    className={classes.managerButton}
                    onClick={handleNoteClick}
                >
                    <span className={classes.buttonText}>
                        <i className="far fa-sticky-note" /> Add Additional
                        Notes
                    </span>
                    <i className="far fa-plus" style={{ fontSize: '18px' }} />
                </Button>
            );
        }
    };

    return (
        <>
            <Card className={classes.card}>
                <span className={classes.label}>
                    <i className="fas fa-users" />
                    Sending referral to:
                </span>
                <div className={classes.org}>
                    {renderReferral()}
                    <Button
                        className={classes.editButton}
                        onClick={handleEditOrgClick}
                    >
                        <i className="far fa-pencil" />
                    </Button>
                </div>
                <div className={classes.referButtonContainer}>
                    {!referralManager && (
                        <Button
                            className={classes.managerButton}
                            onClick={handleCaseManagerClick}
                        >
                            <span className={classes.buttonText}>
                                <i className="far fa-user" /> Specify a case
                                manager
                            </span>
                            <i
                                className="far fa-plus"
                                style={{ fontSize: '18px' }}
                            />
                        </Button>
                    )}
                    {renderNote()}
                </div>
            </Card>
            <div className={classes.sendButtonContainer}>
                <CTAButton1
                    className={classes.sendButton}
                    onClick={handleReferralClick}
                >
                    {referralManager ? 'Send Referral' : 'Send Referrals'}
                </CTAButton1>
            </div>
        </>
    );
};

export default ReferOrgCard;
