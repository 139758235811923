import IntakeButton from './IntakeButton';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    saveBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '15px',
        '& button': {
            margin: '0',
            cursor: 'pointer',
            fontFamily: 'Manrope',
            fontSize: '16px',
            fontWeight: '800'
        },
        '& #skill-skip': {
            background: 'transparent',
            color: '#7378E8'
        }
    }
}));

type SaveSkipButtonsProps = {
    disabled: boolean;
    handleSaveClick: () => void;
    handleSkipClick: () => void;
    noSkip?: boolean;
};

const SaveSkipButtons: React.FC<SaveSkipButtonsProps> = ({
    disabled,
    handleSaveClick,
    handleSkipClick,
    noSkip
}) => {
    const classes = useStyles();

    return (
        <div
            className={classes.saveBtnContainer}
            style={noSkip ? { justifyContent: 'flex-end' } : {}}
        >
            {!noSkip && (
                <IntakeButton
                    handleButtonClick={() => handleSkipClick()}
                    id={'skip'}
                    text={'Skip'}
                />
            )}
            <IntakeButton
                disabled={disabled}
                handleButtonClick={() => handleSaveClick()}
                id={'save'}
                text={'Save'}
            />
        </div>
    );
};

export default SaveSkipButtons;
