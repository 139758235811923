import { useState } from 'react';

import Card from '../../Card';

import { renderCardType } from './utils';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        cardStyle: {
            margin: '0px !important'
        },
        content: {
            borderTop: '1px solid #E3E3FA',
            margin: 0,
            padding: '16px 0'
        },
        rulesContainer: {},
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& i': {
                color: '#7378E8',
                fontSize: '24px'
            }
        }
    })
);

type CardRulesProps = {
    debitType: 'givecard' | 'pex' | null;
    onClose: () => void;
};

const CardRules: React.FC<CardRulesProps> = ({ debitType, onClose }) => {
    const classes = useStyles();

    // to use .yml file
    const rules = [
        {
            content:
                'Use your card exclusively for necessary expenses such as groceries, utilities, and transportation. Avoid non-essential purchases to maintain financial discipline.',
            title: 'Essential Purchases Only'
        },
        {
            content:
                'Cash advances or ATM withdrawals are not permitted with this card. This rule helps avoid high fees and interest rates associated with cash advances.',
            title: 'No Cash Advances'
        },
        {
            content:
                'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            title: 'No Alcohol or Tobacco Products'
        },
        {
            content:
                'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            title: 'Daily Spending Limit'
        }
        // {
        //     content:
        //         'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     title: 'Daily Spending Limit'
        // },
        // {
        //     content:
        //         'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     title: 'Daily Spending Limit'
        // }
    ];

    const [open, setOpen] = useState<number[]>([]);

    const handleOpenRules = (index: number) => {
        if (open.includes(index)) {
            setOpen(open.filter((i) => i !== index));
        } else {
            setOpen([...open, index]);
        }
    };

    return (
        <>
            <div className={classes.titleContainer}>
                <h2>{renderCardType(debitType)} Rules & Restrictions</h2>
                <Button onClick={onClose}>
                    <i className="fas fa-times" />
                </Button>
            </div>
            {rules.map((rule, index) => (
                <Card
                    key={index}
                    active
                    className={classes.cardStyle}
                    onClick={() => handleOpenRules(index)}
                >
                    <div className={classes.rulesContainer}>
                        <div className={classes.titleContainer}>
                            <h2>{rule.title}</h2>
                            <i
                                className={`fas fa-chevron-${
                                    open.includes(index) ? 'up' : 'down'
                                }`}
                            />
                        </div>
                        {open.includes(index) && (
                            <p className={classes.content}>{rule.content}</p>
                        )}
                    </div>
                </Card>
            ))}
        </>
    );
};

export default CardRules;
