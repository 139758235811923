import { useCallback, useState } from 'react';

import AddButton from '../../../CaseManagement/components/AddButton';
import Card from '../../../CaseManagement/components/Card';
import DocumentItemm from './components/DocumentItem';
import LoadingCircle from '../../../../../_shared/LoadingCircle';
import UploadModal from './components/UploadModal';

import { deleteDocument } from '../../../../../../api/documents';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    documentStorageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    }
}));

type Props = {
    documentList: object[] | null;
    exited: boolean;
    fetchDocs: () => void;
    holder: string;
    loading: boolean;
};

const DocumentStorage: React.FC<Props> = ({
    documentList,
    exited,
    fetchDocs,
    holder,
    loading
}) => {
    const classes = useStyles();

    const [showUpload, setShowUpload] = useState<boolean>(false);

    const deleteFile = useCallback(
        (fileId: number) => {
            deleteDocument(fileId).then(() => {
                fetchDocs();
            });
        },
        [fetchDocs]
    );

    const handleDocumentClick = () => {
        setShowUpload(true);
    };

    const onceUploaded = useCallback(() => {
        fetchDocs();
        setShowUpload(false);
    }, [fetchDocs]);

    return (
        <div className={classes.documentStorageContainer}>
            <Card info documents name={holder} />
            {loading && <LoadingCircle />}
            {documentList &&
                documentList.map((doc: any, index: number) => (
                    <DocumentItemm
                        key={index}
                        deleteFile={deleteFile}
                        doc={doc}
                    />
                ))}
            {!exited && (
                <AddButton action={handleDocumentClick} type="document" />
            )}
            {showUpload && (
                <UploadModal open={showUpload} onClose={onceUploaded} />
            )}
        </div>
    );
};

export default DocumentStorage;
