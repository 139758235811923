import PropTypes from 'prop-types';

import Header from './components/Header';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    indicator: {
        width: '30px',
        height: '10px',
        marginRight: '10px',
        borderRadius: '8px',
        backgroundColor: '#7279E8'
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 20px',
        borderBottom: '1px solid #E5E5E5',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F5F5F5'
        },
        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    messageSender: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    message: {
        fontSize: '14px',
        lineHeight: '22px',
        color: '#1F1F51',
        padding: '10px 0',
        textOverflow: 'ellipsis'
    },
    messageList: {
        overflow: 'auto',
        minHeight: 'calc(100vh - 500px)',
        maxHeight: 'calc(100vh - 800px)'
    },
    senderName: {
        fontWeight: '800',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#1F1F51'
    },
    senderDate: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '22px',
        color: '#A5A6BE'
    },
    chatInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start !important'
    },
    emptyContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#1F2152',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '700',
        padding: '20px 0',
        margin: '10px 0'
    }
}));

const ChatRooms = ({
    chatRooms,
    closeView,
    isFetchingChatRooms,
    LoadingCircle,
    viewUserMessage
}) => {
    const classes = useStyles();

    const renderChatRooms = () => {
        if (isFetchingChatRooms) {
            return (
                <div style={{ padding: '20px' }}>
                    <LoadingCircle />
                </div>
            );
        }

        if (chatRooms.length === 0) {
            return (
                <div className={classes.emptyContainer}>
                    <span>No Messages Received Yet</span>
                </div>
            );
        } else {
            return chatRooms.map(
                (message) =>
                    message.recipient.type === 'User' && (
                        <div
                            className={classes.messageContainer}
                            key={message.last_message.id}
                            onClick={() => viewUserMessage(message.recipient)}
                        >
                            {message.last_message.message.sender_type ===
                                'User' &&
                            message.last_message.message.read === false ? (
                                <span className={classes.indicator} />
                            ) : null}
                            <div className={classes.chatInfo}>
                                <div className={classes.messageSender}>
                                    <span className={classes.senderName}>
                                        {message.recipient.name}
                                    </span>
                                    <span className={classes.senderDate}>
                                        {renderTime(message.last_message.date)}
                                    </span>
                                </div>
                                <div className={classes.message}>
                                    <span>{message.last_message.message}</span>
                                </div>
                            </div>
                        </div>
                    )
            );
        }
    };

    const renderTime = (date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const d = new Date(date);
        const time = d.toLocaleTimeString([], { timeStyle: `short` });

        const formatter = new Intl.DateTimeFormat('en', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        if (d.toDateString() === today.toDateString()) {
            return time;
        } else if (d.toDateString() === yesterday.toDateString()) {
            return `Yesterday`;
        } else {
            return formatter.format(d);
        }
    };

    return (
        <>
            <Header closeView={closeView} />
            <div className={classes.messageList}>{renderChatRooms()}</div>
        </>
    );
};

ChatRooms.propTypes = {
    chatRooms: PropTypes.array.isRequired,
    closeView: PropTypes.func.isRequired,
    viewUserMessage: PropTypes.func.isRequired
};

export default ChatRooms;
