import AuthManager from '../auth';

import formatRedemptionFormAnswers from '../util/formatRedemptionFormAnswers';
import SamaritanFetch from './httpClient';

export const fetchRedemptionItems = () =>
    SamaritanFetch.v2('/redemption_templates');

export const fetchRedemptionDetails = (id: string) =>
    SamaritanFetch.v2(`/redemption_templates/${id}`);

export const postRedemption = (
    beaconId: string,
    formAnswers: { amount: number }
) => {
    const userId = AuthManager.getInstance().getUserID();

    const { amount, ...restFormAnswers } = formAnswers;

    try {
        const body = {
            merchant_id: userId,
            amount,
            description: formatRedemptionFormAnswers(restFormAnswers)
        };
        return SamaritanFetch.v2.post(
            `/homelesses/${beaconId}/payments/redeem`,
            {
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
    } catch (e) {
        return Promise.reject(e);
    }
};

export const fetchRedemptionHistoryForHomeless = (id: number, page = 1) =>
    SamaritanFetch.v3('/beacon_redemptions', {
        query: {
            page,
            per_page: 100,
            beacon_redemptions: {
                homeless_id: [id]
            }
        }
    });
