import { useEffect, useState } from 'react';

import AssignCard from './AssignCard';
import AssignCardPopUp from '../AssignCardPopUp';
import PopUp from '../../PopUp';
import PopUp2 from 'components/_shared/PopUp2';
import { CardClose, CardRules } from 'components/_shared/PopUp2/components';

import {
    DetailButton,
    ListButton
} from 'components/holder_profile/profile_2.0/Funds/DebitCard/Buttons';

import {
    DebitCard,
    DebitCardOptions,
    Homeless,
    Needs,
    PexHistory
} from 'types';

import History from '../History';
import WalletCard from './WalletCard';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        autoTransferDebitContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '32px'
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '32px'
        },
        rulesContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
            color: '#739BE8',
            fontSize: '16px',
            fontWeight: 700,
            width: '100%'
        },
        walletCardContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '64px',
            width: '100%',
            '@media (max-width: 1200px)': {
                flexDirection: 'column',
                gap: '32px'
            }
        }
    })
);

type AutoTransferDebitProps = {
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    debitType: 'givecard' | 'pex' | null;
    fullCardHistory: PexHistory[];
    getDebitCardHistory: (
        id: number,
        type: string,
        fullHistory?: boolean
    ) => any;
    holder: Homeless;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
    needs: Needs[];
    recentCardHistory: PexHistory[];
    setDebitType: (type: 'givecard' | 'pex' | null) => void;
    setFullCardHistory: (history: any) => void;
    setPopUp: (popUp: boolean) => void;
    setRecentCardHistory: (history: any) => void;
};

const AutoTransferDebit: React.FC<AutoTransferDebitProps> = ({
    debitCardOptions,
    debitInfo,
    debitType,
    fullCardHistory,
    getDebitCardHistory,
    holder,
    isFetchingDebitHistory,
    isFetchingDebitInfo,
    needs,
    recentCardHistory,
    setDebitType,
    setFullCardHistory,
    setPopUp: setBalancePopUp,
    setRecentCardHistory
}) => {
    const classes = useStyles();

    const { balance, id, photo } = holder;
    const { account_id, available_balance, card_last4, card_type } =
        debitInfo || {
            account_id: null,
            available_balance: 0,
            card_last4: '',
            card_type: ''
        };

    const [assignPopUp, setAssignPopUp] = useState<boolean>(false);
    const [listButtonContent, setListButtonContent] = useState<string>('');
    const [listButtonPopUp, setListButtonPopUp] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState(isFetchingDebitHistory);
    const [popUp, setPopUp] = useState<boolean>(false);
    const [replaceCardView, setReplaceCardView] = useState<boolean>(false);
    const [updatePinView, setUpdatePinView] = useState<boolean>(false);

    const handleListButtonClose = () => {
        setListButtonContent('');
        setListButtonPopUp(false);
    };

    const handleListButtonOpen = (content: string) => {
        setBalancePopUp(false);
        setListButtonContent(content);
        setListButtonPopUp(true);
    };

    const handleReplaceCardClick = () => {
        if (debitCardOptions.length <= 1) {
            setPopUp(true);
        } else {
            setAssignPopUp(true);
        }

        setReplaceCardView(true);
        setUpdatePinView(false);
    };

    const handleUpdatePinClick = () => {
        setPopUp(true);
        setReplaceCardView(false);
        setUpdatePinView(true);
    };

    const popUpProps = {
        balance,
        debitCardOptions,
        debitInfo,
        debitType,
        fullCardHistory,
        holder,
        isFetchingDebitHistory,
        needs,
        recentCardHistory,
        replaceCardView,
        setDebitType,
        setFullCardHistory,
        setRecentCardHistory,
        updatePinView
    };

    const renderAutoTransferDebit = () => {
        if (!account_id) {
            return <AssignCard assignCard={setPopUp} />;
        }

        return (
            <>
                <div className={classes.walletCardContainer}>
                    <WalletCard
                        available_balance={available_balance || 0}
                        debitType={debitType}
                        id={id}
                        isFetchingDebitInfo={isFetchingDebitInfo}
                        last4={card_last4!}
                    />
                    <div className={classes.buttonContainer}>
                        <DetailButton
                            handleClick={handleUpdatePinClick}
                            icon={`far fa-lock-alt`}
                            label={`Change PIN`}
                        />
                        <DetailButton
                            handleClick={handleReplaceCardClick}
                            icon={`far fa-sync`}
                            label={`Replace Card`}
                        />
                    </div>
                </div>
                <div className={classes.rulesContainer}>
                    {/* <ListButton
                        icon="book-open-reader"
                        onClick={() => handleListButtonOpen('rules')}
                    >
                        Card Rules & Restrictions
                    </ListButton> */}
                    <ListButton
                        icon="times-circle"
                        onClick={() => handleListButtonOpen('close')}
                    >
                        Close Card
                    </ListButton>
                </div>
                <History
                    card
                    expanded={expanded}
                    fullHistoryItems={fullCardHistory}
                    historyItems={recentCardHistory}
                    loading={loading}
                    setExpanded={setExpanded}
                />
            </>
        );
    };

    useEffect(() => {
        if (card_type && expanded && fullCardHistory.length === 0) {
            setLoading(true);

            getDebitCardHistory(id, card_type, true)
                .then((data: any) => {
                    setFullCardHistory(data.payload);
                })
                .catch((error: any) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [
        card_type,
        expanded,
        fullCardHistory,
        getDebitCardHistory,
        id,
        setFullCardHistory
    ]);

    useEffect(() => {
        if (isFetchingDebitHistory) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetchingDebitHistory]);

    return (
        <div className={classes.autoTransferDebitContainer}>
            {renderAutoTransferDebit()}
            {assignPopUp && (
                <AssignCardPopUp
                    debitCardOptions={debitCardOptions}
                    handleModalClick={() => setAssignPopUp(false)}
                    photo={photo}
                    setAssignPopUp={setAssignPopUp}
                    setDebitType={setDebitType}
                    setReplaceView={setPopUp}
                />
            )}
            {popUp && (
                <PopUp
                    content="debit card"
                    handleModalClick={() => setPopUp(false)}
                    {...popUpProps}
                />
            )}
            {listButtonContent.length && listButtonPopUp && !popUp && (
                <PopUp2
                    customClasses={
                        listButtonContent === 'rules'
                            ? { modalContent: { overflow: 'reset' } }
                            : {}
                    }
                    debitInfo={debitInfo}
                    onClose={handleListButtonClose}
                    open={listButtonPopUp}
                    title={listButtonContent}
                >
                    {listButtonContent === 'rules' ? (
                        <CardRules
                            debitType={debitType}
                            onClose={handleListButtonClose}
                        />
                    ) : (
                        <CardClose
                            holder={holder}
                            onClose={handleListButtonClose}
                        />
                    )}
                </PopUp2>
            )}
        </div>
    );
};

export default AutoTransferDebit;
