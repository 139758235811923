import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: '16px'
    },
    overlay1: {
        background:
            'linear-gradient(0deg, #000 0%, #000 45%, rgba(0, 0, 0, 0.00) 100%)',
        opacity: 0.5
    },
    overlay2: {
        background:
            'linear-gradient(0deg, #454981 0%, #454981 36%, rgba(69, 73, 129, 0.50) 89.99%, rgba(69, 73, 129, 0.50) 100%)',
        opacity: 0.66
    },
    overlay3: {
        background: 'linear-gradient(0deg, #252946 0%, #252946 100%)',
        opacity: 0.4,
        mixBlendMode: 'multiply'
    },
    overlay4: {
        background:
            'linear-gradient(0deg, rgba(234, 239, 243, 0.00) 0%, rgba(234, 239, 243, 0.00) 14.99%, #EAEFF3 100%)',
        opacity: 0.6,
        mixBlendMode: 'soft-light'
    }
}));

const MainGradient = () => {
    const classes = useStyles();

    return (
        <>
            <span className={`${classes.overlay} ${classes.overlay1}`} />
            <span className={`${classes.overlay} ${classes.overlay2}`} />
            <span className={`${classes.overlay} ${classes.overlay3}`} />
            <span className={`${classes.overlay} ${classes.overlay4}`} />
        </>
    );
};

export default MainGradient;
