import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    pinBox: {
        width: '60px',
        height: '60px',
        background: '#FFFFFF',
        boxShadow: '0px 8px 16px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#A6A6BF'
    },
    pinContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        width: '100%'
    },
    pinDot: {
        fontSize: '36px',
        fontWeight: '500',
        lineHeight: '44px',
        color: '#7378E8'
    },
    pin_view_component: {
        margin: 'auto'
    }
}));

type PINViewProps = {
    nums: string;
};

const PINView = ({ nums }: PINViewProps) => {
    const classes = useStyles();

    const pin = [];

    const renderPin = () => {
        const numsArr = [`X`, `X`, `X`, `X`];

        const displayPin = numsArr.map((num, i) => {
            if (nums[i]) {
                num = nums[i];
                pin.push(num);
            }

            return (
                <div className={classes.pinBox} key={i}>
                    {num !== `X` ? (
                        <div className={classes.pinDot}>•</div>
                    ) : (
                        `X`
                    )}
                </div>
            );
        });

        return displayPin;
    };

    return (
        <div className="pin_view_component">
            <span className={classes.pinContainer}>{renderPin()}</span>
        </div>
    );
};

export default PINView;
