import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        btn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#EDF2FC',
            border: 'none',
            borderRadius: '100%',
            color: '#739BE8',
            fontSize: '20px',
            height: '60px',
            width: '60px'
        },
        btnLabel: {
            color: '#739BE8',
            fontSize: '16px',
            fontWeight: 700,
            paddingTop: '15px',
            textAlign: 'center'
        }
    })
);

type DetailButtonProps = {
    handleClick: () => void;
    icon: string;
    label: string;
};

export const DetailButton: React.FC<DetailButtonProps> = ({
    handleClick,
    icon,
    label
}) => {
    const classes = useStyles();

    return (
        <div className={classes.buttonContainer}>
            <Button className={classes.btn} onClick={handleClick}>
                <i className={icon} />
            </Button>
            <span className={classes.btnLabel}>{label}</span>
        </div>
    );
};

export default DetailButton;
