import Card from '../../CaseManagement/components/Card';
import QuestionCard from './components/QuestionCard';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    supplementalQuestions: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    questions: {
        display: 'flex',
        overflowX: 'auto',
        padding: '0px 16px 24px 16px',
        gap: '24px'
    }
}));

type Props = {
    answers: [
        { answer: string; created_at: string; touchpoint_question_id: number }
    ];
    id: number;
    name: string;
    questions: { id: number; question: string }[];
};

const SupplementalQuestions: React.FC<Props> = ({
    answers,
    id,
    name,
    questions
}) => {
    const classes = useStyles();

    return (
        <div className={classes.supplementalQuestions}>
            <Card info facts name={name} />
            {questions && questions.length > 0 && (
                <div className={classes.questions}>
                    {questions.map((question, index: number) => (
                        <QuestionCard
                            answers={answers}
                            homelessID={id}
                            key={index}
                            prompt={question.question}
                            questionID={question.id}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SupplementalQuestions;
