import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        ellipse: {
            position: 'absolute',
            backgroundColor: '#999DFF',
            borderRadius: '50%',
            filter: 'blur(200px)',
            opacity: 0.5
        }
    })
);

type EllipseProps = {
    position: 'top-left' | 'bottom-right';
};

const Ellipse: React.FC<EllipseProps> = ({ position }) => {
    const classes = useStyles();

    const isTopLeft = position === 'top-left';

    // need to adjust the left values account for screen size
    const ellipseStyles = {
        height: isTopLeft ? 598 : 557,
        ...(isTopLeft
            ? { top: 'calc(50% - (598px/2 + 579px))' }
            : { bottom: -393 }),
        left: isTopLeft
            ? 'calc((50% - 570px/2) - 708px)'
            : 'calc(50% - 619px/2 + 707.5px)',
        width: isTopLeft ? 570 : 619
    };

    return <div className={classes.ellipse} style={ellipseStyles} />;
};

export default Ellipse;
