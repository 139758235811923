import { captureMessage, captureException } from "@sentry/react";
import qs from "qs";

import AuthManager from "../auth";
import { SAMARITAN_URL } from "../settings";

const querySerializer = (query: object) => {
    const str = qs.stringify(query, { encode: false, arrayFormat: 'brackets' });
    // custom replace for brackets
    return str.replace(/={}/g, '{}');
};

const v2 = (url: string, { query = {}, ...options } = { query: {} }) => {
    const token = AuthManager.getInstance().getToken();

    const queryString = querySerializer(query);

    if (queryString) {
        url = `${url}?${queryString}`;
    }

    if (url.includes('?')) {
        url = `${url}&SESSION_TOKEN=${token}`;
    } else {
        url = `${url}?SESSION_TOKEN=${token}`;
    }

    // if no leading slash, add it
    if (url[0] !== '/') {
        url = `/${url}`;
    }

    url = `${SAMARITAN_URL}/api/v2${url}`;

    return fetch(url, options).catch((error) => {
        console.error('Failed to fetch v2 error:', error);
    });
};

interface RequestOptions {
    city?: string;
    headers?: Record<string, string>;
    query?: Record<string, string>;
}

const v3 = async (
    url: string,
    { query = {}, ...options }: RequestOptions = {}
) => {
    const apiToken = AuthManager.getInstance().getApiToken();
    const city = options.city;

    const queryString = querySerializer(query);

    if (queryString) {
        url = `${url}?${queryString}`;
    }

    // if no leading slash, add it
    if (url[0] !== '/') {
        url = `/${url}`;
    }

    if (city && city !== 'www') {
        url = `https://${city}.samaritan.city/api/v3${url}`;
    } else if (city === 'www') {
        url = `https://www.samaritan.city/api/v3${url}`;
    } else {
        url = `${SAMARITAN_URL}/api/v3${url}`;
    }

    return fetch(url, {
        ...options,
        headers: {
            Authorization: `Token token=${apiToken}`,
            ...(options ? options.headers : {})
        }
    }).catch((error) => {
        console.error('Failed to fetch v3 error:', error);
    });
};

const decorateWithHelperMethods = (fn: any) => {
    // TODO: fix fn type of any
    // TODO: fix options type of any
    fn.get = (url: string, options: any) =>
        fn(url, { method: 'GET', ...options });
    fn.post = (url: string, options: any) =>
        fn(url, { method: 'POST', ...options });
    fn.put = (url: string, options: any) =>
        fn(url, { method: 'PUT', ...options });
    fn.delete = (url: string, options: any) =>
        fn(url, { method: 'DELETE', ...options });
    return fn;
};

/**
 * decorator function wraps fetch requests for error reporting.
 *
 * reports warning when `ok != true`.
 * reports error when fetch fails.
 *
 */
const reportErrors = (fn: any) => {
    // TODO: fix fn type of any
    // TODO: fix args type of any
    return async (...args: any) => {
        try {
            const res = await fn(...args);

            if (res && !res.ok) {
                // json a clone of the response or else an error is
                // thrown downstream
                const resBody = await res
                    .clone()
                    .json()
                    .catch(() => null);

                captureMessage(res.statusText, {
                    // level: 'warning',
                    extra: {
                        request: {
                            url: args[0],
                            options: args[1]
                        },
                        response: res,
                        'response body': resBody
                    }
                });
            }

            return res;
        } catch (e) {
            captureException(e);
            throw e;
        }
    };
};

const SamaritanFetch = {
  v2: decorateWithHelperMethods(reportErrors(v2)),
  v3: decorateWithHelperMethods(reportErrors(v3)),
};

export default SamaritanFetch;
