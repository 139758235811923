import { useRef } from 'react';
import ReactGA from 'react-ga4';

import { ProfileItems } from 'types';

import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    completeItemsContainer: {
        position: 'absolute',
        top: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontWeight: 700,
        gap: '10px',
        background: '#999DFF',
        borderRadius: '0 40px 40px 0',
        color: '#FFFFFF',
        cursor: 'pointer',
        marginRight: '20px',
        minWidth: '480px',
        padding: '12px 20px 12px 20%',
        '&:hover': {
            background: '#062D7A',
            transition: 'background 0.5s ease'
        }
    },
    itemLabel: {
        background: '#FFFFFF',
        borderRadius: '100px',
        color: '#999DFF',
        padding: '0px 10px'
    }
}));

type CompleteProfileItemsProps = {
    profileItems: ProfileItems;
    setCompleteProfilePopup: (value: boolean) => void;
};

const CompleteProfileItems: React.FC<CompleteProfileItemsProps> = ({
    profileItems,
    setCompleteProfilePopup
}) => {
    const classes = useStyles();
    const nodeRef = useRef(null);

    const items = Object.keys(profileItems).filter(
        (item) => profileItems[item].status === 'incomplete'
    );

    const handleCompleteProfileClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Complete Profile Click'
        });

        setCompleteProfilePopup(true);
    };

    const renderItemCount = () => {
        if (items.length === 1) {
            return `1 item`;
        } else if (items.length > 1) {
            return `${items.length} items`;
        } else {
            return `0 items`;
        }
    };

    return (
        <ButtonBase
            className={classes.completeItemsContainer}
            component="div"
            onClick={handleCompleteProfileClick}
            ref={nodeRef}
        >
            <span>Complete Profile</span>
            <span className={classes.itemLabel}>{renderItemCount()}</span>
            <i className="fas fa-chevron-right" />
        </ButtonBase>
    );
};

export default CompleteProfileItems;
