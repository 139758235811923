import CONSTANTS from "../constants/constants";

import { SupplementalQuestionAnswers, TouchpointAnswers } from 'types';

const initialState = {
    improvedStatusCount: null,
    improvedStatus: [],
    isFetchingSupplementalQuestionAnswers: false,
    isFetchingTouchpointAnswers: false,
    isFetchingTouchpointAnswersById: false,
    isSubmittingStatusCapture: false,
    isSubmittingSupplementalQuestionAnswer: false,
    isSubmittingSupplementalQuestionAnswerSuccess: false,
    isSubmittingTouchpointAnswers: false,
    isUpdatingTouchpointAnswers: false,
    supplementalQuestionAnswers: null,
    touchpointAnswers: null,
    touchpointAnswersById: [],
    previousAnswers: [],
    updatedAnswerIds: [],
    updatedQuestionIds: []
} as {
    improvedStatusCount: number | null;
    improvedStatus: any[];
    isFetchingSupplementalQuestionAnswers: boolean;
    isFetchingTouchpointAnswers: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingStatusCapture: boolean;
    isSubmittingTouchpointAnswers: boolean;
    isUpdatingTouchpointAnswers: boolean;
    supplementalQuestionAnswers: SupplementalQuestionAnswers[] | null;
    touchpointAnswers: TouchpointAnswers[] | null;
    touchpointAnswersById: TouchpointAnswers[];
    previousAnswers: TouchpointAnswers[];
    updatedAnswerIds: number[];
    updatedQuestionIds: number[];
};

export default function touchpoint_answer_reducer(
    state = initialState,
    action: any
) {
    switch (action.type) {
        case CONSTANTS.GET_TOUCHPOINT_ANSWERS:
            return {
                ...state,
                isFetchingTouchpointAnswers: true
            };
        case CONSTANTS.GET_TOUCHPOINT_ANSWERS_BY_ID:
            return {
                ...state,
                isFetchingTouchpointAnswersById: true
            };
        case CONSTANTS.GET_SUPPLEMENTAL_QUESTION_ANSWERS:
            return {
                ...state,
                isFetchingSupplementalQuestionAnswers: true
            };
        case CONSTANTS.SET_SUPPLEMENTAL_QUESTION_ANSWERS:
            return {
                ...state,
                isFetchingSupplementalQuestionAnswers: false,
                supplementalQuestionAnswers: state.supplementalQuestionAnswers
                    ? [...state.supplementalQuestionAnswers, ...action.payload]
                    : action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_ANSWERS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    isFetchingTouchpointAnswers: false,
                    touchpointAnswers: null
                };
            }

            return {
                ...state,
                isFetchingTouchpointAnswers: false,
                touchpointAnswers: action.payload
            };
        case CONSTANTS.SET_TOUCHPOINT_ANSWERS_BY_ID:
            return {
                ...state,
                isFetchingTouchpointAnswersById: false,
                touchpointAnswersById: action.payload
            };
        case CONSTANTS.UPDATE_SUPPLEMENTAL_QUESTION_ANSWERS:
            const index = state.supplementalQuestionAnswers
                ? state.supplementalQuestionAnswers.findIndex(
                      (answer) =>
                          answer.touchpoint_question_id ===
                          action.payload.touchpoint_question_id
                  )
                : -1;

            if (index === -1) {
                return {
                    ...state,
                    supplementalQuestionAnswers: [
                        ...(state.supplementalQuestionAnswers || []),
                        action.payload
                    ],
                    isSubmittingSupplementalQuestionAnswer: false,
                    isSubmittingSupplementalQuestionAnswerSuccess: true
                };
            } else {
                return {
                    ...state,
                    supplementalQuestionAnswers:
                        state.supplementalQuestionAnswers
                            ? state.supplementalQuestionAnswers.map(
                                  (answer) => {
                                      if (
                                          answer.touchpoint_question_id ===
                                          action.payload.touchpoint_question_id
                                      ) {
                                          return action.payload;
                                      } else {
                                          return answer;
                                      }
                                  }
                              )
                            : null,
                    isSubmittingSupplementalQuestionAnswer: false,
                    isSubmittingSupplementalQuestionAnswerSuccess: true
                };
            }
        case CONSTANTS.UPDATE_TOUCHPOINT_ANSWERS:
            let { answers } = action.payload;
            const { touchpointAnswers } = state;

            // Ensure answers is always an array, whether it's one answer or multiple
            answers = Array.isArray(answers) ? answers : [answers];

            const questionIds = answers.map(
                (answer: {
                    answer: string;
                    quality_of_life_measure_id?: number;
                    question?: { quality_of_life_measure_id: number };
                }) =>
                    answer.question
                        ? answer.question.quality_of_life_measure_id
                        : answer.quality_of_life_measure_id
            );
            const answerIds = answers.map(
                (answer: {
                    answer: string;
                    id: number;
                    question: { quality_of_life_measure_id: number };
                }) => answer.id
            );

            // Update touchpointAnswers array based on matching quality_of_life_measure_id
            const updatedTouchpointAnswers =
                touchpointAnswers?.map((touchpointAnswer) => {
                    const matchingAnswerIndex = answers.findIndex(
                        (answer: {
                            answer: string;
                            quality_of_life_measure_id?: number;
                            question: { quality_of_life_measure_id: number };
                        }) =>
                            answer.question
                                ? answer.question.quality_of_life_measure_id ===
                                  touchpointAnswer.quality_of_life_measure_id
                                : answer.quality_of_life_measure_id ===
                                  touchpointAnswer.quality_of_life_measure_id
                    );

                    if (matchingAnswerIndex !== -1) {
                        return {
                            ...touchpointAnswer,
                            answers: [
                                answers[matchingAnswerIndex],
                                ...(Array.isArray(touchpointAnswer.answers)
                                    ? touchpointAnswer.answers
                                    : [touchpointAnswer])
                            ]
                        };
                    }

                    return touchpointAnswer; // No match, return unchanged
                }) || [];

            // Adding new touchpointAnswer if no match was found and it's a valid answer
            const newAnswersToAdd = answers
                .filter(
                    (answer: {
                        answer: string;
                        quality_of_life_measure_id?: number;
                        question: { quality_of_life_measure_id: number };
                    }) =>
                        !touchpointAnswers?.some((touchpointAnswer) =>
                            answer.question
                                ? touchpointAnswer.quality_of_life_measure_id ===
                                  answer.question.quality_of_life_measure_id
                                : touchpointAnswer.quality_of_life_measure_id ===
                                  answer.quality_of_life_measure_id
                        )
                )
                .map(
                    (answer: {
                        answer: string;
                        quality_of_life_measure_id?: number;
                        question: { quality_of_life_measure_id: number };
                    }) => ({
                        quality_of_life_measure_id: answer.question
                            ? answer.question.quality_of_life_measure_id
                            : answer.quality_of_life_measure_id,
                        answers: [answer]
                    })
                );

            // Combine updated touchpointAnswers with any new answers that need to be added
            const finalTouchpointAnswers = [
                ...updatedTouchpointAnswers,
                ...newAnswersToAdd
            ];

            return {
                ...state,
                isUpdatingTouchpointAnswers: false,
                isSubmittingTouchpointAnswers: false,
                isSubmittingStatusCapture: false,
                touchpointAnswers: finalTouchpointAnswers,
                updatedAnswerIds: [...state.updatedAnswerIds, ...answerIds],
                updatedQuestionIds: [
                    ...state.updatedQuestionIds,
                    ...questionIds
                ],
                previousAnswers: [
                    ...state.previousAnswers,
                    ...(touchpointAnswers
                        ? touchpointAnswers.map((touchpointAnswer) =>
                              touchpointAnswer.answers
                                  ? touchpointAnswer.answers[0]
                                  : touchpointAnswer
                          )
                        : [])
                ]
            };
        case CONSTANTS.SET_TOUCHPOINT_STATUS:
            return {
                ...state,
                isUpdatingTouchpointAnswers: false,
                touchpointAnswersById: action.payload
            };
        case CONSTANTS.SET_IMPROVED_TOUCHPOINT_STATUS:
            const improvedTouchpointAnswer = state.touchpointAnswers
                ? state.touchpointAnswers.map((answer) => {
                      if (
                          answer.touchpoint_question_id ===
                          action.payload.answer.touchpoint_question_id
                      ) {
                          return {
                              ...answer,
                              answers: answer.answers.map((matchingAnswer) => {
                                  if (
                                      matchingAnswer.id ===
                                      action.payload.answer.id
                                  ) {
                                      return {
                                          ...matchingAnswer,
                                          question_type: 'StatusQuestion',
                                          classified_statuses:
                                              action.payload.answer
                                                  .classified_statuses
                                      };
                                  }

                                  return matchingAnswer;
                              })
                          };
                      }

                      return answer;
                  })
                : [];

            const improvedStatusIndex = state.improvedStatus.findIndex(
                (status) =>
                    status.answer.id === action.payload.answer.id &&
                    status.oldAnswer.id === action.payload.oldAnswer.id
            );

            if (improvedStatusIndex !== -1) {
                return {
                    ...state,
                    touchpointAnswers: improvedTouchpointAnswer
                };
            } else {
                return {
                    ...state,
                    improvedStatus: [...state.improvedStatus, action.payload],
                    improvedStatusCount: state.improvedStatusCount
                        ? state.improvedStatusCount + 1
                        : 1,
                    touchpointAnswers: improvedTouchpointAnswer
                };
            }
        case CONSTANTS.SET_REDUCED_TOUCHPOINT_STATUS:
            const reducedTouchpointAnswer = state.touchpointAnswers
                ? state.touchpointAnswers.map((answer) => {
                      if (
                          answer.touchpoint_question_id ===
                          action.payload.touchpoint_question_id
                      ) {
                          return {
                              ...answer,
                              answers: answer.answers.map((matchingAnswer) => {
                                  if (matchingAnswer.id === action.payload.id) {
                                      return {
                                          ...matchingAnswer,
                                          question_type: 'StatusQuestion',
                                          classified_statuses:
                                              action.payload.classified_statuses
                                      };
                                  }

                                  return matchingAnswer;
                              })
                          };
                      }

                      return answer;
                  })
                : [];

            return {
                ...state,
                touchpointAnswers: reducedTouchpointAnswer
            };
        case CONSTANTS.INCREMENT_IMPROVED_STATUS_COUNT:
            return {
                ...state,
                improvedStatusCount: state.improvedStatusCount
                    ? state.improvedStatusCount + 1
                    : 1
            };
        case CONSTANTS.DECREMENT_IMPROVED_STATUS_COUNT:
            const removedImprovedStatusAnswerId =
                state.improvedStatus[0]?.answer.id;
            const removedImprovedStatusQuestionId =
                state.improvedStatus[0]?.oldAnswer.quality_of_life_measure_id;

            return {
                ...state,
                improvedStatus: state.improvedStatus.filter(
                    (status) =>
                        status.answer.id !== removedImprovedStatusAnswerId
                ),
                improvedStatusCount: state.improvedStatusCount
                    ? state.improvedStatusCount - 1
                    : 0,
                updatedAnswerIds: state.updatedAnswerIds.filter(
                    (id) => id !== removedImprovedStatusAnswerId
                ),
                updatedQuestionIds: state.updatedQuestionIds.filter(
                    (id) => id !== removedImprovedStatusQuestionId
                )
            };
        case CONSTANTS.SUBMIT_TOUCHPOINT_ANSWERS:
            return {
                ...state,
                isSubmittingTouchpointAnswers: true
            };
        case CONSTANTS.SUBMIT_STATUS_CAPTURE:
            return {
                ...state,
                isSubmittingStatusCapture: true
            };
        case CONSTANTS.SUBMITTING_SUPPLEMENTAL_QUESTION_ANSWER:
            return {
                ...state,
                isSubmittingSupplementalQuestionAnswer: true,
                isSubmittingSupplementalQuestionAnswerSuccess: false
            };
        case CONSTANTS.RESET_TOUCHPOINT_ANSWERS:
            return initialState;
        case CONSTANTS.RESET:
            return initialState;
        default:
            return state;
    }
}
