import { useRef } from 'react';
import ReactGA from 'react-ga4';

import SideNav from '../side_nav';

import classes from './index.module.scss';

type DrawerNavProps = {
    email: string;
    last_name: string;
    name: string;
};

export default function DrawerNav({ email, last_name, name }: DrawerNavProps) {
    const drawerRef = useRef<HTMLDivElement | null>(null);

    const blurDrawer = () => {
        if (drawerRef.current) {
            drawerRef.current.blur();
        }
    };

    const onHamburgerClick = () => {
        if (drawerRef.current) {
            drawerRef.current.focus();
        }

        ReactGA.send({
            hitType: 'event',
            eventCategory: 'Navigation',
            eventAction: 'Open Hambuger Menu',
            eventLabel: 'Hamburger Menu Opened'
        });
    };

    return (
        <>
            <div
                className={classes.hamburger}
                tabIndex={0}
                onClick={onHamburgerClick}
                aria-label="Open Navigation Drawer"
                role="button"
            >
                <i className="fas fa-bars" />
            </div>
            <div
                ref={drawerRef}
                className={classes.drawerNavContainer}
                tabIndex={0}
                data-testid="drawerSideNavContainer"
            >
                <div
                    className={classes.drawerNavChevron}
                    onClick={blurDrawer}
                    aria-label="Close Navigation Drawer"
                    role="button"
                >
                    <i className="far fa-chevron-left" />
                </div>
                <div className={classes.drawerNav}>
                    <SideNav email={email} name={name} last_name={last_name} />
                </div>
                <div
                    className={classes.drawerNavBackground}
                    onClick={blurDrawer}
                />
            </div>
        </>
    );
}
