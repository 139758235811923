import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';

import { resetCatchUpItemStatus } from 'redux/actions/catch_up_items';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            alignSelf: 'flex-end',
            borderRadius: '40px',
            border: '1.6px solid #E3E3FA',
            color: '#1F2152',
            backgroundColor: '#FFFFFF',
            minHeight: '38.5px',
            minWidth: '0px',
            padding: '6px 12px',
            fontWeight: 500,
            textTransform: 'none',
            zIndex: 3,
            '& i': {
                marginRight: '10px'
            },
            '@media (max-width: 768px)': {
                fontSize: '12px',
                padding: '6px 12px'
            }
        }
    })
);

type CatchUpProps = {
    setQuickCatchUp: (value: boolean) => void;
    setShowCatchUp: (value: boolean) => void;
};

const CatchUp: React.FC<CatchUpProps> = ({
    setQuickCatchUp,
    setShowCatchUp
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleReferClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Quick Catch-Up Click'
        });

        dispatch(resetCatchUpItemStatus());
        setQuickCatchUp(true);
        setShowCatchUp(true);
    };

    return (
        <Button className={classes.button} onClick={handleReferClick}>
            <i className="far fa-bolt" />
            Quick Catch-Up
        </Button>
    );
};

export default CatchUp;
